import React from 'react';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import { Results } from '@amzn/amazon-devicequalification-ui-results/dist/index.js';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

const styles = theme => ({
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: 1,
    left: 1,
    zIndex: 1
  },
  command: {
    height: 130,
    width: 450,
    textAlign: 'center'
  },
  popmessage: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '100%',
    flexShrink: 0,
    marginTop: 30
  },
  filterContainer: {
    position: 'relative',
    width: '100%'
  }
});

class ResultsWrapper extends React.Component {
  render() {
    if (isEmpty(this.props.auth.session)) {
      //This logic gets invoked if the user is directly trying to access the 
      //Results page through URL. If the session is not there, the page will be redirected.
      return <Redirect to="/" />
    }
    console.log(JSON.stringify(this.props.auth));
    return (<div><Results environment={this.props.auth.environment} session={this.props.auth.session} /></div>);
  }
}

ResultsWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object
};

export default compose(connect(state => state), withStyles(styles, { params: {} }))(ResultsWrapper);
import {
  Spinner,
  Tabs
} from "@amzn/awsui-components-react";
import get from "lodash/get";
import isNil from "lodash/isNil"
import { ACMScenarios } from '@amzn/amazon-devicequalification-ui-oakrun/dist/index.js';
import React, {useEffect, useState } from "react";
import { CardContainer, InfoContainer } from "./InfoContainer";
import TestProgressTable from "./TestProgressTable"
import {TEST_PROGRESS_COLUMNS} from "./TestProgressTableConfig"
import "./styles.css";

function ACMTestInfo({ testDetails }) {
  useEffect(() => {
    console.log(testDetails);
  }, []);
  const panesCount = 2;
  let panes = [];
  for (let i = 0; i < testDetails.length; i += testDetails.length / 2)
    panes.push(testDetails.slice(i, i + testDetails.length / 2));
  return (
    <div>
      <InfoContainer name="Test Info" panes={panes} />
    </div>
  );
}

function ACMDevicesInfo({ deviceMapping }) {
  let deviceDef = {
    header: (item) => `Host Name: ${item.deviceTag}`,
    sections: [
      {
        id: "dsn",
        header: "Device Serial No",
        content: (item) => item.dsn,
      },
    ],
  };
  let devicesList = [];
  Object.entries(deviceMapping).forEach(([deviceTag, device]) => {
    devicesList.push({ ...device, deviceTag: deviceTag });
  });
  return (
    <div className="">
      <CardContainer deviceDef={deviceDef} devices={devicesList} />
    </div>
  );
}

function getTestInfo(request) {
 
  const options = JSON.parse(request.options);
  const features = options.testOptions.features;
  let scenarioNamesList = [];
  if (!isNil(features)) {
    features.map((feature) => {
      let name = ACMScenarios.TESTID_DEVICE_MAP[feature] && ACMScenarios.TESTID_DEVICE_MAP[feature].scenario
      scenarioNamesList.push(name || feature);
    });
  }

  let ctsModulesPlanned = {};
  let fosTestSuiteModulesSelected = {};
  let planned = {"Number of test cases" : "360"}

  if(options["testSuiteDisplayName"] ==  "CTS") {
    ctsModulesPlanned = getModulesPlannedForCTS(options)
    planned = ctsModulesPlanned
  }
  else if(options["testSuiteDisplayName"] ==  "FOS") {
    fosTestSuiteModulesSelected = getTestSuiteModulesSelectedForFOS(options)
    planned = fosTestSuiteModulesSelected
  }
  
   return [
    { Login: request.ownerEmail },
    { "Lab Name": request.labName },
    { "Test Suite": options.testSuiteDisplayName },
    planned
  ];
}

function getDevicesInfo(request) {
  return JSON.parse(request.mapping).deviceMapping;
}

function getModulesPlannedForCTS(options){
  let testCustomizations = options["testOptions"]["ftvPayload"]["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]
  let ctsModulesPlanned;
  //TODO - https://issues.labcollab.net/browse/PTI-1001 : Use module count sent by plugin
  if(testCustomizations["include-modules"]){
     ctsModulesPlanned = {"Modules included" : testCustomizations["include-modules"].length}
  } else if (testCustomizations["exclude-modules"]){
    ctsModulesPlanned = {"Modules excluded" : 322 - testCustomizations["exclude-modules"].length}
  } else {   
      //hard-coding number of modules 
      //TODO : we need a way to get total modules queued based on OS version
      ctsModulesPlanned = {"Modules to be executed" : "322"}
  }
  return ctsModulesPlanned;
}

function getTestSuiteModulesSelectedForFOS(options){
  let standard = options["testOptions"]["ftvPayload"]["TestRunDetails"]["TestCasesList"]["fos_platform"]["Standard"]
  let fosTestSuiteModulesSelected;
  if(standard["testCustomizations"]["testSuite"]) {
    fosTestSuiteModulesSelected = {"Test Suite Selected" : standard["testCustomizations"]["testSuite"]}
  }
  else{
    fosTestSuiteModulesSelected = {"Test Suite Selected" : standard["testSuite"]}
  }
  return fosTestSuiteModulesSelected;
}

function getTestStatus(data, testSuite, options) {
  let ftvPayload = get(data, "clientUpdates");
  let items = [];
  let tableContent = {}
  if(!isNil(ftvPayload)){
    Object.keys(ftvPayload["status_count"]).forEach((status) => {
        tableContent[status] = ftvPayload["status_count"][status]
    })
    if(testSuite == "FTV") {
      tableContent["in_progress"] = ftvPayload["in_progress"] ? ftvPayload["in_progress"] : ""
      tableContent["eta"] = "70 hours"
    } else if(testSuite == "CTS") {
      delete tableContent["in_progress"]
      tableContent["total"] = Object.values(getModulesPlannedForCTS(options))[0]
      //TODO : The initial update sent by plugin would contain the wrong eta.
      //Task to skip displaying the eta for the first time : https://issues.labcollab.net/browse/PTI-898
      tableContent["eta"] = ftvPayload["total_eta_in_min"] + " minutes"
    }
    items.push(tableContent)
  }

  return items;
}


function getStatusUpdate(data) {
  let statusUpdate = ""
  let ftvPayload = get(data, "clientUpdates");
  if(!isNil(ftvPayload)){
    statusUpdate = ftvPayload["notification_message"]
  }
  return statusUpdate;
}

function ACMLiveRun({ request, data }) {
  
  const options = JSON.parse(request.options);
  const [testStatus, setTestStatus] = useState("STARTING");
  
  useEffect(() => {
    const defaultStatus = get(data, "testStatus.status", "STARTING");
    setTestStatus(defaultStatus);
  }, [data]);

  const tableHeader = options.testSuiteDisplayName == "CTS" ? "Module Progress" : "Test Progress";  

  return (
    <div>
      <Tabs
        tabs={[
          {
            label: "Test Info",
            id: "testInfo",
            content: <ACMTestInfo testDetails={getTestInfo(request)} />
          },
          {
            label: "Device Info",
            id: "deviceInfo",
            content: <ACMDevicesInfo deviceMapping={getDevicesInfo(request)} />,
          },
        ]}
        activeTabId="testInfo"
      ></Tabs>

      {/* //TODO : Do not show spinner after test execution starts -  https://issues.labcollab.net/browse/PTI-891 */}
      {testStatus !== "SUCCEEDED" && (
        <div className="awsui-util-t-c">
          <div>
            <Spinner size="large"></Spinner>
          </div>
          <div>{testStatus.replace("_", " ")}</div>
        </div>
      )}

      {testStatus === "SUCCEEDED" && (
        <div className="awsui-util-status-info awsui-util-font-size-4 awsui-util-t-c">
          Test Completed
        </div>
      )}
      
       <div>
            <TestProgressTable
            columnDefinitions={options.testSuiteDisplayName == "FTV" ? TEST_PROGRESS_COLUMNS : TEST_PROGRESS_COLUMNS.slice(1)}
            items={getTestStatus(data, options.testSuiteDisplayName, options)}
            header={tableHeader}/>
        </div>
        <br></br>
        <div className="awsui-util-status-info awsui-util-font-size-3 awsui-util-t-c">
        {getStatusUpdate(data)}
        </div>
    </div>

  );
}

export default React.memo(ACMLiveRun);
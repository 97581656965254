import { createSlice, nanoid } from '@reduxjs/toolkit'
import { LOCALE_LIST } from '../../../constants';

const isValidJSON = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

function validateJsonSchema(data, schema, requiredKeys, isList) {
  if (typeof data !== 'object' || (isList && !Array.isArray(data)) || data == null) {
      console.log("info is invalid.");
      return;
  }
  for (let i=0;i<data.length;i++) {
      let object = data[i];
      if(!(requiredKeys.every((i) => object.hasOwnProperty(i)))){
          console.log("info is invalid since some keys are missing");
          return;
      }
      var errors = Object.keys(schema).filter(function (key) {
        return !schema[key](object[key]);
      })
      return errors;
  }
}

// TODO: Check if below string values could be inferred from intl
export const validate = (state) => {
  const error = {};
  if(!state) {
    throw new Error("Please pass valid state information")
  }

  const {locale, enableEmailNotification, customizedScenarios, scenarioType, 
      isDryRun, dryRunBundleUrl, dryRunTestMetadata, isRetryConfigured, retryTimes, retryTypes} = state;

  if(!state.name || !state.name.trim() || state.name.match(/\W/g)) {
    error['name'] = 'Name cannot be empty or have special characters';
  }

  if(state.testrailRunId && !state.testrailRunId.match(/^\d+$/)) {
      error['testrailRunId'] = 'Testrail Run ID can contain only numbers';
    }

  if(isDryRun){
    if(!isValidJSON(JSON.stringify(dryRunTestMetadata))) 
      error["dryRunTestMetadata"] = "Please enter test meta data in valid json format";
    else{
      const dryRunTestMetadataRequiredKeys = ['testName', 'testConfig', 'testRailCaseIdList', 
        'testFW', 'testInvoke', 'artefactsOnFail', 'components'];
      const dryRunTestMetadataSchema = {
        testName: function (value) {
          return value && value.trim() && typeof value === 'string';
        },
        testConfig: function (value){
          return value && typeof value === 'object' && value["RebootTime"] && parseInt(value["RebootTime"]);
        },
        testRailCaseIdList: function (value){
          return value && typeof value === 'object' && Array.isArray(value) && value.filter((key, index) => !/^\d+$/.test(key)).length === 0
        },
        testFW: function (value) {
          return value && typeof value === 'string';
        },
        testInvoke: function (value) {
          return value || typeof value === 'string';
        },
        artefactsOnFail: function (value){
          return value && typeof value == 'object' && Array.isArray(value) && value.filter((key, index) => typeof key !== 'string').length === 0
        },
        components: function (value) {
          return value && typeof value === 'string';
        },
      };
      const jsonValidationErrors = validateJsonSchema(dryRunTestMetadata, dryRunTestMetadataSchema, dryRunTestMetadataRequiredKeys, true);
      if(jsonValidationErrors && Object.keys(jsonValidationErrors).length !== 0){
        error["dryRunTestMetadata"] = "Please enter these fields in proper format - " + jsonValidationErrors;
      }
    }
  }
    
  if(isDryRun && !dryRunBundleUrl){
    error["dryRunBundleUrl"] = "Please enter a valid test package url";
  }

  if(isRetryConfigured) {
    if(retryTimes <= 0 || retryTimes > 20) {
      error["retryTimes"] = "Retry attempts must be between 0 and 20.";
    }
    if(retryTypes.length===0) {
      error["retryTypes"] = "Please choose at least one retry type.";
    }
  }

  if(!locale) {
    error['locale'] = 'Please select a locale';
  }
  if(typeof(enableEmailNotification) !== 'boolean') {
    error['enableEmailNotification'] = 'Enable email notification not selected';
  }

  if(!scenarioType) {
    error['scenarioType'] = "Please select a scenarioType";
  }

  if(scenarioType !== SCENARIO_TYPE.STANDARD && scenarioType !== SCENARIO_TYPE.CUSTOM) {
    error['scenarioType'] = "Please select a valid scenario type";
  }

  return Object.keys(error).length ? error : null;
}


export const SCENARIO_TYPE = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
}


const EMPTY_CUSTOMIZATION = {
  category: null,
  scenarios: []
}

const getInitialState = () => ({
  scenarioType: SCENARIO_TYPE.STANDARD,
  name: null,
  testrailRunId: null,
  locale: LOCALE_LIST[0],
  enableEmailNotification: false,
  error: null,
  customizedScenarios: [
    EMPTY_CUSTOMIZATION
  ],
  isDryRun: false,
  shouldCreateTestrailRun: false,
  testrailSuite: null,
  deviceLocale: null,
  deviceTypeGroup: null,
  dryRunBundleUrl: null,
  dryRunTestMetadata: [{
    "testName": "TestFireTvLauncher",
    "testConfig": {
        "RebootTime": 10
    },
    "testRailCaseIdList": ["237526144"],
    "testFW": "DryRunMozart",
    "testInvoke": "mozart_tests.fire_tv.demos.TestFireTvLauncher",
    "artefactsOnFail": ["screenshot", "uidump", "logcat"],
    "components": "@FireTV-Launcher"
  }],
  isRetryConfigured: true,
  retryTimes: "10",
  retryTypes: ["not_executed", "failed"]
});

export const scenarioSelectionSlice = createSlice({
  name: 'scenearioSelection',
  initialState: getInitialState(),
  reducers: {
    setCategoryAndScenario: (state, action) => {
      const {category, scenarios, index} = action.payload;
      if(state.scenarioType === SCENARIO_TYPE.STANDARD) {
        return;
      }

      const catScenObj = {
        category,
        scenarios
      }
      if(index > -1) {
        state.customizedScenarios[index] = catScenObj
      }
    },
    setLocale: (state, action) => {
      const locale = action.payload;
      state.locale = locale;

    },
    setId: (state, action) => {
      const id = action.payload;
      if(!id) {
        state.id = nanoid();
      } else {
        state.id = id;
      }
    },
    setName: (state, action) => {
      const name = action.payload;
      state.name = name;
    },
    setTestrailRunId: (state, action) => {
      const testrailRunId = action.payload;
      state.testrailRunId = testrailRunId;
    },
    setEnableEmailNotification: (state, action) => {
      const enableEmailNotification = action.payload;
      state.enableEmailNotification = enableEmailNotification;
    },
    setScenarioType: (state, action) => {
      const scenarioType = action.payload;
      const prevScenarioType = state.scenarioType;

      if(scenarioType === SCENARIO_TYPE.STANDARD && prevScenarioType === SCENARIO_TYPE.CUSTOM) {
        state.scenarioType = SCENARIO_TYPE.STANDARD;
        state.customizedScenarios = [
          EMPTY_CUSTOMIZATION
        ];
      }
      else if(scenarioType === SCENARIO_TYPE.CUSTOM) {
        state.scenarioType = SCENARIO_TYPE.CUSTOM;
      }
    },
    removeCategory: (state, action) => {
      const {index} = action.payload;
      if(state.customizedScenarios.length === 1 && index === 0) {
        state.customizedScenarios = [EMPTY_CUSTOMIZATION];
      } else {
        state.customizedScenarios.splice(index, 1);
      }
    },
    addNewCustomization: (state) => {
      state.customizedScenarios.push(EMPTY_CUSTOMIZATION);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsDryRun: (state, action) => {
      state.isDryRun = action.payload;
    },
    setDryRunBundleUrl: (state, action) => {
      state.dryRunBundleUrl = action.payload;
    },
    setDryRunTestMetadata: (state, action) => {
      state.dryRunTestMetadata = JSON.parse(action.payload);
    },
    setIsRetryConfigured: (state, action) => {
      state.isRetryConfigured = action.payload;
    },
    setRetryTimes: (state, action) => {
      state.retryTimes = action.payload;
    },
    setRetryTypes: (state, action) => {
      const retryType = action.payload;
      if (!state.retryTypes.includes(retryType)) {
        state.retryTypes.push(retryType);
      }
    },
    removeRetryTypes: (state, action) => {
      const retryType = action.payload;
      state.retryTypes = state.retryTypes.filter(type => type !== retryType);
    },
    setShouldCreateTestrailRun: (state, action) => {
      state.shouldCreateTestrailRun = action.payload;
    },
    setTestrailSuite: (state, action) => {
      state.testrailSuite = action.payload;
    },
    setDeviceLocale: (state, action) => {
      state.deviceLocale = action.payload;
    },
    setDeviceTypeGroup: (state, action) => {
      state.deviceTypeGroup = action.payload;
    },
    reset: () => getInitialState()
  },
})

export const {
  setCategoryAndScenario,
  removeCategory,
  addNewCustomization,
  setId,
  setName,
  setTestrailRunId,
  setScenarioType,
  setLocale,
  setEnableEmailNotification,
  setIsDryRun,
  setDryRunBundleUrl,
  setDryRunTestMetadata,
  setShouldCreateTestrailRun,
  setTestrailSuite,
  setDeviceLocale,
  setDeviceTypeGroup,
  setError,
  reset,
  setIsRetryConfigured,
  setRetryTimes,
  setRetryTypes,
  removeRetryTypes,
} = scenarioSelectionSlice.actions


export const customizationSelector = (state) => state.acmScenarioSlection.customizedScenarios;
export const scenarioSliceSelector = (state) => state.acmScenarioSlection.scenarioType;
export const scenarioCategoryErrorSelector = (state) => state.acmScenarioSlection.error;
export const testSuiteConfigSelector = (state) => state.acmScenarioSlection;


export default scenarioSelectionSlice.reducer

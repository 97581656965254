import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Table, TableSelection } from '@amzn/awsui-components-react';
import { setSelectedTests, testList } from '../../../stores/newRun/testList/testListSlice';
import { updateUserAccessInfo } from '../../../stores/newRun/userAccessSlice';
import { loadTest } from '../../../stores/newRun/testList/testListMiddleware';
import { setInitiateDeleteTest, setInitiateTestExecution, testListUISelector } from '../../../stores/newRun/testList/testListUiSlice';
import ExecuteTestOperation from './executeTestOperation';
import AddNewTestOperation from './addNewTestOperation';
import DeleteTestOperation from './deleteTestOperation';
import { API_STATUS } from '../../../constants';
import { Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

const TestList = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const allTests = useSelector(testList);
  const { testExecuteStatus } = useSelector(testListUISelector)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateUserAccessInfo({session: props.session, environment: props.environment}));
    Util.setCommonStore(props.session, props.environment);
  }, []);
  const getColumnHeaders = () => {
    return [
      {
        id: "testGroupName",
        header: <FormattedMessage id="TEST_NAME" />,
        cell: e => e.name,
      },
      {
        id: "testSuite",
        header: <FormattedMessage id="TEST_SUITE" />,
        cell: e => e.testSuite.displayName
      },
      {
        id: "locale",
        header: <FormattedMessage id="LOCALE" />,
        cell: e => e.locale
      }
    ]
  }

  const onItemsSelected = (selectedItems) => {
    dispatch(setSelectedTests(selectedItems));
  }

  const onRowClicked = (selectedItem) => {
    dispatch(loadTest(selectedItem.id))
    history.push('/v2/run/create');
  }

  const onDelete = () => {
    dispatch(setInitiateDeleteTest(true))
  }

  const onExecuteTests = () => {
    dispatch(setInitiateTestExecution(true))
  }

  const onAddtest = () => {
    history.push('/v2/run/create')
  }

  const selectedItems = allTests.filter(test=>test.isSelected);

  return (
    <div>
      <div className="testExecutionList">
        <Table
          columnDefinitions={getColumnHeaders()}
          items={allTests}
          onRowClick={e=>onRowClicked(e.detail.item)}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>
                  <FormattedMessage id="TESTS" />
                  {" "}
                  <span id="custom-action-stripe-counter" className="awsui-util-header-counter">({selectedItems.length})</span>
                </h2>
              </div>
              <div className="awsui-util-action-stripe-group">

                <Button
                  disabled={selectedItems.length <= 0}
                  onClick={onDelete}>
                  <FormattedMessage id="DELETE" />
                </Button>
                <Button
                  disabled={selectedItems.length <= 0}
                  variant="primary"
                  onClick={onExecuteTests}
                  loading={testExecuteStatus === API_STATUS.PENDING}
                  >
                  <FormattedMessage id="EXECUTE_TESTS" />
                </Button>
                {
                  allTests.length > 0 && <Button onClick={onAddtest} variant="primary"><FormattedMessage id="ADD_NEW_TEST" /></Button>
                }
              </div>
            </div>
          }
          loading={false}
          loadingText={intl.formatMessage({id: "LOADING_RESOURCES"})}
          empty={
            <div className="awsui-util-t-c">
              <p className="awsui-util-mb-s"> <FormattedMessage id="NO_TESTS_TO_SHOW" /> </p>
              <div className="awsui-util-mb-m">
                <Button variant="primary" onClick={onAddtest}>
                  <FormattedMessage id="ADD_NEW_TEST" />
                </Button>
              </div>
            </div>
          }
        >
          <TableSelection
            onSelectionChange = {e=>onItemsSelected(e.detail.selectedItems)}
            selectedItems={selectedItems}
            trackBy="id"
          />
        </Table>
      </div>
      <ExecuteTestOperation />
      <AddNewTestOperation />
      <DeleteTestOperation />
    </div>
  );
}

export default TestList;

const MANIFEST_BASE_URL = "https://dtzsgmefzdeac.cloudfront.net/ManifestFiles/";

/**
 * Acoustic Manifest Files
 */
// TODO: Rename this to AQT_Common instead of Acoustic_Common.
const ACOUSTIC_COMMON_RESOURCE_MANIFEST_FILE = 'Acoustic_Common_audioFiles_manifest.json';
const ACOUSTIC_COMMON_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Common/20220922/" + ACOUSTIC_COMMON_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_DE_DE_RESOURCE_MANIFEST_FILE = 'Acoustic_de_DE_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_DE_DE_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/de_DE/20220426/" + ACOUSTIC_DE_DE_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_EN_AU_RESOURCE_MANIFEST_FILE = 'Acoustic_en_AU_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_EN_AU_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/en_AU/20230208/" + ACOUSTIC_EN_AU_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_EN_GB_RESOURCE_MANIFEST_FILE = 'Acoustic_en_GB_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_EN_GB_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/en_GB/20220426/" + ACOUSTIC_EN_GB_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_EN_IN_RESOURCE_MANIFEST_FILE = 'Acoustic_en_IN_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_EN_IN_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/en_IN/20220426/" + ACOUSTIC_EN_IN_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_EN_US_RESOURCE_MANIFEST_FILE = 'Acoustic_en_US_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/en_US/20220922/" + ACOUSTIC_EN_US_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_ES_ES_RESOURCE_MANIFEST_FILE = 'Acoustic_es_ES_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_ES_ES_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/es_ES/20220908/" + ACOUSTIC_ES_ES_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_ES_MX_RESOURCE_MANIFEST_FILE = 'Acoustic_es_MX_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_ES_MX_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/es_MX/20220426/" + ACOUSTIC_ES_MX_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_ES_US_RESOURCE_MANIFEST_FILE = 'Acoustic_es_US_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_ES_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/es_US/20220426/" + ACOUSTIC_ES_US_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_FR_CA_RESOURCE_MANIFEST_FILE = 'Acoustic_fr_CA_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_FR_CA_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/fr_CA/20220506/" + ACOUSTIC_FR_CA_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_FR_FR_RESOURCE_MANIFEST_FILE = 'Acoustic_fr_FR_audioFiles_manifest.json';
const ACOUSTIC_FR_FR_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/fr_FR/20220902/" + ACOUSTIC_FR_FR_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_HI_IN_RESOURCE_MANIFEST_FILE = 'Acoustic_hi_IN_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_HI_IN_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/hi_IN/20220708/" + ACOUSTIC_HI_IN_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_IT_IT_RESOURCE_MANIFEST_FILE = 'Acoustic_it_IT_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_IT_IT_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/it_IT/20220426/" + ACOUSTIC_IT_IT_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_JA_JP_RESOURCE_MANIFEST_FILE = 'Acoustic_ja_JP_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_JA_JP_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/ja_JP/20220426/" + ACOUSTIC_JA_JP_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_PT_BR_RESOURCE_MANIFEST_FILE = 'Acoustic_pt_BR_Near_Field_audioFiles_manifest.json';
const ACOUSTIC_PT_BR_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/pt_BR/20220426/" + ACOUSTIC_PT_BR_RESOURCE_MANIFEST_FILE;

const ACOUSTIC_AR_SA_RESOURCE_MANIFEST_FILE = 'Acoustic_ar_SA_Near_Field_audioFiles_manifest.json';

const ACOUSTIC_AR_SA_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Acoustic/Near_Field/ar_SA/20220426/" + ACOUSTIC_AR_SA_RESOURCE_MANIFEST_FILE;


/**
 * Far Manifest Files
 */
const FAR_DE_DE_RESOURCE_MANIFEST_FILE = 'Acoustic_de_DE_FAR_audioFiles_manifest.json';
const FAR_DE_DE_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/de_DE/20221001/" + FAR_DE_DE_RESOURCE_MANIFEST_FILE;

const FAR_EN_AU_RESOURCE_MANIFEST_FILE = 'Acoustic_en_AU_FAR_audioFiles_manifest.json';
const FAR_EN_AU_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/en_AU/20221001/" + FAR_EN_AU_RESOURCE_MANIFEST_FILE;

const FAR_EN_CA_RESOURCE_MANIFEST_FILE = 'Acoustic_en_CA_FAR_audioFiles_manifest.json';
const FAR_EN_CA_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/en_CA/20221001/" + FAR_EN_CA_RESOURCE_MANIFEST_FILE;

const FAR_EN_GB_RESOURCE_MANIFEST_FILE = 'Acoustic_en_GB_FAR_audioFiles_manifest.json';
const FAR_EN_GB_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/en_GB/20221001/" + FAR_EN_GB_RESOURCE_MANIFEST_FILE;

const FAR_EN_IN_RESOURCE_MANIFEST_FILE = 'Acoustic_en_IN_FAR_audioFiles_manifest.json';
const FAR_EN_IN_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/en_IN/20221001/" + FAR_EN_IN_RESOURCE_MANIFEST_FILE;

const FAR_EN_US_RESOURCE_MANIFEST_FILE = 'Acoustic_en_US_FAR_audioFiles_manifest.json';
const FAR_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/en_US/20221001/" + FAR_EN_US_RESOURCE_MANIFEST_FILE;

const FAR_ES_ES_RESOURCE_MANIFEST_FILE = 'Acoustic_es_ES_FAR_audioFiles_manifest.json';
const FAR_ES_ES_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/es_ES/20221001/" + FAR_ES_ES_RESOURCE_MANIFEST_FILE;

const FAR_ES_MX_RESOURCE_MANIFEST_FILE = 'Acoustic_es_MX_FAR_audioFiles_manifest.json';
const FAR_ES_MX_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/es_MX/20221001/" + FAR_ES_MX_RESOURCE_MANIFEST_FILE;

const FAR_ES_US_RESOURCE_MANIFEST_FILE = 'Acoustic_es_US_FAR_audioFiles_manifest.json';
const FAR_ES_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/es_US/20221001/" + FAR_ES_US_RESOURCE_MANIFEST_FILE;

const FAR_FR_CA_RESOURCE_MANIFEST_FILE = 'Acoustic_fr_CA_FAR_audioFiles_manifest.json';
const FAR_FR_CA_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/fr_CA/20221001/" + FAR_FR_CA_RESOURCE_MANIFEST_FILE;

const FAR_FR_FR_RESOURCE_MANIFEST_FILE = 'Acoustic_fr_FR_FAR_audioFiles_manifest.json';
const FAR_FR_FR_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/fr_FR/20221001/" + FAR_FR_FR_RESOURCE_MANIFEST_FILE;

const FAR_HI_IN_RESOURCE_MANIFEST_FILE = 'Acoustic_hi_IN_FAR_audioFiles_manifest.json';
const FAR_HI_IN_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/hi_IN/20221001/" + FAR_HI_IN_RESOURCE_MANIFEST_FILE;

const FAR_IT_IT_RESOURCE_MANIFEST_FILE = 'Acoustic_it_IT_FAR_audioFiles_manifest.json';
const FAR_IT_IT_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/it_IT/20221001/" + FAR_IT_IT_RESOURCE_MANIFEST_FILE;

const FAR_JA_JP_RESOURCE_MANIFEST_FILE = 'Acoustic_ja_JP_FAR_audioFiles_manifest.json';
const FAR_JA_JP_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/ja_JP/20221001/" + FAR_JA_JP_RESOURCE_MANIFEST_FILE;

const FAR_PT_BR_RESOURCE_MANIFEST_FILE = 'Acoustic_pt_BR_FAR_audioFiles_manifest.json';
const FAR_PT_BR_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/pt_BR/20221001/" + FAR_PT_BR_RESOURCE_MANIFEST_FILE;

const FAR_AR_SA_RESOURCE_MANIFEST_FILE = 'Acoustic_ar_SA_FAR_audioFiles_manifest.json';
const FAR_AR_SA_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Far/ar_SA/20221001/" + FAR_AR_SA_RESOURCE_MANIFEST_FILE;

/**
 * Functional Manifest Files
 */
const FUNCTIONAL_EN_US_RESOURCE_MANIFEST_FILE = 'Functional_en_US_audioFiles_manifest.json';
const FUNCTIONAL_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Functional/en_US/20200707/" + FUNCTIONAL_EN_US_RESOURCE_MANIFEST_FILE;

/**
 * Automotive Functional Manifest Files
 */
const FUNCTIONAL_AUTOMOTIVE_EN_US_RESOURCE_MANIFEST_FILE = 'Functional_Automotive_en_US_audioFiles_manifest.json';
const FUNCTIONAL_AUTOMOTIVE_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "AutoFunctional/en_US/20220628/" + FUNCTIONAL_AUTOMOTIVE_EN_US_RESOURCE_MANIFEST_FILE;

/**
 * Automotive Navigation Functional Manifest Files
 */
const FUNCTIONAL_AUTOMOTIVE_NAV_EN_US_RESOURCE_MANIFEST_FILE = 'Automotive_Navigation_audioFiles_manifest.json';
const FUNCTIONAL_AUTOMOTIVE_NAV_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "AutomotiveNavigationFunctional/en_US/20220802" + FUNCTIONAL_AUTOMOTIVE_NAV_EN_US_RESOURCE_MANIFEST_FILE;

/**
 * Music Manifest Files
 */
const MUSIC_DE_DE_RESOURCE_MANIFEST_FILE = 'Music_de_DE_audioFiles_manifest.json';
const MUSIC_DE_DE_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Music/de_DE/20200517/" + MUSIC_DE_DE_RESOURCE_MANIFEST_FILE;

const MUSIC_EN_GB_RESOURCE_MANIFEST_FILE = 'Music_en_GB_audioFiles_manifest.json';
const MUSIC_EN_GB_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Music/en_GB/20200517/" + MUSIC_EN_GB_RESOURCE_MANIFEST_FILE;

const MUSIC_EN_US_RESOURCE_MANIFEST_FILE = 'Music_en_US_audioFiles_manifest.json';
const MUSIC_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Music/en_US/20221027/" + MUSIC_EN_US_RESOURCE_MANIFEST_FILE;

const MUSIC_JA_JP_RESOURCE_MANIFEST_FILE = 'Music_ja_JP_audioFiles_manifest.json';
const MUSIC_JA_JP_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Music/ja_JP/20201110/" + MUSIC_JA_JP_RESOURCE_MANIFEST_FILE;

/**
 * Auto Local Search Files
 */
const AUTO_LOCAL_SEARCH_GOOGLE_EN_US_RESOURCE_MANIFEST_FILE = 'AutoLocalSearch_Google_en_US_audioFiles_manifest.json';
const AUTO_LOCAL_SEARCH_GOOGLE_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "AutoLocalSearch/20200430/" + AUTO_LOCAL_SEARCH_GOOGLE_EN_US_RESOURCE_MANIFEST_FILE;

const AUTO_LOCAL_SEARCH_SIRI_EN_US_RESOURCE_MANIFEST_FILE = 'AutoLocalSearch_Siri_en_US_audioFiles_manifest.json';
const AUTO_LOCAL_SEARCH_SIRI_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "AutoLocalSearch/20200430/" + AUTO_LOCAL_SEARCH_SIRI_EN_US_RESOURCE_MANIFEST_FILE;

/**
 * Stability Manifest Files
 */
const STABILITY_EN_US_RESOURCE_MANIFEST_FILE = 'Stability_en_US_audioFiles_manifest.json';
const STABILITY_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Stability/en_US/20201209/" + STABILITY_EN_US_RESOURCE_MANIFEST_FILE;

/**
 * UPL Manifest Files
 */
const UPL_EN_US_RESOURCE_MANIFEST_FILE = 'UPL_en_US_audioFiles_manifest.json';
const UPL_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "UPL/en_US/20200921/" + UPL_EN_US_RESOURCE_MANIFEST_FILE;

/**
 * Qual Test Manifest Files
 */
const QUAL_COMMON_RESOURCE_MANIFEST_FILE = 'Qual_Common_audioFiles_manifest.json';
const QUAL_COMMON_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Qual/Common/20200819/" + QUAL_COMMON_RESOURCE_MANIFEST_FILE;

const Qual_EN_US_RESOURCE_MANIFEST_FILE = 'Qual_en_US_audioFiles_manifest.json';
const Qual_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Qual/en_US/20200817/" + Qual_EN_US_RESOURCE_MANIFEST_FILE;

const SECURITY_EN_US_RESOURCE_MANIFEST_FILE = 'AVSSecurityTools_en_US_audioFiles_manifest.json';
const SECURITY_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Security/en_US/" + SECURITY_EN_US_RESOURCE_MANIFEST_FILE;


/**
 * Mobile Manifest Files
 */

const MOBILE_COMMON_RESOURCE_MANIFEST_FILE = 'Mobile_Common_audioFiles_manifest.json';
const MOBILE_COMMON_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/Common/20210217/" + MOBILE_COMMON_RESOURCE_MANIFEST_FILE;

const MOBILE_DE_DE_RESOURCE_MANIFEST_FILE = 'Mobile_de_DE_audioFiles_manifest.json';
const MOBILE_DE_DE_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/de_DE/20210217/" + MOBILE_DE_DE_RESOURCE_MANIFEST_FILE;

const MOBILE_EN_AU_RESOURCE_MANIFEST_FILE = 'Mobile_en_AU_audioFiles_manifest.json';
const MOBILE_EN_AU_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/en_AU/20210217/" + MOBILE_EN_AU_RESOURCE_MANIFEST_FILE;

const MOBILE_EN_GB_RESOURCE_MANIFEST_FILE = 'Mobile_en_GB_audioFiles_manifest.json';
const MOBILE_EN_GB_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/en_GB/20210217/" + MOBILE_EN_GB_RESOURCE_MANIFEST_FILE;

const MOBILE_EN_IN_RESOURCE_MANIFEST_FILE = 'Mobile_en_IN_audioFiles_manifest.json';
const MOBILE_EN_IN_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/en_IN/20210217/" + MOBILE_EN_IN_RESOURCE_MANIFEST_FILE;

const MOBILE_EN_US_RESOURCE_MANIFEST_FILE = 'Mobile_en_US_audioFiles_manifest.json';
const MOBILE_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/en_US/20210217/" + MOBILE_EN_US_RESOURCE_MANIFEST_FILE;

const MOBILE_ES_ES_RESOURCE_MANIFEST_FILE = 'Mobile_es_ES_audioFiles_manifest.json';
const MOBILE_ES_ES_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/es_ES/20210217/" + MOBILE_ES_ES_RESOURCE_MANIFEST_FILE;

const MOBILE_ES_MX_RESOURCE_MANIFEST_FILE = 'Mobile_es_MX_audioFiles_manifest.json';
const MOBILE_ES_MX_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/es_MX/20210217/" + MOBILE_ES_MX_RESOURCE_MANIFEST_FILE;

const MOBILE_ES_US_RESOURCE_MANIFEST_FILE = 'Mobile_es_US_audioFiles_manifest.json';
const MOBILE_ES_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/es_US/20210217/" + MOBILE_ES_US_RESOURCE_MANIFEST_FILE;

const MOBILE_FR_CA_RESOURCE_MANIFEST_FILE = 'Mobile_fr_CA_audioFiles_manifest.json';
const MOBILE_FR_CA_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/fr_CA/20210217/" + MOBILE_FR_CA_RESOURCE_MANIFEST_FILE;

const MOBILE_FR_FR_RESOURCE_MANIFEST_FILE = 'Mobile_fr_FR_audioFiles_manifest.json';
const MOBILE_FR_FR_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/fr_FR/20210217/" + MOBILE_FR_FR_RESOURCE_MANIFEST_FILE;

const MOBILE_HI_IN_RESOURCE_MANIFEST_FILE = 'Mobile_hi_IN_audioFiles_manifest.json';
const MOBILE_HI_IN_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/hi_IN/20210217/" + MOBILE_HI_IN_RESOURCE_MANIFEST_FILE;

const MOBILE_IT_IT_RESOURCE_MANIFEST_FILE = 'Mobile_it_IT_audioFiles_manifest.json';
const MOBILE_IT_IT_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/it_IT/20210217/" + MOBILE_IT_IT_RESOURCE_MANIFEST_FILE;

const MOBILE_PT_BR_RESOURCE_MANIFEST_FILE = 'Mobile_pt_BR_audioFiles_manifest.json';
const MOBILE_PT_BR_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "Mobile/pt_BR/20220607/" + MOBILE_PT_BR_RESOURCE_MANIFEST_FILE;


/**
 * Mobile Functional Manifest Files
 */
const MOBILE_FUNCTIONAL_EN_US_RESOURCE_MANIFEST_FILE = 'Mobile_en_US_Functional_audioFiles_manifest.json';
const MOBILE_FUNCTIONAL_EN_US_RESOURCE_DOWNLOAD_URL = MANIFEST_BASE_URL + "MobileFunctional/en_US/20220923/" + MOBILE_FUNCTIONAL_EN_US_RESOURCE_MANIFEST_FILE;

function DownloadResource(fileAlias, manifestFile, downloadUrl) {
  this.fileAlias = fileAlias;
  this.manifestFile = manifestFile;
  this.downloadUrl = downloadUrl;
}

export const DOWNLOAD_RESOURCE_JSON =
  [
    {
       "file_name": ACOUSTIC_COMMON_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_COMMON_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_AR_SA_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_AR_SA_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_DE_DE_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_DE_DE_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_EN_AU_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_EN_AU_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_EN_GB_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_EN_GB_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_EN_IN_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_EN_IN_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_EN_US_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_ES_ES_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_ES_ES_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_ES_MX_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_ES_MX_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_ES_US_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_ES_US_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_FR_CA_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_FR_CA_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_FR_FR_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_FR_FR_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_HI_IN_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_HI_IN_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_IT_IT_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_IT_IT_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_JA_JP_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_JA_JP_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": ACOUSTIC_PT_BR_RESOURCE_MANIFEST_FILE,
       "download_url": ACOUSTIC_PT_BR_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": FUNCTIONAL_EN_US_RESOURCE_MANIFEST_FILE,
        "download_url": FUNCTIONAL_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": FUNCTIONAL_AUTOMOTIVE_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": FUNCTIONAL_AUTOMOTIVE_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": FUNCTIONAL_AUTOMOTIVE_NAV_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": FUNCTIONAL_AUTOMOTIVE_NAV_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": MUSIC_DE_DE_RESOURCE_MANIFEST_FILE,
        "download_url": MUSIC_DE_DE_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": MUSIC_EN_US_RESOURCE_MANIFEST_FILE,
        "download_url": MUSIC_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": MUSIC_JA_JP_RESOURCE_MANIFEST_FILE,
        "download_url": MUSIC_JA_JP_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": AUTO_LOCAL_SEARCH_GOOGLE_EN_US_RESOURCE_MANIFEST_FILE,
        "download_url": AUTO_LOCAL_SEARCH_GOOGLE_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": AUTO_LOCAL_SEARCH_SIRI_EN_US_RESOURCE_MANIFEST_FILE,
        "download_url": AUTO_LOCAL_SEARCH_SIRI_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
        "file_name": FAR_AR_SA_RESOURCE_MANIFEST_FILE,
        "download_url": FAR_AR_SA_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_DE_DE_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_DE_DE_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_EN_AU_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_EN_AU_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_EN_CA_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_EN_CA_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_EN_GB_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_EN_GB_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_EN_IN_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_EN_IN_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_EN_US_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_ES_ES_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_ES_ES_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_ES_MX_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_ES_MX_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_ES_US_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_ES_US_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_FR_CA_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_FR_CA_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_FR_FR_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_FR_FR_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_HI_IN_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_HI_IN_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_IT_IT_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_IT_IT_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_JA_JP_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_JA_JP_RESOURCE_DOWNLOAD_URL
    },
    {
       "file_name": FAR_PT_BR_RESOURCE_MANIFEST_FILE,
       "download_url": FAR_PT_BR_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": STABILITY_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": STABILITY_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": UPL_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": UPL_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": QUAL_COMMON_RESOURCE_MANIFEST_FILE,
      "download_url": QUAL_COMMON_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": Qual_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": Qual_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_COMMON_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_COMMON_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_DE_DE_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_DE_DE_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_EN_AU_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_EN_AU_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_EN_GB_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_EN_GB_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_EN_IN_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_EN_IN_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_ES_ES_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_ES_ES_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_ES_MX_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_ES_MX_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_ES_US_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_ES_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_FR_CA_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_FR_CA_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_FR_FR_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_FR_FR_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_HI_IN_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_HI_IN_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_IT_IT_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_IT_IT_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_PT_BR_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_PT_BR_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": SECURITY_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": SECURITY_EN_US_RESOURCE_DOWNLOAD_URL
    },
    {
      "file_name": MOBILE_FUNCTIONAL_EN_US_RESOURCE_MANIFEST_FILE,
      "download_url": MOBILE_FUNCTIONAL_EN_US_RESOURCE_DOWNLOAD_URL
    }
  ]

import React from 'react';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import { Labs } from '@amzn/amazon-devicequalification-ui-labs/dist/index.js';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

const styles = theme => ({
  root: {
    width: '100%',
    minWidth: 600
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight: 'bold'
  },
  container: {
    margin: 'auto',
    width: 500
  },
  message: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '100%',
    flexShrink: 0,
    marginTop: 100
  },
  command: {
    height: 250,
    width: 450,
    textAlign: 'center',
    overFlowX: 'auto'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -13,
    marginLeft: -13
  },
  wrapper: {
    position: 'relative'
  }
});

class LabsWrapper extends React.Component {
  render() {
    if (isEmpty(this.props.auth.session)) {
      //This logic gets invoked if the user is directly trying to access the 
      //Labs page through URL. If the session is not there, the page will be redirected. 
      return <Redirect to="/" />
    }
    return (<div><Labs environment={this.props.auth.environment} session={this.props.auth.session} /></div>);
  }
}

LabsWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object
};

export default compose(connect(state => state), withStyles(styles, { params: {} }))(LabsWrapper);

import { initialState } from './store'

/**
 * Loads and returns the state from the browser's localStorage
 * @returns state or initialState
 */
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState == null || serializedState == undefined) {
            return initialState;
        }
        let persistedState = JSON.parse(serializedState);
        return persistedState;
    } catch(err) {
        return initialState;
    }
};

/**
 * Saves the app state to browser's localStorage
 * @param state app state
 */
export const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(err) {
        // Ignore write errors
    }
};
import axios from 'axios';
import { ApiConstants, AppConstants, Auth, Store, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

export var jobSockets = {};

export function fetchLabsAndRasPis() {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  let labsAndThingsPromises = [];
  return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(labs => {
      if (labs.hasOwnProperty('data')) {
        Util.logToConsole('Labs: ' + JSON.stringify(labs.data));
        labs.data.forEach(labMap => {
          labsAndThingsPromises.push(
            axios.get(controllerEndpoint +
              ApiConstants.JOBS_URL + '/' +
              labMap.id + '/' +
              ApiConstants.THINGS,
              Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
            .then(rasPis => {
              return {
                lab: labMap,
                rasPis: rasPis.data ? rasPis.data : []
              };
            })
            .catch(error => {
              Util.networkError(error, fetchLabsAndRasPis.name);
              return {
                error: AppConstants.NETWORKERR
              };
            })
          );
        });
        return labsAndThingsPromises;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchLabsAndRasPis.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function fetchLabs() {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  console.log("fetchLabs session : " + Store.aqtStore.getState().session)
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(labs => {
      if (labs.hasOwnProperty('data')) {
        Util.logToConsole('Labs: ' + JSON.stringify(labs.data));
        return labs.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchLabs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function fetchRasPis(labId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(rasPis => {
      if (rasPis.hasOwnProperty('data')) {
        Util.logToConsole('RasPis: ' + JSON.stringify(rasPis.data));
        return rasPis.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchRasPis.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function getRasPiSetupCommand(rasPiId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
        ApiConstants.THING_SETUP_URL.replace('thingId', rasPiId),
        Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(thingSetupCom => {
        if (thingSetupCom.hasOwnProperty('data')) {
          Util.logToConsole('RasPi Setup Command: ' + thingSetupCom.data.cmdLine);
          return {
            data: thingSetupCom.data.cmdLine
          };
       } else {
        return {
          error: AppConstants.NOCMDLINE
        };
       }
      })
      .catch(error => {
        Util.networkError(error, getRasPiSetupCommand.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

export function actionRasPi(labId, rasPiId, actionId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction[actionId].action,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(rasPiStatus => {
      if (rasPiStatus.hasOwnProperty('data')) {
        Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
        return { success: rasPiStatus.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      Util.networkError(error, actionRasPi.name);
      return {
        error: error.message
      };
    });
  }
}

export function actionRasPiWithBodyAndAckTimeout(labId, rasPiId, actionId, body, ackTimeout) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.JOBS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction[actionId].action +
      '?ackTimeout=' + ackTimeout,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(rasPiStatus => {
      if (rasPiStatus.hasOwnProperty('data')) {
        Util.logToConsole('RasPi status : ' + rasPiId + JSON.stringify(rasPiStatus.data));
        return { success: rasPiStatus.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      Util.networkError(error, actionRasPiWithBodyAndAckTimeout.name);
      return {
        error: error.response.data.error,
      };
    });
  }
}

export function actionLab(labId, actionId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!labId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NOLab
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL + '/' +
      labId + '/' +
      AppConstants.rasPiAction[actionId].action,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(rasPiStatus => {
      if (rasPiStatus.hasOwnProperty('data')) {
        Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
        return { success: rasPiStatus.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      Util.networkError(error, actionRasPi.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
  }
}


export function getAllowlistedScenarios() {
 if (Util.getSession() === AppConstants.SESSIONERR) {
  return new Promise(resolve => {
    resolve({
      error: AppConstants.SESSIONERR
    })
   });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.ALLOWLISTED_SCENARIOS_URL,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.hasOwnProperty('data')) {
      Util.logToConsole('Get allowlisted scenarios: ' + JSON.stringify(response.data));
      return { scenarios: response.data ? response.data  : []};
     } else {
      return { error: AppConstants.SERVERERR }
    }
   })
   .catch(error => {
     Util.networkError(error, getAllowlistedScenarios.name);
     return { error: AppConstants.NETWORKERR };
  });
}

export function talkRasPi(labId, rasPiId, fileName, timeOut,
  rasPiControlName = AppConstants.RAS_PI_CONTROL_NAME) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    let body = {
            'waveFileName': fileName,
            'volume': 100,
            'controlName': rasPiControlName
          }
    return axios.post(controllerEndpoint +
      ApiConstants.JOBS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction.TALK.action +
      '?actionTimeout=' + timeOut,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(rasPiStatus => {
      if (rasPiStatus.hasOwnProperty('data')) {
        Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
        return { success: rasPiStatus.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      Util.networkError(error, talkRasPi.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
  }
}

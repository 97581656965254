(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@amzn/awsui-components-react"));
	else if(typeof define === 'function' && define.amd)
		define("AmazonDeviceQualificationUIComponents", ["React", "@amzn/awsui-components-react"], factory);
	else if(typeof exports === 'object')
		exports["AmazonDeviceQualificationUIComponents"] = factory(require("react"), require("@amzn/awsui-components-react"));
	else
		root["AmazonDeviceQualificationUIComponents"] = factory(root["React"], root["@amzn/awsui-components-react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4639__, __WEBPACK_EXTERNAL_MODULE__6856__) => {
return 
const CustomOptionsConstants = {
    SCHEME_DEF_KEY: 'schemeDef',
    MOBILE_TRAINERS_KEY: 'trainers',
    MOBILE_NOISES_KEY: 'NoiseTypes',
    IAR_DIRECTORY: 'IAR ',
    RSR_DIRECTORY: 'RSR ',

    // Needed for backward compatibility to keep old custom options for customized tests
    REVERSE_ACTOR_MAP: {
        '2.7 m 90 deg': 'speakNineNinety',
        '2.7 m 30 deg': 'speakNineThirty',
        '0.9 m 90 deg': 'speakThreeNinety',
        '0.9 m 30 deg': 'speakThreeThirty',
        'Automotive Companion': 'automotiveCompanion'
    },

    // Needed for backward compatibility to keep old custom options for customized tests
    REVERSE_NOISE_MAP: {
        'Silence': 'SILENCE',
        'External Music': 'LAPTOP',
        'Device Playback': 'DEVICE',
        'Noise': 'KITCHEN',
        'Car Noise': 'CAR',
        'Pub': 'PUB',
        'Pink': 'PINK',
        'Rock': 'ROCK',
        'Traffic Light Mode': 'AUTOMOTIVE_TRAFFIC_LIGHT_MODE',
        'Medium Speed Mode': 'AUTOMOTIVE_MEDIUM_SPEED',
        'Medium Speed with Windows Open': 'AUTOMOTIVE_MEDIUM_SPEED_WINDOWS_OPEN',
        'High Speed Mode': 'AUTOMOTIVE_HIGH_SPEED',
        'Medium Speed with Device Playback': 'AUTOMOTIVE_MEDIUM_SPEED_DEVICE_PLAYBACK',
        'High Speed with Device Playback': 'AUTOMOTIVE_HIGH_SPEED_DEVICE_PLAYBACK'
    },

    UTTERANCE_DIRECTORY_MAP: {
        'AC_FAR_FIELD': {
            'STANDARD': {
                '2.7 m 90 deg': 'Standard',
                '2.7 m 30 deg': 'Standard'
            },
            'QUICK': {
                '2.7 m 90 deg': 'Quick',
                '2.7 m 30 deg': 'Quick'
            },
            'DEMO': {
                '2.7 m 90 deg': 'Demo',
                '2.7 m 30 deg': 'Demo'
            },
            'CUSTOM_STANDARD': {
                '2.7 m 90 deg': 'Standard',
                '2.7 m 30 deg': 'Standard'
            },
            'CUSTOM_QUICK': {
                '2.7 m 90 deg': 'Quick',
                '2.7 m 30 deg': 'Quick'
            },
            'CUSTOM_DEMO': {
                '2.7 m 90 deg': 'Demo',
                '2.7 m 30 deg': 'Demo'
            }
        },
        'AC_NEAR_FIELD': {
            'STANDARD': {
                '0.9 m 90 deg': 'Standard',
                '0.9 m 30 deg': 'Standard'
            },
            'QUICK': {
                '0.9 m 90 deg': 'Quick',
                '0.9 m 30 deg': 'Quick'
            },
            'DEMO': {
                '0.9 m 90 deg': 'Demo',
                '0.9 m 30 deg': 'Demo'
            },
            'CUSTOM_STANDARD': {
                '0.9 m 90 deg': 'Standard',
                '0.9 m 30 deg': 'Standard'
            },
            'CUSTOM_QUICK': {
                '0.9 m 90 deg': 'Quick',
                '0.9 m 30 deg': 'Quick'
            },
            'CUSTOM_DEMO': {
                '0.9 m 90 deg': 'Demo',
                '0.9 m 30 deg': 'Demo'
            }
        },
        'AC_TRAINED_MOBILE': {
            'STANDARD': {
                '0.9 m 90 deg': 'Mobile Standard'
            },
            'QUICK': {
                '0.9 m 90 deg': 'Mobile Quick'
            },
            'DEMO': {
                '0.9 m 90 deg': 'Mobile Demo'
            },
            'CUSTOM_STANDARD': {
                '0.9 m 90 deg': 'Mobile Standard'
            },
            'CUSTOM_QUICK': {
                '0.9 m 90 deg': 'Mobile Quick'
            },
            'CUSTOM_DEMO': {
                '0.9 m 90 deg': 'Mobile Demo'
            }
        },
        'CT_WAKE_AND_RESPONSE': {
            'STANDARD': { '0.9 m 90 deg': 'Standard' },
            'QUICK': { '0.9 m 90 deg': 'Quick' },
            'DEMO': { '0.9 m 90 deg': 'Demo' },
            'CUSTOM_STANDARD': { '0.9 m 90 deg': 'Standard' },
            'CUSTOM_QUICK': { '0.9 m 90 deg': 'Quick' },
            'CUSTOM_DEMO': { '0.9 m 90 deg': 'Demo' }
        },
        'AUTOMOTIVE_FRR_RAR': {
            'STANDARD': { 'Automotive Companion': 'Standard' },
            'DEMO': { 'Automotive Companion': 'Demo' },
            'CUSTOM_STANDARD': {'Automotive Companion': 'Standard'},
            'CUSTOM_QUICK': {'Automotive Companion': 'Quick'},
            'CUSTOM_DEMO': {'Automotive Companion': 'Demo'},
            'CUSTOMIZED': { 'Automotive Companion': 'Customized' },
        },
        'AUTOMOTIVE_WWDD': {
            'STANDARD': { 'Automotive Companion': 'Standard' },
            'DEMO': { 'Automotive Companion': 'Demo' },
        },
        'AUTOMOTIVE_FAR': {
            'STANDARD': { 'Automotive Companion': 'Standard' },
            'DEMO': { 'Automotive Companion': 'Demo' },
        },
        'AUTOMOTIVE_UPL': {
            'STANDARD': { 'Automotive Companion': 'Standard' },
            'DEMO': { 'Automotive Companion': 'Demo' },
        }
    }
}

export default CustomOptionsConstants;

import React, { PureComponent } from 'react';

import { AppConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import Grid from './Grid';

class SecurityTestDetails extends PureComponent {

  /**
   * Test details required for security test
   * @param {object} params
   */
  generateTestDetails = (params) => {
    const testSuiteLabel = AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY[params.testSuite];
    const { rasPi: { label, config = {} } = {} } = params.hotspotOptions || {};
    const keyValuePairs = [
      {
        label: 'Login',
        value: params.ownerEmail
      },
      {
        label: 'Customer ID ',
        value: params.customerId,
      },
      {
        label: 'Device Serial Number',
        value: params.dsn
      },
      {
        label: testSuiteLabel,
        value: Util.getScenarioTypeToDisplay(params.scenarioType, params.testSuite)
      },
      {
        label: 'Amazon ID',
        value: Util.getAmazonIdToDisplay(params.deviceType, params.scenarioType)
      },
      {
        label: 'Lab',
        value: params.labName
      },
      {
        label: 'RasPi Name',
        value: label
      },
      {
        label: 'Wifi Name',
        value: config.ssid
      },
      {
        label: 'Email Notification',
        value: params.sendCompletionEmail ? 'Enabled' : 'Disabled',
      },
      {
        label: 'Start Time (UTC)',
        value: params.startTime,
      },
    ];

    return keyValuePairs;
  }

  render() {
    const { params } = this.props;

    if (params.testSuite !== AppConstants.SECURITY_SCENARIO_ID) return (<div />);

    const keyValuePairs = this.generateTestDetails(params);
    return (<Grid keyValuePairs={keyValuePairs} />);
  }
}

export default SecurityTestDetails;

import axios from 'axios';

export const fetchAppConfig = async () => {
  const appConfig = await axios.get("/config.json").then(config => {
    if (config.data.loadLocal) {
      console.log('Loading local config');
      config.data = {
        aws_cognito_identity_pool_id: "us-west-2:c98e9171-4b3e-4385-92cc-3899a8886511",
        aws_cognito_region: "us-west-2",
        aws_sign_in_enabled: "enable",
        aws_user_pools: "enable",
        aws_user_pools_id: "us-west-2_nlUkid4Hr",
        aws_user_pools_mfa_type: "OFF",
        aws_user_pools_web_client_id: "4cg2vllep63547j84q57eaqkk7",
        controllerEndpoint: "https://api.us-west-2.alpha.amazon-aqt.com",
        envType: "FireTV"
      }
    }
    return config.data;
  });
  return appConfig;
}
import {Util} from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { BASE_URL, STAGE_TYPES } from "../constants";

var environment = null;

export function setEnvironment(env) {
  environment = env;
}
export const getStage = () => {
  if(Util.isAlphaEnvironment()) {
    return STAGE_TYPES.ALPHA;
  }
  if(Util.isBetaEnvironment()) {
    return STAGE_TYPES.BETA;
  }
  if(Util.isProdEnvironment()) {
    return STAGE_TYPES.PROD;
  }

  return STAGE_TYPES.ALPHA;
}


export const getUrl = (service) => {
  const STAGE = getStage();
  return `${BASE_URL[STAGE]}${service}`;

}

export const isInternalCustomer = () => {
  return true;
}
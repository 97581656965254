import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AppLogin from './containers/AppLogin'
import { Provider } from 'react-redux';
import createStore from './redux/store';
import LiveRun from "./containers/LiveRun";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import FootBar from './components/FootBar';
import Header from './components/Header';
import LabsWrapper from './components/LabsWrapper';
import OAKRunWrapper from './components/OAKWrapper';
import ResultsWrapper from './components/ResultsWrapper';
import Home from './components/Home';
import '@amzn/awsui-components-react/index.css';
import { loadState, saveState } from './redux/localStorage';
import { fetchAppConfig } from './appConfig';
import Amplify from 'aws-amplify';

const persistedState = loadState();
const store = createStore(persistedState);
//Make sure that the state in browser is updated with each changes. 
store.subscribe(()=> {
  saveState(store.getState());
});

const configureAmplifyAndRenderApp = async () => {
  const appConfig = await fetchAppConfig();
  if (appConfig) {
    Amplify.configure(appConfig);
    ReactDOM.render(
      <Provider store={store}>
        <Router>
          <div id="container">
            <div>
              <Header />
            </div>
            <div id="app-container" className='awsui'>
              <AppLogin />
              <Switch>
                <Route path='/liveRuns' component={LiveRun} />
                <Route path='/home' component={Home} />
                <Route path='/labs' component={LabsWrapper} />
                <Route path='/results' component={ResultsWrapper} />
                <Route path='/v2/run' component={OAKRunWrapper} />
              </Switch>
            </div>
            <div id="footbar">
              <div className='awsui'><FootBar /></div>
            </div>
          </div>
        </Router>
      </Provider>,
      document.getElementById('root')
    );
  } 
}

configureAmplifyAndRenderApp();
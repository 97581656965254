/**
 * Component to render test feeds
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import TestMetrics from './TestMetrics';
import FunctionalTestMetrics from './Functional/FunctionalTestMetrics';
import FunctionalUtteranceDetails from './Functional/FunctionalUtteranceDetails';
import TestDetails from './TestDetails';
import LiveFeedProgressBar from './LiveFeedProgressBar';
import LiveFeedProgressBarFunctional from './Functional/LiveFeedProgressBarFunctional';
import CustomTestProgressBar from './CustomTest/CustomTestProgressBar';
import CustomTestMetrics from './CustomTest/CustomTestMetrics';
import { AppConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

const TEST_DETAILS = {
  'label': 'Test Details',
  'id': 'testDetailsTab',
};

const TEST_METRICS = {
  'label': 'Test Metrics',
  'id': 'testMetricsTab',
};

const TEST_UTTERANCE_DETAILS = {
  'label': 'Utterance Details (Alexa Cloud Validation)',
  'id': 'utteranceDetailsTab',
};

const testSuitesWithOnlyDetailsTab = [
  AppConstants.SECURITY_SCENARIO_ID
];

/**
 * Component to display following test feeds:
 * 1. Test details in single tab
 * 2. Test metrics in single tab
 * 3. Progress bar displaying test progress
 */
class FeedsContainer extends React.Component {
  // React state for test details & test metrics tab & utterance details
  state = {
    activeTabId: 'testMetricsTab',
    utteranceDetailsTestName: AppConstants.EMPTY
  };

  // Handles tab change event generated when Utterances button is clicked
  // in Test Metrics tab
  handleTabChange = (tabId, functionalTestCaseName) => {
    this.setState({
      activeTabId: tabId,
      utteranceDetailsTestName: functionalTestCaseName
    });
  }

  // Handles tab change event generated when Back button is clicked in Utterance
  // Details tab
  handleBackButtonClicked = (tabId) => {
    this.setState({
      activeTabId: tabId
    });
  }

  /**
   * Handle tab change
   * @param event
   */
  handleChange = (event) => {
    if (!event) return;

    this.setState({
      activeTabId: event.detail.activeTabId
    })
  }

  // Method to load proper tabs for live run page.
  getTabsToRender = () => {
    const { testSuite, scenarioType } = this.props.params.testDetails;
    // Render only details tab for these test suites
    if (testSuitesWithOnlyDetailsTab.includes(testSuite)) {
       return [TEST_DETAILS];
     } else if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
       return [TEST_DETAILS, TEST_METRICS, TEST_UTTERANCE_DETAILS];
     }

     // Default, show details and metrics tabs
     return [TEST_DETAILS, TEST_METRICS];
  }

  // Method to get active tab Id
  getActiveTabId = () => {
    const { scenarioType, testSuite } = this.props.params.testDetails;
    if (testSuitesWithOnlyDetailsTab.includes(testSuite)) {
      return 'testDetailsTab';
    }
    return this.state.activeTabId ? this.state.activeTabId : 'testDetailsTab';
 }

  renderProgressBar = (props = {}) => {
    const { testDetails, testStats, testStatus, elapsedSeconds } = props;
    if (!testDetails || Object.keys(testDetails).length === 0) return;

    const { testSuite, scenarioType } = testDetails;
    const params = {
      labJobId: testDetails.labJobId,
      testStatus: testStatus,
      testType: testDetails.testType,
      testSuite: testDetails.testSuite,
      customOptions: testDetails.customOptions,
      scenarioType: testDetails.scenarioType,
      marketPlace: testDetails.marketPlace
    };

    const testSuitesWithPayload = [
      AppConstants.SECURITY_SCENARIO_ID
    ];

    if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return (
        <LiveFeedProgressBarFunctional
          params={{
            ...params,

            functionalTestCases: testStats.functionalTestCases,
            refMicStatus: testStats.refMicStatus,
            elapsedSeconds: elapsedSeconds,
          }}
        />
      );
    } else if (testSuite === AppConstants.CUSTOM_SCENARIO_ID) {
      return (
        <CustomTestProgressBar
          params={{
            ...params,
            testStats: testStats,
            config: testDetails.config,
            testStatus: testDetails.state,
          }}
        />
      );
    } else if (testSuitesWithPayload.includes(testSuite)) {
      return (
        <LiveFeedProgressBar
          params={{
            ...params,
            payload: testStats.payload,
          }}
        />
      );
    }

    // Progress bar for all other test suites
    const percentage = Util.isTestCompleted(testStatus.toLowerCase()) ? 100 : testStats.percentage;
    return (
      <LiveFeedProgressBar
        params={{
          ...params,
          inProgressPercentage: percentage,
          measureTypeCustomPayload: testStats.measureTypeCustomPayload,
          mobileVoiceTrainingIsManual: testDetails.customOptions.mobileVoiceTrainingIsManual,
        }}
      />
    );
  }

  renderTestDetails  = (props = {}) => {
    const { testDetails } = props;
    if (!testDetails || Object.keys(testDetails).length === 0) return;
    return (
      <TestDetails params={testDetails} />
    );
  }

  renderTestMetrics = (props = {}) => {
    const { testDetails = {}, testStats = {}, testStatus, elapsedSeconds, labJobId, noiseFeeds } = props;
    if (Object.keys(testDetails).length === 0) {
      return (<div></div>);
    }

    const { testSuite, scenarioType } = testDetails;

    // Don't render metrics tab for these test suites
    if (testSuitesWithOnlyDetailsTab.includes(testSuite)) return;

    const functionalTestMetricsParams = {
      functionalTestCases: testStats.functionalTestCases,
      testCaseCategoryStatusMap: testStats.testCaseCategoryStatusMap,
      startTime: testStats.startTime,
      elapsedTime: testStats.elapsedTime,
      avgTestTime: testStats.avgTestTime,
      currentFunctionalTest: testStats.currentFunctionalTest,
      questionPlayed: testStats.played,
      dutHeard: testStats.heard,
      dutResponded: testStats.responded,
      scoreFrr: testStats.scoreFrr,
      scoreRar: testStats.scoreRar,
      utteranceValidationType: testStats.utteranceValidationType,
      testStats: testStats,
      utteranceStatus: testStats.functionalUtteranceStatus,
      utteranceAttribute: testStats.utteranceAttribute,
      elapsedSeconds: elapsedSeconds,
      labJobId: testDetails.labJobId
    }

    if (testDetails.testSuite === AppConstants.CUSTOM_SCENARIO_ID) {
      return (
        <CustomTestMetrics
          params={{
            testStats: testStats,
            testStatus: testStatus,
            labJobId: labJobId,
            elapsedSeconds: elapsedSeconds
          }}
        />
      );
    } else if (testDetails.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return (
        <FunctionalTestMetrics
          params={functionalTestMetricsParams}
          onTabChange={this.handleTabChange }
        />
      );
    }

    return (
      <TestMetrics
        params={{
          startTimeFar: testStats.startTime,
          labJobIdFar: testStats.labJobId,
          testStatusFar: testStatus,
          testType: testDetails.testType,
          scenarioType: testDetails.scenarioType,
          testStats: testStats,
          elapsedSeconds: elapsedSeconds,
          testStatus: testStatus,
          noiseFeeds: noiseFeeds
        }}
      />
    );
  }

  renderUtteranceDetails = (props = {}) => {
    const { testStats, testDetails, elapsedSeconds } = props;
    const functionalUtterancesParams = {
      functionalTestCases: testStats.functionalTestCases,
      testCaseCategoryStatusMap: testStats.testCaseCategoryStatusMap,
      startTime: testStats.startTime,
      elapsedTime: testStats.elapsedTime,
      avgTestTime: testStats.avgTestTime,
      utteranceDetailsTestName: this.state.utteranceDetailsTestName,
      currentFunctionalTest: testStats.currentFunctionalTest,
      questionPlayed: testStats.played,
      dutHeard: testStats.heard,
      dutResponded: testStats.responded,
      scoreFrr: testStats.scoreFrr,
      scoreRar: testStats.scoreRar,
      utteranceValidationType: testStats.utteranceValidationType,
      utteranceStatus: testStats.functionalUtteranceStatus,
      utteranceAttribute: testStats.utteranceAttribute,
      elapsedSeconds: elapsedSeconds,
      testStats: testStats,
      labJobId: testDetails.labJobId
    }
    if (testDetails.testSuite !== AppConstants.FUNCTIONAL_SCENARIO_ID) return;

    return (
      <FunctionalUtteranceDetails
        params={functionalUtterancesParams}
        onBackButtonClicked={this.handleBackButtonClicked }
      />
    );
  }

  // Renders test feed
  render = () => {
    const { params } = this.props;
    Util.logToConsole('TestDebug - Test stats in Feedscontainer = ' + JSON.stringify(params.testStats));
    let tabsToRender = this.getTabsToRender();
    let activeTabId = this.getActiveTabId();

    return (
      <div className='feedContainer awsui-util-container awsui-util-no-gutters awsui-util-m-n'>
        { this.renderProgressBar(params) }
        <AWSUI.Tabs
          tabs={ tabsToRender }
          activeTabId={ activeTabId }
          onChange={this.handleChange}
        >
        </AWSUI.Tabs>
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-m-n'>
          { activeTabId === 'testDetailsTab' && this.renderTestDetails(params) }
          { activeTabId === 'testMetricsTab' && this.renderTestMetrics(params) }
          { (activeTabId === 'utteranceDetailsTab')
            && this.renderUtteranceDetails(params) }
        </div>
      </div>
    );
  }
}

FeedsContainer.propTypes = {
  params: PropTypes.object.isRequired
};

export default FeedsContainer;

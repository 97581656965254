import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'aws-amplify';
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import FormField from "@amzn/awsui-components-react-v3/polaris/form-field";
import { AppConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

const PASSWORD_MISMATCH_ERROR = 'Passwords entered are either empty or does not match';

const styles = theme => ({
  paperControl: theme.mixins.gutters({
    width: 300,
    paddingTop: 30,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  }),
  container: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 10,
  },
  formControl: {
    margin: theme.spacing.unit
  },
  textField: {
    width: 300,
    marginBottom: theme.spacing.unit,
  },
  logo: {
    width: 190,
    height: 90,
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  }
});

class ResetPassword extends React.Component {

  state = {
    newpassword: AppConstants.EMPTY,
    confirmpassword: AppConstants.EMPTY,
    passcode: AppConstants.EMPTY,
    error: AppConstants.EMPTY,
    showPassword: false
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, error: AppConstants.EMPTY });
  }

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleResetPasswordBackToLogin = () => {
    this.props.backtologin({});
  }

  handleResetPasswordSubmit = () => {
    if (!this.state.confirmpassword || this.state.newpassword !== this.state.confirmpassword) {
      this.setState({ error: PASSWORD_MISMATCH_ERROR });
    } else {
       Auth.forgotPasswordSubmit(this.props.params.username, this.state.passcode, this.state.newpassword)
        .then(response => {
          Util.logToConsole('Password reset successfully');
          this.props.backtologin({});
        })
        .catch( err => {
          var message;
          if (typeof err === 'string') {
            message = err;
          } else {
            message = err.message;
        }
        this.setState({ error: message });
        Util.logToConsole('Error changing passoword: ' + message);
      });
    }
  }

  render() {
    const { classes } = this.props;

    return(
      <div>
        <Grid className={classes.container} align="center">
          <Grid item xs={12}>
            <Paper className={classes.paperControl} elevation={4}>
              <Grid item xs={12}>
                <img className={classes.logo}
                  src="Resources/images/Fire_TV_logo_Transparent.png"
                  alt="Fire TV"
                />
              </Grid> 
              <Grid item xs={12}>
              <Typography variant='title'>
                  Reset Password
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.textField}>
                  <InputLabel
                    className="login-input"
                    htmlFor="passcode"
                  >
                    Verification Code
                  </InputLabel>
                  <Input
                    className="login-input"
                    id="passcode"
                    key="passcode"
                    name="passcode"
                    value={this.state.passcode}
                    onChange={this.handleChange('passcode')}
                  />
                </FormControl>
              </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.textField}>
                    <InputLabel
                      className="login-input"
                      htmlFor="newpassword"
                    >
                      New Password
                    </InputLabel>
                    <Input
                      className="login-input"
                      id="newpassword"
                      key="newpassword"
                      name="newpassword"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange('newpassword')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Show Password"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                       }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.textField}>
                    <InputLabel
                      className="login-input"
                      htmlFor="confirmpassword"
                    >
                      Confirm New Password
                    </InputLabel>
                    <Input
                      className="login-input"
                      id="confirmpassword"
                      key="confirmpassword"
                      name="confirmpassword"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange('confirmpassword')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Show Password"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                       }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Button
                      variant='primary'
                      className='awsui-util-pt-s'
                      onClick={ this.handleResetPasswordSubmit }
                     >
                      Submit
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <div>
                      <a onClick={this.handleResetPasswordBackToLogin}>
                        Back to Sign In
                      </a>
                    </div>
                  </FormControl>      
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <FormField errorText = {this.state.error}/>
                  </FormControl>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  params: PropTypes.object.isRequired,
  backtologin: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default withStyles(styles, {params:{}})(ResetPassword);
import React, { useEffect } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { Controller, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js'
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import Header from '@amzn/awsui-components-react-v3/polaris/header';

export function getTotalNumberOfTestCases(testCases) {
  var total = 0;
  Object.keys(testCases).forEach((key) => {
    if (Array.isArray(testCases[key])) {
      total = total + testCases[key].length;
    } else if (typeof (testCases[key]) === 'object' && testCases[key] !== null) {
      total = total + getTotalNumberOfTestCases(testCases[key]);
    }
  });
  return total;
}

function applySuperScript(content) {
  return (
    <span>
      {content}
      <sup>*</sup>
    </span>
  );
}

const Home = () => {
  const [deviceDetails, setDeviceDetails] = React.useState({});
  const [testRunDetails, setTestRunDetails] = React.useState({})
  const history = useHistory();
  const fetchDevicePreferences = () => {
    return Promise.resolve(Controller.fetchDevicePreferences().then(devicePreference => {
      const deviceDetails = devicePreference.data.DeviceDetails
      const testRunDetails = devicePreference.data.TestRunDetails
      setTestRunDetails(testRunDetails)
      setDeviceDetails(deviceDetails)
    }));
  }

  let testDetailColumnDefinitions = [
    {
      id: "number",
      header: "Sl.No",
      cell: item => item.slno || "-"
    },
    {
      id: "name",
      header: "Test suite name",
      cell: item => item.name || "-"
    },
    {
      id: "requirement",
      header: "Mandatory/ Recommended",
      cell: item => item.requirement || "-"
    },
    {
      id: "testCaseCount",
      header: "No. of test cases",
      cell: item => item.testCaseCount || "-"
    },
    {
      id: "moduleCount",
      header: "No. of modules",
      cell: item => item.moduleCount || "-"
    },
    {
      id: "eta",
      header: "ETA",
      cell: item => item.eta || "-"
    }
  ]
  
  let testDetailItems = [
    {
      "slno": 1,
      "name": "Fire TV Qual Suite",
      "requirement": "Mandatory",
      "testCaseCount": "360",
      "moduleCount": "4",
      "eta": "70 hrs"
    },
    {
      "slno": 2,
      "name": "Compatibility Test Suite (CTS)",
      "requirement": "Mandatory",
      "testCaseCount": applySuperScript("400K+ "),
      "moduleCount": applySuperScript("322 "),
      "eta": "2 - 3 days"
    }
  ];

  const auth = useSelector(state => state.auth)
  useEffect(() => {
    Util.setCommonStore(auth.session, auth.environment);
    if (isEmpty(auth.session)) {
      history.push('/');
    } else {
      fetchDevicePreferences();
    }
  }, [auth]);

  return (
    <div>
      <div style={{ padding: 20 }}>
        <Card >
          <CardContent>
            <h2 >
              Device Details
            </h2>
            <br />
            <p>
              Device Type : {deviceDetails["device_type"]}
            </p>
            <br />
            <p>Onboarded Components : @FireTV-Launcher, @FireTV-Settings, @FireTV-OOBE, @FireTV-AppStore</p>
            <br />
            <p>Customisations chosen : None</p>
          </CardContent>
        </Card>
      </div>
      <div style={{ padding: 20 }}>
        <Table  columnDefinitions={testDetailColumnDefinitions} items={testDetailItems}
          loadingText="Loading resources"
          sortingDisabled
          header={<Header> Test Details </Header>} />
          <p><i>* Counts are subject to change</i></p>
      </div>
    </div>
  )

};

export default Home;

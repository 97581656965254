import { validate as validateTestInit, setError as setTestInitError } from "../../../../stores/newRun/testInitSlice";
import { setError as setLabConfigError, validate as validateLabConfig } from "../../../../stores/newRun/acm/labConfigSlice";
import { setError as setCompError, validate as validateComp } from "../../../../stores/newRun/componentSlice";
import { setError as setScenarioSelError, validate as validateScenSelection } from "../../../../stores/newRun/acm/scenarioSelectionSlice";
import { updateStep } from "../../../../stores/newRun/acm/uiSlice";
import { FTV_GOTO_STEP } from "../../../../constants";
 
// https://stackoverflow.com/questions/43246890/is-using-getstate-in-a-redux-thunk-good-practice
export const validateAndPass = (nextStep) =>  (dispatch, getState) => {
 
  switch(nextStep) {
    case FTV_GOTO_STEP.TEST_SUITE: {
      dispatch(updateStep(nextStep));
      break;
    }
    case FTV_GOTO_STEP.LAB_CONFIG: {
     const testInitErr = validateTestInit(getState().testInit);
     dispatch(setTestInitError(testInitErr))
     if(!testInitErr) {
       dispatch(updateStep(nextStep));
     }
     break;
   }
    case FTV_GOTO_STEP.SCEN_SELECTION: {
      const testInitErr = validateTestInit(getState().testInit);
      const labConfigErr = validateLabConfig(getState());
      dispatch(setTestInitError(testInitErr));
      dispatch(setLabConfigError(labConfigErr));
      if(!testInitErr && !labConfigErr) {
      dispatch(updateStep(nextStep));
      }
      break;
    }
    
    case FTV_GOTO_STEP.PREVIEW: {
      const testInitErr = validateTestInit(getState().testInit);
      // TODO: https://issues.labcollab.net/projects/PTI/issues/PTI-1782 - Use ftvScenarioSelectionSlice
      const scenSelErr = validateScenSelection(getState().acmScenarioSlection);
      const componentError =  validateComp(getState().componentsList,
                                           getState().acmScenarioSlection.scenarioType,
                                           getState().testInit.testSuite.displayName);
      const labConfigErr = validateLabConfig(getState());
      dispatch(setTestInitError(testInitErr));
      dispatch(setScenarioSelError(scenSelErr));
      dispatch(setLabConfigError(labConfigErr));
      dispatch(setCompError(componentError));
      if(!testInitErr && !scenSelErr && !labConfigErr && !componentError) {
        dispatch(updateStep(nextStep));
      }
      break;
    }
    default: break;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import {
  ColumnLayout
} from '@amzn/awsui-components-react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import {Actions, AppConstants, MusicConstants, Controller, Util, CustomCalendarInput, Store} from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { COLUMN_DEFINITIONS, SORTABLE_COLUMNS } from './TableConfig';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { startCase } from 'lodash';
import ld from 'lodash';

const SCENARIO_TYPE = 'scenarioType';
const TEST_TYPE = 'testType';
const SCENARIO_WITH_NO_TEST_TYPE = ['AC_WWDD_QUICK_TEST', 'AC_WWDD_FULL_TEST', 'AC_FAR', 'AC_FAR_CUSTOM'];
const GENERIC_REPORT_ERROR = 'Error generating the report. Please re-run the test';

const allOption = {
  id: 'ALL',
  label: 'All'
}

const TEST_TYPES_RESULTPAGE = [
{
  id: 'ALL',
  label: 'All',
},
{
  id: 'STANDARD',
  label: 'Standard',
} , {
  id: 'QUICK',
  label: 'Quick',
} , {
  id: 'DEMO',
  label: 'Demo',
} , {
  id: 'CUSTOMIZED',
  label: 'Customized',
}];

const TEST_TYPES_MUSIC_RESULTPAGE = [
{
  id: 'ALL',
  label: 'All',
}, {
  id: 'STANDARD',
  label: 'Standard',
}, {
  id: 'SMOKE',
  label: 'Smoke',
}];

// Device Virtual Assistants for Auto Local search scenario
const DEVICE_VIRTUAL_ASSISTANT_AUTO_LOCAL_SEARCH_RESULTPAGE = [
{
  id: 'ALL',
  label: 'All',
}, {
  id: 'ALEXA',
  label: 'Alexa',
}, {
  id: 'GOOGLE',
  label: 'Google',
}, {
  id: 'SIRI',
  label: 'Siri',
}];

const SCENARIO_TYPES_RESULTPAGE = [
{
  id: 'ALL',
  label: 'All',
}, {
  id: 'AC_FAR_FIELD',
  label: 'Far Field',
}, {
  id: 'AC_NEAR_FIELD',
  label: 'Near Field',
}, {
  id: 'AC_WWDD_QUICK_TEST',
  label: 'WWDD - Quick Test',
}, {
  id: 'AC_WWDD_FULL_TEST',
  label: 'WWDD - Full Test',
}, {
  id: 'AC_FAR',
  label: 'FAR',
}, {
  id: 'AC_FAR_CUSTOM',
  label: 'FAR - Custom'
}];

const MSP_RESULTPAGE = [
{
  id: 'ALL',
  label: 'All',
}, {
  id: 'MUSIC_MSK',
  label: 'Music Skills',
}, {
  id: 'MUSIC_MSK_CUSTOM_STATION',
  label: 'Music Skills - Custom station',
}, {
  id: 'MUSIC_MSK_LIVE_STATION',
  label: 'Music Skills - Live station',
}, {
  id: 'MUSIC_MSK_ON_DEMAND',
  label: 'Music Skills - On Demand',
}, {
  id: 'MUSIC_IHRT_CUSTOM',
  label: 'iHeartRadio Custom',
}, {
  id: 'MUSIC_IHRT_LIVE',
  label: 'iHeartRadio Live',
}, {
  id: 'MUSIC_TUNEIN',
  label: 'TuneIn',
}, {
   id: 'MUSIC_TUNEIN_PODCAST',
   label: 'TuneIn Podcast',
}, {
     id: 'MUSIC_TUNEIN_RADIO',
     label: 'TuneIn Radio',
}, {
  id: 'MUSIC_AMAZON_MUSIC',
  label: 'Amazon Music',
// }, {
//   id: 'MUSIC_PANDORA',
//   label: 'Pandora',
}, {
  id: 'MUSIC_AUDIBLE',
  label: 'Audible'
}, {
  id: 'MUSIC_KINDLE',
  label: 'Kindle'
}];

const FUNCTIONAL_SCENARIO_TYPES_RESULTSPAGE = [
  {
    id: 'ALL',
    label: 'All'
  }, {
    id: 'FUNC_ALL',
    label: 'Standard'
  }, {
    id: 'FUNC_CUSTOM',
    label: 'Custom'
  }
];

const STABILITY_SCENARIO_TYPES_RESULTSPAGE = [
  {
    id: 'STABILITY_STRESS',
    label: 'Stress'
  }
];

const UPL_SCENARIO_TYPES_RESULTSPAGE = [
  {
    id: 'UPL_STANDARD',
    label: 'Standard'
  }, {
    id: 'UPL_QUICK',
    label: 'Quick'
  }
];

// Temporary: Functional scenarios for alpha environment
const FUNCTIONAL_SCENARIO_TYPES_RESULTSPAGE_ALPHA = [
  {
    id: 'ALL',
    label: 'All'
  }, {
    id: 'FUNC_ALL',
    label: 'Standard'
  }, {
    id: 'FUNC_CUSTOM',
    label: 'Custom'
  }, {
    id: 'MULTIMODAL',
    label: 'Multimodal'
  }
];

const FIRE_TV_TEST_SUITES = [{
  id: "FTV",
  label: "FTV Qualification Suite"
},
{
  id: "CTS",
  label: "CTS Pre-Qualification Suite"
},
{
  id: "FOS",
  label: "FOS Qualification Suite"
}];

// Auto Local Search scenario types
const AUTO_LOCAL_SEARCH_SCENARIO_TYPES_RESULTSPAGE = [
  {
    id: 'ALL',
    label: 'All'
  }, {
    id: 'FULL',
    label: 'Full'
  }, {
    id: 'SMOKE',
    label: 'Smoke'
  }
];

const CLOSE_TALK_SCENARIO_TYPE_RESULTSPAGE = [
  {
    id: 'ALL',
    label: 'All'
  },
  {
    id: 'CT_WAKE_AND_RESPONSE',
    label: 'Wake & Response Accuracy'
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test'
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test'
  }, {
    id: 'AC_FAR',
    label: 'FAR'
  }
];

const MOBILE_SCENARIO_TYPE_RESULTSPAGE = [
  {
    id: 'ALL',
    label: 'All'
  }, {
    id: 'AC_MOBILE',
    label: 'Untrained Mobile [Deprecated]'
  }, {
    id: 'AC_TRAINED_MOBILE',
    label: 'Wake & Response Accuracy'
  }, {
    id: 'AC_VOICE_ENROLLMENT_ROBUSTNESS',
    label: 'Voice Enrollment Robustness'
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test'
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test'
  }, {
    id: 'AC_FAR',
    label: 'FAR'
  }, {
    id: 'MOBILE_FUNCTIONAL',
    label: 'Functional'
  }
];

const STATUS = [
{
  id: 'ALL',
  label: 'All',
}, {
  id: 'Passed',
  label: 'Passed',
}, {
  id: 'Failed',
  label: 'Failed',
}, {
  id: 'Error',
  label: 'Error',
}, {
  id: 'Running',
  label: 'Running',
}, {
  id: 'Canceled',
  label: 'Canceled',
}, {
  id: 'Aborted',
  label: 'Aborted',
}, {
  id: 'Queued',
  label: 'Queued',
}];

const LOCALE = [{
  id: 'ALL',
  label: 'All',
}, {
  id: 'en_US',
  label: 'en-US'
} , {
  id: 'en_CA',
  label: 'en-CA'
}, {
  id: 'en_AU',
  label: 'en-AU'
}, {
  id: 'en_GB',
  label: 'en-GB'
} , {
  id: 'en_IN',
  label: 'en-IN'
} , {
  id: 'hi_IN',
  label: 'hi-IN'
} , {
  id: 'de_DE',
  label: 'de-DE'
} , {
  id: 'ja_JP',
  label: 'ja-JP'
} , {
  id: 'fr_FR',
  label: 'fr-FR'
}, {
  id: 'fr_CA',
  label: 'fr-CA'
}, {
  id: 'es_ES',
  label: 'es-ES'
}, {
  id: 'es_MX',
  label: 'es-MX'
}, {
  id: 'es_US',
  label: 'es-US'
}, {
  id: 'it_IT',
  label: 'it-IT'
}, {
   id: 'pt_BR',
   label: 'pt-BR'
}];

const JOB_STATE = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  INPROGRESS: 'inprogress',
  REQUESTED: 'requested',
  RUNNING: 'running'
};

const REPORT_STATUS = {
  FAILURE: '0',
  SUCCESS: '1',
  ERROR: '2',
  CANCELED_OR_ABORTED: '3',
  EXECUTED: '4'
};

const TEST_STATUS_VALUES = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  ERROR: 'Error',
  RUNNING: 'Queued',
  CANCELED: 'Canceled',
  EXECUTED: 'Executed',
  ABORTED: 'Aborted',
  REQUESTED: 'Queued',
  INPROGRESS: 'Running',
  TERMINATED: 'Terminated',
  NONE: '-'
};

const TEST_RESULT_VALUES_FTV = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  ERROR: 'Error',
  TERMINATED: 'Terminated',
  EXECUTED: 'Executed',
  NONE: '-'
};

const STATUS_FTV = [
  {
    id: 'ALL',
    label: 'All',
  }, {
    id: 'Queued',
    label: 'Queued',
  }, {
    id: 'Completed',
    label: 'Completed',
  },{
    id: 'In-Progress',
    label: 'In-Progress',
  }, {
    id: 'Error',
    label: 'Error',
  }, {
    id: 'Terminated',
    label: 'Terminated',
  } ];

const RESULT_FTV = [
  {
    id: 'ALL',
    label: 'All',
  }, {
    id: 'Passed',
    label: 'Passed',
  }, {
    id: 'Failed',
    label: 'Failed',
  }, {
    id: 'Error',
    label: 'Error',
  }, {
    id: 'Terminated',
    label: 'Terminated',
  }];

const TEST_STATUS_VALUES_FTV = {
  QUEUED: 'Queued',
  INPROGRESS: 'In-Progress',
  COMPLETED: 'Completed',
  TERMINATED: 'Terminated',
  ERROR: 'Error'
};

const TEST_STATUS_MAP = {
  'Passed': {testStatusClass: 'awsui-util-status-positive', testStatusIcon: 'status-positive'},
  'Executed': {testStatusClass: 'awsui-util-status-positive', testStatusIcon: 'status-positive'},
  'Failed': {testStatusClass: 'awsui-util-status-negative', testStatusIcon: 'status-negative'},
  'Error': {testStatusClass: 'awsui-util-status-negative', testStatusIcon: 'status-warning'},
  'Running': {testStatusClass: 'awsui-util-status-info', testStatusIcon: 'status-in-progress'},
  'Canceled': {testStatusClass: 'awsui-util-status-info', testStatusIcon: 'status-stopped'},
  'Terminated': {testStatusClass: 'awsui-util-status-info', testStatusIcon: 'status-stopped'},
  'Completed': {testStatusClass: 'awsui-util-status-positive', testStatusIcon: 'status-positive'},
  'Aborted': {testStatusClass: 'awsui-util-status-negative', testStatusIcon: 'status-negative'},
  'Queued': {testStatusClass: 'awsui-util-status-info', testStatusIcon: 'status-pending'},
  'In-Progress': {testStatusClass: 'awsui-util-status-info', testStatusIcon: 'status-in-progress'},
  '-': {testStatusClass: 'awsui-util-status-info', testStatusIcon: 'status-in-progress'}
};

const styles = theme => ({
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: 1,
    left: 1,
    zIndex: 1
  },
  command: {
    height: 130,
    width: 450,
    textAlign: 'center'
  },
  popmessage: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '100%',
    flexShrink: 0,
    marginTop: 30
  },
  filterContainer: {
    position: 'relative',
    width: '100%'
  }
});

const FROM_DATE = 'fromDate';
const TO_DATE = 'toDate';

class Results extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      jobs: {
        loading: false,
        data: [],
        error: {
          isError: false,
          message: null
        }
      },
      downloadUrl: {
        loading: false,
        jobId: null,
        url: null,
        error: {
          isError: false,
          message: null
        }
      },
      fromDate: moment(`${moment().year()}-01-01`),
      toDate: moment(),
      isDateRangeValid: true,
      testSuite: AppConstants.ALL,
      jobStatus: AppConstants.ALL,
      testResult: AppConstants.ALL,
      scenarioType: AppConstants.ALL,
      testType: AppConstants.ALL,
      deviceVirtualAssistant: AppConstants.ALL,
      deviceType: AppConstants.EMPTY,
      labSelected: AppConstants.ALL,
      localeSelected: AppConstants.ALL,
      testTypeDisabled: false,
      anchorEl: null,
      allowlistedScenarioIds: [],
      labs: [],
      session: this.props.environment,
      environment: this.props.session
    };
    Store.aqtStore.dispatch(Actions.setEnvironment(this.state.environment));
    Store.aqtStore.dispatch(Actions.setSession(this.state.session));
    Util.setCommonStore(this.props.session, this.props.environment);
  }

  static getDerivedStateFromProps (props, state) {
    if(state.environment !== props.environment ||
       state.session !== props.session) {
        return {
          session: props.session,
          environment: props.environment
        };
      }
      //No change in state in this case. 
      return null;
  }

  componentDidMount() {
    this.getAllowlistedScenarios();
    this.fetchLabs();
  }

  /**
   * Method to fetch labs
   */
  fetchLabs = () => {
    let labsIn = [];
    labsIn.push({
      id: AppConstants.ALL,
      label: AppConstants.ALL.charAt(0) + AppConstants.ALL.slice(1).toLowerCase()
    });
    return Promise.resolve(Controller.fetchLabs().then(labs => {
      if (!labs.hasOwnProperty('error')) {
        labs.forEach(lab => {
          labsIn.push({
            id: lab.name,
            label: lab.name
          });
        });
        this.setState({
          labs: labsIn,
          labSelected: labsIn[0].id
        });
      } else {
        Util.logToConsole('Error retrieving labs');
      }
    }));
  }

  sortJobData = (jobData) => {
   return  jobData.sort((a, b) => {
      return a.experimentRunId === b.experimentRunId ? (a.executionOrder - b.executionOrder) : (a.date > b.date) ? -1 : 1
   })
  }
  getTestSuiteDisplayNameForJob = (testSuite, jobOptions) => {
    if(testSuite === AppConstants.FTV) {
      let jobSuite = AppConstants.FTV;
      if(this.isJobWithCTSTestSuite(jobOptions)) {
        jobSuite = AppConstants.CTS
      } else if (this.isJobWithFOSTestSuite(jobOptions)) {
        jobSuite = AppConstants.FOS
      }
      const testSuite = FIRE_TV_TEST_SUITES.find((suite) => suite.id === jobSuite);
      return testSuite ? testSuite.label : AppConstants.EMPTY;
    } else{
      return Util.getDisplayNameForId(AppConstants.ALLOWLISTED_SCENARIOS, testSuite);
    }
  }

  getJobs = (query) => {
    //let cacheName = AppConstants.cache.JOBS;
    this.setState({ jobs: {
      ...this.state.jobs,
      loading: true
    }});

    Controller.fetchJobs(query).then(jobs => {
      if (!jobs.hasOwnProperty('error')) {
        Util.logToConsole('Jobs: ' + JSON.stringify(jobs));
        let jobData = [];
        jobs.forEach(job => {
          let options = JSON.parse(job.options);
          let testSuite = job.scenarioName ? AppConstants.TEST_SUITE_MAP[job.scenarioName] : AppConstants.ACOUSTIC_SCENARIO_ID;
          let amazonId = this.getAmazonId(options);
          let scenarioType = (options && options.hasOwnProperty('testOptions')) ?
            options.testOptions.scenarioType :
            AppConstants.EMPTY;
          let amazonIdToDisplay = Util.getAmazonIdToDisplay(amazonId, scenarioType);
          let deviceVirtualAssistant = this.getDeviceVirtualAssistant(options);
          let executionOrder = ld.get(options, 'executionOrder', 0);
          // TODO: This logic needs to be improved so that testType & deviceType are queried while fetching jobs from the DB itself.
          // Following if condition is a temporary workaround to filter results on already fetched jobs. More information in the following SIM:
          // https://sim.amazon.com/issues/P15400224
          if (options && this.meetsFilteringCriteria(options, job.state, job.reportStatus, job.summaryReportDownloadURL, testSuite,
                job.labName, options.marketPlace, amazonIdToDisplay, deviceVirtualAssistant)) {
            jobData.push({
              id: job.id,
              state: job.state ? job.state.charAt(0) + job.state.slice(1).toLowerCase() : null,
              reportStatus: job.reportStatus,
              testStatus: this.getTestStatus,
              testResult: this.getTestResult,
              summaryReportDownloadURL: job.summaryReportDownloadURL,
              testName: Util.getTestName(options),
              testSuiteName: this.getTestSuiteDisplayNameForJob(testSuite, options),
              scenarioType: Util.getScenarioTypeToDisplay(scenarioType, testSuite,
                options.testOptions && options.testOptions.config ? options.testOptions.config : {}),
              testType: Util.getTestTypeDisplay(options.testType, scenarioType, testSuite),
              deviceType: amazonIdToDisplay,
              date: new Date(job.createdAt),
              executionOrder: executionOrder,
              experimentRunId: job.experimentRunId || 0,
              urlButton: this.getUrlButton,
              deviceVirtualAssistant: Util.getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS,
                deviceVirtualAssistant)
          });
         }
        });
        this.setState({
          jobs: {
            loading: false,
            data: this.sortJobData(jobData),
            error: { isError: false, message: null }
        }});
        //sessionStorage.setItem(cacheName, JSON.stringify(jobData));
      } else {
        this.setState({
          jobs: {
            loading: false,
            data: [],
            error: { isError: true, message: jobs.error }
        }});
        //sessionStorage.removeItem(cacheName);
      }
    });
  }

  /**
   * Gets Device Virtual Assistant from job options
   * @param options options JSON from Jobs payload
   * @returns Device Virtual Assistant
   */
  getDeviceVirtualAssistant = (options) => {
    if (options && options.hasOwnProperty('testOptions')
          && options.testOptions.hasOwnProperty('customOptions')) {
      return options.testOptions.customOptions.deviceVirtualAssistant;
    }
    return AppConstants.UNAVAILABLE;
  }

  /**
   * Gets Amazon ID value from JSON
   * @param options options JSON from Jobs payload
   * @returns Amazon Id
   */
  getAmazonId = (options) => {
    if (options && options.hasOwnProperty('testOptions')) {
      // If amazonId field exists in options JSON, retrieve amazon ID from there
      if (options.testOptions.hasOwnProperty('amazonId')) {
        return options.testOptions.amazonId;
      }
      // If amazon Id field is not in payload, retrieve amazon Id from 'deviceType'
      // This is needed for backward compatibility
      if (options.deviceType) {
        return options.deviceType;
      }

      if (options.testOptions && options.testOptions.customOptions && options.testOptions.customOptions.amazonId) {
        return options.testOptions.customOptions.amazonId;
      }
    }
    return AppConstants.EMPTY;
  }

  /**
   * Get scenario types for given test suite
   * @param testSuite
   */
  getScenarioTypes = (testSuite) => {
    const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
    if (!testSuiteInfo || !testSuiteInfo.SCENARIO_TYPE) {
      Util.logToConsole('Invalid testSuite: ', testSuite);
      return [];
    }

    const types = testSuiteInfo.SCENARIO_TYPE.map(({ id, label }) => ({ id, label }));
    return [allOption, ...types];
  }

  /**
   * Get test types for given test suite
   * @param testSuite
   */
  getTestTypes = (testSuite) => {
    const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
    if (!testSuiteInfo || !testSuiteInfo.TEST_TYPES) {
      Util.logToConsole('Invalid testSuite: ', testSuite);
      return [];
    }
    const testTypes = testSuiteInfo.TEST_TYPES;
    const types = AppConstants.TEST_TYPES
      .filter(item => testTypes.includes(item.type))
      .map(item => ({ id: item.type, label: item.label }));
    return [allOption, ...types];
  }

  /**
   * Get scenario label for given test suite
   * @param testSuite
   */
  getScenarioLabel = (testSuite) => {
    const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
    if (!testSuiteInfo || !testSuiteInfo.LABEL) {
      Util.logToConsole('Invalid testSuite: ', testSuite);
      return 'Scenario';
    }

    return testSuiteInfo.LABEL;
  }

  /**
   * Method to retrieve allowlisted scenarios for the account
   * @return List of allowlisted scenarios for the account
   */
  getAllowlistedScenarios = () => {
    let allowlistedScenariosIn = [];
    allowlistedScenariosIn.push({
      id: AppConstants.ALL,
      label: AppConstants.ALL.charAt(0) + AppConstants.ALL.slice(1).toLowerCase()
    });
    return Promise.resolve(Controller.getAllowlistedScenarios().then(response => {
      if (!response.hasOwnProperty('error')) {
        response.scenarios.forEach(allowlistedScenario => {
          if (Util.isFireTVEnv()) {
            if (AppConstants.FIRE_TV_ALLOWLISTED_SCENARIO_TYPES.includes(allowlistedScenario.name)) {
              allowlistedScenariosIn.push(...FIRE_TV_TEST_SUITES);
            }
          } else if (AppConstants.ALLOWLISTED_SCENARIO_TYPES.includes(allowlistedScenario.name)
            && !Util.checkScenarioExistsInList(allowlistedScenariosIn, AppConstants.TEST_SUITE_MAP[allowlistedScenario.name])) {
            allowlistedScenariosIn.push({
              id: AppConstants.TEST_SUITE_MAP[allowlistedScenario.name],
              label: Util.getDisplayNameForId(AppConstants.ALLOWLISTED_SCENARIOS, AppConstants.TEST_SUITE_MAP[allowlistedScenario.name])
            });
          }
        });
       if (allowlistedScenariosIn.length === 2) {
        // If list.length == 2 that means account is allowlisted only for single scenario, so make
        // it selected by default in test suite dropdown
        allowlistedScenariosIn = allowlistedScenariosIn.filter(item => item.id !== AppConstants.ALL);
        this.setState({
          testSuite: allowlistedScenariosIn[0].id
        });
       } else if (allowlistedScenariosIn.length === 1) {
        // If list.length == 1 that means allowlisted scenarios list is empty. Allowlist for all
        // scenarios in such case
        for (var i = 0; i < AppConstants.ALLOWLISTED_SCENARIOS.length; i++) {
          allowlistedScenariosIn.push({
           id: AppConstants.ALLOWLISTED_SCENARIOS[i].id,
           label: AppConstants.ALLOWLISTED_SCENARIOS[i].label
         });
        }
       }
      this.setState({
        allowlistedScenarioIds: allowlistedScenariosIn
      });
      // Load jobs once allowlisted scenarios are retrieved
      this.getJobs(AppConstants.EMPTY);
     }
    }));
  }

  isJobWithCTSTestSuite = (jobOptions) => {
    if (jobOptions.hasOwnProperty('testOptions')) {
      if (jobOptions.testOptions.hasOwnProperty('features')) {
        return jobOptions.testOptions.features.includes(AppConstants.CTS_STANDARD);
      }
    }
    return false;
  }
  isJobWithFOSTestSuite = (jobOptions) => {
    if (jobOptions.hasOwnProperty('testOptions')) {
      if (jobOptions.testOptions.hasOwnProperty('features')) {
        return jobOptions.testOptions.features.includes(AppConstants.FOS_STANDARD);
      }
    }
    return false;
  }

  isJobWithFTVTestSuite = (jobOptions) => {
    if (jobOptions.hasOwnProperty('testOptions')) {
      if (jobOptions.testOptions.hasOwnProperty('features')) {
        return jobOptions.testOptions.features.some((feature) => feature.startsWith(AppConstants.FIRE_TV_FEATURES_PREFIX));
      }
    }
    return false;
  }

  /**
   * Method which determines whether current job meets filtering criteria or not
   * @param options Job options for current job
   * @param jobStatus Status for job from batch
   * @param reportStatus Report status returned by controller
   * @param summaryReportDownloadURL Report download URL returned by controller
   * @param testSuite Test suite for current job
   * @param labName Lab name for current job
   * @param marketPlace Locale for current job
   * @param amazonId Amazon ID for current job
   * @param deviceVirtualAssistant Device virtual assistant for current job
   * @return true or false based on job meets filtering criteria or not
   */
  meetsFilteringCriteria = (options, jobStatus, reportStatus, summaryReportDownloadURL, testSuite,
    labName, marketPlace, amazonId, deviceVirtualAssistant) => {
    // Return true only when all fields in current job meet filtering criteria entered through different dropdowns
    // in UI on Results page

    // We send testType = EMPTY for scenarios which don't have test type such as all Music scenarios, WWDD & FAR
    // Adding this condition to maintain backward compatibility so that older records show up in search
    if (options.testType === AppConstants.EMPTY) {
      options.testType = options.testOptions.scenarioType;
    }
    let testTypesMatch = this.state.testType === AppConstants.ALL || options.testType === this.state.testType;
    let scenarioTypesMatch = this.state.scenarioType === AppConstants.ALL || options.testOptions.scenarioType === this.state.scenarioType;
    let deviceTypesMatch = this.state.deviceType === AppConstants.EMPTY || amazonId === this.state.deviceType;
    let testStatusValue = Util.isFireTVEnv() ? this.getTestStatusValueForFireTVEnv(jobStatus, reportStatus, summaryReportDownloadURL) : this.getTestStatusValue(jobStatus, reportStatus, summaryReportDownloadURL);
    let testStatusMatch = this.state.jobStatus === AppConstants.ALL || this.state.jobStatus === testStatusValue;
    let testResultValue = this.getTestResultValueForFireTVEnv(jobStatus, reportStatus, summaryReportDownloadURL);
    let testResultMatch = this.state.testResult === AppConstants.ALL
      || this.state.testResult === testResultValue
      || testResultValue == TEST_RESULT_VALUES_FTV.EXECUTED; // Executed is for backward compatibility. 
    let labNamesMatch = this.state.labSelected === AppConstants.ALL || this.state.labSelected === labName;
    let localesMatch = this.state.localeSelected === AppConstants.ALL || this.state.localeSelected === marketPlace || this.state.localeSelected === options.locale;

    let testSuitesMatch = false;
    if (this.state.testSuite === AppConstants.ALL) {
      //All jobs will be visible no matter what is the value of the testsuite in the job.
      testSuitesMatch = true;
    } else if (testSuite === AppConstants.FTV) {
      //Handles FTV case where we have CTS and FTV suites as of now. 
      testSuitesMatch = (this.state.testSuite === AppConstants.CTS && this.isJobWithCTSTestSuite(options) ||
        this.state.testSuite === AppConstants.FTV && this.isJobWithFTVTestSuite(options) ||
        this.state.testSuite === AppConstants.FOS && this.isJobWithFOSTestSuite(options));
    } else {
      //AQT use case where the test suite in dropdown has to match the job test suite. 
      testSuitesMatch = (this.state.testSuite === testSuite);
    }
    // Only for test suite = Security, check if following filtering criteria is met:
    // 1. Test suite = SECURITY
    // 2. Test status match (Succeeded, Failed etc.)
    if (testSuite === AppConstants.SECURITY_SCENARIO_ID) {
      return testSuitesMatch && testStatusMatch;
    }
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      let deviceVirtualAssistantMatch = this.state.deviceVirtualAssistant === AppConstants.ALL ||
        this.state.deviceVirtualAssistant === deviceVirtualAssistant;
      return scenarioTypesMatch && deviceTypesMatch && testStatusMatch && testSuitesMatch
        && labNamesMatch && localesMatch && deviceVirtualAssistantMatch;
    }
    return testTypesMatch && scenarioTypesMatch && deviceTypesMatch && testStatusMatch && testSuitesMatch
        && labNamesMatch && localesMatch && testResultMatch;
  }

  /**
   * Decides whether to  display error tooltip next to test result status on Results page
   * @param state Job status from controller
   * @param summaryReportDownloadURL Report URL from controller
   * @returns true or false to decide whether to display error tooltip
   */
  shouldDisplayErrorTooltip = (state, summaryReportDownloadURL) => {
    if (state) {
      // If report download URL is available, return false
      if (summaryReportDownloadURL) {
        return false;
      }
      // If report download URL is not available, check if test case has completed
      // Only if test is completed, return true
      return AppConstants.COMPLETED_STATES.includes(state.toLowerCase())
        && state.toLowerCase() !== JOB_STATE.COMPLETED;
    }
    return true;
  }

  getUrlButton = (jobId, state, summaryReportDownloadURL) => {
    // When report download URL is available, provide an option to download report from UI
    return (
      !this.shouldDisplayErrorTooltip(state, summaryReportDownloadURL) ? ( <div>
        <div className={ this.props.classes.wrapper }>
          <IconButton color='default' variant='fab' aria-label='download'
            disabled={ this.getDownloadUrlButtonState(jobId, state) }
            onClick={ (event) => {
              this.getDownloadUrl(event.currentTarget, jobId);
            }}
          >
            <SaveIcon />
          </IconButton>
          { ( this.state.downloadUrl.jobId === jobId ? this.state.downloadUrl.loading : false ) &&
            <CircularProgress size={46} className={ this.props.classes.buttonProgress } />
          }
        </div>
        { this.getUrlStatusPop() }
      </div> ) : (
        <div className='awsui-util-ml-m awsui-util-mt-m awsui-util-mb-m awsui-util-status-negative'>
          <AWSUI.Tooltip text={ GENERIC_REPORT_ERROR } size='small' position='top'>
            <AWSUI.Icon name='status-info'></AWSUI.Icon>
          </AWSUI.Tooltip>
        </div>
      )
    );
  }

  /**
   * Method which determines whether testStatus is 'Error'
   * @param jobStatus Job state returned from batch
   * @param reportStatus Report status returned by controller
   * @summaryReportDownloadURL URL to download the report
   * @return whether test status is 'error' or not
   */
  isErrorState = (jobStatus, reportStatus, summaryReportDownloadURL) => {
    let isError = false;
    if (!jobStatus) {
      isError = true;
    } else if (AppConstants.COMPLETED_STATES.includes(jobStatus.toLowerCase())
                && jobStatus.toLowerCase() !== JOB_STATE.COMPLETED
                && (!summaryReportDownloadURL
                      || reportStatus === REPORT_STATUS.ERROR) 
                      || (Util.isFireTVEnv() && jobStatus.toLowerCase() === JOB_STATE.FAILED)) {
      isError = true;
    }
    return isError;
  }

  /**
   * Method which determines whether test status is 'succeeded'
   * @param jobStatus Job state returned from batch
   * @param reportStatus Report status returned by controller
   * @return whether test status is 'succeeded' or not
   */
  isSuccessState = (jobStatus, reportStatus) => {
    return jobStatus === JOB_STATE.SUCCEEDED && (!reportStatus || reportStatus === REPORT_STATUS.SUCCESS);
  }

  /**
   * Method which determines whether test status is 'succeeded'
   * @param jobStatus Job state returned from batch
   * @param reportStatus Report status returned by controller
   * @return whether test status is 'succeeded' or not
   */
  isFailureState = (jobStatus, reportStatus) => {
    let isFailure = false;
    if (jobStatus === JOB_STATE.FAILED) {
      isFailure = true;
    } else if (jobStatus === JOB_STATE.SUCCEEDED && reportStatus === REPORT_STATUS.FAILURE) {
      isFailure = true;
    }
    return isFailure;
  }

  /** Gets test status to display on Results page - calculated based on Job status + report Status + summaryReportDownloadURL
  * @param jobStatus Job Status returned by batch
  * @param reportStatus Report status returned by report
  * @param summaryReportDownloadURL URL returned from controller to download the report
  * @return Status to display under Test status column
  */
  getTestStatus = (jobStatus, reportStatus, summaryReportDownloadURL) => {
    let testStatusValue = Util.isFireTVEnv() ? this.getTestStatusValueForFireTVEnv(jobStatus, reportStatus, summaryReportDownloadURL) : 
    this.getTestStatusValue(jobStatus, reportStatus, summaryReportDownloadURL) ;
   
    return (
        <div className={ TEST_STATUS_MAP[testStatusValue].testStatusClass }>
          <AWSUI.Icon name={ TEST_STATUS_MAP[testStatusValue].testStatusIcon }/>
            <span className = 'awsui-util-ml-xs'>
            { testStatusValue }
            </span>
        </div>
      )
  }

  /** Gets the value for test Status - calculated based on Job status + report Status + summaryReportDownloadURL
  * @param jobStatus Job Status returned by batch
  * @param reportStatus Report status returned by report
  * @param summaryReportDownloadURL URL returned from controller to download the report
  * @return Test status
  */
  getTestStatusValue = (jobStatus, reportStatus, summaryReportDownloadURL) => {
    // Assign 'In Progress' as default test state value
    let testStatusValue = TEST_STATUS_VALUES.INPROGRESS;

    if (this.isErrorState(jobStatus, reportStatus, summaryReportDownloadURL)) {
      // Error state
      testStatusValue = TEST_STATUS_VALUES.ERROR;
    } else if (jobStatus.toLowerCase() === JOB_STATE.COMPLETED) {
      // Completed state -- Display it as Running till SUCCEEDED, FAILED or ERROR state is received
      testStatusValue = TEST_STATUS_VALUES.INPROGRESS;
    } else if (jobStatus.toLowerCase() === JOB_STATE.SUCCEEDED && reportStatus === REPORT_STATUS.EXECUTED) {
      // Passed state
      testStatusValue = TEST_STATUS_VALUES.EXECUTED;
    } else if (this.isSuccessState(jobStatus.toLowerCase(), reportStatus)) {
      // Passed state
      testStatusValue = TEST_STATUS_VALUES.PASSED;
    } else if (this.isFailureState(jobStatus.toLowerCase(), reportStatus)) {
      // Failed state
      testStatusValue = TEST_STATUS_VALUES.FAILED;
    } else if (jobStatus.toLowerCase() === JOB_STATE.CANCELED) {
      // Canceled state
      testStatusValue = TEST_STATUS_VALUES.CANCELED;
    } else if (jobStatus.toLowerCase() === JOB_STATE.RUNNING
      || jobStatus.toLowerCase() === JOB_STATE.REQUESTED) {
      // Queued state
      testStatusValue = TEST_STATUS_VALUES.RUNNING;
    }
    // Check if report status is aborted, if so, override existing test status value since
    // any aborted test needs to show 'Aborted' status

    // Python reporting currently returns aborted if the test's expected utterance
    // count does not match with actual utterance played count so in that case
    // check if the job status is not cancelled inorder to report as aborted.
    if (reportStatus === REPORT_STATUS.CANCELED_OR_ABORTED
      && jobStatus.toLowerCase() !== JOB_STATE.COMPLETED
      && jobStatus.toLowerCase() !== JOB_STATE.CANCELED) {
      testStatusValue = TEST_STATUS_VALUES.ABORTED;
    }
    return testStatusValue;
  }

  /** Gets the value for test Status - calculated based on Job status + report Status + summaryReportDownloadURL
  * @param jobStatus Job Status returned by batch
  * @param reportStatus Report status returned by report
  * @param summaryReportDownloadURL URL returned from controller to download the report
  * @return Test status
  */
  getTestStatusValueForFireTVEnv = (jobStatus, reportStatus, summaryReportDownloadURL) => {
    // Assign 'In Progress' as default test state value
    let testStatusValue = TEST_STATUS_VALUES_FTV.INPROGRESS;

    if (this.isErrorState(jobStatus, reportStatus, summaryReportDownloadURL)) {
      // Error state
      testStatusValue = TEST_STATUS_VALUES_FTV.ERROR;
    } else if (jobStatus.toLowerCase() === JOB_STATE.COMPLETED) {
      // Completed state -- Display it as Running till SUCCEEDED, FAILED or ERROR state is received
      testStatusValue = TEST_STATUS_VALUES_FTV.INPROGRESS;
    } else if (jobStatus.toLowerCase() === JOB_STATE.SUCCEEDED) {
      // Completed state
      testStatusValue = TEST_STATUS_VALUES_FTV.COMPLETED;
    } else if (jobStatus.toLowerCase() === JOB_STATE.CANCELED) {
      // Terminated state
      testStatusValue = TEST_STATUS_VALUES_FTV.TERMINATED;
    } else if(jobStatus.toLowerCase() === JOB_STATE.RUNNING
        || jobStatus.toLowerCase() === JOB_STATE.REQUESTED) {
      // Queued state
      testStatusValue = TEST_STATUS_VALUES_FTV.QUEUED;
    } 
    return testStatusValue;
  }

  getTestResult = (jobStatus, reportStatus, summaryReportDownloadURL) => {
    let testResult = this.getTestResultValueForFireTVEnv(jobStatus, reportStatus, summaryReportDownloadURL);
   
    return (
      testResult != TEST_RESULT_VALUES_FTV.NONE ? (
        <div className={ TEST_STATUS_MAP[testResult].testStatusClass }>
          <AWSUI.Icon name={ TEST_STATUS_MAP[testResult].testStatusIcon }/>
            <span className = 'awsui-util-ml-xs'>
            { testResult }
            </span>
        </div>
        )
        : 
        testResult
      )
  }

  /** 
  * @return Test result
  */
  getTestResultValueForFireTVEnv = (jobStatus, reportStatus, summaryReportDownloadURL) => {
    // Assign 'In Progress' as default test state value
    let testStatusValue = 
    this.getTestStatusValueForFireTVEnv(jobStatus, reportStatus, summaryReportDownloadURL);

    let testResultValue;
    if (testStatusValue === TEST_STATUS_VALUES_FTV.TERMINATED 
      || testStatusValue === TEST_STATUS_VALUES_FTV.ERROR) {
        testResultValue = testStatusValue;
    } else if (reportStatus === REPORT_STATUS.FAILURE ) {
      testResultValue = TEST_RESULT_VALUES_FTV.FAILED;
    } else if (reportStatus === REPORT_STATUS.SUCCESS) {
      testResultValue = TEST_RESULT_VALUES_FTV.PASSED;
    } else if (reportStatus === REPORT_STATUS.EXECUTED) {
      // Terminated state
      // Sending Executed in this case in order to maintain backward compatibility. 
      // For all the jobs which have completed already, report status will be 4. 
      testResultValue = TEST_RESULT_VALUES_FTV.EXECUTED;
    } else  {
      // No Test Result available as of now. Will be updated once the job completes. 
      testResultValue = TEST_RESULT_VALUES_FTV.NONE;
    } 
    return testResultValue;
  }

  getDownloadUrlButtonState = (jobId, state) => {
    if (this.state.downloadUrl.jobId === jobId) {
      return this.state.downloadUrl.loading;
    }
    // Need to disable download report button when test is NOT Succeeded, Failed or Canceled
    // Need to disable also when 'completed' because download URL might not be available until it
    // eventually reaches Succeeded, Failed or Canceled state
    return !AppConstants.COMPLETED_STATES.includes(state.toLowerCase())
      || state.toLowerCase() === JOB_STATE.COMPLETED;
  }

  getDownloadUrl = (target, jobIdIn) => {
    this.setState({ downloadUrl: {
      ...this.state.downloadUrl,
      jobId: jobIdIn,
      loading: true
    }});
    Controller.fetchDownloadUrl(jobIdIn).then(link => {
      if (!link.hasOwnProperty('error')) {
        this.setState({
          downloadUrl: {
            jobId: jobIdIn,
            loading: false,
            url: link,
            error: { isError: false, message: null }
        }});
        //The report will open in a new tab. 
        window.open(link, "_blank");
      } else {
        this.setState({
          anchorEl: target,
          downloadUrl: {
            jobId: jobIdIn,
            loading: true,
            url: null,
            error: { isError: true, message: link.error }
        }});
      }
    });
  }

  getUrlStatusPop = () => {
    return (
      <Popover
        open={ Boolean(this.state.anchorEl) }
        anchorEl={ this.state.anchorEl }
        onClose={ () => {
          this.setState({ anchorEl: null, downloadUrl: { ...this.state.downloadUrl, loading: false } });
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className={ this.props.classes.command }>
          <CardContent>
            { this.state.downloadUrl.error.isError && (
              <Typography className={ this.props.classes.popmessage } variant='caption' color='error'>
                { this.state.downloadUrl.error.message }
              </Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
    );
  }

  checkDeviceTypeExists = (deviceTypeListSoFar, deviceTypeFromJobs) => {
    if (deviceTypeListSoFar) {
      for (var i=0; i<deviceTypeListSoFar.length; i++) {
        if (deviceTypeListSoFar[i].id === deviceTypeFromJobs)
          return true;
      }
    }
    return false;
  }

  getSelectField = (id, label, hintText, options, selected) => {
    return (
      <AWSUI.FormField
        label={ label }
        hintText={ hintText }
      >
        <div style={{ maxWidth: 600 }}>
          <AWSUI.Select
            options={ options }
            selectedId={ selected }
            onChange={ event => { this.onChangeSelect(id, event) } }
            enableFiltering={ true }
            filteringLabel='label'
            disabled = { this.isDropdownEnabled(id) }
          >
          </AWSUI.Select>
        </div>
      </AWSUI.FormField>
    );
  }

  getInputField = (id, label) => {
    return (
      <AWSUI.FormField
        label={ label }
      >
        <div style={{ maxWidth: 600 }}>
          <AWSUI.Input
            value={ this.state[id] }
            onChange={ event => {
              if (event.detail.value !== AppConstants.EMPTY) {
                this.onChangeInput(id, event)
            }}}
            onInput={ event => {
              if (event.detail.value === AppConstants.EMPTY) {
                this.setState({
                  [id] : AppConstants.EMPTY
                })
            }}}
          ></AWSUI.Input>
        </div>
      </AWSUI.FormField>
    );
  }

  getDateInputField = (id, label) => {
    return (
      <AWSUI.FormField
        label={ label }
      >
        <DatePicker
          customInput={ <CustomCalendarInput /> }
          selected={ this.state[id] }
          maxDate={ {} }
          onChange={ date => { this.setState({ [id]: date }) } }
        />
      </AWSUI.FormField>
    );
  }

  validateInputDateRange = (fromDate, toDate) => {
    var isInputDateRangeValid = fromDate.startOf('day') <= toDate.startOf('day');
    this.setState({
      isDateRangeValid: isInputDateRangeValid
    });
    return isInputDateRangeValid;
  }

  queryJobsWithFilters = () => {
    if (this.validateInputDateRange(this.state.fromDate, this.state.toDate)) {
      this.getJobs(Controller.generateJobQuery(this.state.fromDate.startOf('day'), this.state.toDate.endOf('day')));
    }
  }

  onChangeSelect = (id, event) => {
    if (id !== SCENARIO_TYPE) {
      this.setState({
        [id]: event.detail.selectedOption.id
     })
    } else {
      let scenarioSelected = event.detail.selectedOption.id;
      if (SCENARIO_WITH_NO_TEST_TYPE.includes(scenarioSelected)) {
        this.setState({
          [id]: event.detail.selectedOption.id,
          testType: event.detail.selectedOption.id,
          testTypeDisabled: true,
       })
      } else {
        this.setState({
          [id]: event.detail.selectedOption.id,
        })
        if (this.state.testTypeDisabled) {
          this.setState({
            testType : AppConstants.ALL,
            testTypeDisabled: false
          })
        }
      }
    }

    if (Util.isAQTEnv() && id === 'testSuite') {
      // Reset the filter criteria when test suite is switched from the dropdown
      // For FTV use case, we dont have a requirement to reset all the search fields when test suite is changed. 
      this.resetAllSearchCriteria();
      this.getJobs(AppConstants.EMPTY);
    }
  }

  resetAllSearchCriteria = () => {
    this.setState({
      //Setting true since the date range will be valid
      //after reset. The the case where there was a invalid date range, 
      //This will reset to a valid date range. 
      isDateRangeValid: true,
      testSuite: AppConstants.ALL,
      jobStatus: AppConstants.ALL,
      testResult: AppConstants.ALL,
      scenarioType: AppConstants.ALL,
      testType: AppConstants.ALL,
      labSelected: AppConstants.ALL,
      localeSelected: AppConstants.ALL,
      deviceVirtualAssistant: AppConstants.ALL,
      deviceType: AppConstants.EMPTY,
      fromDate: moment(`${moment().year()}-01-01`),
      toDate: moment(),
    });
  }

  isDropdownEnabled = (id) => {
    if (id === TEST_TYPE) {
      if (SCENARIO_WITH_NO_TEST_TYPE.includes(this.state.scenarioType)) {
        return true;
      }
    }
    return false;
  }

  onChangeInput = (id, event) => {
    this.setState({
      [id]: event.detail.value
    });
  }

  setDefaultDeviceTypeDropdown() {
    if (this.state.deviceType)
      return this.state.deviceType;
    if (this.state.deviceTypeList)
      return this.state.deviceTypeList[0].id;
    return AppConstants.ALL;
  }

  render() {
    // Logic to dynamically change columns displayed on Results page based on what value is selected
    // in "Test Suite" dropdown
    let resultsPageColumns = COLUMN_DEFINITIONS;
    let locationsToRemove = AppConstants.resultsPageColumnsMap[this.state.testSuite];
    let envColumnsToRemove = AppConstants.resultsPageColumnEnvFilterMap[Util.getEnv()];
    for (var i = 0; i < locationsToRemove.length; i++) {
      resultsPageColumns = resultsPageColumns.filter(item => item.id !== locationsToRemove[i]);
    }
    for (var j = 0; j < envColumnsToRemove.length; j++) {
      resultsPageColumns = resultsPageColumns.filter(item => item.id !== envColumnsToRemove[j]);
    }

    const {
      scenarioType = AppConstants.EMPTY,
      testType = AppConstants.EMPTY,
      testSuite
    } = this.state;

    return (
      <div>
        { this.state.jobs.error.isError || this.state.jobs.loading ? (
          (() => {
            if (this.state.jobs.error.isError) {
              return (
                <AWSUI.Alert
                  header='Cannot retrieve results at this time'
                  content={ this.state.jobs.error.message }
                  type='error'
                ></AWSUI.Alert>
              )
            } else {
              return ( <div align='center'><CircularProgress size={ 50 } /></div> )
            }
          })()
        ) : (
          <div>
            <AWSUI.FormSection header='Results Filter' className={ this.props.classes.filterContainer }>
              <AWSUI.ColumnLayout columns={ 3 }>
                <div data-awsui-column-layout-root='true'>
                  { this.getDateInputField(FROM_DATE, startCase(FROM_DATE))}
                  { this.getDateInputField(TO_DATE, startCase(TO_DATE))}
                </div>
              </AWSUI.ColumnLayout>
              <AWSUI.ColumnLayout columns={ 1 }>
              <AWSUI.FormField
                errorText={ this.state.isDateRangeValid ? AppConstants.EMPTY : AppConstants.INVALID_DATE_RANGE } />
              </AWSUI.ColumnLayout>
                <div className='column-layout-5' >
                <div className='column-item'>
                 { this.getSelectField('testSuite', 'Test Suite', AppConstants.EMPTY,
                    this.state.allowlistedScenarioIds,
                    this.state.testSuite ? this.state.testSuite : AppConstants.EMPTY) }
                </div>
                <div className='column-item'>
                 { this.getSelectField('jobStatus', 'Test Status', AppConstants.EMPTY,
                     Util.isFireTVEnv() ? STATUS_FTV : STATUS,
                     this.state.jobStatus ? this.state.jobStatus : AppConstants.EMPTY) }
                </div>
                <div className='column-item'>
                 { Util.isFireTVEnv() && this.getSelectField('testResult', 'Test Result', AppConstants.EMPTY,
                     RESULT_FTV,
                     this.state.testResult ? this.state.testResult : AppConstants.EMPTY) }
                </div>
                <div className='column-item'>
                 { this.state.testSuite !== AppConstants.SECURITY_SCENARIO_ID &&
                   (
                    this.getSelectField('labSelected', 'Lab', AppConstants.EMPTY,
                      this.state.labs,
                      this.state.labSelected ? this.state.labSelected : AppConstants.EMPTY)
                   )
                 }
                 </div>
                 <div className='column-item'>
                 { this.state.testSuite !== AppConstants.SECURITY_SCENARIO_ID &&
                   (
                    this.getSelectField('localeSelected', 'Locale', AppConstants.EMPTY,
                      LOCALE,
                      this.state.localeSelected ? this.state.localeSelected : AppConstants.EMPTY)
                   )
                 }
                 </div>
                 { this.state.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID &&
                    (this.getSelectField('scenarioType', AppConstants.ACOUSTIC_SCENARIO_LABEL, AppConstants.EMPTY,
                      SCENARIO_TYPES_RESULTPAGE, scenarioType)) }
                 { this.state.testSuite === MusicConstants.MUSIC_SCENARIO_ID &&
                     (this.getSelectField('scenarioType', MusicConstants.MUSIC_SCENARIO_LABEL, AppConstants.EMPTY,
                      MSP_RESULTPAGE, scenarioType)) }
                 { this.state.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID &&
                     (this.getSelectField('scenarioType', AppConstants.FUNCTIONAL_SCENARIO_LABEL, AppConstants.EMPTY,
                      Util.isLocalEnvironment() || Util.isAlphaEnvironment() ?
                        FUNCTIONAL_SCENARIO_TYPES_RESULTSPAGE_ALPHA :
                        FUNCTIONAL_SCENARIO_TYPES_RESULTSPAGE, scenarioType)) }
                 { this.state.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID &&
                    (this.getSelectField('scenarioType', AppConstants.CLOSE_TALK_SCENARIO_LABEL, AppConstants.EMPTY,
                      CLOSE_TALK_SCENARIO_TYPE_RESULTSPAGE, scenarioType)) }
                 { this.state.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID &&
                    (this.getSelectField('scenarioType', AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_LABEL, AppConstants.EMPTY,
                      AUTO_LOCAL_SEARCH_SCENARIO_TYPES_RESULTSPAGE, scenarioType)) }
                 { this.state.testSuite === AppConstants.MOBILE_SUITE_ID &&
                    (this.getSelectField('scenarioType', AppConstants.MOBILE_SCENARIO_LABEL, AppConstants.EMPTY,
                      MOBILE_SCENARIO_TYPE_RESULTSPAGE, scenarioType)) }
                 { this.state.testSuite === AppConstants.STABILITY_SCENARIO_ID &&
                    (this.getSelectField('scenarioType', AppConstants.STABILITY_SCENARIO_LABEL, AppConstants.EMPTY,
                    STABILITY_SCENARIO_TYPES_RESULTSPAGE, scenarioType)) }
                 { this.state.testSuite === AppConstants.UPL_SCENARIO_ID &&
                    (this.getSelectField('scenarioType', AppConstants.UPL_SCENARIO_LABEL, AppConstants.EMPTY,
                    UPL_SCENARIO_TYPES_RESULTSPAGE, scenarioType)) }
                 {
                   this.state.testSuite === AppConstants.QUAL_SCENARIO_ID &&
                   this.getSelectField(
                     'scenarioType',
                     this.getScenarioLabel(this.state.testSuite),
                     AppConstants.EMPTY,
                     this.getScenarioTypes(this.state.testSuite),
                     scenarioType
                    )
                 }
                 {
                   this.state.testSuite === AppConstants.QUAL_SCENARIO_ID &&
                   this.getSelectField(
                     'testType',
                     'Test Type',
                     AppConstants.EMPTY,
                     this.getTestTypes(this.state.testSuite),
                     testType
                    )
                 }
                 { (this.state.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID
                      || this.state.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID
                      || this.state.testSuite === AppConstants.MOBILE_SUITE_ID) &&
                   (this.getSelectField('testType', 'Test Type', AppConstants.EMPTY,
                     TEST_TYPES_RESULTPAGE, testType))
                 }
                 {
                   this.state.testSuite === MusicConstants.MUSIC_SCENARIO_ID
                    && (this.getSelectField('testType', 'Test Type', AppConstants.EMPTY,
                    TEST_TYPES_MUSIC_RESULTPAGE, testType))
                 }
                 {
                   this.state.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
                    && (this.getSelectField('deviceVirtualAssistant',
                          AppConstants.DEVICE_VIRTUAL_ASSISTANT_LABEL,
                          AppConstants.EMPTY,
                          DEVICE_VIRTUAL_ASSISTANT_AUTO_LOCAL_SEARCH_RESULTPAGE,
                          this.state.deviceVirtualAssistant ? this.state.deviceVirtualAssistant : AppConstants.EMPTY)) }
                 {
                   this.state.testSuite === AppConstants.STABILITY_SCENARIO_ID
                    && (this.getSelectField('testType', 'Test Type', AppConstants.EMPTY,
                    STABILITY_SCENARIO_TYPES_RESULTSPAGE, testType))
                 }
                 {
                   this.state.testSuite === AppConstants.UPL_SCENARIO_ID
                    && (this.getSelectField('testType', 'Test Type', AppConstants.EMPTY,
                    UPL_SCENARIO_TYPES_RESULTSPAGE, testType))
                 }
                 { !(Util.isFireTVEnv())
                    && (this.state.testSuite !== AppConstants.SECURITY_SCENARIO_ID)
                   (
                    this.getInputField('deviceType', 'Amazon ID')
                   )
                 }
                 {
                   this.state.testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID &&
                   this.getSelectField(
                     'testType',
                     'Test Type',
                     AppConstants.EMPTY,
                     this.getTestTypes(this.state.testSuite),
                     testType
                    )
                 }
                </div>
                <AWSUI.ColumnLayout columns={4}>
                  <div data-awsui-column-layout-root='true'>
                    <div className='awsui-util-mt-m'><p></p></div>
                    <div className='awsui-util-mt-m' style={{ textAlign: 'center' }}>
                      <AWSUI.Button text='Reset Filters' variant='primary'
                        onClick={() => {
                          this.resetAllSearchCriteria();
                        }} />
                    </div>
                    <div className='awsui-util-mt-m' style={{ textAlign: 'center' }}>
                      <AWSUI.Button text='Search' variant='primary'
                        onClick={() => {
                          this.queryJobsWithFilters();
                        }} />
                    </div>
                  </div>
                </AWSUI.ColumnLayout>
              </AWSUI.FormSection>
           {
              <AWSUI.Table
                columnDefinitions={ resultsPageColumns }
                items={ this.state.jobs.data }
                empty={<p>No records found.</p>}
              >
                <AWSUI.TableSorting sortableColumns={ SORTABLE_COLUMNS }/>
                  <AWSUI.TableFiltering
                    filteringPlaceholder='Search Results'
                  />
                <AWSUI.TablePagination />
              </AWSUI.Table>
           }
          </div>
        )}
      </div>
    );
  }
}


Results.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object,
  session: PropTypes.object.isRequired,
  environment: PropTypes.object.isRequired
};

export default withStyles(styles, {params:{}})(Results);
import AppConstants from '../../Constants/AppConstants';

export function setEnvironment(environment){
  return { type: AppConstants.ENVIRONMENT, environment }
}

export function setSession(session) {
  return { type: AppConstants.SESSION, session }
}

export function setLiveFeed(actionType, liveFeed) {
  return { type: actionType, liveFeed }
}

export function saveState(stateName, inputState) {
  return { type: AppConstants.SAVE, name: stateName, inputState }
}

export function deleteState(stateName) {
  return { type: AppConstants.DELETE, name: stateName }
}
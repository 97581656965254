import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectedTests } from '../../../stores/newRun/testList/testListSlice';
import { executeTest } from '../../../stores/newRun/testList/testListMiddleware';
import { setInitiateTestExecution, setStatus, testListUISelector } from '../../../stores/newRun/testList/testListUiSlice';
import { API_STATUS } from '../../../constants';
import { Alert, Modal, Table, Button } from '@amzn/awsui-components-react';

export default () => {
  const allSelectedTests = useSelector(selectedTests);
  const { testExecuteStatus, initiateExecuteTest } = useSelector(testListUISelector);
  const dispatch = useDispatch();

  const handleCancel = (e) => {
    if(testExecuteStatus === API_STATUS.PENDING) {
      e.preventDefault();
    } else {
      dispatch(setInitiateTestExecution(false));
      dispatch(setStatus(API_STATUS.INIT));
    }
  }

  const handleOkClick = () => {
    dispatch(executeTest());
  }

  const testExecuteCallInitiated = () => {
    return (testExecuteStatus === API_STATUS.SUCCESS || 
      testExecuteStatus === API_STATUS.ERROR || 
      testExecuteStatus === API_STATUS.PENDING)
  }

  const getColumnHeaders = () => {
    return [
      {
        id: "testGroupName",
        header: <FormattedMessage id="TEST_NAME" />,
        cell: e => e.name,
      },
      {
        id: "testSuite",
        header: <FormattedMessage id="TEST_SUITE" />,
        cell: e => e.testSuite.displayName
      },
      {
        id: "locale",
        header: <FormattedMessage id="LOCALE" />,
        cell: e => e.locale
      }
    ]
  }

  let executionView;
  let modalFooter;
  if(testExecuteStatus === API_STATUS.SUCCESS) {
    executionView = (
      <Alert type="success" className="awsui-util-mh-s awsui-util-mv-s" >
        <FormattedMessage id="TESTS_HAVE_BEEN_SUBMITTED_FOR_EXECUTION_SUCCESSFULLY" />
      </Alert>
    );
    modalFooter = (
      <span className="awsui-util-f-r">
        <Button onClick={(handleCancel)}>
          <FormattedMessage id="OK" />
        </Button>
      </span>
    )
  } else {
    executionView = (
      <>
        {
          testExecuteStatus === API_STATUS.ERROR && <Alert type="error" className="awsui-util-mh-s awsui-util-mv-s" >
            <FormattedMessage id="FAILED_TO_EXECUTE_TEST_RETRY_AGAIN" />
          </Alert>
        }
        <Table
          columnDefinitions={getColumnHeaders()}
          items={allSelectedTests}
          loading={false}
        />
      </>
    )
    modalFooter = (
      <span className="awsui-util-f-r">
        <span className="awsui-util-mr-s"><FormattedMessage id="ARE_YOU_SURE_YOU_WANT_TO_PROCEED" /></span>
        <Button variant="link" onClick={handleCancel}>
          <FormattedMessage id="CANCEL" />
        </Button>
        <Button data-testid = "proceedBtn" variant="primary" onClick={(handleOkClick)} loading={testExecuteStatus === API_STATUS.PENDING} disabled={allSelectedTests.length === 0}>
          <FormattedMessage id="PROCEED" />
        </Button>
      </span>
    )
  }


  return (
    <div>
      <Modal
        header={!testExecuteCallInitiated() && <FormattedMessage id="FOLLOWING_TESTCASES_WILL_BE_EXECUTED_IN_ORDER" />}
        visible={initiateExecuteTest}
        onDismiss={handleCancel}
        size="large"
        footer={modalFooter}
      >
        <div className="executeTestPopup">
          {
            executionView
          }
        </div>
      </Modal>
    </div>
  );
}

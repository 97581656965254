
import {AppConstants} from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
export const LABS_EXPANDABLE_COLUMNS = [{
    id: 'labName',
    cell: item => item.labName,
    minWidth: '300px',
    allowLineWrap: false
}];

export const OVERVIEW_COLUMNS = [{
    id: 'raspiName',
    header: () => 'Host Name',
    cell: item => ( item.name ),
    minWidth: '200px',
    allowLineWrap: true
  }, {
    id: 'location',
    header: () => 'Location',
    cell: item => ( item.location ),
    minWidth: '100px',
    allowLineWrap: false
  }, {
    id: 'status',
    header: () => 'Status',
    cell: item => ( item.getPingStatus(item.labId, item.rasPiIndex) ),
    minWidth: '100px',
    allowLineWrap: false
  }, {
    id: 'downloadStatus',
    header: () => 'Download Status',
    cell: item => ( item.getDownloadStatus(item.labId, item.rasPiIndex) ),
    minWidth: '550px',
    allowLineWrap: true
  }, {
    id: 'setup',
    header: () => 'Setup and Calibrate',
    cell: item => ( item.getSetupAndCalibrate(item.id, item.labId, item.rasPiIndex, AppConstants.rasPiAction.CALIBRATE.id) ),
    minWidth: '50px',
    allowLineWrap: false
}];

export const OVERVIEW_COLUMNS_FIRETV = [{
  id: 'raspiName',
  header: () => 'Host Name',
  cell: item => ( item.name ),
  minWidth: '200px',
  allowLineWrap: true
},  {
  id: 'status',
  header: () => 'Status',
  cell: item => ( item.getPingStatus(item.labId, item.rasPiIndex) ),
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'downloadStatus',
  header: () => 'Download Status',
  cell: item => ( item.getDownloadStatus(item.labId, item.rasPiIndex) ),
  minWidth: '550px',
  allowLineWrap: true
}, {
  id: 'setup',
  header: () => 'Setup',
  cell: item => ( item.getSetupAndCalibrate(item.id, item.labId, item.rasPiIndex, AppConstants.rasPiAction.CALIBRATE.id) ),
  minWidth: '50px',
  allowLineWrap: false
}];

export const DETAIL_COLUMNS = [{
    id: 'raspiName',
    header: () => 'Host Name',
    cell: item => ( item.name ),
    minWidth: '200px',
    allowLineWrap: true
  }, {
    id: 'musiclocation',
    header: () => 'Location',
    cell: item => ( item.musiclocation ),
    minWidth: '100px',
    allowLineWrap: true
  }];

export const SORTABLE_COLUMNS = [
];

export const DUT_DETAIL_COLUMNS = [{
  id : 'dsn',
  header: ()=> 'DSN',
  cell: item => (item.dsn),
  minWidth: '200px',
  allowLineWrap: true
},
{
  id : 'dtid',
  header: ()=> 'Device Type ID',
  cell: item => (item.dtid),
  minWidth: '200px',
  allowLineWrap: true
},
{
  id : 'build_number',
  header: ()=> 'Build Number',
  cell: item => (item.build_number),
  minWidth: '200px',
  allowLineWrap: true
},
{
  id : 'locale',
  header: ()=> 'Locale',
  cell: item => (item.locale),
  minWidth: '200px',
  allowLineWrap: true
}]
import React, { useEffect } from "react";
import Tabs from "@amzn/awsui-components-react-v3/polaris/tabs";
import Icon from "@amzn/awsui-components-react-v3/polaris/icon";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom'

export default () => {
  //Used to set the active tab id, which correspondingly 
  //highlights the selected tab. 
  const history = useHistory();
  const appTabs = [
    {
      label: <div> <Icon name="home" />
        &nbsp;Home
      </div>,
      id: "first",
      href: '/home'
    },
    {
      label: <div> <Icon name="settings" />
        &nbsp;Labs
      </div>,
      id: "second",
      href: '/labs'
    },
    {
      label: <div> <Icon name="refresh" />
        &nbsp;New Run
      </div>,
      id: "third",
      href: '/v2/run'
    },
    {
      label: <div> <Icon name="share" />
        &nbsp;Live Runs
      </div>,
      id: "fourth",
      href: '/liveRuns'
    },
    {
      label: <div> <Icon name="download" />
        &nbsp;Results
      </div>,
      id: "fifth",
      href: '/results'
    }];
  const [activeTabId, setActiveTabId] = React.useState('');
  useEffect(() => {
    
    //handles only browser navigation;
    const handlePageNavigation = () => {
      console.log(history.location.pathname);
      const activeTab = appTabs.find(tab => history.location.pathname.startsWith(tab.href));
      const activeId =  activeTab ? activeTab.id : 'first'
      setActiveTabId(activeId);
    }

    const handlePageFirstLoad = () => {
      const activeTab = appTabs.find(tab => history.location.pathname.startsWith(tab.href));
      const activeId =  activeTab ? activeTab.id : 'first'
      var defaultPath = activeTab ? activeTab.href : '/home';
      setActiveTabId(activeId);
      history.push(defaultPath);
    }

    //handle initial page load. 
    handlePageFirstLoad();
    
    window.addEventListener('popstate', handlePageNavigation);
   
    return () => {
      window.removeEventListener('popstate', handlePageNavigation);
    };
  }, [history]);
  return (
    <div>
      <Tabs
        className="tabs"
        onChange={(event) => {
          setActiveTabId(event.detail.activeTabId);
          history.push(event.detail.activeTabHref);
        }}
        activeTabId={activeTabId}
        tabs={appTabs}
        variant="container"
      />
    </div>
  );
}

import axios from 'axios';

import {ApiConstants, AppConstants, AQTHotspotConstants, Store, SyncResourceConstants, Util, Auth} from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

import { v4 as uuidv4 } from 'uuid';
import ld from 'lodash';

export var jobSockets = {};

export function convertStrToJson(strObj) {
  if (strObj !== null && strObj.length > 0) {
    return (0, eval)('(' + strObj + ')');
  } else {
    return null;
  }
}

export function fetchLabsAndRasPis() {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  let labsAndThingsPromises = [];
  return axios.get(controllerEndpoint +
    ApiConstants.LABS_URL,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(labs => {
      if (labs.hasOwnProperty('data')) {
        Util.logToConsole('Labs: ' + JSON.stringify(labs.data));
        labs.data.forEach(labMap => {
          labsAndThingsPromises.push(
            axios.get(controllerEndpoint +
              ApiConstants.LABS_URL + '/' +
              labMap.id + '/' +
              ApiConstants.THINGS,
              Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
              .then(rasPis => {
                return {
                  lab: labMap,
                  rasPis: rasPis.data ? rasPis.data : []
                };
              })
              .catch(error => {
                Util.networkError(error, fetchLabsAndRasPis.name);
                return {
                  error: AppConstants.NETWORKERR
                };
              })
          );
        });
        return labsAndThingsPromises;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchLabsAndRasPis.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function fetchLabs() {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.LABS_URL,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(labs => {
      if (labs.hasOwnProperty('data')) {
        Util.logToConsole('Labs: ' + JSON.stringify(labs.data));
        return labs.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchLabs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function fetchRasPis(labId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.LABS_URL + '/' +
    labId + '/' +
    ApiConstants.THINGS,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(rasPis => {
      if (rasPis.hasOwnProperty('data')) {
        Util.logToConsole('RasPis: ' + JSON.stringify(rasPis.data));
        return rasPis.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchRasPis.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function getRasPiSetupCommand(rasPiId, pluginsToInstall) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    let envType = Store.aqtStore.getState().environment.envType;
    let pythonVersion = "";
    if (AppConstants.FIRE_TV_ENV_TYPE == envType) {
      //Logic for fire tv specific query params. 
      pluginsToInstall.push(AppConstants.FIRE_TV_PLUGINS);
      pythonVersion = "3.8.11";
    }
    let queryString = getQueryParamsForRaspiSetup(pluginsToInstall, pythonVersion);
    return axios.get(controllerEndpoint +
      ApiConstants.THING_SETUP_URL.replace('thingId', rasPiId) + queryString,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(thingSetupCom => {
        if (thingSetupCom.hasOwnProperty('data')) {
          Util.logToConsole('RasPi Setup Command: ' + thingSetupCom.data.cmdLine);
          return {
            data: thingSetupCom.data.cmdLine
          };
        } else {
          return {
            error: AppConstants.NOCMDLINE
          };
        }
      })
      .catch(error => {
        Util.networkError(error, getRasPiSetupCommand.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

export function getQueryParamsForRaspiSetup (pluginsToInstall, pythonVersion) {
  let queryParams = new URLSearchParams();
  if (pluginsToInstall && !(pluginsToInstall.length === 0)) {
    queryParams.append("plugins", pluginsToInstall);
  } 
  if (pythonVersion && !(pythonVersion.length === 0)) {
    queryParams.append("pythonVersion", pythonVersion);
  }
  let queryString = queryParams.toString();
  //Append "?" if there are actual query parameters to be sent.
  return queryString.length === 0 ? queryString : `?${queryString}`;  
}

/**
 * Invoke v2 update api to perform software update on ubuntu thing
 * @param labId
 * @param rasPiId
 * @param actionId
 * @returns {Promise<unknown>|Promise<AxiosResponse<any> | {error: *}>}
 */
export function actionUbuntu(labId, rasPiId, actionId) {
  // TODO: write wrappers to customize plugins as params to this request
  const updatePayload = {
    "packages": [
      {
        "name": "mdxirs",
        "version": "latest"
      },
      {
        "name": "mdx_plugin_aqt_automotive",
        "version": "latest"
      }
    ]
  }

  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.LABS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction[actionId].action + '/' +
      'v2',
      updatePayload,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return {success: rasPiStatus.data};
        } else {
          return {error: AppConstants.SERVERERR}
        }
      })
      .catch(error => {
        Util.networkError(error, actionRasPi.name);
        return {
          error: error.message
        };
      });
  }
}

export function actionRasPi(labId, rasPiId, actionId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
      ApiConstants.LABS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction[actionId].action,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        Util.networkError(error, actionRasPi.name);
        return {
          error: error.message
        };
      });
  }
}

export function actionRasPiWithBodyAndAckTimeout(labId, rasPiId, actionId, body, ackTimeout) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    const action = AppConstants.rasPiAction[actionId] ? AppConstants.rasPiAction[actionId].action : actionId;
    console.log(action);
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.LABS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      action +
      '?ackTimeout=' + ackTimeout,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          Util.logToConsole('RasPi status : ' + rasPiId + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        Util.networkError(error, actionRasPiWithBodyAndAckTimeout.name);
        return {
          error: AppConstants.NETWORK_ERROR
        };
      });
  }
}

export function actionLab(labId, actionId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!labId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NOLab
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
      ApiConstants.LABS_URL + '/' +
      labId + '/' +
      AppConstants.rasPiAction[actionId].action,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        Util.networkError(error, actionRasPi.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}


export function getAllowlistedScenarios() {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.ALLOWLISTED_SCENARIOS_URL,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.hasOwnProperty('data')) {
        Util.logToConsole('Get allowlisted scenarios: ' + JSON.stringify(response.data));
        return { scenarios: response.data ? response.data : [] };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      Util.networkError(error, getAllowlistedScenarios.name);
      return { error: AppConstants.NETWORKERR };
    });
}

export function talkRasPi(labId, rasPiId, fileName, timeOut,
  rasPiControlName = AppConstants.RAS_PI_CONTROL_NAME) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    let body = {
      'waveFileName': fileName,
      'volume': 100,
      'controlName': rasPiControlName
    }
    return axios.post(controllerEndpoint +
      ApiConstants.LABS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction.TALK.action +
      '?actionTimeout=' + timeOut,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          Util.logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        Util.networkError(error, talkRasPi.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

/**
 * Gets the list of manifests for particular lab and location
 */
export function getManifestsForRasPi(labId, rasPiId, ackTimeout, actionTimeout) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.LABS_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      SyncResourceConstants.ACTION_GET_MANIFESTS_STATE +
      '?ackTimeout=' + ackTimeout +
      '&actionTimeout=' + actionTimeout,
      SyncResourceConstants.GET_MANIFESTS_STATE_BODY,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        Util.networkError(error, getManifestsForRasPi.name);
        return {
          error: AppConstants.NETWORK_ERROR
        };
      });
  }
}

/**
 * Performs a put into Labs table updating preference column for given lab.
 * @param labId id of Lab for which preference column is updated.
 * @param preferenceStr user preference json string of raspi location and name
 * @returns success -> {success: success code, data: response data}
 *          error -> {error: error code}
 */
export function saveLabUserPreference(labId, preferenceStr) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!labId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NOLABS
      })
    });
  } else {
    let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
    let body = {
      'preference': preferenceStr
    }
    return axios.put(controllerEndpoint +
      ApiConstants.LABS_URL + '/' + labId,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
      .then(labUpdateResponse => {
        if (labUpdateResponse.hasOwnProperty('status')
          && labUpdateResponse.hasOwnProperty('data')
          && labUpdateResponse.status == 200) {
          return {
            success: labUpdateResponse.status,
            data: labUpdateResponse.data
          };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        Util.networkError(error, talkRasPi.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

/**
 * send MQTT message to RasPi
 *
 * @param {string} companyId
 * @param {string} rasPiName
 * @param {object} payload
 */
export function sendMQTTMessage(companyId, rasPiName, payload, timeout = AQTHotspotConstants.MQTT_TIMEOUT) {

  let uniqId = uuidv4();
  let topic = companyId + "/" + companyId + "-" + rasPiName + "/" + uniqId;

  const mqttPayload = {
    requestTopic: topic + "/request",
    responseTopic: topic + "/response",
    payload,
  };

  Util.logToConsole("Sending MQTT message: ", mqttPayload);

  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }

  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.post(controllerEndpoint +
    ApiConstants.MQTT_MESSAGE_URL +
    '?waitForActionTime=' + timeout,
    mqttPayload,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      Util.logToConsole("Response from MQTT: ", response);
      return response;
    })
    .catch(error => {
      Util.logToConsole("Error from MQTT: ", error);
      return error.response.data;
    });

}

/**
 * Perfroms controller api call send MQTT message
 * invoke plugin actions
 *
 * @param {string} companyId
 * @param {string} rasPiName
 * @param {string} payload
 */
export function commonOps(companyId, rasPiName, payload) {
  return sendMQTTMessage(companyId, rasPiName, payload)
    .then(response => {
      if (response.error) {
        let payload = convertStrToJson(response.error);
        // console.log('response :', payload);
        if (payload.state == 'failed') {
          return new Promise(resolve => { resolve(false) });
        }
      } else {
        try {
          const actualResponse = convertStrToJson(response.data);
          const actualPayload = convertStrToJson(actualResponse.payload);
          if (actualPayload.code == 200) {
            return new Promise(resolve => { resolve(true) });
          }
        } catch (err) {
          Util.logToConsole('Exception caught: ', err)
        } finally {
          return new Promise(resolve => { resolve(false) });
        }
      }
    });
}

/**
 * Utility function to ping the raspi for
 * online/offline check
 *
 * @param {string} labId
 * @param {string} rasPiId
 */
export function pingRasPi(labId, rasPiId) {
  return actionRasPi(labId, rasPiId, 'STATE').then(response => {
    console.log(response.success.payload.thingStatus);
    let rasPiStatus = response.success.payload.thingStatus || AQTHotspotConstants.RASPI_OFFLINE;
    return new Promise(resolve => {
      resolve(rasPiStatus)
    })
  })
    .catch(error => {
      console.log("Exception caught while fetching the ping status for ".rasPiId);
      return new Promise(resolve => {
        resolve(AQTHotspotConstants.RASPI_OFFLINE)
      })
    });
}


/**
 * Create hotspot with the provided ssid and
 * passphrase
 *
 * @param {string} companyId
 * @param {string} rasPiName
 * @param {string} ssid
 * @param {string} passphrase
 */
export function createHotspot(companyId, rasPiName, ssid, passphrase) {
  const payload = { ssid, passphrase };
  let hotspotConfig = AQTHotspotConstants.CREATE_HOTSPOT_PAYLOAD;
  hotspotConfig['payload'] = payload;
  return sendMQTTMessage(
    companyId, rasPiName, hotspotConfig)
    .then(response => {
      Util.logToConsole('response from createHotspot request: ', response);
      if (!ld.isEmpty(response) && ld.has(response, 'data', null)) {
        const responseData = JSON.parse(response.data);
        if (ld.get(responseData, 'payload.code', null) === 200) {
          return true;
        }
      }
      return false;
    });
}


/**
 * Retrieve hotspot configs for the requested raspi
 *
 * @param {string} companyId
 * @param {string} rasPiName
 */
export function getHotspotConfigs(companyId, rasPiName) {
  return sendMQTTMessage(companyId, rasPiName, AQTHotspotConstants.GET_HOTSPOT_CONFIG_PAYLOAD)
    .then(response => {
      if (response && response.data && response.data.length > 0) {
        if (response.data[0].search(AQTHotspotConstants.PLUGIN_NOT_INSTALLED) > -1 || response.data[0].search(AQTHotspotConstants.PLUGIN_NOT_CONFIGURED) > -1) {
          return {
            data: null,
            status: AQTHotspotConstants.PLUGIN_NOT_INSTALLED
          }
        } else {
          return {
            data: convertStrToJson(response.data[0]),
            status: AQTHotspotConstants.PLUGIN_INSTALLED
          }
        }
      } else {
        return {
          data: null,
          status: AQTHotspotConstants.PLUGIN_ERROR
        }
      }
    });
}

/**
 * Fetches connected devices for the hotspot-enabled raspi
 * @param {string} companyId
 * @param {string} rasPiName
 */
export function getConnectedDevices(companyId, rasPiName) {
  return sendMQTTMessage(companyId, rasPiName, AQTHotspotConstants.GET_CONNECTED_DEVICES_PAYLOAD)
    .then(response => {
      if (response.error && response.error.search(AQTHotspotConstants.PLUGIN_NOT_INSTALLED) > -1) {
        return {
          data: null,
          status: AQTHotspotConstants.PLUGIN_NOT_INSTALLED
        }
      } else if (!ld.isNil(response) && ld.has(response, 'data') && response.data.length > 0 && response.status === 200) {
        let data = convertStrToJson(response.data);
        let payload = convertStrToJson(data.payload);
        return {
          data: payload,
          status: AQTHotspotConstants.PLUGIN_INSTALLED
        }
      } else {
        return {
          data: null,
          status: AQTHotspotConstants.PLUGIN_ERROR
        }
      }
    });
}


/**
 * Performs AQT hotspot plugin installation on the raspi
 *
 * @param {string} labId
 * @param {string} rasPiId
 */
export function installHotspotPluginOnRasPi(labId, rasPiId) {
  return actionRasPiWithBodyAndAckTimeout(labId,
    rasPiId,
    AQTHotspotConstants.ACTION_INSTALL_PLUGIN,
    AQTHotspotConstants.PAYLOAD_INSTALL_PLUGIN,
    AQTHotspotConstants.ACTION_TIMEOUT).then(response => {
      console.log(response);
      const status = response.success && response.success.state === 'complete' ? true : false;
      return new Promise(resolve => {
        resolve(status)
      });
    })
    .catch(error => {
      console.log("Exception caught while installing plugin");
      return new Promise(resolve => {
        resolve(0);
      })
    });
}


/**
 * Perfroms ping to raspi with retry mechanism
 *
 * @param {string} labId
 * @param {string} rasPiId
 * @param {int} retryCount
 */
export async function retryPing(labId, rasPiId, retryCount) {
  for (let i = 1; i <= retryCount; i++) {

    await sleep(10000 * i);
    console.log('retrying ping request -', i);
    const status = await pingRasPi(labId, rasPiId);
    switch (status) {
      case AQTHotspotConstants.RASPI_ONLINE:
        return new Promise(resolve => { resolve(AQTHotspotConstants.RASPI_ONLINE) });
      case AQTHotspotConstants.RASPI_OFFLINE:
      default:
        break;
    }
  }
  return new Promise(resolve => { resolve(AQTHotspotConstants.RASPI_OFFLINE) });
}

/**
 * Utility function to sleep asynchronously
 *
 * @param {int} time
 */
export function sleep(time) {
  Util.logToConsole('sleep for', time, 'seconds');
  return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * Utility function to generate item field for calibration option in labs page
 *
 * @param {string} file name prefix
 */

export function generateItemFieldsForCalibration(filename, type) {
  let items = [];
  if (type == "SKILL") {
    ld.each(AppConstants.MARKETPLACE, (val) => {
      if (!["en_CA", "ja_JP"].includes(val.id)) {
        let item = {};
        item.id = val.id + filename;
        item.text = val.label;
        items.push(item);
      }
    });
    return items;
  } else if (type == "HAPPY") {
    ld.each(AppConstants.MARKETPLACE, (val) => {
      if (!["en_CA"].includes(val.id)) {
        let item = {};
        item.id = val.id + filename;
        item.text = val.label;
        items.push(item);
      }
    });
    return items;
  }
}

/**
 * Utility function for creation of a new lab
 *
 * @param {string} labName name of the lab to be created
 * @param {list} hostList list of hosts within the lab
 */
export function addLab(labName, hostList) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let newLabPayload = {}
  newLabPayload["name"] = labName;
  newLabPayload["lock"] = true;
  newLabPayload["rpisName"] = hostList;
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.post(controllerEndpoint +
    ApiConstants.LABS_URL,
    newLabPayload,
    Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      return response;
    })
    .catch(error => {
      return {error: error.response.data};
    });
}
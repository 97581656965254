import { DEVICE_LOCALES } from '../../../../constants';

export let testsuiteOptions = [{
    id: "BAT",
    label: "SDL BAT",
    disabled: false
  }];

export let deviceTypeGroupOptions = [
    {
      id: "ftve",
      label: "FTVE",
      disabled: false
    },
    {
      id: "smp",
      label: "SMP",
      disabled: false
    }
  ]

export let deviceLocaleOptions = [];
  for (var i=0; i<DEVICE_LOCALES.length; i++) {
    deviceLocaleOptions.push({id: DEVICE_LOCALES[i], label: DEVICE_LOCALES[i]})
  }

/**
 * Component to render progress bar
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, AQTTestOptionsParser, labPageSocketHandler, Store, SyncResourceConstants, SyncResourceUtil, Util }
    from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { getLabInformation } from '../../../containers/LiveRun/controller';
import { fetchRasPis } from '../../../containers/RasPiTable/controller';
import openSocket from 'socket.io-client';
import { actionRasPi } from '../../../containers/RasPiTable/controller'
import './AutoSyncProgressBar.css';
import { SYNC_PROGRESS_COLUMNS, AutoSyncProgressBarConstants } from './AutoSyncProgressConfig';

const RESOURCE_SYNC_INTERVAL = 2000;
const jobSockets = {};

class AutoSyncProgressBar extends React.Component {
  // React state for test details & test metrics tab & utterance details
  _mounted = false;

  componentDidMount() {
    // Call getLabInfoAndOpenSocket to get Lab info and open the socket to listen to the pi state
    Util.logToConsole("Get lab Info and Open socket for the lab ID => " + this.props.params.labJobId);
    this.getLabInfoAndOpenSocket(this.props.params.labJobId)
    this._mounted = true;
  }

  componentWillUnmount() {
      this._mounted = false;
      this.closeJobSocketsOfAutoSync(jobSockets);
      clearTimeout(this.lazyLoadJobs);
  }

  state = {
    validRasPiListAndStatus: {},
    raspiResourceSyncData: {}
  };

  closeJobSocketsOfAutoSync(jobSockets) {
    Object.keys(jobSockets).forEach(function (socketId) {jobSockets[socketId].close()});
  }

 /**
 * Retrieves valid raspi locations for current test options and stores in state
 * @param testSuite
 * @param scenarioType
 * @param testType
 */
  getLocationsForTestOptions(testSuite, scenarioType, testType) {
     return AQTTestOptionsParser.get_locations_for_test_options(testSuite, scenarioType, testType,
         this.props.params.customOptions, this.props.params.marketPlace);
  }
  /*
  *  For Data Format for details, please look at https://sim.amazon.com/issues/P22217092
  *  Subscribing to shadowStateChange sometimes give the states of different pis in a single payload
  *  This method will parse the shadow state of each PI(things) and set the state with the calculated progressBar Data
  */
  aggregateResourceSyncData = (raspiResourceSyncData) => {
    Util.logToConsole("Incoming raspi shadow data => " + JSON.stringify(raspiResourceSyncData));
    let validLocationsAndStatus = this.state.validRasPiListAndStatus;
    Object.keys(raspiResourceSyncData).forEach( (thingName) => {
        if (Object.keys(validLocationsAndStatus).includes(thingName)) {
            let piSyncData = raspiResourceSyncData[thingName];
            this.setState({
                raspiResourceSyncData: {
                    ...this.state.raspiResourceSyncData,
                    [thingName]: piSyncData
                }
            });
            Util.logToConsole("Setting resource sync data for the pi => " + thingName)
            Util.logToConsole("Modified state of Resource sync data  => " + JSON.stringify(this.state.raspiResourceSyncData));
        }
    });
  }

  /**
   * Returns true if raspi is part of custom test job mapping.
   * @param {string} testSuite Test Suite name
   * @param {object} testConfig Test config info
   * @param {object} rasPi Raspi object
   */
  isValidLocationForCustomTest = (testSuite, testConfig, rasPi) => {
    let isValid = false;
    if (testSuite == AppConstants.CUSTOM_SCENARIO_ID && testConfig && testConfig.actorLabMapping
        && rasPi && rasPi.thingName) {
          let actorMapping = testConfig.actorLabMapping;
          let actorThingNames = Object.keys(actorMapping).map(function(piLocKey){return actorMapping[piLocKey]});
          isValid = actorThingNames.includes(rasPi.thingName);
        }

    return isValid;
  }

    /**
     * Creates socket connection to IOT Shadow for each raspi in Lab via MDX controller
     * @param labJobId
     */
  getLabInfoAndOpenSocket = (labJobId) => {
    this.lazyLoadJobs = setTimeout(() => {
      let labId = AppConstants.EMPTY
      Util.logToConsole("Get Lab information from LabJodId => " + labJobId)
      getLabInformation(labJobId).then(labInfo => {
        Util.logToConsole("Lab Information => " + JSON.stringify(labInfo))
        labId = labInfo.id;
        let rasPiListAndStatus = {};
        let validLocationsForTest = this.getLocationsForTestOptions(this.props.params.testSuite,
            this.props.params.scenarioType, this.props.params.testType);
        fetchRasPis(labId).then(rasPis => {
            this.buildThingNameToRaspiNameMap(labInfo, rasPis);
          if (!rasPis.hasOwnProperty('error')) {
            rasPis.forEach((rasPi, index) => {
                let raspiLocationName = Util.getRasPiLoc(rasPi, index, AppConstants.defaultLocation, labInfo);
                if (this.isValidLocationForCustomTest(this.props.params.testSuite, this.props.params.testConfig, rasPi)
                    || (this.props.params.testSuite != AppConstants.CUSTOM_SCENARIO_ID
                        && validLocationsForTest.has(raspiLocationName))) {
                    rasPiListAndStatus[rasPi.thingName] = {};
                    actionRasPi(labId, rasPi.id, AppConstants.rasPiAction.STATE.id).then(response => {
                        let status = AppConstants.OFFLINE;
                        if (response.hasOwnProperty("success")
                            && response.success.hasOwnProperty("payload")
                            && response.success.payload.hasOwnProperty("thingStatus")
                            && response.success.payload.thingStatus.toUpperCase().includes(AppConstants.ONLINE.trim().toUpperCase())) {
                              status = AppConstants.ONLINE;
                        } else if (response.hasOwnProperty("error")) {
                          status = AppConstants.STATUS_ERROR;
                        }
                        rasPiListAndStatus[rasPi.thingName] = {
                            status: status
                        }
                        return;});
                }
            });
          }
        });
        this.setState({
            validRasPiListAndStatus: rasPiListAndStatus
        });
        return labId;
      }).then(labId => {
        Util.logToConsole("Opening socket for Lab Id to get resource sync state => " + labId)
        let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
        const jobSocket = openSocket(controllerEndpoint, {
          query: {
            token: Store.aqtStore.getState().session.idToken.jwtToken,
            request: "labLiveStatus",
            labID: labId,
            pushIntervalMilliseconds: RESOURCE_SYNC_INTERVAL
          }
        });
        // Save jobSockets to close it while unmounting the component
        jobSockets[labId] = jobSocket;
        // Open Socket and use aggregateResourceSyncData function to calculate the download progress
        labPageSocketHandler.raspiSocketHandlers(labId, jobSocket, this.aggregateResourceSyncData);
      }).catch(error => {
        Util.logToConsole('Error encountered while fetching labs');
        Util.networkError(error, fetchRasPis.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
    }, RESOURCE_SYNC_INTERVAL);
  }

  buildThingNameToRaspiNameMap = (labInfo, rasPis) => {
    let thingNameToRaspiNameMap = {};
    if (rasPis && labInfo) {
        rasPis.forEach(rasPi => {
            let thingName = rasPi.thingName;
            let raspiLocName = Util.getRasPiName(rasPi, labInfo);
            thingNameToRaspiNameMap[thingName] = raspiLocName;
        })
        this.setState({
            thingNameToRaspiNameMap: thingNameToRaspiNameMap
        });
    }
  }
  /**
   * Gets ovrall progress text to display size of resources downloaded so far
   */
  getProgressBarResourceSizeText = (syncData) => {
    let sizeCompleted = syncData['overall_completed_size'];
    let totalSize = syncData['overall_total_size'];
    return (
        <div>
            <span>
              <b>
              Resources Downloaded (Size):
              </b>
            </span>
          <span>
            {
              syncData.hasOwnProperty('size_remaining') && (
              <span className='green-color-text awsui-util-ml-s'>
                 Current: { SyncResourceUtil.getFileSize(syncData['total_size'] - syncData['size_remaining'], true)} / {SyncResourceUtil.getFileSize(syncData['total_size'], true) }
              </span>)
            }
            <span className='grey-color-text awsui-util-ml-s'>
                Overall: { SyncResourceUtil.getFileSize(sizeCompleted, false)} / {SyncResourceUtil.getFileSize(totalSize, false) }
            </span>
          </span>
        </div>
    )
  }

  /**
   * Get overall progress text to display on overall progress bar
   */
  getOverallProgressText = (syncData) => {
    let overallCompleted = SyncResourceUtil.countAndPercentage(syncData['overall_completed'], syncData['overall_total']);
    let overallFailed = SyncResourceUtil.countAndPercentage(syncData['overall_failed'], syncData['overall_total']);
    let overallPending = SyncResourceUtil.countAndPercentage(syncData['overall_pending'], syncData['overall_total']);
    return (
        <div>
            <span>
              <b>
              Resources Count:
              </b>
            </span>
          <span>
              <span className='green-color-text awsui-util-ml-s'>
                Completed: { overallCompleted }
              </span>
              <span className='red-color-text awsui-util-ml-s'>
                Failed: { overallFailed }
              </span>
              <span className='grey-color-text awsui-util-ml-s'>
                Pending:  { overallPending }
              </span>
            </span>
        </div>
    )
  }

  /**
   * Gets sync status to display for particular resource
   */
  getStatusForResource = (status, syncData) => {
    return (
        <div>
          <AWSUI.ColumnLayout columns={ 3 } borders='none'>
            <div className={ SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[status].class }>
              <AWSUI.Icon name={ SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[status].icon }
                          variant={ SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[status].variant }/>
              <span className = { SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[status].textStyle }>
                      { AutoSyncProgressBarConstants.SYNC_STATES[status]['label'] }
                    </span>
            </div>
            <div>
              <AWSUI.ProgressBar
                  description={ this.getOverallProgressText(syncData) }
                  value={ syncData.overall_total && syncData.overall_total > 0 ? parseInt(syncData.overall_completed, 10) * 100 / parseInt(syncData.overall_total, 10) : 0 }
                  additionalInfo={ this.getProgressBarResourceSizeText(syncData) }
              ></AWSUI.ProgressBar>
            </div>
          </AWSUI.ColumnLayout>
        </div>
    );
  }

    /**
     * Gets current rasPi status to display.
     * @param raspiListAndStatus
     * @param thingName
     * @returns Display DOM for online,offline,error/unavailable cases.
     */
  getPingStatus = (raspiListAndStatus, thingName) => {
    let rasPiStatus = raspiListAndStatus && raspiListAndStatus[thingName] ? raspiListAndStatus[thingName] : 'Unknown';
    if (rasPiStatus) {
      return (
        <div>
          { rasPiStatus.status ? (
            <div className={ rasPiStatus.status === AppConstants.ONLINE ? 'awsui-util-status-positive' : 'awsui-util-status-negative' }>
              <AWSUI.Icon name={ rasPiStatus.status === AppConstants.ONLINE ? 'status-positive' : 'status-negative' } />
              { rasPiStatus.status }
            </div>
          ) : (
            <div align='center'><AWSUI.Spinner /></div>
          )}
        </div>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  getRasPiNameFromThingName = (rasPiName) => {
      if (this.state.thingNameToRaspiNameMap
          && this.state.thingNameToRaspiNameMap.hasOwnProperty(rasPiName)) {
          return this.state.thingNameToRaspiNameMap[rasPiName];
      }
    return rasPiName.substring(rasPiName.lastIndexOf('-') + 1);
  }

  // Renders test feed
  render = () => {
    let syncProgressData = [];
    Util.logToConsole(" AutoSyncProgressBar progress data =>" + JSON.stringify(this.state.raspiResourceSyncData));
    if (this.state.raspiResourceSyncData
          && Object.keys(this.state.raspiResourceSyncData).length > 0) {
        let raspiResourceSyncData = this.state.raspiResourceSyncData;
        // Sorts raspi locations based on sync state; In the order Inprogress -> Failed -> Completed
        raspiResourceSyncData = Object.keys(raspiResourceSyncData).map(function(key) {
            return [key, raspiResourceSyncData[key]];
        }).sort(function(first, second) {
            if(first && second) {
              if(first.length > 1 && first[1].hasOwnProperty('state')
                  && second.length > 1 && second[1].hasOwnProperty('state')) {
                return second[1].state.localeCompare(first[1].state);
              }
            }
          // Return 0 by default if any of the objects in comparison is null
          // so that original order of input is preserved
          return 0;
        });
        raspiResourceSyncData.forEach(raspiThing => {
            syncProgressData.push(
                {
                    raspiName: this.getRasPiNameFromThingName(raspiThing[0]),
                    status: this.getPingStatus(this.state.validRasPiListAndStatus, raspiThing[0]),
                    resourceSyncStatus: this.getStatusForResource(raspiThing[1].state, raspiThing[1].syncData)
                }
            )
        });
    }
    // TODO: Render below sync-progress table only when at-least one entry is available.
    return (
        syncProgressData.length > 0 ? (
          <div >
            <AWSUI.Table
              columnDefinitions={ SYNC_PROGRESS_COLUMNS }
              items={ syncProgressData }
            >
            </AWSUI.Table>
          </div>
        ) : (
          <div>
            {
              Util.getLoadingMessage(SyncResourceConstants.RETRIEVING_AUTO_SYNC_MESSAGE)
            }
          </div>
        )
    );
  }
}

AutoSyncProgressBar.propTypes = {
  params: PropTypes.object.isRequired
};

export default AutoSyncProgressBar;

import { FormSection, ColumnLayout, FormField, Toggle, Select, Input, Textarea, Checkbox } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALE_LIST } from '../../../../constants';
import { setEnableEmailNotification, setId, setName, setTestrailRunId, testSuiteConfigSelector,
  setIsDryRun, setDryRunBundleUrl, setDryRunTestMetadata, setShouldCreateTestrailRun,
  setTestrailSuite, setDeviceLocale, setDeviceTypeGroup, setIsRetryConfigured, setRetryTypes, setRetryTimes, removeRetryTypes
} from '../../../../stores/newRun/acm/scenarioSelectionSlice';
import { testsuiteOptions, deviceLocaleOptions, deviceTypeGroupOptions } from './newRunConfigs';
import { testInitSelector } from '../../../../stores/newRun/testInitSlice';
import ScenarioSelection from './scenarioSelection';
import { isInternalCustomer } from "../../../../services/utils";

export default () => {
  return <div>
    <StandardSelection />
    <ScenarioSelection />
  </div>
}
 
const StandardSelection = () => {
  const {error, enableEmailNotification, locale, name, testrailRunId, 
        isDryRun, dryRunBundleUrl, dryRunTestMetadata, shouldCreateTestrailRun, testrailSuite, deviceLocale, deviceTypeGroup, id, isRetryConfigured, retryTypes, retryTimes} = useSelector(testSuiteConfigSelector);
  const { testSuite } = useSelector(testInitSelector);
  const dispatch = useDispatch();
 
  useEffect(
    ()=> {
      if(!id) {
        dispatch(setId());
      }
    }, []
  )

  const handleEnableEmailNotificationChange = (e) => {
    dispatch(setEnableEmailNotification(e.detail.checked))
  }
 
  const handleNameChange = (ip) => {
    dispatch(setName(ip.detail.value));
  }

  const handleTestrailRunIdChange = (run) => {
    dispatch(setTestrailRunId(run.detail.value));
  }

  const handleDryRunBundleUrlChange = (e) => {
    dispatch(setDryRunBundleUrl(e.detail.value))
  }

  const handleIsDryRunChange = (e) => {
    dispatch(setIsDryRun(e.detail.checked))
  }

  const handleLocalTestMetaDataChange = (e) => {
    dispatch(setDryRunTestMetadata(e.detail.value))
  }
 
  const options = [{
    label: LOCALE_LIST[0],
    id: LOCALE_LIST[0]
  }];
 
  const selectedOption = {
    label: locale,
    id: locale
  };

  const handleRetryTypesChange = (e, value) => {
    if(e.detail.checked) {
      dispatch(setRetryTypes(value))
    } else{
      dispatch(removeRetryTypes(value))
    }
  }

  const handleRetryTimesChange = (e) => {
    dispatch(setRetryTimes(e.detail.value))
  }

  const handleIsRetryConfigured = (e) => {
    dispatch(setIsRetryConfigured(e.detail.checked))
  }

  const handleShouldCreateTestrailRunChange = (e) => {
    setTestrailRunId(null);
    dispatch(setShouldCreateTestrailRun(e.detail.checked))
  }

  const handleTestrailSuiteChange = (event) => {
    dispatch(setTestrailSuite(event.detail.selectedOption))
  }

  const handleDeviceLocaleChange = (event) => {
    dispatch(setDeviceLocale(event.detail.selectedOption))
  }

  const handleDeviceTypeGroupChange = (event) => {
    dispatch(setDeviceTypeGroup(event.detail.selectedOption))
  }
 
  const hasError = error && typeof(error) === "object";
  const testrailComponent = (<FormField label={<FormattedMessage id="TESTRAIL_RUN_ID" />} description={<FormattedMessage id="PROVIDE_TESTRAIL_RUN_ID" />}>
  <Input
    onChange={handleTestrailRunIdChange}
    value={testrailRunId}
    autofocus
    invalid={hasError && !!error["testrailRunId"]}
  />
  {
    hasError && error["testrailRunId"] ? <div className='formErrMsg'>{error["testrailRunId"]}</div> : null
  }
  </FormField>)

  const dryRunBundleDetails = (<>
    <FormField label={<FormattedMessage id="DRY_RUN_BUNDLE_URL" />} description={<FormattedMessage id="DRY_RUN_BUNDLE_URL_DESCRIPTION" />}>
      <Input
        onChange={handleDryRunBundleUrlChange}
        value={dryRunBundleUrl}
        autofocus
        invalid={!!error}  />
        {
          hasError && error.dryRunBundleUrl ? <div className='formErrMsg'>{error.dryRunBundleUrl}</div> : null
        }
    </FormField>
    <FormField label={<FormattedMessage id="DRY_RUN_TEST_META_DATA" />} description={<FormattedMessage id="DRY_RUN_TEST_META_DATA_DESCRIPTION" />}>
      <Textarea
        onChange={handleLocalTestMetaDataChange}
        value={JSON.stringify(dryRunTestMetadata, null, "\t")}
        autofocus
        invalid={!!error}  />
        {
          hasError && error.dryRunTestMetadata ? <div className='formErrMsg'>{error.dryRunTestMetadata}</div> : null
        }
    </FormField>
  </>);


const retryConfigurations = (<>
    <FormField label={<FormattedMessage id="RETRY_TYPE_LABEL" invalid={hasError && !!error["retryTypes"]}/>}>
      {
        hasError && error["retryTypes"] ? (
            <div className="formErrMsg">{error["retryTypes"]}</div>
        ) : null
      }
      <Checkbox label={<FormattedMessage id="RETRY_TYPE_NOT_EXECUTED" />} checked={retryTypes.includes('not_executed')}
                onChange={(event) => handleRetryTypesChange(event, 'not_executed')} />

      <Checkbox label={<FormattedMessage id="RETRY_TYPE_FAILED" />} checked={retryTypes.includes('failed')}
                onChange={(event) => handleRetryTypesChange(event, 'failed')}/>

    </FormField>
    <FormField label={<FormattedMessage id="RETRY_TIMES"/>}>
      <Input type="number" onChange={handleRetryTimesChange}
             value={retryTimes}
             invalid={hasError && !!error["retryTimes"]}/>
      {
        hasError && error["retryTimes"] ? (
            <div className="formErrMsg">{error["retryTimes"]}</div>
        ) : null
      }
    </FormField>
  </>);


const createTestrailRunDetails = (<>
  <FormField label={<FormattedMessage id="TESTRAIL_SUITE" />} description={<FormattedMessage id="TESTRAIL_SUITE_DESC" />}>
    <Select
      options={testsuiteOptions}
      onChange={handleTestrailSuiteChange}
      selectedOption={testrailSuite}
      selectedLabel="Selected"
      value={testrailSuite}
      invalid={!!error}
    ></Select>
    {
        hasError && error.testrailSuite ? <div className='formErrMsg'>{error.testrailSuite}</div> : null
    }
    </FormField>
    <FormField label={<FormattedMessage id="DEVICE_LOCALE" />} description={<FormattedMessage id="DEVICE_LOCALE_DESC" />}>
    <Select
      options={deviceLocaleOptions}
      onChange={handleDeviceLocaleChange}
      selectedOption={deviceLocale}
      selectedLabel="Selected"
      value={deviceLocale}
      invalid={!!error}
    ></Select>
    {
        hasError && error.deviceLocale ? <div className='formErrMsg'>{error.deviceLocale}</div> : null
    }
    </FormField>
    <FormField label={<FormattedMessage id="DEVICE_TYPE_GROUP" />} description={<FormattedMessage id="DEVICE_TYPE_GROUP_DESC" />}>
    <Select
      options={deviceTypeGroupOptions}
      onChange={handleDeviceTypeGroupChange}
      selectedOption={deviceTypeGroup}
      selectedLabel="Selected"
      value={deviceTypeGroup}
      invalid={!!error}
    ></Select>
    {
        hasError && error.deviceTypeGroup ? <div className='formErrMsg'>{error.deviceTypeGroup}</div> : null
    }
  </FormField>
  </>
);


return (
    <FormSection header={<FormattedMessage id="GENERAL_INFO" />}>
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <FormField label={<FormattedMessage id="TEST_NAME" />} description={<FormattedMessage id="PROVIDE_NAME_FOR_YOUR_TEST" />}>
            <Input
              onChange={handleNameChange}
              value={name}
              autofocus
              invalid={hasError && !!error["name"]}/>
            {
              hasError && error.name ? <div className='formErrMsg'>{error.name}</div> : null
            }
          </FormField>

          {isInternalCustomer() && (testSuite && testSuite.displayName==='FTV') && !shouldCreateTestrailRun && testrailComponent}

          <FormField label={<FormattedMessage id="LOCALE" />} description={<FormattedMessage id="PROVIDE_LOCALE_TO_BE_TESTED" />}>
 
            <Select
              options={options}
              selectedOption={selectedOption}
              selectedLabel="Selected"
              invalid={hasError && !!error["locale"]}
              ></Select>
              {
                hasError && error["locale"] ? <div className='formErrMsg'>{error["locale"]}</div> : null
              }
 
          </FormField>

          {testSuite && testSuite.displayName==='CTS' && (
              <>
                <Toggle checked={isRetryConfigured} onChange={handleIsRetryConfigured}>
                  <FormattedMessage id="RETRY_CTS_TESTCASES" />
                </Toggle>
                {isRetryConfigured && retryConfigurations}
              </>
          )}

          <Toggle checked={enableEmailNotification} onChange={handleEnableEmailNotificationChange}>
              <FormattedMessage id="ENABLE_EMAIL_NOTIFICATION" />
          </Toggle>

          {isInternalCustomer() && (testSuite && testSuite.displayName==='FTV') && !shouldCreateTestrailRun && (
            <Toggle checked={isDryRun} onChange={handleIsDryRunChange}>
              <FormattedMessage id="UPLOAD_LOCAL_TEST_PACKAGE" />
            </Toggle>
          )}

          {isInternalCustomer() && (testSuite && testSuite.displayName==='FTV') && !isDryRun && (
            <Toggle checked={shouldCreateTestrailRun} onChange={handleShouldCreateTestrailRunChange}>
              <FormattedMessage id="CREATE_TESTRAIL_RUN_TOGGLE" />
            </Toggle>
          )}

          {isInternalCustomer() && (testSuite && testSuite.displayName==='FTV') && shouldCreateTestrailRun && createTestrailRunDetails}

          {isInternalCustomer() && (testSuite && testSuite.displayName==='FTV') && isDryRun && dryRunBundleDetails}

        </div>
      </ColumnLayout>
    </FormSection>
  )
}
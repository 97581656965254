import { logToConsole } from "./index";
import AppConstants from '../Constants/AppConstants';
import MusicConstants from '../Constants/MusicConstants';
import CustomOptionsConstants from '../Constants/CustomOptionsConstants';
import { DOWNLOAD_RESOURCE_JSON } from './DownloadResource';
import ld from 'lodash';

export function get_test_configs_json() {
    let test_options_json = require('./AQTTestOptions');
    return test_options_json;
}

function convert_test_options(conv_suite, conv_scenario, conv_test_type, conv_locale) {
  if (conv_suite === AppConstants.CLOSE_TALK_SCENARIO_ID && conv_scenario === AppConstants.LATENCY_FULL) {
      conv_scenario = AppConstants.LATENCY
  }
  conv_test_type = convert_custom_testType(conv_test_type);
  return { conv_suite, conv_scenario, conv_test_type, conv_locale };
}

/**
 * Returns subType info for given test options
 * @param suite Ex: ACOUSTIC
 * @param scenario Ex: AC_FAR_FIELD
 * @param test_type Ex: QUICK/STANDARD. This is optional
 * @param locale Ex: en_US
 * @returns sub type info. Ex: "ACOUSTIC AC_FAR_FIELD Quick_Test en_US"
 */
export function get_sub_type_for_test_options(suite, scenario, test_type, locale) {
    let aqt_test_configs = get_test_configs_json();
    logToConsole("get_sub_type_for_test_options suite:" + suite + ", scenario:" + scenario + ", locale:" + locale);

    let jp = require('jsonpath');

    test_type = convert_custom_testType(test_type);

    logToConsole("TestType:" + test_type);

    let testSubType;
    try {
        testSubType = jp.query(aqt_test_configs, '$.defs.nodes.' + suite + '.' + scenario + '.' + locale + '.subType');
    } catch (e) {
        logToConsole("Error getting subType information");
    }

    logToConsole(testSubType);
    return (testSubType == undefined || testSubType.length == 0) ? AppConstants.EMPTY : testSubType[0];
}

/**
 * Returns all locations for given test options
 * @param suite Ex: ACOUSTIC
 * @param scenario Ex: AC_FAR_FIELD
 * @param test_type Ex: QUICK/STANDARD. This is optional
 * @param customOptions Of the test currently in-progress
 * @locale Locale used for current test.
 * @returns Empty set if no match. Set of test locations. Example: { '1.8 m 135 deg', '2.7 m 90 deg' }
 */
export function get_locations_for_test_options(suite, scenario, test_type, customOptions, locale) {
    let locations_for_current_test_config = new Set();
    // TODO: Remove this once we consume all suites and scenarios from build tool output JSON
    if ([MusicConstants.MUSIC_SCENARIO_ID, AppConstants.FUNCTIONAL_SCENARIO_ID, AppConstants.STABILITY_SCENARIO_ID,
        AppConstants.UPL_SCENARIO_ID, AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID].includes(suite)) {
        logToConsole("non-acoustic suite type requiring location4. Returning 0.9 m 90 deg ");
        locations_for_current_test_config.add(AppConstants.LOCATION_THREE_NINETY);
        return locations_for_current_test_config;
    }
  if (scenario === AppConstants.MOBILE_FUNCTIONAL) {
    locations_for_current_test_config = AppConstants.MOBILE_FUNCTIONAL_ACTORS;
    return locations_for_current_test_config;
  }
    // For customized tests, extract locations selected by user
    if (test_type != undefined && test_type.includes(AppConstants.CUSTOMIZED) && customOptions != undefined) {
        let schemeDefList = customOptions[CustomOptionsConstants.SCHEME_DEF_KEY]
        if (schemeDefList && schemeDefList.length > 0) {
            for (let j = 0; j < schemeDefList.length; j++) {
                let currentActorMap = schemeDefList[j];
                let currentActor = Object.keys(currentActorMap)[0];
                locations_for_current_test_config.add(currentActor);
                let currentNoiseList = schemeDefList[j][currentActor];
                for(let noiseIndex = 0; noiseIndex < currentNoiseList.length; noiseIndex++) {
                    if (Object.keys(currentNoiseList[noiseIndex])[0] != AppConstants.NOISE_TYPE_DEVICE_PLAYBACK
                        && Object.keys(currentNoiseList[noiseIndex])[0] != AppConstants.NOISE_TYPE_SILENCE) {
                        locations_for_current_test_config.add(AppConstants.NOISE_LOCATION);
                    }
                }
            }
        }
        return locations_for_current_test_config;
    }

    let aqt_test_configs = get_test_configs_json();
    logToConsole("get_locations_for_test_options suite:" + suite + ", scenario:" + scenario + "locale:" + locale);

    let jp = require('jsonpath');

    try {
        locations_for_current_test_config = new Set();
        let locale_data = jp.query(aqt_test_configs, '$.defs.nodes.' + suite + '.' + scenario + '.' + locale)[0];
        logToConsole("locale_data");
        logToConsole(locale_data);
        Object.keys(locale_data).forEach(locale_level_key => {
            if (AppConstants.actorMappingKeys.includes(locale_level_key)) {
                locations_for_current_test_config.add(locale_level_key);
            }
        })
    } catch (e) {
        logToConsole("Error getting locations for test options");
        logToConsole(e);
    }
    // In-case of lookup failure display all locations
    if (locations_for_current_test_config.size == 0) {
        logToConsole("ERROR: Lookup of JSON to get locations applicable to testOptions has failed. Returning all locations.");
        locations_for_current_test_config = new Set(AppConstants.actorMappingKeys);
    }
    logToConsole(locations_for_current_test_config);
    return locations_for_current_test_config;
}

/**
 * Returns an array of manifests for given suite, scenario and locale
 * @param suite
 * @param scenario
 * @param locale
 * @param location Note: location is optional. If not specified all manifests are returned.
 * @param test_type Such as {QUICK, DEMO}. By default it is assumed STANDARD
 * @returns Empty array if no match. Array of manifests applicable
 * [ { id: 'admin',
    fileName: 'manifest_enUS_Admin.json',
    directory: 'en_US/Admin',
    downloadUrl: 'https://dtzsgmefzdeac.cloudfront.net/manifests/en_US/Admin/manifest_enUS_Admin.json',
    locale: { id: 'en_US', name: 'en_US', title: 'en_US' } } ]
 */
export function get_manifests_for_test_options(suite, scenario, locale, location, test_type) {
    logToConsole("get_manifests_for_test_options suite:" + suite + ", scenario:" + scenario + ", locale:" + locale);

    let aqt_test_configs = get_test_configs_json();

    let jp = require('jsonpath');
    let manifests = [];
    try {
        let resourceReferenceQuery;
        if (location == undefined) {
            resourceReferenceQuery ='$.defs.nodes.' + suite + '.' + scenario + '.' + locale + '..resources[*]';
        } else {
            resourceReferenceQuery ='$.defs.nodes.' + suite + '.' + scenario + '.' + locale + '..["' + location + '"].resources[*]';
        }
        if (resourceReferenceQuery.length == 0) {
            logToConsole("Manifests not found for :" + suite + ", scenario:" + scenario + ", locale:" + locale + ", location:" + location);
            return;
        }
        let resource_reference = jp.query(aqt_test_configs, resourceReferenceQuery);
        if (resource_reference.length > 0) {
          let uniqReference = ld.uniq(resource_reference)
          uniqReference.forEach(ref => {
            let fileQuery = '$.refs..[?(@.id=="' + ref + '")]..fileName';
            let downloadQuery = '$.refs..[?(@.id=="' + ref + '")]..downloadURL';
            let fileName = jp.query(aqt_test_configs, fileQuery);
            let downloadURL = jp.query(aqt_test_configs, downloadQuery);
            if (fileName.length > 0 && downloadURL.length > 0) {
              manifests.push({
                "fileName": fileName[0],
                "downloadURL": downloadURL[0]
              });
            }
          });
        }
    } catch (e) {
        logToConsole("Error getting manifests for test options suite:" + suite + ", scenario:" + scenario);
        logToConsole(e);
    }
    logToConsole("json lookup manifests:"+ JSON.stringify(manifests));
    return manifests;
}

/**
 * Input sample below:
 * {
	"ACOUSTIC": {
		"AC_FAR_FIELD": [
			"en_GB",
			"en_US",
			"fr_FR"
		],
		"AC_NEAR_FIELD": [
			"en_GB",
			"en_US"
		]
	},
	"FUNCTIONAL": {
		"FUNC_ALL": [
			"en_US"
		]
	},
	"FUNC_CUSTOM": [
		"en_US"
	]
}
 * @param resource_sync_options
 * @param locationsSelectedForSync Array of locations. Ex: ['0.9 m 90 deg']
 * @returns Array of manifest information to be submitted for resource sync. Example
 * [{
		"file_name": "Functional_en_US_audioFiles_manifest.json",
		"download_url": "https://dtzsgmefzdeac.cloudfront.net/ManifestFiles/Functional/en_US/20190829/Functional_en_US_audioFiles_manifest.json"
	}]
 */
export function get_manifests_for_resource_sync_options(resource_sync_options, locationsSelectedForSync) {
    logToConsole("get_manifests_for_resource_sync_options");
    logToConsole(resource_sync_options);
    logToConsole(locationsSelectedForSync);
    let manifests = [];
    locationsSelectedForSync.forEach(raspiLocation => {
        Object.keys(resource_sync_options).forEach((aqt_test_suite) => {
            let test_suite_data = resource_sync_options[aqt_test_suite];
            Object.keys(test_suite_data).forEach((scenario_type) => {
                test_suite_data[scenario_type].forEach((locale) => {
                    let manifests_for_current_options = get_manifests_for_test_options(aqt_test_suite, scenario_type, locale, raspiLocation);
                    if (manifests_for_current_options.length == 0) {
                        manifests_for_current_options = get_manifests_for_test_options(aqt_test_suite, scenario_type, locale, undefined);
                        logToConsole("Retrieved manifests without location for suite:" + aqt_test_suite + ", scenario:" + scenario_type + ", locale:" + locale + ", location:" + raspiLocation);
                        logToConsole(manifests_for_current_options);
                    }
                    if (manifests_for_current_options.length == 0) {
                        manifests_for_current_options = get_manifests_from_manifest_const_lookup(aqt_test_suite,
                            scenario_type, locale);
                    }
                    if (manifests_for_current_options != undefined && manifests_for_current_options.length > 0) {
                        manifests_for_current_options.forEach((manifest_item) => {
                            manifests.push({
                                "file_name": manifest_item.fileName,
                                "download_url": manifest_item.downloadURL
                            })
                        })
                    }
                })
            })
        } )
    });
    let unique_resources = {};
    manifests.forEach((manifest_item) => {
        if (!unique_resources.hasOwnProperty(manifest_item.download_url)) {
            unique_resources[manifest_item.download_url] = manifest_item;
        }
    })
    let deDupManifests = Object.values(unique_resources);
    return deDupManifests;
}

/**
 * Given input manifest file name, returns corresponding test_suite, scenario_type, locale it belongs to.
 * @param manifest_file_name
 * @returns AQT test options corresponding to the manifest file.
 * Example: {
 *     "test_suite": "ACOUSTIC",
 *     "scenario_type": "AC_FAR_FIELD",
 *     "locale": "en_US"
 * }
 */
export function get_aqt_test_info_from_manifest_file(manifest_file_name) {
    logToConsole("get_aqt_test_info_from_manifest_file manifest:" + manifest_file_name);
    let aqt_test_configs = get_test_configs_json();
    let jp = require('jsonpath');

    let test_options_corresponding_to_manifest = {};

    let manifest_lookup;
    try {
        manifest_lookup = jp.query(aqt_test_configs, '$.refs..[?(@.fileName=="' + manifest_file_name + '")]');
        if (manifest_lookup.length > 0) {
            let subType_id = manifest_lookup[0].id;
            let subType_lookup = jp.nodes(aqt_test_configs, '$.defs.nodes..[?(@.subType=="' + subType_id + '")]');
            let subType_path = subType_lookup[0].path;
            test_options_corresponding_to_manifest["test_suite"] = subType_path[3];
            test_options_corresponding_to_manifest["scenario_type"] = subType_path[4];
            test_options_corresponding_to_manifest["locale"] = subType_path[5];
        }
    } catch (e) {
        logToConsole("Error look-up of manifest:" + manifest_file_name);
        logToConsole(e);
    }
    // If lookup by manifest.json fails; Older manual manifests
    if (Object.keys(test_options_corresponding_to_manifest).length == 0) {
        if (manifest_file_name.toLowerCase().includes("functional")) {
            test_options_corresponding_to_manifest["test_suite"] = AppConstants.FUNCTIONAL_SCENARIO_ID;
            test_options_corresponding_to_manifest["scenario_type"] = AppConstants.STANDARD;
            test_options_corresponding_to_manifest["locale"] = AppConstants.MARKETPLACE_FUNCTIONAL[0].id;
        } else if (manifest_file_name.toLowerCase().includes("music")) {
            test_options_corresponding_to_manifest["test_suite"] = MusicConstants.MUSIC_SCENARIO_ID;
            test_options_corresponding_to_manifest["scenario_type"] = AppConstants.STANDARD;
            let locale = "en_US";
            if (manifest_file_name.toLowerCase().includes("de_de")) {
                locale = "de_DE";
            } else if (manifest_file_name.toLowerCase().includes("en_gb")) {
                locale = "en_GB";
            } else  if (manifest_file_name.toLowerCase().includes("ja_jp")) {
                locale = "ja_JP";
            }
            test_options_corresponding_to_manifest["locale"] = locale;
        } else {
            test_options_corresponding_to_manifest["test_suite"] = AppConstants.ACOUSTIC_SCENARIO_ID;
            test_options_corresponding_to_manifest["scenario_type"] = AppConstants.STANDARD;
            let locale = "en_US";
            for (let i = 0; i < DOWNLOAD_RESOURCE_JSON.length; i++) {
                if (DOWNLOAD_RESOURCE_JSON[i].file_name.toLowerCase() == manifest_file_name.toLowerCase()) {
                    let pathname = new URL(DOWNLOAD_RESOURCE_JSON[i].download_url).pathname;
                    let pathSplits = pathname.split("/");
                    if (manifest_file_name.toLowerCase().includes("far")) {
                        test_options_corresponding_to_manifest["scenario_type"] = "FAR";
                        if (pathSplits.length > 4) {
                            locale = pathSplits[4];
                        }
                    } else {
                        if (pathSplits.length > 3) {
                            locale = pathSplits[3];
                        }
                    }
                    test_options_corresponding_to_manifest["locale"] = locale;
                    break;
                }
            }
        }
    }
    logToConsole(test_options_corresponding_to_manifest);

    return test_options_corresponding_to_manifest;
}

/**
 * Gets test options corresponding to each manifest file name in input list.
 * @param manifest_files_list
 * @returns {{}} Example: {"en_US_Admin.json": {"test_suite": "ACOUSTIC","scenario_type": "AC_FAR_FIELD","locale": "en_US"}}
 */
export function get_aqt_test_info_from_manifest_files_list(manifest_files_list) {
    logToConsole("get_aqt_test_info_from_manifest_files_list");
    logToConsole(manifest_files_list);
    let aqt_test_info_list = {};
    manifest_files_list.forEach((manifest_file_name) => {
        aqt_test_info_list[manifest_file_name] = get_aqt_test_info_from_manifest_file(manifest_file_name);
    })
    return aqt_test_info_list;
}

/**
 * Returns an array of manifests for given suite and locale
 * @param suite
 * @param scenario_type
 * @param locale
 * @returns Empty array if no match. Array of manifests applicable
 * [ {
    fileName: 'manifest_enUS_Admin.json',
    downloadURL: 'https://dtzsgmefzdeac.cloudfront.net/manifests/en_US/Admin/manifest_enUS_Admin.json' } ]
 */
export function get_manifests_from_manifest_const_lookup(test_suite, scenario_type, locale) {
    let manifests = [];
    let suite_lowercase = test_suite.toLowerCase();
    let locale_lowercase = locale.toLowerCase();
    const commonManifestFiles = [AppConstants.AQT_COMMON_MANIFEST_FILE];

    if (test_suite === AppConstants.QUAL_SCENARIO_ID) {
      commonManifestFiles.push(AppConstants.QUAL_COMMON_MANIFEST_FILE);
    }

    if (test_suite === AppConstants.MOBILE_SUITE_ID) {
      commonManifestFiles.push(AppConstants.MOBILE_COMMON_MANIFEST_FILE);
    }

    if (AppConstants.FAR_TEST_TYPES.includes(scenario_type)) {
        DOWNLOAD_RESOURCE_JSON.filter(resource =>
          (
            resource.file_name.toLowerCase().includes("far") &&
            resource.file_name.toLowerCase().includes(locale_lowercase)
          ) ||
          commonManifestFiles.includes(resource.file_name.toLowerCase())
        ).forEach(filtered_manifest => {
          manifests.push({
            fileName: filtered_manifest.file_name,
            downloadURL: filtered_manifest.download_url
          });
        });
        logToConsole("Returning FAR & common manifests");
        return manifests;
    }

    if ([AppConstants.CLOSE_TALK_SCENARIO_ID, AppConstants.AUTOMOTIVE_SCENARIO_ID].includes(test_suite)) {
        test_suite = AppConstants.ACOUSTIC_SCENARIO_ID;
        suite_lowercase = test_suite.toLowerCase();
    }
    let manifests_filtered_by_suite_and_locale = DOWNLOAD_RESOURCE_JSON.filter(resource =>
        (resource.file_name.toLowerCase().includes(suite_lowercase)
        && resource.file_name.toLowerCase().includes(locale_lowercase))
        || commonManifestFiles.includes(resource.file_name.toLowerCase())
        || (test_suite == AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID && resource.file_name.toLowerCase().includes("autolocalsearch")));
    manifests_filtered_by_suite_and_locale.forEach(filtered_manifest => {
        manifests.push({
            fileName: filtered_manifest.file_name,
            downloadURL: filtered_manifest.download_url
        });
    });

    logToConsole("Returning manifests for suite:" + test_suite + ",scenario:" + scenario_type + ",locale:" + locale);
    logToConsole(manifests);
    return manifests;
}

/**
 * Gets corresponding testType for CUSTOMIZED test runs.
 * @param test_type
 * @returns {string}
 */
export function convert_custom_testType(test_type) {

    if (test_type == undefined
        || test_type.length == 0
        || test_type == AppConstants.CUSTOMIZED) {
        return AppConstants.STANDARD;
    }

    // Manifests will be same for custom test types; i.e. CUSTOMIZED_STANDARD will run STANDARD manifest.
    if (test_type.includes(AppConstants.CUSTOMIZED)) {
        if (test_type.includes(AppConstants.STANDARD)) {
            test_type = AppConstants.STANDARD;
        } else if (test_type.includes(AppConstants.QUICK)) {
            test_type = AppConstants.QUICK;
        } else if (test_type.includes(AppConstants.DEMO)) {
            test_type = AppConstants.DEMO;
        }
    }
    return test_type;
}

/**
 * Builds a dictionary with location as key and list of resources required as value. All locations required for test are added.
 * @param suite
 * @param scenario
 * @param test_type
 * @param customOptions
 * @param locale
 * @returns {
     "0.9 m 90 deg": [{
		"file_name": "Functional_en_US_audioFiles_manifest.json",
		"download_url": "https://dtzsgmefzdeac.cloudfront.net/ManifestFiles/Functional/en_US/20190829/Functional_en_US_audioFiles_manifest.json"
	}]
 }
 */
export function get_resources_required_by_location(suite, scenario, test_type, customOptions, locale) {
    let resources_required_by_location = {};
    logToConsole("Get resources required by location: suite:" + suite + ", scenario:" + scenario + ", locale:" + locale + "customOptions:" + customOptions);
    // For suites such as closetalk auto-sync is not setup
    if ([AppConstants.FUNCTIONAL_SCENARIO_ID, MusicConstants.MUSIC_SCENARIO_ID, AppConstants.ACOUSTIC_SCENARIO_ID,
         AppConstants.STABILITY_SCENARIO_ID, AppConstants.UPL_SCENARIO_ID, AppConstants.MOBILE_SUITE_ID].includes(suite)) {
        let locations_for_current_test = get_locations_for_test_options(suite, scenario, test_type, customOptions, locale);
        let currentScenario = {};
        currentScenario[scenario] = [ locale ];
        let resource_sync_options = {};
        resource_sync_options[suite] = currentScenario;
        locations_for_current_test.forEach(location => {
            resources_required_by_location[location] = get_manifests_for_resource_sync_options(resource_sync_options, [location]);
        });
    }
    return resources_required_by_location;
}

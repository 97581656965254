/**
 * Component to render progress bar for Functional scenario
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, FunctionalTestCases, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import '../LiveFeedProgressBar.css';
import { getOverallTestStatusList, getActualPercentage, 
  getOverallTestStatus, getCloudValidationTestStatusList, getCompletedTestsNum,
  isRefMicValidationPending } 
  from './FunctionalLiveFeedUtil';
import AutoSyncProgressBar from '../AutoSync/AutoSyncProgressBar';

// Progress bar filler component to display progress in green color
const Filler = (props) => {
  let fillerId = props.labJobId ? 'filler_' + props.labJobId : 'filler';
  return ( 
    <div className='filler' id={ fillerId } style={{ width: `${ props.percentage }%`}}/>
  )
}
 
// Progress bar component to display actual progress bar
const ProgressBar = (props) => {
  return (
    <div className='progress-bar'>
      <Filler percentage={ props.percentage }/>
    </div>
  )
}
 
/**
 * Component to display progress bar consisting of following states:
 * 1. Started
 * 2. In progress (with % of progress)
 * 3. Completed
 */
class LiveFeedProgressBarFunctional extends React.Component {
  /**
   * Decides whether to display 'In progress' status or not
   * @param overallStatus Overall status of the test run
   * @return Returns true only if test is in progress or is completed.
   */
  shouldDisplayInProgressState = (overallStatus) => {
     return FunctionalTestCases.COMPLETED_STATES.includes(overallStatus) 
        || overallStatus === FunctionalTestCases.IN_PROGRESS
        || overallStatus === FunctionalTestCases.EVALUATING_RESULT;
  }
 
  /**
   * Decides whether to display 'Completed' state or not
   * @param overallStatus Overall status of the test run
   * @return Returns true only if test is completed.
   */
  shouldDisplayCompletedState = (overallStatus) => {
     return FunctionalTestCases.COMPLETED_STATES.includes(overallStatus);
  }
 
  /**
   * Gets text to display when test is in progress
   * @param overallStatus Overall Status of the test run
   * @param actualPercentage Percentage of number of test cases completed
   * @param testStatus Test status from controller
   * @return Text whether test is loading or In progress with percentage
   */
  getInProgressText = (overallStatus, actualPercentage, testStatus) => {
    // Check if test Status belongs to RasPi update or Resource download
    if (Util.isRasPiUpdateCompleted(testStatus) && AppConstants.RASPI_STATES_DISPLAY.hasOwnProperty(testStatus)) {
      return AppConstants.RASPI_STATES_DISPLAY[testStatus];
    }
    let isTestInProgress = this.shouldDisplayInProgressState(overallStatus);
    let isTestCompleted = this.shouldDisplayCompletedState(overallStatus);
    if (isTestCompleted) {
      return 'In Progress (100%)';
    }
    if (isTestInProgress) {
      if (!actualPercentage || actualPercentage === 0) {
        return 'In Progress';
      } else {
        return 'In Progress (' + actualPercentage.toFixed(1) + '%)';
      }
    }
    return 'In Progress';
  }
  
  /**
   * Gets the percentage value needed to render progress bar
   * @param overallStatus Overall test status based on status of all tests
   * @param actualPercentage Actual percentage value --> Number of tests completed/ Total number of tests
   * @return Percentage value needed to render progress bar
   */
  getPercentageToRender = (overallStatus, actualPercentage) => {
    if (overallStatus === FunctionalTestCases.QUEUED) {
      return 5;
    } else if (overallStatus === FunctionalTestCases.IN_PROGRESS
        || overallStatus === FunctionalTestCases.EVALUATING_RESULT) {
      return 10 + actualPercentage * 0.8;
    } else if (overallStatus === FunctionalTestCases.COMPLETED) {
      return 100;
    }
    // By default load progress bar upto QUEUED state
    return 5;
  }
 
  /**
   * Method to display informational message when ALL cloud validation is completed but ref mic
   * validation is still in progress
   * @param cloudValidationCompletedNum Number of cloud validations completed
   * @returns true or false to decide whether to display the message or not
   */
  shouldDisplayRefMicInProgressMessage = (cloudValidationCompletedNum) => {
    if (cloudValidationCompletedNum && this.props.params.functionalTestCases) {
      // If ALL cloud validation is completed & Ref mic validation is pending for any of the
      // test, return true
      return cloudValidationCompletedNum === this.props.params.functionalTestCases.length 
        && isRefMicValidationPending(this.props.params.functionalTestCases);
    }
    return false;
  }

  /**
   * Method to get display friendly text for reference mic status
   * @param refMicStatus Reference mic status from custom payload
   * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
   */
  getRefMicStatusText = (refMicStatus) => {
    if (refMicStatus !== undefined && FunctionalTestCases.REF_MIC_STATUS.hasOwnProperty(refMicStatus)) {
      return FunctionalTestCases.REF_MIC_STATUS[refMicStatus].displayText;
    }
    return this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
      FunctionalTestCases.REF_MIC_STATUS[FunctionalTestCases.REF_MIC_STATUS_UNAVAILABLE].displayText:
      FunctionalTestCases.REF_MIC_STATUS[FunctionalTestCases.REF_MIC_STATUS_OFFLINE].displayText;
  }
 
  /**
   * Method to get color coding & style for ref mic based on its status
   * @param refMicStatus Reference mic status from custom payload
   * @returns Color coding & style for reference mic text based on its status
   */
  getRefMicDisplayStyle = (refMicStatus) => {
    if (refMicStatus !== undefined && FunctionalTestCases.REF_MIC_STATUS.hasOwnProperty(refMicStatus)) {
      return FunctionalTestCases.REF_MIC_STATUS[refMicStatus].displayStyle;
    }
    return this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
      FunctionalTestCases.REF_MIC_STATUS[FunctionalTestCases.REF_MIC_STATUS_UNAVAILABLE].displayStyle:
      FunctionalTestCases.REF_MIC_STATUS[FunctionalTestCases.REF_MIC_STATUS_OFFLINE].displayStyle;
  }

  // Renders progress bar
  render() {
    let testStatus = this.props.params.testStatus ? this.props.params.testStatus.toLowerCase() : AppConstants.EMPTY;
    let overallStatusList = getOverallTestStatusList(this.props.params.functionalTestCases);
    let cloudValidationStatusList = getCloudValidationTestStatusList(this.props.params.functionalTestCases);
    let cloudValidationCompletedNum = getCompletedTestsNum(cloudValidationStatusList);
    let actualPercentage = getActualPercentage(overallStatusList);
    let overallStatus = getOverallTestStatus(overallStatusList);

    // Retrieve reference mic status & style
    let referenceMicStatusText = this.getRefMicStatusText(this.props.params.refMicStatus);
    let referenceMicDisplayStyle = this.getRefMicDisplayStyle(this.props.params.refMicStatus);
    referenceMicDisplayStyle += ' awsui-util-ml-s';

    return (
      <div>
        <div className='breadcrumb-style'>
          <AWSUI.BreadcrumbGroup>
            <AWSUI.BreadcrumbItem text='Started'/>
            {
              !Util.isRasPiUpdateInProgress(testStatus) && (
                <AWSUI.BreadcrumbItem text={ this.getInProgressText(overallStatus, actualPercentage, testStatus) }/>
              )
            }
            {
              Util.isRasPiUpdateInProgress(testStatus) && (
                Util.getAnimatedRasPiState(AppConstants.RASPI_STATES_DISPLAY[testStatus])
              )
            }
            { this.shouldDisplayCompletedState(overallStatus) && (
              <AWSUI.BreadcrumbItem text='Completed'/>
            )}
          </AWSUI.BreadcrumbGroup>
        </div>
        {
          Util.isRasPiResourceSyncUpdateInProgress(testStatus) && (
            <div className='breadcrumb-style'>
              <AutoSyncProgressBar params={{ labJobId: this.props.params.labJobId,
                scenarioType: this.props.params.scenarioType,
                testSuite: this.props.params.testSuite,
                customOptions: this.props.params.customOptions,
                marketPlace: this.props.params.marketPlace }} />
            </div>
          )
        }
        {
          !Util.isRasPiResourceSyncUpdateInProgress(testStatus) && (
            <ProgressBar percentage={ this.getPercentageToRender(overallStatus, actualPercentage) } />
          )
        }
        {
          this.props.params.functionalTestCases &&
            this.props.params.functionalTestCases.length > 0 && (
              <div align='center' className='awsui-util-mt-xxl'>
                <h3 className='awsui-util-ml-xl awsui-util-mt-l awsui-util-mb-l ref-mic-status-style'>
                  <span>
                    {
                      this.props.params.refMicStatus === FunctionalTestCases.REF_MIC_STATUS_ONLINE && (
                        <span>
                          <span className='ref-mic-recording-braces-style'>
                            [&nbsp;
                              </span>
                                 <span className='ref-mic-recording-text-style'>
                                  Rec
                                </span>
                                <b className='ref-mic-recording-dot-style'>
                                  .
                                </b>
                                <span className='ref-mic-recording-braces-style'>
                              &nbsp;]
                            </span>
                          </span>
                        )
                      }
                    <b> Reference Mic Status: </b>
                    <span className={ referenceMicDisplayStyle }>
                      <AWSUI.Icon name='microphone'/>
                      <span className='awsui-util-ml-xs'>
                        { referenceMicStatusText }
                      </span>
                    </span>
                 </span>
              </h3>
            </div>
          )
        }
        {
          this.shouldDisplayRefMicInProgressMessage(cloudValidationCompletedNum, this.props.params.functionalTestCases)
            && (
              Util.getLoadingMessage(FunctionalTestCases.REF_MIC_IN_PROGRESS_MESSAGE)
            )
        }
      </div>
    )
  }
}
 
LiveFeedProgressBarFunctional.propTypes = {
  params: PropTypes.object.isRequired
};
 
export default LiveFeedProgressBarFunctional;
/**
 * This file contains helper methods for Sync Resource functionality
 */
import {AppConstants, AQTTestOptionsParser, SyncResourceConstants, Util} from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import AWSUI from '@amzn/awsui-components-react';
import React from 'react';

/**
 * Validates Sync Resource options before submitting for actual sync
 */
export function validateSyncResourceOptions(selectedOptionsMap) {
  let isValid = false;
  if (!selectedOptionsMap) {
    return isValid;
  }
  if (Object.keys(selectedOptionsMap).length === 0) {
    return isValid;
  }
  Object.keys(selectedOptionsMap).map(labId => {
    // Retrieve the map of test suites from overall map
    let testSuitesMap = selectedOptionsMap[labId];
    if (!testSuitesMap || Object.keys(testSuitesMap).length == 0) {
      return false;
    }
    Object.keys(testSuitesMap).map(testSuite => {
      // Retrieve the map of scenario types under this test suite
      let sceanarioTypesMap = testSuitesMap[testSuite];
      if (sceanarioTypesMap) {
        Object.keys(sceanarioTypesMap).map(scenarioType => {
          if (scenarioType !== SyncResourceConstants.LABEL
                && scenarioType !== SyncResourceConstants.EXPANDED) {
            let localesMap = sceanarioTypesMap[scenarioType];
            if (localesMap) {
              Object.keys(localesMap).map(key => {
                if (key !== SyncResourceConstants.LABEL
                      && key !== SyncResourceConstants.EXPANDED
                      && key !== SyncResourceConstants.SELECTED 
                      && !isValid) {
                  isValid = true;
                  // At least one checked locale is found at this point, so return true
                  return isValid; 
                }
              })
            }
          }
        })
      }
    })
  })
  return isValid;
}

/**
  * Gets data to be displayed for sync resource options before final submission for sync
  */
export function getSyncResourceOptionsData(selectedOptionsMap) {
  let syncResourceOptionsData = [];
  Object.keys(selectedOptionsMap).map(labId => {
    // Retrieve the map of test suites from overall map
    let testSuitesMap = selectedOptionsMap[labId];
    if (testSuitesMap) {
      Object.keys(testSuitesMap).map(testSuite => {
        let testSuiteLabel = testSuitesMap[testSuite][SyncResourceConstants.LABEL];
        // Retrieve the map of scenario types under this test suite
        let sceanarioTypesMap = testSuitesMap[testSuite];
        if (sceanarioTypesMap) {
          // Retrieve locales selected for the particular scenario
          Object.keys(sceanarioTypesMap).map(scenarioType => {
            if (scenarioType !== SyncResourceConstants.LABEL
                  && scenarioType !== SyncResourceConstants.EXPANDED) {
              let scenarioTypeValue = sceanarioTypesMap[scenarioType];
              let scenarioTypeLabel = sceanarioTypesMap[scenarioType][SyncResourceConstants.LABEL];
              // Only if current scenario type has at least one locale selected in it,
              // add it to final selection
              if (scenarioTypeValue && Object.keys(scenarioTypeValue).length > 3) {
                // Generate locales string to be dislayed as comma separated values
                let localesString = AppConstants.EMPTY;
                Object.keys(scenarioTypeValue).map(key => {
                  if (key !== SyncResourceConstants.LOCALES_ALL 
                        && key !== SyncResourceConstants.EXPANDED
                        && key !== SyncResourceConstants.LABEL
                        && key !== SyncResourceConstants.SELECTED) {
                    let localesMap = scenarioTypeValue[key];
                    localesString += localesMap[SyncResourceConstants.LABEL] + ', '
                  }
                })
                localesString = localesString.substring(0, localesString.length - 2);
                // Push the record in table to be displayed
                syncResourceOptionsData.push({
                  testSuite : testSuiteLabel,
                  scenarioType: scenarioTypeLabel,
                  locales: localesString
                });
              }
            }
          })
        }
      })
    }
  })
  return syncResourceOptionsData;
}

/**
 * Gets the list of selected Pis for Sync Controls tab
 */
export function getLocationsSelected(locations) {
  locations = locations.sort();
  let locationsString = AppConstants.EMPTY;
  Object.keys(locations).map(location => {
    let locationName = locations[location];
    if (locationName != SyncResourceConstants.LOCALES_ALL) {
      locationsString += locationName + ',  ';
    }
  })
  locationsString = locationsString.substring(0, locationsString.length - 3);
  return locationsString;
}

/**
 * Validates if at least one locations has been selected for resource sync
 */
export function validateLocatons(locations) {
  return locations && locations.length > 0;
}

/**
 * Collapses all other test suite expanded sections if one particular test suite is expanded
 */
export function collapseOtherTestSuites(selectedOptionsMap, expandedSectionId) {
  if (selectedOptionsMap && expandedSectionId) {
    Object.keys(selectedOptionsMap).map(labId => {
      // Retrieve test suites map
      let testSuitesMap = selectedOptionsMap[labId];
      if (testSuitesMap) {
        // Iterate over test suites map to collapse all other test suites except the one
        // which is currently expanded
        Object.keys(testSuitesMap).map(testSuite => {
          if(testSuite !== expandedSectionId) {
            let testSuiteValue = testSuitesMap[testSuite];
            // Collapse the test suite
            testSuiteValue[SyncResourceConstants.EXPANDED] = false;
            testSuitesMap[testSuite] = testSuiteValue;     
          }
        })
        // Save the updated map
        selectedOptionsMap[labId] = testSuitesMap;
      }
    })
  }
  return selectedOptionsMap;
}

/**
 * Collapses all other scenario types if one particular scenario type is expanded
 */
export function collapseOtherScenarioTypes(selectedOptionsMap, expandedTestSuite, expandedScenario) {
  if (selectedOptionsMap && expandedTestSuite && expandedScenario) {
    Object.keys(selectedOptionsMap).map(labId => {
      // Retrieve test suites map
      let testSuitesMap = selectedOptionsMap[labId];
      // Retrieve the scenario type map for the test suite
      let sceanarioTypesMap = testSuitesMap[expandedTestSuite];
      if (sceanarioTypesMap) {
        // Iterate over test suites map to collapse all other test suites except the one
        // which is currently expanded
        Object.keys(sceanarioTypesMap).map(key => {
          if(key !== SyncResourceConstants.LABEL
               && key !== SyncResourceConstants.EXPANDED
               && key !== expandedScenario) {
            let scenarioTypeValue = sceanarioTypesMap[key];
            // Collapse the scenario type
            scenarioTypeValue[SyncResourceConstants.EXPANDED] = false;
            sceanarioTypesMap[key] = scenarioTypeValue;     
          }
        })
        // Save the updated map
        testSuitesMap[expandedTestSuite] = sceanarioTypesMap;
        selectedOptionsMap[labId] = testSuitesMap;
      }
    })
  }
  return selectedOptionsMap;
}

/**
 * Gets the expandable section for test suites and scneario types
 */
export function getExpandableSectionContent(header, isExpanded, isSelected, isTestSuite) {
  let headerStyle = isTestSuite ? 'dark-gray-color-text' : 'light-gray-color-text';
  if (!isExpanded && isSelected) {
    headerStyle='green-color-text';
  }
  return (
    <AWSUI.ColumnLayout columns={ 4 } borders='none'>
    <div className='awsui-util-mb-xs' data-awsui-column-layout-root='true'>
      <h4 className={ headerStyle} >
      { 
        header 
      }
      {
        !isExpanded && isSelected && (
          <span className='awsui-util-ml-m'>&#10004;</span>
        )
      }
      </h4>
      <div></div>
      <div></div>
      <div></div>
    </div> 
  </AWSUI.ColumnLayout>
  );
}

/**
 * Checks whether at least one checkbox is checked under particullar scenario type
 */
export function getSelectedValuForScenario(selectedOptionsMap, labId, testSuite, scenarioType) {
  let selected = false;
  if (selectedOptionsMap && labId && testSuite && scenarioType) {
    if (selectedOptionsMap.hasOwnProperty(labId)) {
      let innerMap = selectedOptionsMap[labId];
        if (innerMap.hasOwnProperty(testSuite)) {
          let testSuitesMap = innerMap[testSuite];
          if (testSuitesMap.hasOwnProperty(scenarioType)) {
          let sceanarioTypesMap = testSuitesMap[scenarioType];
          if (sceanarioTypesMap.hasOwnProperty(SyncResourceConstants.SELECTED)) {
            selected = sceanarioTypesMap[SyncResourceConstants.SELECTED];
          }
        }
      }
    }
  }
  return selected;
}

/**
 * Checks whether at least one checkbox is checked under particullar test suite
 */
export function getSelectedValuForTestSuite(selectedOptionsMap, labId, testSuite) {
  let selected = false;
  if (selectedOptionsMap && labId && testSuite) {
    if (selectedOptionsMap.hasOwnProperty(labId)) {
      let innerMap = selectedOptionsMap[labId];
        if (innerMap.hasOwnProperty(testSuite)) {
          let testSuitesMap = innerMap[testSuite];
          Object.keys(testSuitesMap).map(key => {
            if(!selected 
              && key !== SyncResourceConstants.LABEL
              && key !== SyncResourceConstants.EXPANDED
              && testSuitesMap.hasOwnProperty(key)) {
                let sceanarioTypesMap = testSuitesMap[key];
                // Check if at least one scenario has at least one checkbox checked under this test suite
                if (sceanarioTypesMap 
                  && sceanarioTypesMap.hasOwnProperty(SyncResourceConstants.SELECTED)
                  && sceanarioTypesMap[SyncResourceConstants.SELECTED]) {
                selected = true;    
            }
          }
        })
      }
    }
  }
  return selected;
}

/**
 * Checks/ unchecks ALL locales for ALL scenarios for a particular test suite
 */
export function onCheckedAllForTestSuite(selectedOptionsMap, labId, testSuite, isChecked) {
  if(selectedOptionsMap && labId && testSuite) {
    if(selectedOptionsMap.hasOwnProperty(labId)) {
      let testSuitesMap = selectedOptionsMap[labId];
      let syncResourceOptions = SyncResourceConstants.SYNC_RESOURCE_OPTIONS;
      // Retrieve scenario types map for this test suite
      let scenarioTypesMap = syncResourceOptions[testSuite];
      let scenarioTypesValue = {
        'expanded' : true,
        'label': scenarioTypesMap.label
      };
      // Iterate through all scenarios for this test suite & mark checked = true or false for all locales
      // under each scenario
      Object.keys(scenarioTypesMap.scenarioTypes).map(scenarioType => {
        let localesMap = {
          'expanded': false,
          'label': scenarioTypesMap.scenarioTypes[scenarioType]['label'],
          'selected': isChecked
        };
        // Iterate over locales for this scenario type to mark checked = true if
        // select all is marked as true
        if (isChecked) {
          let locales = scenarioTypesMap.scenarioTypes[scenarioType]['locales'];
          locales.map(locale => {
            localesMap[locale['id']] = {
              'checked': true,
              'label': locale.label
            }
          })
        }
        scenarioTypesValue[scenarioType] = localesMap;
      })
      // Update the overall map
      testSuitesMap[testSuite] = scenarioTypesValue;
      selectedOptionsMap[labId] = testSuitesMap
    }
  }
  return selectedOptionsMap;
}

/**
 * Checks whether all checkboxes for all locales are checked for all scenarios under this
 * test suite
 */
export function isAllCheckedForAllScenarioTypes(selectedOptionsMap, labId, testSuite) {
  let isSelected = true;
  if(selectedOptionsMap && labId && testSuite) {
    if(selectedOptionsMap.hasOwnProperty(labId)) {
      let testSuitesMap = selectedOptionsMap[labId];
      if (testSuitesMap.hasOwnProperty(testSuite)) {
        let scenarioTypesMapFromOptions = testSuitesMap[testSuite];
        let syncResourceOptions = SyncResourceConstants.SYNC_RESOURCE_OPTIONS;
        // Retrieve scenario types map for this test suite
        let scenarioTypesMap = syncResourceOptions[testSuite]['scenarioTypes'];
        Object.keys(scenarioTypesMap).map(scenarioType => {
          if (isSelected &&  scenarioTypesMapFromOptions.hasOwnProperty(scenarioType)) {
            let localesMap = scenarioTypesMapFromOptions[scenarioType];
            if (!localesMap.hasOwnProperty(SyncResourceConstants.LOCALES_ALL)) {
              isSelected = false;
            } else {
              if (!localesMap[SyncResourceConstants.LOCALES_ALL]['checked']) {
                isSelected = false;
              }
            }
          } else {
            isSelected = false;
          }
        })
      }
    }
  }
  return isSelected;  
}

/**
 * Generates a JSON with manifest file names when sync resources button is clicked
 * from Sync Controls tab
 */
export function generateSyncResourceJson(selectedOptionsMap, labId) {
  let syncResourceJson = [];
  if (selectedOptionsMap && labId) {
    if(selectedOptionsMap.hasOwnProperty(labId)) {
      let testSuitesMap = selectedOptionsMap[labId];
      Object.keys(testSuitesMap).map(testSuite => {
        let scenarioTypeMap = testSuitesMap[testSuite];
        if (scenarioTypeMap) {
          Object.keys(scenarioTypeMap).map(scenarioType => {
            if(scenarioType !== SyncResourceConstants.LABEL 
                && scenarioType !== SyncResourceConstants.EXPANDED) {
              let localesMap = scenarioTypeMap[scenarioType];
              if(localesMap) {
                Object.keys(localesMap).map(locale => {
                  if(locale !== SyncResourceConstants.EXPANDED
                      && locale !== SyncResourceConstants.LABEL
                      && locale !== SyncResourceConstants.SELECTED
                      && locale !== SyncResourceConstants.LOCALES_ALL) {
                    let syncFileName = testSuite + '-' + scenarioType + '-' + locale + '.json';
                    let downloadUrl = SyncResourceConstants.MANIFEST_BASE_URL + '/Folder/' + syncFileName;
                    syncResourceJson.push({
                      'file_name' : syncFileName,
                      'download_url' : downloadUrl
                    });
                  }
                })
              }                
            }
          })
        }
      })
    }
  }
  return syncResourceJson;
}

/**
 * Generate JSON to invoke a controller API which provides download resource files + urls
 */
export function generateSyncResourceDownloadJson(selectedOptionsMap, labId) {
  let syncResourceJson = {};
  if (selectedOptionsMap && labId) {
    if(selectedOptionsMap.hasOwnProperty(labId)) {
      let testSuitesMap = selectedOptionsMap[labId];
      Object.keys(testSuitesMap).map(testSuite => {
        let scenarioTypeMap = testSuitesMap[testSuite];
        if (scenarioTypeMap) {
          Object.keys(scenarioTypeMap).map(scenarioType => {
            if(scenarioType !== SyncResourceConstants.LABEL 
                && scenarioType !== SyncResourceConstants.EXPANDED) {
              let localesMap = scenarioTypeMap[scenarioType];
              let locales = [];
              if(localesMap) {
                Object.keys(localesMap).map(locale => {
                  if(locale !== SyncResourceConstants.EXPANDED
                      && locale !== SyncResourceConstants.LABEL
                      && locale !== SyncResourceConstants.SELECTED
                      && locale !== SyncResourceConstants.LOCALES_ALL) {
                    // Generate the list of locales for testSuite + scenarioType
                    locales.push(locale);
                  }
                })
                if(locales.length > 0) {
                  // Retrieve the scenario types map for JSON being built
                  let testSuiteId = SyncResourceConstants.TEST_SUITE_MAPPING[testSuite];
                  let scenarios = syncResourceJson.hasOwnProperty(testSuiteId) ? syncResourceJson[testSuiteId] : {};
                  // Set locales for scenario
                  scenarios[scenarioType] = locales;
                  syncResourceJson[testSuiteId] = scenarios;
                }
              }                
            }
          })
        }
      })
    }
  }
  return syncResourceJson;
}

/**
 * Generates a JSON with manifest file names when sync resources is clicked from
 * Sync status tab
 */
export function generateSyncResourceJsonFromManifestList(manifestsMap) {
  let syncResourceJson = [];
  if(manifestsMap) {
    Object.keys(manifestsMap).map(manifest => {
      let downloadUrl = SyncResourceConstants.MANIFEST_BASE_URL + '/Folder/' + manifest;
      syncResourceJson.push({
        'file_name' : manifest,
        'download_url' : downloadUrl
      });
    })
  }
  return syncResourceJson;
}

/*
 * Gets count and percentage for numer/ denom
 */
export function countAndPercentage(numer, denom, isPercentageForFile = false, isFileSizeInKbs = true) {
  try {
      if (numer && denom && denom > 0) {
          let percentage = parseInt(numer, 10) * 100 / parseInt(denom, 10);
          // If count & percentage is for file size, convert file size to appropriate unites
          // before displaying
          if (isPercentageForFile) {
              numer = getFileSize(numer, isFileSizeInKbs);
              denom = getFileSize(denom, isFileSizeInKbs);
          }
          return numer + ' / ' + denom + ' (' + percentage.toFixed(0) + '%)';
      }
  } catch (error) {
      Util.logToConsole('Error: ' + countAndPercentage.name + ' - Trace: ',
          JSON.stringify(error))
  }
  return '0';
}

/**
 * Gets overall sync resource progress percentage to display in location expandable section
 */
export function getOverallPercentage(numer, denom) {
  try {
    if (numer && denom) {
      let percentage = parseInt(numer, 10) * 100 / parseInt(denom, 10);
      return percentage.toFixed(0) + '%';
    }
  } catch (error) {
    Util.logToConsole('Error: ' + getOverallPercentage.name + ' - Trace: ',
      JSON.stringify(error))
  }
  return '0';
}

/*
  * Gets the size of file in bytes, KBs, MBs or GBs
  */
 export function getFileSize(fileSize, isFileSizeInKbs) {
  try {
      let fileSizeUnit = ' bytes';
      if (fileSize) {
          if (fileSize > 1024) {
              fileSize = (parseFloat(fileSize) / 1024).toFixed(0);
              fileSizeUnit = ' KB'
          }
          // If needed, return file size in MBs
          if (!isFileSizeInKbs && fileSize > 1024) {
              fileSize = (parseFloat(fileSize) / 1024).toFixed(0);
              fileSizeUnit = ' MB';
          }
          return fileSize + fileSizeUnit;
      }
  } catch (error) {
      Util.logToConsole('Error: ' + countAndPercentage.name + ' - Trace: ',
          JSON.stringify(error))
  }
  return '0 bytes';
}

/**
  * Get overall progress text to display on overall progress bar
  */
export function getOverallProgressText(syncData) {
  if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
    let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
    if(inProgressData && inProgressData.hasOwnProperty('status')) {
      let status = inProgressData['status'];
      let overallCompleted = countAndPercentage(status['overall_completed'], status['overall_total']);
      let overallFailed = countAndPercentage(status['overall_failed'], status['overall_total']);
   
      let totalCompletedManifests = status.hasOwnProperty('total_completed_manifests') ?
        status['total_completed_manifests'] : 0;
      let totalFailedManifests = status.hasOwnProperty('total_completed_manifests') ?
        status['total_failed_manifests'] : 0;
      let totalQueuedManifests = status.hasOwnProperty('total_completed_manifests') ?
        status['total_queued_manifests'] : 0;
      let totalManifests = totalCompletedManifests + totalFailedManifests + totalQueuedManifests;

      let overallCompletedManifests = countAndPercentage(totalCompletedManifests, totalManifests);
      let overallFailedManifests = countAndPercentage(totalFailedManifests, totalManifests);

      return (
        <div>
          <div>
            <span>
              <b>
                Resources Manifest Count: 
              </b>
            </span>
            <span>
              <span className='green-color-text awsui-util-ml-s'>
                <b>Completed:</b> { overallCompletedManifests }
              </span>
              {
                overallFailedManifests && overallFailedManifests !== AppConstants.EMPTY && overallFailedManifests !== '0' && (
                  <span className='red-color-text awsui-util-ml-l'>
                    <b>Failed:</b> { overallFailedManifests }
                  </span>
                )
              }
            </span>
          </div>
          <div className='awsui-util-mt-m'>
            <span>
              <b>
              Files Count: 
              </b>
            </span>
            <span>
              <span className='green-color-text awsui-util-ml-s'>
                <b>Completed:</b> { overallCompleted }
              </span>
              {
                overallFailed && overallFailed !== AppConstants.EMPTY && overallFailed !== '0' && (
                  <span className='red-color-text awsui-util-ml-l'>
                    <b>Failed:</b> { overallFailed }
                  </span>
                )
              }
            </span>
          </div>
        </div>
      )
    }
  }
  return(
    <div align='center'><AWSUI.Spinner /></div>
  )
}

/**
 * Gets overall in progress percentage
 */
export function getOverallProgressPercentage(syncData) {
  if(syncData) {
    if(syncData.hasOwnProperty('state')) {
      let overallState = syncData.hasOwnProperty('state') ? syncData['state'] : AppConstants.EMPTY;
      if(overallState !== AppConstants.EMPTY) {
        // If overall status in in progress, return the % files completed
        if(overallState === SyncResourceConstants.OVERALL_STATE_IN_PROGRESS) {
          if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
            let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
            if(inProgressData.hasOwnProperty('status')) {
              let status = inProgressData['status'];
              let overallPending = status.hasOwnProperty('overall_pending') ? status['overall_pending'] : 0;
              let overallTotal = status.hasOwnProperty('overall_total') ? status['overall_total'] : 0;
              if(overallTotal !== 0 && overallTotal >= overallPending) {
                let overallPercentage = ((overallTotal - overallPending)/overallTotal) * 100;
                return overallPercentage;
              }
            }
          }
        } else {

          if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
            let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
            if(inProgressData.hasOwnProperty('status')) {
              let status = inProgressData['status'];
              let overallPending = status.hasOwnProperty('overall_pending') ? status['overall_pending'] : 0;
              let overallTotal = status.hasOwnProperty('overall_total') ? status['overall_total'] : 0;
              if(overallTotal !== 0 && overallTotal >= overallPending) {
                let overallPercentage = ((overallTotal - overallPending)/overallTotal) * 100;
                return overallPercentage;
              }
            }
          }
        }
      }
    }
  }
  return 0;
}

/**
 * Gets overall count of resources completed (completed/ total) to display in overview
 * progress bar
 */
export function getOverallCompletedCount(syncData, elapsedSeconds) {
  if(syncData && syncData.hasOwnProperty('state')) {
    let overallState = syncData.hasOwnProperty('state') ? syncData['state'] : AppConstants.EMPTY;
    // Check if resource sync is in progress or not
    if(overallState === SyncResourceConstants.OVERALL_STATE_IN_PROGRESS) {
      if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
        let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
        if(inProgressData.hasOwnProperty('status')) {
          let status = inProgressData['status'];
          let overallPending = status.hasOwnProperty('overall_pending') ? status['overall_pending'] : 0;
          let overallTotal = status.hasOwnProperty('overall_total') ? status['overall_total'] : 0;
       
          if(overallTotal !== 0 && overallTotal >= overallPending) {
            let overallDone = overallTotal - overallPending;
            let overallDoneText = overallDone.toString() + '/ ' + overallTotal.toString();
            return (
              <span className='awsui-util-ml-m'>
                <span>
                  { overallDoneText }
                </span>
              </span>
            )
          }
        }
      }
    } else {
      if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
        let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
        if(inProgressData.hasOwnProperty('status')) {
          let status = inProgressData['status'];
          let overallPending = status.hasOwnProperty('overall_pending') ? status['overall_pending'] : 0;
          let overallTotal = status.hasOwnProperty('overall_total') ? status['overall_total'] : 0;
          let overallFailed = status.hasOwnProperty('overall_failed') ? status['overall_failed'] : 0;
       
          if(overallTotal !== 0 && overallTotal >= overallPending) {
            let overallDone = overallTotal - overallPending;
            let overallDoneText = AppConstants.EMPTY;
            if(overallState === SyncResourceConstants.FAILED) {
              overallDoneText =  overallFailed + '/ ' + overallTotal;
            } else if (overallState === SyncResourceConstants.COMPLETE) {
              overallDoneText = overallDone + '/ ' + overallTotal;
            }
            return (
              <span>
                <span className='awsui-util-ml-m'>
                  <span>
                    { overallDoneText }
                  </span>
                  <span className='awsui-util-ml-m'>
                  {
                    extractLastUpdateDateFromComplete(syncData) 
                  }
                  </span>
                </span>
              </span>
            )
          }
        }
      }
    }
  }

  return (
    elapsedSeconds < SyncResourceConstants.MAX_WAIT_TIME ? (
      <span align='left' className='awsui-util-ml-s'><AWSUI.Spinner /></span>
    ) : (
      <span align='left' className='awsui-util-ml-s'>
        <span className='white-color-text'>0</span>
      </span>
    )
  );
}

/**
 * Gets the timestamp for download which was either completed or failed
 */
export function extractLastUpdateDateFromComplete(syncData) {
  let syncDate = getSyncCompletedDate(syncData);
  if(syncDate !== AppConstants.EMPTY) {
    return (
      <span className='awsui-util-ml-m'>
        <span>
          <b>Last Sync'd: </b>{ syncDate }
        </span>
      </span>
    )
  }
  return (
    <div align='center'>
      <span>{ AppConstants.EMPTY }</span>
    </div>
  )
}

/**
 * Gets the date when last resource sync was completed
 */
export function getSyncCompletedDate(syncData) {
  try {
    if(syncData && syncData.hasOwnProperty('state')) {
      let overallState = syncData.hasOwnProperty('state') ? syncData['state'] : AppConstants.EMPTY;
      // Display last sync'd timestamp only for resource sync which is complete/ failed
      if(overallState !== SyncResourceConstants.OVERALL_STATE_IN_PROGRESS) {
        if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
          let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
          if(inProgressData.hasOwnProperty('status')) {
            let status = inProgressData['status'];
            let lastUpdatedDate = status.hasOwnProperty('state_change_date') ? status['state_change_date'] : AppConstants.EMPTY;
            let lastUpdatedDateParsed = Date.parse(lastUpdatedDate);
            let syncDate = getShortDate(new Date(lastUpdatedDateParsed).toString());
            return syncDate;  
          }
        }
      }
    }
  } catch(error) {
    Util.logToConsole('Error retrieveing the latest timestamp');
  }
  return AppConstants.EMPTY;
}

/**
 * Gets shorter version of date to display
 */
export function getShortDate(date) {
  let syncDate = AppConstants.EMPTY;
  try {
    if(date && date !== AppConstants.EMPTY) {
      let splitDate = date.split("(");
      if (splitDate && splitDate.length == 2) {
        let splitDateInfo = splitDate[0];
        let timeZoneInfo = '(' + splitDate[1];
        let dateInfo = splitDateInfo.split(" ");
        if(dateInfo && dateInfo.length >= 5) {
          syncDate += dateInfo[1] + " " + dateInfo[2] + " " + dateInfo[3] + " "
          + dateInfo[4] + " " + timeZoneInfo;
        }
      }
    }
  } catch(error) {
    Util.logToConsole('Error extracting date for resource sync');
  }
  return syncDate;
}

/**
 * Gets overall state for resource sync for particular location
 */
export function getOverviewProgressBarState(syncData, elapsedSeconds) {
  let overallState = AppConstants.EMPTY;
  if(syncData) {
    let overallState = syncData.hasOwnProperty('state') ? syncData['state'] : AppConstants.EMPTY;
    if (overallState === AppConstants.EMPTY || !SyncResourceConstants.OVERALL_SYNC_STATES.hasOwnProperty(overallState)) {
      overallState = elapsedSeconds < SyncResourceConstants.MAX_WAIT_TIME ?
         SyncResourceConstants.OVERALL_LOADING_STATE :
         SyncResourceConstants.FAILED_LOADING_STATE;
    }
    return (
      <div className={ SyncResourceConstants.OVERALL_SYNC_STATES[overallState].class }>
        <h5 className = { SyncResourceConstants.OVERALL_SYNC_STATES[overallState].textStyle }>
          <AWSUI.Icon name={ SyncResourceConstants.OVERALL_SYNC_STATES[overallState].icon }
            variant={ SyncResourceConstants.OVERALL_SYNC_STATES[overallState].variant }/>
          <span className='awsui-util-ml-xs'>
            { SyncResourceConstants.OVERALL_SYNC_STATES[overallState].label }
          </span>
          {
            overallState === SyncResourceConstants.FAILED_LOADING_STATE && (
              <span className='black-color-style'>
                <AWSUI.Tooltip className='awsui-util-ml-m awsui-util-no-margin tooltip-inner'
                  text={ SyncResourceConstants.SYNC_DATA_MISSING_MESSAGE } 
                  size='small' 
                  position='right'>
                  <AWSUI.Icon className='black-color-text' name='status-info'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </span>
            )
          }
        </h5>
      </div>
    )
  }
  
  overallState = elapsedSeconds < SyncResourceConstants.MAX_WAIT_TIME ?
    SyncResourceConstants.OVERALL_LOADING_STATE :
    SyncResourceConstants.FAILED_LOADING_STATE;
  return (
    <div className={ SyncResourceConstants.OVERALL_SYNC_STATES[overallState].class }>
      <h5 className = { SyncResourceConstants.OVERALL_SYNC_STATES[overallState].textStyle }>
        <AWSUI.Icon name={ SyncResourceConstants.OVERALL_SYNC_STATES[overallState].icon }
          variant={ SyncResourceConstants.OVERALL_SYNC_STATES[overallState].variant }/>
        <span className='awsui-util-ml-xs'>
          { SyncResourceConstants.OVERALL_SYNC_STATES[overallState].label }
        </span>
        {
          overallState === SyncResourceConstants.FAILED_LOADING_STATE && (
            <span className='black-color-style'>
              <AWSUI.Tooltip className='awsui-util-ml-m awsui-util-no-margin tooltip-inner'
                  text={ SyncResourceConstants.SYNC_DATA_MISSING_MESSAGE } 
                  size='small' 
                  position='right'>
                  <AWSUI.Icon className='black-color-text' name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </span>
          )
        }
      </h5>
    </div>
  )
}

/**
 * Gets ovrall progress text to display size of resources downloaded so far
 */
export function getProgressBarResourceSizeText(syncData) {
  if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
    let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
    if(inProgressData && inProgressData.hasOwnProperty('status')) {
      let status = inProgressData['status'];
      let totalSize = status['overall_total_size'];
      let sizeCompleted = status['overall_completed_size'];
      let downloadSize = countAndPercentage(sizeCompleted, totalSize, true, false);
      let downloadCount = '0'
      if(downloadSize) {
        let downloadCountSplit = downloadSize.split('(');
        if(downloadCountSplit && downloadCountSplit.length >= 1) {
          downloadCount = downloadCountSplit[0];
        }
      }
      return (
        <div>
          <span>
            <b>
            Files Downloaded (Size): 
            </b>
          </span>
          <span>
            <span className='green-color-text awsui-util-ml-s'>
                { downloadCount }
            </span>
          </span>
        </div>
      )
    }
  }
  return(
    <div align='center'><AWSUI.Spinner /></div>
  )
}

/**
 * Gets company ID from Lab data JSON
 */
export function getCompanyId(labData) {
  let companyId = AppConstants.EMPTY;
  if(labData) {
    if(labData.hasOwnProperty('lab')) {
      let lab = labData['lab'];
      if(lab && lab.hasOwnProperty('companyId')) {
        companyId = lab['companyId']; 
      }
    }
  }
  return companyId;
}

/**
 * Gets the indexes of the locations for which resource sync needs to be initiated from
 * Sync Resources tab
 */
export function getSyncLocationIndexes(locations, labData) {
  let rasPiIndexes = [];
  if(locations && labData) {
    let rasPis = labData.hasOwnProperty('rasPis') ? labData['rasPis'] : [];
    // Iterate over locations selected to figure out it's index for resource sync purposes
    if(rasPis && rasPis.length > 0 && locations.length > 0) {
      locations.map(location => {
        // Itearte over all locations to retrieve it's index
        for(let i = 0; i < rasPis.length; i++) {
          let rasPi = rasPis[i];
          if(rasPi && rasPi.hasOwnProperty('name')) {
            let locationNameFromRaspiJson = Util.getRasPiName(rasPi, labData);
            // Add the index to the list of indexes upon match
            if(locationNameFromRaspiJson.includes(location)) {
              rasPiIndexes.push(i);
            }
          }
        }
      });
    }
  }
  return rasPiIndexes;
}

/**
 * Checks if lab is locked or available to run the test 
 */
export function isLabLocked(labData) {
  let isLocked = false;
  if(labData && labData.hasOwnProperty('lab')) {
    let lab = labData['lab'];
    if(lab && lab.hasOwnProperty('lock')) {
      isLocked = lab['lock'] == 0 ? false : true;
    }
  }
  return isLocked;
}

/**
 * Checks if any of the locations are offline before starting resource sync
 * This is mainly used on Sync Options tab where one or more locations could be selected before
 * submitting resources for download
 */
export function checkLocationsOfflineBeforeSubmit(labData, locations) {
  let locationsOffline = false;
  if(labData) {
    // Check status of selected locations in rasPi data
    if(labData.hasOwnProperty('rasPis')) {
      let rasPis = labData.rasPis;
      if(rasPis && rasPis.length > 0) {
        if(locations && locations.length > 0) {
          locations.map(locationName => {
            // Search for location status in rasPis data
            rasPis.map(rasPi => {
              let rasPiName = Util.getRasPiName(rasPi, labData);
              if(!locationsOffline && rasPiName && rasPiName.includes(locationName)) {
                let rasPiStatus = rasPi.hasOwnProperty('rasPiStatus') ? rasPi['rasPiStatus'] : {};
                if(Object.keys(rasPiStatus).length > 0) {
                  let status = rasPiStatus.hasOwnProperty('status') ? rasPiStatus['status'] : AppConstants.OFFLINE;
                  if(status !== AppConstants.ONLINE) {
                    locationsOffline = true;
                  }
                }
              }
            })
          })
        }
      }
      return locationsOffline;
    }
  }
  return locationsOffline;
}

/**
 * Gets the JSON needs to be submitted for resource sync based on list of manifest file names
 * directly retrieved from controller
 */
export function generateSyncResourceDownloadJsonFromFileNames(manifestsForLocation) {
  let syncResourceJson = {};
  if(manifestsForLocation && Object.keys(manifestsForLocation).length > 0) {
    // Iterate through all manifests which are checked for resource sync
    Object.keys(manifestsForLocation).map(manifest => {
      let splitManifest = AQTTestOptionsParser.get_aqt_test_info_from_manifest_file(manifest);
      if(splitManifest && Object.keys(splitManifest).length > 0) {
        let testSuite = splitManifest.hasOwnProperty('test_suite') ? splitManifest['test_suite'] : AppConstants.EMPTY;
        let scenarioType = splitManifest.hasOwnProperty('scenario_type') ? splitManifest['scenario_type'] : AppConstants.EMPTY;
        let locale = splitManifest.hasOwnProperty('locale') ? splitManifest['locale'] : AppConstants.EMPTY;
        // Add entry to the sync resource JSON only if test suite, scenario type & locale all are detected successfully
        if(testSuite && scenarioType && locale && 
            testSuite !== AppConstants.EMPTY && scenarioType !== AppConstants.EMPTY && locale !== AppConstants.EMPTY) {
          let scenarioTypeMap = syncResourceJson.hasOwnProperty(testSuite) ? syncResourceJson[testSuite] : {};
          let locales = scenarioTypeMap.hasOwnProperty(scenarioType) ? scenarioTypeMap[scenarioType] : [];
          // Add locale to the list of locales
          locales.push(locale);
          // Update maps
          scenarioTypeMap[scenarioType] = locales;
          syncResourceJson[testSuite] = scenarioTypeMap   
        }
      }
    })
  }
  return syncResourceJson;
}

/**
 * Gets display friendly name for given manifest file which is in the format returned by IoT shadow/ controller
 * e.g input -> en_US_Quick_Test_AC_FAR_FIELD_ACOUSTIC.json
 * output -> Acoustic - Far Field - en-US
 */
export function getManifestDisplayName(resourceName) {
  if(!resourceName || resourceName === AppConstants.EMPTY) {
    return AppConstants.EMPTY;
  } 

  // By default, assign resource display friendly name to whatever raw resource name is retrieved from
  // back end by replacing .json in the same
  let resourceNameDisplay = resourceName.replace('.json', '');
  let splitManifest = AQTTestOptionsParser.get_aqt_test_info_from_manifest_file(resourceName);
  
  // Genrate display friendly resource name
  if(splitManifest && Object.keys(splitManifest).length > 0) {
    let testSuite = splitManifest.hasOwnProperty('test_suite') ? splitManifest['test_suite'] : AppConstants.EMPTY;
    let scenarioType = splitManifest.hasOwnProperty('scenario_type') ? splitManifest['scenario_type'] : AppConstants.EMPTY;
    let locale = splitManifest.hasOwnProperty('locale') ? splitManifest['locale'] : AppConstants.EMPTY;
    if(testSuite && scenarioType && locale && 
      testSuite !== AppConstants.EMPTY && scenarioType !== AppConstants.EMPTY && locale !== AppConstants.EMPTY) {
        resourceNameDisplay = getManifestDisplayNameFromSplitFile(resourceNameDisplay, testSuite, scenarioType, locale);   
    }
  }
  return resourceNameDisplay;
}

/**
 * Gets display friendly name for manifest file based on testSuite, scenarioType & locale IDs
 * passed as input
 */
export function getManifestDisplayNameFromSplitFile(resourceNameDisplay, testSuite, scenarioType, locale) {
  if(testSuite && scenarioType && locale) {
    // Get display friendly test suite name
    let testSuiteDisplay = SyncResourceConstants.TEST_SUITE_REVERSE_MAP.hasOwnProperty(testSuite) ?
      SyncResourceConstants.TEST_SUITE_REVERSE_MAP[testSuite]['label'] :
      testSuite;

    let testSuiteId = SyncResourceConstants.TEST_SUITE_REVERSE_MAP.hasOwnProperty(testSuite) ?
      SyncResourceConstants.TEST_SUITE_REVERSE_MAP[testSuite]['suiteId'] :
      testSuite;

    // Get display friendly scenario type name
    let scenarioTypeMap = SyncResourceConstants.SYNC_RESOURCE_OPTIONS.hasOwnProperty(testSuiteId) ?
      SyncResourceConstants.SYNC_RESOURCE_OPTIONS[testSuiteId] :
      {};
    let scenarioTypeDisplay = scenarioType;
    if(scenarioTypeMap && Object.keys(scenarioTypeMap).length > 0) {
      let allScenarios = scenarioTypeMap['scenarioTypes'];
      if(allScenarios && allScenarios.hasOwnProperty(scenarioType)) {
        scenarioTypeDisplay = allScenarios[scenarioType]['label'];
      }
    }

    // For locale, simply replace underscore (_) with - (-)
    let localeDisplay = locale.replace('_', '-');
    resourceNameDisplay = testSuiteDisplay + '  -  ' + scenarioTypeDisplay + '  -  ' + localeDisplay;
  }
  return resourceNameDisplay
}



/**
 * Tab for downloading resources by providing one or more options for Test Suite, Scenario type, locale & one 
 * or more locations
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { Actions, AppConstants, AQTTestOptionsParser, Store, SyncResourceConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { validateSyncResourceOptions, validateLocatons, collapseOtherTestSuites,
  collapseOtherScenarioTypes, getExpandableSectionContent, getSelectedValuForScenario,
  getSelectedValuForTestSuite, onCheckedAllForTestSuite, isAllCheckedForAllScenarioTypes,
  getSyncLocationIndexes, generateSyncResourceDownloadJson,
  checkLocationsOfflineBeforeSubmit, getSyncResourceOptionsData, getLocationsSelected }
  from './SyncResourceUtil';
import { SYNC_RESOURCE_OPTIONS_COLUMNS, SORTABLE_COLUMNS } from './SyncResourceOptionsTableConfig.js'
import { fetchLabs } from './controller';

class SyncResourceControl extends React.Component {

  state = {
    selectedOptionsMap: {},
    locations: [],
    isSubmitClicked: false,
    isSyncOptionsError: false,
    isErrorLablocked: false,
    isLocationError: false,
    isLocationOfflineError: false,
    isSyncSubmitting: false,
    isManifestEmptyError: false,
    testSuitesWithAllSelections: [],
    downloadResourceJson: []
  };

  componentWillUnmount() {
    // If tab is navigated away, save the state of selections made in this tab
    let labId = this.props.params.labId;
    if(labId) {
       this.handleTabChangeSyncResources(labId);
    } 
  }

  componentWillMount() {
    // Retrieve the already stored state from state store
    let labId = this.props.params.labId;

    if(labId) {
      let key = labId + SyncResourceConstants.SYNC_RESOURCES_TAB_ID;
      let syncSubmittedKey = labId + SyncResourceConstants.SYNC_RESOURCES_TAB_ID + '_syncSubmitted';
      
      if (Store.stateStore.getState().hasOwnProperty(key)) {
        let savedState =  Store.stateStore.getState()[key];

        if(Store.stateStore.getState().hasOwnProperty(syncSubmittedKey)) {
          let syncSubmittedValue = Store.stateStore.getState()[syncSubmittedKey];
          // If resources has not been submitted for download yet, retrieve the saved state for this tab,
          // otherwise show fresh options
          if (!syncSubmittedValue) {
            // Set the state to the saved one
            this.state = savedState;
          } else {
            Store.stateStore.dispatch(Actions.saveState(syncSubmittedKey, false));
          }
        } else {
          this.state = savedState;
        }
      }
    }
  }
  
  /**
   * Gets the online/ offline/ Error status for RasPi
   */
  getPingStatus = (rasPiStatus, locationName) => {
    if (rasPiStatus) {
      return (
        <div>
          <span className='dark-grey-color-text awsui-util-mr-s'>
            { locationName }
          </span>
          { rasPiStatus.status ? (
            <span className={ rasPiStatus.status === AppConstants.ONLINE ? 'awsui-util-status-positive' : 'awsui-util-status-negative' }>
              <AWSUI.Icon name={ rasPiStatus.status === AppConstants.ONLINE ? 'status-positive' : 'status-negative' }
                variant={ 'normal' } />
              <span className='dark-grey-color-text awsui-util-ml-xs'>
                { rasPiStatus.status }
              </span>
            </span>
          ) : (
            <span>
              <AWSUI.Spinner />
            </span>
          )}
        </div>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  /**
   * Renders Sync Resource options as nested exapandable sections with checkboxes at 
   * leaf level
   */
  getSyncResourceLocations = (labData) => {
    if (!labData || !labData.hasOwnProperty('rasPis')) {
      return (
        <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
          <span>
            <AWSUI.Icon variant="error" name="status-warning"></AWSUI.Icon>
            <span className='awsui-util-ml-s'>{ SyncResourceConstants.ERROR_NO_LOCATON_DATA }</span>
          </span>
        </div>
      );
    }

    let rasPis = labData.rasPis;
    return (
      <div className='awsui-util-mt-xl'>
      <AWSUI.FormField
        label={ <h3>{ 'Locations' }</h3> }
        errorText={ AppConstants.EMPTY }
      >
      <AWSUI.ColumnLayout columns={ 3 } className='awsui-util-container'>
        <div data-awsui-column-layout-root='true'>
        <AWSUI.Checkbox
          label={ 'Select/Deselect All' }
          checked= { this.isLocationChecked(SyncResourceConstants.LOCALES_ALL) }
          onChange={ event => { this.onLocationChecked(SyncResourceConstants.LOCALES_ALL, rasPis, event, labData) } }
        />
        { 
          rasPis.map(rasPi => {
            let location = Util.getRasPiName(rasPi, labData);
               return (
                <AWSUI.Checkbox
                  label={ 
                    <span>
                      { 
                        this.getPingStatus(rasPi.rasPiStatus, location) 
                      }
                    </span>
                  }
                  checked= { this.isLocationChecked(location) }
                  onChange={ event => { this.onLocationChecked(location, rasPis, event) } }
                />
              )
            })} 
        </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
      </div>
    )
  }

  /**
   * Event handler for checking/ unchecking particular location name
   */
  onLocationChecked = (location, rasPis, event, labData) => {
    let isChecked = event.detail.checked ? true: false;
    let locations = this.state.locations;
    if (location) {
      if (isChecked && !locations.includes(location)) {
        // Add this location to the list if it's not there already
        locations.push(location);
        // If "All" checkbox was checked, we need to add all locations to 
        // checked state
        if (location === SyncResourceConstants.LOCALES_ALL) {
          rasPis.map(rasPi => {
            let currentLocation = Util.getRasPiName(rasPi, labData);
            if (!locations.includes(currentLocation)) {
              locations.push(currentLocation);
            }
          })
        }
        // All locations were checked, we need to check "All" checkbox as well
        if (locations.length === SyncResourceConstants.MAX_LOCATIONS) {
          locations.push(SyncResourceConstants.LOCALES_ALL);
        }
      } else {
        // Remove location from the list on uncheck event
        if (locations.includes(location)) {
          let locationIndex = locations.indexOf(location);
          if (locationIndex > -1 && locationIndex < locations.length) {
            locations.splice(locationIndex, 1);
          }
        }
        // If "All" was unchecked, we need to uncheck all locations
        if (location === SyncResourceConstants.LOCALES_ALL) {
          locations = [];
        } 
        // If all locations were checked & if any one of them is unchecked, uncheck "All"
        // as well
        if (locations.length === SyncResourceConstants.MAX_LOCATIONS) {
          let locationIndex = locations.indexOf(SyncResourceConstants.LOCALES_ALL);
          if (locationIndex > -1 && locationIndex < locations.length) {
            locations.splice(locationIndex, 1);
          }
        }
      }
      this.setState({
        ...this.state,
        locations: locations,
        isLocationError: false,
        isLocationOfflineError: false
      })
    }
  }

  /**
   * Returns whether location is checked or not
   */
  isLocationChecked = (location) => {
    if (location) {
      return this.state.locations.includes(location);
    }
    return false;
  }

  /**
   * Renders Sync Resource options as nested exapandable sections with checkboxes at 
   * leaf level
   */
  getSyncResourceOptions = (labId, allowlistedScenarioIds) => {
    return (
      <AWSUI.FormField
        label={ <h3>{ 'Sync Resources Options' }</h3> }
        errorText={ AppConstants.EMPTY }
      >
      <AWSUI.Checkbox 
        className='awsui-util-mb-s awsui-util-mt-s awsui-util-ml-s'
        label='Select/Deselect All'
        checked= { this.isAllChecked(labId, allowlistedScenarioIds) }
        onChange={ event => { this.onCheckedAll(labId, allowlistedScenarioIds, event) }}
        />
      {
       Object.keys(SyncResourceConstants.SYNC_RESOURCE_OPTIONS).map(testSuite => {
         // Only display allowlisted test suites
         if (!allowlistedScenarioIds.includes(testSuite)) {
           return (
             <div>
             </div>
           )
         }
         let testSuiteLabel = SyncResourceConstants.SYNC_RESOURCE_OPTIONS[testSuite]['label'];
         let isSelected = getSelectedValuForTestSuite(this.state.selectedOptionsMap, labId, testSuite);  
         let isExpanded = this.isSectionExpandedTestSuite(labId, testSuite);
         return (
          <AWSUI.ExpandableSection
            header={ getExpandableSectionContent(testSuiteLabel, isExpanded, isSelected, true) }
            expanded={ isExpanded }
            onChange={ event => { this.onSectionExpandedTestSuite(labId, testSuite, testSuiteLabel, event) } }
            variant={ 'borderless' }
          >
          <AWSUI.Checkbox 
            className='awsui-util-mb-s awsui-util-mt-s awsui-util-ml-s'
            label='Select/Deselect All'
            checked= { this.isAllCheckedForTestSuite(labId, testSuite) }
            onChange={ event => { this.onCheckedAllForTestSuite(labId, testSuite, event) }}
            />
          {
            this.getScenariosForTestSuite(labId, testSuite)
          }
          </AWSUI.ExpandableSection>
         )
        })
      }
      </AWSUI.FormField>
    )
  }

  /**
   * Gets if "Select/Deselect All" option is checked or not for all test suites
   */
  isAllChecked = (labId, allowlistedScenarioIds) => {
    let isChecked = false;
    if (labId && allowlistedScenarioIds && allowlistedScenarioIds.length > 0) {
      // Get list of test suites for which "All" is selected
      let testSuitesWithAllSelections = this.state.testSuitesWithAllSelections;
      let allChecked = true;
      if(testSuitesWithAllSelections.length === allowlistedScenarioIds.length) {
        allowlistedScenarioIds.map(testSuite => {
          // Even if single test suite is not part of test suites with all selections, we should
          // show "Select/Deselect All" checkbox as unchecked
          if(allChecked && !testSuitesWithAllSelections.includes(testSuite)) {
            allChecked = false;
          }
        });
        isChecked = allChecked;
      }
    }
    return isChecked;
  }

  /**
   * Checks/ unchecks "Select/Deselect All" checkbox for all allowlisted test suites
   */
  onCheckedAll = (labId, allowlistedScenarioIds, event) => {
    if(labId && allowlistedScenarioIds && allowlistedScenarioIds.length > 0) {
      // Check or uncheck "Select/Deselect All" for each individual test suite allowlisted
      allowlistedScenarioIds.map(testSuite => {
        this.onCheckedAllForTestSuite(labId, testSuite, event);
      });
    }

    // Collapse all expanders at test suite level after selection has been made for all
    let selectedOptionsMap = this.state.selectedOptionsMap;
    let testSuitesMap = selectedOptionsMap[labId];
    Object.keys(testSuitesMap).map(testSuite => {
      testSuitesMap[testSuite]['expanded'] = false;
      selectedOptionsMap[labId] = testSuitesMap;
    });
  
    this.setState({
      ...this.state,
      selectedOptionsMap: selectedOptionsMap
    })
  }

  /**
   * Gets if "Select/Deselect All" option at the test suite level is checked or not
   */
  isAllCheckedForTestSuite = (labId, testSuite) => {
    if (labId) {
      return this.state.testSuitesWithAllSelections.includes(testSuite);
    }
    return false;
  }

  /**
   * Checks/ unchecks "Select/Deselect All" checkbox at the test suite level
   */
  onCheckedAllForTestSuite = (labId, testSuite, event) => {
    if (labId) {
      let isChecked = event.detail.checked ? true : false;
      let selectedOptionsMap = this.state.selectedOptionsMap;
      if(!selectedOptionsMap.hasOwnProperty(labId)) {
        selectedOptionsMap[labId] = {};
      }
      selectedOptionsMap = onCheckedAllForTestSuite(selectedOptionsMap, labId, testSuite, isChecked);
      let testSuitesWithAllSelections = this.state.testSuitesWithAllSelections;

      // If All is selected at this test suite level, add it to list of such test suites to keep track of it
      if (isChecked && !testSuitesWithAllSelections.includes(testSuite)) {
        testSuitesWithAllSelections.push(testSuite)
      } 

      // If All is deselected at this test suite level, remove it from list of such test suites to keep track of it
      if (!isChecked && testSuitesWithAllSelections.includes(testSuite)) {
        let testSuiteIndex = testSuitesWithAllSelections.indexOf(testSuite);
        if (testSuiteIndex > -1) {
          testSuitesWithAllSelections.splice(testSuiteIndex, 1);
        }
      }

      this.setState({
        ...this.state,
        selectedOptionsMap : selectedOptionsMap,
        testSuitesWithAllSelections: testSuitesWithAllSelections,
        isSyncOptionsError: false,
        isManifestEmptyError: false
      })
    }
  }

  /** 
   * Gets scenarios for expanded test suite
   */
  getScenariosForTestSuite = (labId, testSuite) => {
    // Retrieve scenario types for this test suite
    let scenarioTypes = SyncResourceConstants.SYNC_RESOURCE_OPTIONS[testSuite].scenarioTypes;
    return (
      Object.keys(scenarioTypes).map(scenarioType => {
        let scenarioTypeLabel = 
          SyncResourceConstants.SYNC_RESOURCE_OPTIONS[testSuite]['scenarioTypes'][scenarioType][SyncResourceConstants.LABEL];
        let isSelected = getSelectedValuForScenario(this.state.selectedOptionsMap, labId, testSuite, scenarioType);  
        let isExpanded = this.isSectionExpandedScenarioType(labId, testSuite, scenarioType)
        return (
         <AWSUI.ExpandableSection className='awsui-util-ml-xl'
           header={ getExpandableSectionContent(scenarioTypeLabel, isExpanded, isSelected, false) }
           expanded={ isExpanded }
           onChange={ event => { this.onSectionExpandedScenarioType(labId, testSuite, scenarioType, 
             scenarioTypeLabel, event) } }
           variant={ 'borderless' }
         >
         {
           this.getLocalesForScenario(labId, testSuite, scenarioType)
         }
         </AWSUI.ExpandableSection>
        )
       })
    )
  }

  /**
   * Gets locales as checkboxes for expanded scenario
   */
  getLocalesForScenario = (labId, testSuite, scenarioType) => {
    // Retrieve list of locales for current test suite + scenario type
    let locales = SyncResourceConstants.SYNC_RESOURCE_OPTIONS[testSuite]['scenarioTypes'][scenarioType]['locales'];
    return (
      <AWSUI.ColumnLayout columns={ 4 } className='awsui-util-container'>
      <div data-awsui-column-layout-root='true' className='awsui-util-pb-s'>
      { 
        locales.map(locale => {
        return (
          <AWSUI.Checkbox
            label={ locale['label'] }
            checked= { this.isLocaleChecked(labId, testSuite, scenarioType, locale['id']) }
            onChange={ event => { this.onLocaleChecked(labId, testSuite, scenarioType, locale['id'], 
              locale['label'], locales, event) } }
          />
        )
      })}          
      </div>
     </AWSUI.ColumnLayout> 
    )
  }

  /**
   * Event handler for expanding/ collapsing a test suite
   */
  onSectionExpandedTestSuite = (labId, testSuite, testSuiteLabel, event) => {
    if (labId) {
      let isExpanded = event.detail.expanded ? true : false;
      // If there's already a sync resource options mapping stored for current lab in state,
      // retrieve it
      let selectedOptionsMap = {};
      let testSuitesMap = {};
      let testSuiteValue = {};
      if (this.state.selectedOptionsMap.hasOwnProperty(labId)) {
        testSuitesMap = this.state.selectedOptionsMap[labId];
      }
      if (testSuitesMap.hasOwnProperty(testSuite)) {
        testSuiteValue = testSuitesMap[testSuite];
      }
      testSuiteValue['expanded'] = isExpanded;
      testSuiteValue['label'] = testSuiteLabel;
      testSuitesMap[testSuite] = testSuiteValue;
      selectedOptionsMap[labId] = testSuitesMap;

      // Collapse all other test suites if current test suite is expanded
      if (isExpanded) {
        selectedOptionsMap = collapseOtherTestSuites(selectedOptionsMap, testSuite);
      }
      this.setState({
        ...this.state,
        selectedOptionsMap: selectedOptionsMap
      });
    }
  }

  /** 
   * Method to check whether current test suite section is expanded or collapsed
   */
  isSectionExpandedTestSuite = (labId, testSuite) => {
    if (labId) {
      // By default keep all sections collapsed
      if (Object.keys(this.state.selectedOptionsMap).length == 0) {
        return false;
      }
      if (!this.state.selectedOptionsMap.hasOwnProperty(labId)) {
        return false;
      }
      // Retrieve test suites map for current lab
      let testSuitesMap = this.state.selectedOptionsMap[labId]; 
      if (Object.keys(testSuitesMap).length == 0) {
        return false;
      }
      if (!testSuitesMap.hasOwnProperty(testSuite)) {
        return false;
      }
      return testSuitesMap[testSuite]['expanded'];
    }
    return false;
  }

  /**
   * Event handler for expanding/ collapsing scenario type expanders
   */
  onSectionExpandedScenarioType = (labId, testSuite, scenarioType, scenarioTypeLabel, event) => {
    if (labId) {
      let isExpanded = event.detail.expanded ? true : false;
      // If there's already a sync resource options mapping stored for current lab in state,
      // retrieve it
      let selectedOptionsMap = {};
      let testSuitesMap = {};
      let testSuiteValue = {};
      let scenarioTypeValue = {};
      if (this.state.selectedOptionsMap.hasOwnProperty(labId)) {
        testSuitesMap = this.state.selectedOptionsMap[labId];
      }
      if (testSuitesMap.hasOwnProperty(testSuite)) {
        testSuiteValue = testSuitesMap[testSuite];
      }
      if (testSuiteValue.hasOwnProperty(scenarioType)) {
        scenarioTypeValue = testSuiteValue[scenarioType];
      }
      scenarioTypeValue['expanded'] = isExpanded;
      scenarioTypeValue['label'] = scenarioTypeLabel;
      testSuiteValue[scenarioType] = scenarioTypeValue;
      testSuitesMap[testSuite] = testSuiteValue;
      selectedOptionsMap[labId] = testSuitesMap;

      // Collapse other scenario types if current scenario type is expanded
      if (isExpanded) {
        selectedOptionsMap = collapseOtherScenarioTypes(selectedOptionsMap, testSuite, scenarioType);
      }
      this.setState({
        ...this.state,
        selectedOptionsMap: selectedOptionsMap
      });
    }
  }

  /** 
   * Method to check whether current scenario type within test suite is 
   * expanded or not
   */
  isSectionExpandedScenarioType = (labId, testSuite, scenarioType) => {
    if (labId) {
      // By default keep all sections collapsed
      if (Object.keys(this.state.selectedOptionsMap).length == 0) {
        return false;
      }
      if (!this.state.selectedOptionsMap.hasOwnProperty(labId)) {
        return false;
      }
      // Retrieve test suites map for current lab
      let testSuitesMap = this.state.selectedOptionsMap[labId]; 
      if (Object.keys(testSuitesMap).length == 0) {
        return false;
      }
      if (!testSuitesMap.hasOwnProperty(testSuite)) {
        return false;
      }
      let testSuitesMapValue = testSuitesMap[testSuite];
      // Retrieve scenario types map within this test suite map
      if (!testSuitesMapValue.hasOwnProperty(scenarioType)) {
        return false;
      }
      let scenarioTypesMap = testSuitesMapValue[scenarioType];
      return scenarioTypesMap['expanded'];
    }
    return false;
  }

  /**
   * Event handler for checking/ unchecking locale under particular scenario type
   */
  onLocaleChecked = (labId, testSuite, scenarioType, locale, localeLabel, locales, event) => {
    if (labId) {
      let isChecked = event.detail.checked ? true : false;
      let testSuitesWithAllSelections = this.state.testSuitesWithAllSelections;
      // If there's already a sync resource options mapping stored for current lab in state,
      // retrieve it
      let selectedOptionsMap = {};
      let testSuitesMap = {};
      let testSuiteValue = {};
      let scenarioTypeValue = {};
      let localesMap = {};
      if (this.state.selectedOptionsMap.hasOwnProperty(labId)) {
        testSuitesMap = this.state.selectedOptionsMap[labId];
      }
      // Retrieve test suites within outer map
      if (testSuitesMap.hasOwnProperty(testSuite)) {
        testSuiteValue = testSuitesMap[testSuite];
      }
      // Retrieve scenarios within test suite
      if (testSuiteValue.hasOwnProperty(scenarioType)) {
        scenarioTypeValue = testSuiteValue[scenarioType];
      }
      // Retrieve locales within scenario
      if (scenarioTypeValue.hasOwnProperty(locale)) {
        localesMap = scenarioTypeValue[locale];
      }
      if (isChecked) {
        localesMap['checked'] = isChecked;
        localesMap['label'] = localeLabel;
        // If any checkbox for this scenario is checked, mark "selected" as true for that
        // scenario expanded section
        scenarioTypeValue[SyncResourceConstants.SELECTED] = true;
        scenarioTypeValue[locale] = localesMap;
        // If all checkboxes for this scenario type are checked, then also check "All" checkbox
        // This can be determined by excluding "label" and "expanded" keys from scenario type map
        // and checking if all locales  are checked
        if (Object.keys(scenarioTypeValue).length - 3 === locales.length - 1) {
          localesMap = {
            'checked' : isChecked,
            'label': SyncResourceConstants.LOCALES_ALL_LABEL
          }
          scenarioTypeValue[SyncResourceConstants.LOCALES_ALL] = localesMap;
        }
      } else {
        // If unchecked, remove the entry from the map
        if(scenarioTypeValue.hasOwnProperty(locale)) {
          delete scenarioTypeValue[locale];
        }
        // If last checkbox was unchecked, mark "select" as false for that scenario expanded section
        if(Object.keys(scenarioTypeValue).length === 3) {
          scenarioTypeValue[SyncResourceConstants.SELECTED] = false;
        }
        // Even if one locale is unchecked under this test suite, if "Select/Deselect All" checkbox was
        // marked as checked, uncheck it
        let testSuiteIndex = testSuitesWithAllSelections.indexOf(testSuite);
        if (testSuiteIndex > -1) {
          testSuitesWithAllSelections.splice(testSuiteIndex, 1);
        }
      }
      // If "All" checkbox is checked/ unchecked, then check/ uncheck respective
      // all checkboxes under particular scenario type
      if (locale === SyncResourceConstants.LOCALES_ALL) {
        locales.map(currentLocale => {
          if (currentLocale['id'] !== SyncResourceConstants.EXPANDED
                && currentLocale['id'] !== SyncResourceConstants.LABEL
                && currentLocale['id'] !== SyncResourceConstants.SELECTED) {
            if (isChecked) {
              let currentLocalesMap = scenarioTypeValue[currentLocale['id']];
              if (!currentLocalesMap) {
                currentLocalesMap = {};
              }
              currentLocalesMap['checked'] = isChecked;
              currentLocalesMap[SyncResourceConstants.LABEL] = currentLocale['label'];
              scenarioTypeValue[currentLocale['id']] = currentLocalesMap;
            } else {
              // If unchcked, remove entry from the map
              if (scenarioTypeValue.hasOwnProperty(currentLocale['id'])) {
                delete scenarioTypeValue[currentLocale['id']];
              }
              // If last checkbox was unchecked, mark "select" as false for that scenario expanded section
              if(Object.keys(scenarioTypeValue).length === 3) {
                scenarioTypeValue[SyncResourceConstants.SELECTED] = false;
              }
              // Even if one locale is unchecked under this test suite, if "Select/Deselect All" checkbox was
              // marked as checked, uncheck it
              let testSuiteIndex = testSuitesWithAllSelections.indexOf(testSuite);
              if (testSuiteIndex > -1) {
                testSuitesWithAllSelections.splice(testSuiteIndex, 1);
              }
            }
          }
        })
      } else {
        // Even if single locale is unchecked, we need to uncheck "All" as well
        if (!isChecked) {
          delete scenarioTypeValue[SyncResourceConstants.LOCALES_ALL];
        }
        // If last checkbox was unchecked, mark "select" as false for that scenario expanded section
        if(Object.keys(scenarioTypeValue).length === 3) {
          scenarioTypeValue[SyncResourceConstants.SELECTED] = false;
        }
        // Even if one locale is unchecked under this test suite, if "Select/Deselect All" checkbox was
        // marked as checked, uncheck it
        let testSuiteIndex = testSuitesWithAllSelections.indexOf(testSuite);
        if (testSuiteIndex > -1) {
          testSuitesWithAllSelections.splice(testSuiteIndex, 1);
        }
      } 
      testSuiteValue[scenarioType] = scenarioTypeValue;
      testSuitesMap[testSuite] = testSuiteValue;
      selectedOptionsMap[labId] = testSuitesMap;

      // Confirm whether to check "Select/Deselect All" checkbox at test suite level
      if(isAllCheckedForAllScenarioTypes(selectedOptionsMap, labId, testSuite)) {
        if(!testSuitesWithAllSelections.includes(testSuite)) {
          testSuitesWithAllSelections.push(testSuite);
        }
      }

      this.setState({
        ...this.state,
        selectedOptionsMap: selectedOptionsMap,
        testSuitesWithAllSelections: testSuitesWithAllSelections,
        isSyncOptionsError: false,
        isManifestEmptyError: false
      });
    }
  }

  /** 
   * Method to check whether locale under scenario type is checked or not
   */
  isLocaleChecked = (labId, testSuite, scenarioType, locale) => {
    if (labId) {
      // By default keep all sections collapsed
      if (Object.keys(this.state.selectedOptionsMap).length == 0) {
        return false;
      }
      if (!this.state.selectedOptionsMap.hasOwnProperty(labId)) {
        return false;
      }
      // Retrieve test suites map for current lab
      let testSuitesMap = this.state.selectedOptionsMap[labId]; 
      if (Object.keys(testSuitesMap).length == 0) {
        return false;
      }
      if (!testSuitesMap.hasOwnProperty(testSuite)) {
        return false;
      }
      let testSuitesMapValue = testSuitesMap[testSuite];
      // Retrieve scenario types map within this test suite map
      if (!testSuitesMapValue.hasOwnProperty(scenarioType)) {
        return false;
      }
      let scenarioTypesMap = testSuitesMapValue[scenarioType];
      if (!scenarioTypesMap.hasOwnProperty(locale)) {
        return false;
      }
      return scenarioTypesMap[locale]['checked'];
    }
    return false;
  }

  /**
   * Invoked when submit button is clicked after making selection of sync resource options
   */
  submitSyncResourceOptionsClicked = (labData) => {
    // Check whether Sync resource are set correctly
    let isSyncOptionsError = validateSyncResourceOptions(this.state.selectedOptionsMap);
    let isLocationError = validateLocatons(this.state.locations);
    let isLocationOfflineError = checkLocationsOfflineBeforeSubmit(labData, this.state.locations);
  
    // Check if one or more of the selected locations is offline
    this.setState({
      isSubmitClicked: isSyncOptionsError && isLocationError && !isLocationOfflineError,
      isSyncOptionsError: !isSyncOptionsError,
      isLocationError: !isLocationError,
      isLocationOfflineError: isLocationOfflineError
    }) 
  }

  /**
   * Invoked when back button is clicked to modify selection of resource sync options
   */
  backButtonClicked = () => {
    this.setState({
      isSubmitClicked : false
    })
  }

  /**
   * Invoked when sync resource button is clicked to sync resources after all options have been
   * selected
   */
  syncResourceClicked = (labId, labData) => {
    // Check if lab is locked, if so, don't invoke resource download
    let isLabLocked = SyncResourceConstants.LAB_AVAILABLE;

    fetchLabs().then(labs => {
      if (!labs.hasOwnProperty('error')) {
        labs.forEach(lab => {
          if(lab.hasOwnProperty('id') && lab.id === labId) {
            isLabLocked = lab.hasOwnProperty('lock') ? lab['lock'] : SyncResourceConstants.LAB_AVAILABLE;
            Util.logToConsole('Lab locked status for ' + labId + ' from controller API = ' + isLabLocked);
          }
        });
      } else {
        Util.logToConsole('Error retrieving labs');
      }
    });

    let downloadResourceJson = [];
  
    this.setState({
      ...this.state,
      isSyncSubmitting: true
    })

    setTimeout(() => {
      Util.logToConsole('Lab locked status before submitting the resource sync for ' + labId + ' = ' + isLabLocked);
      // Generate download manifest JSON & kick off resource sync only if lab is not locked
      if(isLabLocked === SyncResourceConstants.LAB_AVAILABLE) {
        // Get the indexes of locations for which resources needs to be sync'd
        let rasPiIndexes = getSyncLocationIndexes(this.state.locations, labData);
        let downloadResourceJsonForController = generateSyncResourceDownloadJson(this.state.selectedOptionsMap, labId);
        let locationsSelectedForSync = [];
        rasPiIndexes.forEach(raspiIndex => locationsSelectedForSync.push(AppConstants.actorMappingKeys[raspiIndex]));
        downloadResourceJson = AQTTestOptionsParser.get_manifests_for_resource_sync_options(downloadResourceJsonForController, locationsSelectedForSync);

        // Invoke resource sync for locations & options selected
        if(downloadResourceJson && downloadResourceJson.length > 0
          && rasPiIndexes && rasPiIndexes.length > 0) {
            // Save the fact that we are submitting sync & navigating away from the tab automatically
            let syncSubmittedKey = labId + SyncResourceConstants.SYNC_RESOURCES_TAB_ID + '_syncSubmitted';
            Store.stateStore.dispatch(Actions.saveState(syncSubmittedKey, true));
            this.handleSyncClicked(labId, rasPiIndexes, downloadResourceJson, AppConstants.rasPiAction.SYNC.id);
        }
      }

      this.setState({
        ...this.state,
        isErrorLablocked: isLabLocked === SyncResourceConstants.LAB_AVAILABLE ? false : true,
        downloadResourceJson: downloadResourceJson,
        isSyncSubmitting : false,
        isManifestEmptyError: downloadResourceJson.length == 0 ? true : false
      })}, SyncResourceConstants.GET_LAB_STATUS_TIMEOUT);
  }

  /*
   * Displays sync resource options selected in table format before submitting for downloading
   */
  getSyncResourceOptionsFeed = () => {
    let syncResourceOptionsData = getSyncResourceOptionsData(this.state.selectedOptionsMap);
    let selectedLocations = getLocationsSelected(this.state.locations);
    return (
      <div>
      <AWSUI.FormField className='awsui-util-mb-xl'
        label={ <h3>{ 'Locations selected' }</h3> }
        errorText={ AppConstants.EMPTY }
      >
      {
        <span className='awsui-util-mb-xl'>
          { selectedLocations }
        </span>
      }
      </AWSUI.FormField>
      <AWSUI.FormField className='awsui-util-mt-xl'
        label={ 
          <h3 className='awsui-util-mt-xl'>
            { 'Sync Resource Options selected' }
          </h3> 
        }
        errorText={ AppConstants.EMPTY }
      >
        <div className='scrollstylecontainerlabs'>
          <AWSUI.Table
            columnDefinitions={ SYNC_RESOURCE_OPTIONS_COLUMNS }
            items={ syncResourceOptionsData }
          >
            <AWSUI.TableSorting sortableColumns={ SORTABLE_COLUMNS }/>
          </AWSUI.Table>
        </div>
      </AWSUI.FormField>
      </div>
    )
  }

  /**
   * Inovked when tab is switched
   */
  handleTabChangeSyncResources = (labId) => {
    // Save the state
    let key = labId + SyncResourceConstants.SYNC_RESOURCES_TAB_ID;
    Store.stateStore.dispatch(Actions.saveState(key, this.state));
    this.props.onTabChange();            
  }

  /**
   * Invoked when Sync is clicked, this method transfers control back to parent container to
   * perform the actual sync
   */
  handleSyncClicked = (labId, rasPiIndex, downloadResourceJson, id) => {
    this.props.syncClicked(labId, rasPiIndex, downloadResourceJson, id);
  }

  render() {
    let allowlistedScenarioIds = this.props.params.whitelistedScenarioIds;
    let labId = this.props.params.labId;
    let labData = this.props.params.labData;
   
    return (
      <div>
      {
        !this.state.isSubmitClicked ? (
          <div>
          {
            this.getSyncResourceOptions(labId, allowlistedScenarioIds)
          }
          {
            this.getSyncResourceLocations(labData)
          }
          {
            this.state.isSyncOptionsError && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant="error" name="status-warning"></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.SYNC_MANIFEST_ERROR }</span>
                </span>
              </div>
            )
          }
          {
            this.state.isLocationError && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant="error" name="status-warning"></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.LOCATIONS_ERROR }</span>
                </span>
              </div>
            )
          }
          {
            this.state.isLocationOfflineError && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant="error" name="status-warning"></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.LOCATIONS_OFFLINE_ERROR }</span>
                </span>
              </div>
            )
          }
          <div style={{ textAlign: 'center' }}>
            <AWSUI.Button 
              variant='normal' 
              text= { 'Submit' }
              onClick={ () => this.submitSyncResourceOptionsClicked(labData) }>
            </AWSUI.Button>
          </div>
        </div>
      ) : (
        <div>
          <div className='awusi-util-mb-xl'>
          {
            this.getSyncResourceOptionsFeed()
          }
          </div>
          {
            this.state.isErrorLablocked && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant="error" name="status-warning"></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.LAB_LOCKED_ERROR }</span>
                </span>
              </div>
            )
          }
          {
            this.state.isManifestEmptyError && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant="error" name="status-warning"></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.MANIFEST_EMPTY_ERROR }</span>
                </span>
              </div>
            )
          }
          {
            this.state.isSyncSubmitting 
              && !this.state.isErrorLablocked 
              && !this.state.isSyncOptionsError
              && !this.state.isLocationError && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m blue-color-text'>
                <span>
                  <AWSUI.Icon variant='link' name='status-info'></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.SUBMIT_SYNC_INFO }</span>
                </span>
              </div>
            )
          }
          <div className='awsui-util-mt-l' style={{ textAlign: 'center' }}>
            <AWSUI.Button 
              variant='normal' 
              text= { 'Back' }
              disabled={ this.state.isSyncSubmitting }
              onClick={ () => this.backButtonClicked() }/>
            <AWSUI.Button 
              variant='normal' 
              text= { 'Sync' }
              disabled = { this.state.isSyncSubmitting || this.state.isLocationOfflineError }
              onClick={ () => this.syncResourceClicked(labId, labData) }/>
          </div>
        </div>
      )
    }
    </div>
    )
  }
}

SyncResourceControl.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  syncClicked: PropTypes.func.isRequired
};

export default SyncResourceControl;

import constants from '../constants/auth';

const initialState = {
  session: {},
  environment: {},
  error: undefined
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_ENVIRONMENT:
      return {
        ...state,
        environment: payload
      };
    case constants.SET_SESSION:
      return {
        ...state,
        session: payload
      };
    default:
      return state;
  }
};

export default reducer;

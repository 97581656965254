import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { API_STATUS, STAGE_TYPES, URL_MAPS } from "../../constants";
import client from "../../services/client";
import { getStage, getUrl } from "../../services/utils";
import { SCENARIO_MAPS } from "../../../newSystem/constants/Scenarios";
import { Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
const UDT_TESTS_FOR_NEW_RUN_V2 = new Date(Date.parse("2022-12-31"));

export const fetchScenarios = createAsyncThunk("api/scenarios", async () => {
  const response = await client.get(getUrl(URL_MAPS.SCENARIOS));
  return response.data;
});

export const fetchCustomScenarios = createAsyncThunk(
  "api/customscenarios",
  async () => {
    const response = await client.get(getUrl(URL_MAPS.CUSTOMSCENARIOS));
    return response.data;
  }
);

const getInitialState = () => ({
  status: API_STATUS.INIT,
  scenarioList: [],
  scenarioIdMap: {},
  error: null,
});

export const scenariosListSlice = createSlice({
  name: "scenariosList",
  initialState: getInitialState(),
  reducers: {
    reset: () => getInitialState(),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarios.fulfilled, (state, action) => {
        state["status"] = API_STATUS.SUCCESS;
        if (Array.isArray(action.payload)) {
          let validScenarios=[];
          action.payload.map((scen)=>{
            if(scen.name in SCENARIO_MAPS){
              validScenarios.push(scen.name)
          }})

          validScenarios.map((validScenario)=>{
            const Scenario = action.payload.find(
              ({ name }) => name === validScenario);
            if(Scenario){
              let validTestSuites = [];
              SCENARIO_MAPS[validScenario].map((envmap)=>{
                if(Object.keys(envmap) == "AQT" && Util.isAQTEnv()){
                  validTestSuites.push(...envmap["AQT"]);
                }
                if(Object.keys(envmap) == "FTV" && Util.isFireTVEnv()){
                  validTestSuites.push(...envmap["FTV"]);
                }
              });
              validTestSuites.map((test_suite,index)=>{
                const isTestSuiteAlreadyLoaded = state.scenarioList.find((s)=>s.id == index);
                if(!isTestSuiteAlreadyLoaded){
                  let newScen = {...Scenario}
                  newScen["scenarioId"] = newScen.id;
                  newScen["id"] = index + "";
                  newScen["displayName"] = test_suite;
                  newScen["mapping"] = [
                    {
                      scenarioName: validScenario + " Scenario",
                      duts: ["dut1"],
                      actors: ["Loc1"],
                      tag: "",
                    }];
                  state["scenarioList"] = [...state["scenarioList"], newScen];
              }})
            }
          })}
        })
      .addCase(fetchScenarios.pending, (state) => {
        state["status"] = API_STATUS.PENDING;
      })
      .addCase(fetchScenarios.rejected, (state) => {
        state["status"] = API_STATUS.ERROR;
        state["error"] = "Failed to fetch scenarios";
      })
      .addCase(fetchCustomScenarios.fulfilled, (state, action) => {
        state["status"] = API_STATUS.SUCCESS;

        let defaultScenarioId = "70bf486e-b465-4ff1-9a6e-dcaf5d796ff1";
        const stage = getStage();
        if (stage === STAGE_TYPES.ALPHA) {
          defaultScenarioId = "66fc2b2d-edc0-4b07-912f-39bc9e5e440c";
        } else if (stage === STAGE_TYPES.BETA) {
          defaultScenarioId = "3a591589-1702-468f-890b-6a461109c7c8";
        }

        if (Array.isArray(action.payload)) {
          let customScen = action.payload;
          customScen = customScen.filter(
            (s) => new Date(Date.parse(s.createdAt)) >= UDT_TESTS_FOR_NEW_RUN_V2
          );
          customScen = customScen.filter((cs) =>
            _.isEmpty(state.scenarioList.find((s) => s.id === cs.id))
          );
          customScen.map((scen) => {
            scen["category"] = "UDT"; // TODO: OAK ENABLEMENT | This would be expanded based on UDT test destribution
            scen["displayName"] = scen.name;
            // TODO: OAK ENABLEMENT | This will be driven from config from api response
            scen["mapping"] = [
              {
                scenarioName: "Custom Scenario",
                duts: ["dut1"],
                actors: ["pi1"],
                tag: "",
              },
            ];
            scen["scenarioId"] =
              state.scenarioIdMap["AQTUDTV2"] || defaultScenarioId;
          });
          state["scenarioList"] = [...state["scenarioList"], ...customScen];
        }
      })
      .addCase(fetchCustomScenarios.pending, (state) => {
        state["status"] = API_STATUS.PENDING;
      })
      .addCase(fetchCustomScenarios.rejected, (state) => {
        state["status"] = API_STATUS.ERROR;
        state["error"] = "Failed to fetch custom scenarios";
      });
  },
});

export const { reset } = scenariosListSlice.actions;

export const scenariosSelector = (state) => state.scenariosList;

export default scenariosListSlice.reducer;

import {
 Table
} from "@amzn/awsui-components-react";

function TestProgressTable({
 items = [],
 columnDefinitions = [],
 header = ""
}) {
 if (items.length >0) {
   return (
     <Table
     columnDefinitions={columnDefinitions}
     items={items}
     header={header}
     >
     </Table>
   );
 }
 return null;
}

export default TestProgressTable;
(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("@amzn/awsui-components-react"));
	else if(typeof define === 'function' && define.amd)
		define("AmazonDeviceQualificationUIOAKRun", ["React", "ReactDOM", "@amzn/awsui-components-react"], factory);
	else if(typeof exports === 'object')
		exports["AmazonDeviceQualificationUIOAKRun"] = factory(require("react"), require("react-dom"), require("@amzn/awsui-components-react"));
	else
		root["AmazonDeviceQualificationUIOAKRun"] = factory(root["React"], root["ReactDOM"], root["@amzn/awsui-components-react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__639__, __WEBPACK_EXTERNAL_MODULE__346__, __WEBPACK_EXTERNAL_MODULE__856__) => {
return 
import { createSlice } from '@reduxjs/toolkit'
import client from '../../services/client'
import {setEnvironment} from '../../services/utils';

export const userAccessSlice = createSlice({
  name: 'userAccessSlice',
  initialState: {
    session: null,
    environment: null
  },
  reducers: {
    updateUserAccessInfo: (state, action) => {
      client.setToken(action.payload.session.idToken.jwtToken);
      setEnvironment(action.payload.environment)
      state.session = action.payload.session;
      state.environment = action.payload.environment;
    }
  },
})

export const { updateUserAccessInfo } = userAccessSlice.actions;
export const userAccessSelector = (state) => state.userAccess;


export default userAccessSlice.reducer

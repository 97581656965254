import React from 'react';
import axios from 'axios';
import Login from '../Login';
import ForgotPassword from '../Login/ForgotPassword';
import ResetPassword from '../Login/ResetPassword';
import ChangeInitialPassword from '../Login/ChangeInitialPassword';
import Amplify, { Auth } from 'aws-amplify';
import { AppConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { connect } from 'react-redux'
import App from '../../app';
import { isEmpty } from 'lodash';
import * as authActions from '../../redux/actions/auth';
import { withRouter } from 'react-router-dom';
import { fetchAppConfig } from '../../appConfig';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

/**
 * Main App Login component which renders the corresponding login component 
 * as per requirement. 
 */
class AppLogin extends React.Component {

  state = {
    isSignedIn: false,
    sessionObj: {},
    isForgotPassword: false,
    isResetPassword: false,
    sessionExpired: window.location.href.includes(AppConstants.SESSION_EXPIRED_PARAM)
    ? true : false,
    isChangeInitialPassword: false,
    username: AppConstants.EMPTY,
    password: AppConstants.EMPTY,
    user: {}
  };

  static getDerivedStateFromProps(props, state) {
    if (isEmpty(props.auth.session)) {
      //The session in redux store is empty. 
      if (isEmpty(state.sessionObj)) {
        //Both React and redux states are empty. No updates required to session. 
        return null;
      } else {
        //Means the react state contains outdated session object, since the redux state has already been reset to empty. 
        //We will update the state in this case.
        return {
          isSignedIn: false,
          sessionObj: {}
        }
      }
    }
    //No changes in this case. 
    return null;
  }

  validateAndUpdateSession = () => {
    var signedIn = undefined;
    var session = undefined;
    const authUpdate = Auth.currentSession()
      .then(sessionResponse => {
        signedIn = true;
        session = sessionResponse;
      })
      .catch(err => {
        signedIn = false;
        session = {};
      }).then(() => {
        if (this.state.isSignedIn !== signedIn) {
          return {
            isSignedIn: signedIn,
            sessionObj: session
          };
        } else {
          return {};
        }
      }
      );
    return authUpdate;
  }

  validateUser() {
    const updatedState = this.validateAndUpdateSession();
    updatedState.then(data => {
      this.props.setSession(data.sessionObj);
      this.updateState(data);
    })
  }

  updateState(data) {
    //Updating the React state as per outcome of amplify operations. 
    if (!isEmpty(data)) {
      this.setState(data);
    }
  }

  handleSignOut() {
    Auth.signOut()
    .then(() => {
      this.setState({ isSignedIn: false, sessionObj: {} });
      //Since signout complete, we need to reset redux store as well. 
      //This inturn causes the AppLogin component to re-render and automatically
      //Renders login page since session will be empty.
      this.props.setSession({});
    })
    .catch(error => {
      Util.logToConsole('Error signing out : ' + JSON.stringify(error));
    });
    // History will be available here in props
    // since it is linked with "withRouter" in the default statement below. 
    const { history } = this.props;
    history.push('/');
  }

  componentDidMount() {
  if (this.state.sessionExpired) {
    this.handleSignOut();
  } else {
    const appConfig = fetchAppConfig();
    appConfig.then((data) => {
      this.props.setEnvironment(data);
    });
    if(!isEmpty(this.props.auth.session)) {
      //Now that we are storing the user session, 
      //This will be empty if the user is not logged in. 
      //We will validate only if there is a session already. 
      this.validateUser();
    }
  }
}

  handleSignIn = (session) => {
    let sessionJsonString = JSON.stringify(session);
    if (sessionJsonString.includes(NEW_PASSWORD_REQUIRED)) {
      this.setState({ isSignedIn: false, isChangeInitialPassword: true, user: session });
    } else {
      window.location.reload(true);
    }
    this.props.setSession(session);
    this.setState({ isSignedIn: true, sessionObj: session });
  }

  handleForgotPassword = () => {
    this.setState({ isForgotPassword: true });
  }

  handleBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false });
  }

  handleResetPasswordBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false });
  }

  handleResetPasswordSubmit = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false });
  }

  handleSendCode = (username) => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: true, username: username });
  }

  handleResetPasswordHaseCode = (username) => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: true, username: username });
  }

  handleChangePasswordBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false, isChangeInitialPassword: false });
  }

  handleChangePasswordSubmit = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false, isChangeInitialPassword: false, sessionObj: {} });
  }

  /**
   * Render pages related to authentication
   */
  renderAuth = () => {
    const { isChangeInitialPassword, isResetPassword, isForgotPassword } = this.state;
    if (isForgotPassword && !isResetPassword && !isChangeInitialPassword) {
      return (
        <ForgotPassword
          backtologin={this.handleBackToLogin}
          sendcode={this.handleSendCode}
          resetpassword={this.handleResetPasswordHaseCode}
        />
      );
    } else if (isResetPassword && !isChangeInitialPassword) {
      return (
        <ResetPassword
          params={{ username: this.state.username }}
          backtologin={this.handleResetPasswordBackToLogin}
          submit={this.handleResetPasswordSubmit}
        />
      );
    } else if (isChangeInitialPassword) {
      return (
        <ChangeInitialPassword
          params={{ user: this.state.user }}
          backtologin={this.handleChangePasswordBackToLogin}
          submit={this.handleChangePasswordBackToLogin}
        />
      )
    }

    return (
      <Login
        authenticate={this.handleSignIn}
        forgotpassword={this.handleForgotPassword}
      />
    );
  }

  render() {
    const { isSignedIn, isForgotPassword, isResetPassword, isChangeInitialPassword } = this.state;
    if (!isSignedIn || isForgotPassword || isResetPassword || isChangeInitialPassword) {
      return this.renderAuth();
    }
    return <App />;
  }
}

export default withRouter(connect(s => s, {
  setEnvironment: authActions.setEnvironment,
  setSession: authActions.setSession,
})(AppLogin));
/**
 * Component to display test details
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, FunctionalTestCases, MusicConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import SecurityTestDetails from './Security';
import get from 'lodash/get';

/**
 * Component which displays following test details:
 * 1. Customer ID
 * 2. Device Serial Number
 * 3. Build Info
 * 4. Locale
 * 5. Test Type
 * 6. Scenario Type
 * 7. Custom Options
 * 8. Amazon ID
 */
class TestDetails extends React.Component {

  /**
   * Gets custom options in display friendly format
   * @param options Custom options
   * @param testSuite Test Suite
   * @return Returns custom options list in display friendly format
   */
  customOptPretty(options, testSuite) {
    let customOptionsDisplay = Util.getCustomOptionsDisplayMap(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(customOptionsDisplay).map(option => {
           let optionDisplay = option;
           // If it's a Close Talk Scenario, display 0.9 m 90 deg as HATS
           if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID && option === AppConstants.LOCATION_THREE_NINETY) {
              optionDisplay = AppConstants.CT_HATS_LOCATION;
           }
           return (<div key={ option } > { optionDisplay + ' - ' + customOptionsDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Gets the list of trainers in display friendly format
   * @param options Custom options
   * @return Returns list of trainers in display friendly format
   */
  getTrainersPretty(options) {
    let trainersDisplay = Util.getTrainers(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        <div> { trainersDisplay } </div>
      </div>
    );
  }

  /**
   * Gets the list of Functional Test cases for Functional Custom scenario in display friendly format
   * @param options Custom options
   * @return Returns list of Functional test cases in display friendly format
   */
  getFunctionalTestCasesPretty(testParams) {
    let functionalTestCasesDisplay = Util.getFunctionalTestCases(testParams);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(functionalTestCasesDisplay).map(option => {
           return (<div key={ option } > { option + ' ==> ' + functionalTestCasesDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Method used to display 'Scenario Type' label based on Test Suite
   * @param testSuite Test Suite
   * @return Scenario Type label based on type of test suite
   */
  getTestTypeLabelToDisplay = (testSuite) => {
    if (AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY.hasOwnProperty(testSuite)) {
      return AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY[testSuite];
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets Scenario type for display based on input scenario type
   * @param scenarioType Scenario type
   * @param testSuite Test Suite
   * @param config Current job config information if applicable
   * @return Customer facing scenario type
   */
  getScenarioTypeToDisplay(scenarioType, testSuite, config) {
    if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return Util.getDisplayNameForId(Util.getFunctionalScenarios(), scenarioType)
    }
    return Util.getScenarioTypeToDisplay(scenarioType, testSuite, config);
  }

  /**
   * Gets Device virtual assistant to display
   * @param testSuite Test Suite
   * @param customOptions Custom options
   * @returns Device Virtual Assistant
   */
  getDeviceVirtualAssistant = (testSuite, customOptions) => {
    if (testSuite
         && testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
         && customOptions) {
      return Util.getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS,
        customOptions.deviceVirtualAssistant);
    }
    return AppConstants.NOT_APPLICABLE;
  }
  /**
   * Renders test details feeds for Acoustic
   */
  renderAcousticTestDetailsFeeds = () => {
    const { customOptions = {} } = this.props.params;
    const customize = ((this.props.params.scenarioType === FunctionalTestCases.FUNC_CUSTOM
      || this.props.params.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM) ||
      (this.props.params.testType === AppConstants.CUSTOMIZED ||
        this.props.params.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION));
    return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { Util.Util.renderLabelElement('Login ', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { Util.Util.renderLabelElement('Locale', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY, Util.getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { Util.Util.renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { Util.Util.renderLabelElement(this.getTestTypeLabelToDisplay(this.props.params.testSuite), AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY, this.getScenarioTypeToDisplay(this.props.params.scenarioType,
                this.props.params.testSuite, this.props.params.config)) }
            { Util.Util.renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === this.props.params.dsn) }
            {
              <>
                <div>
                  { Util.Util.renderLabelElement('Test Type', AppConstants.EMPTY) }
                </div>
                <div>
                  { Util.Util.renderLabelElement(AppConstants.EMPTY,
                    Util.getTestTypeDisplay(this.props.params.testType, this.props.params.scenarioType, this.props.params.testSuite)) }
                </div>
              </>
            }
            { Util.Util.renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY,
                Util.getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            {
              Util.isA4PCDevice(this.props.params.customOptions.amazonId) &&
                get(this.props.params.customOptions, 'a4pcConfig.aqtA4PCCompanionAppEnabled', false) &&
              Util.Util.renderLabelElement("Wakeword Mode", AppConstants.EMPTY)
            }
            {
              Util.isA4PCDevice(this.props.params.customOptions.amazonId) &&
              get(this.props.params.customOptions, 'a4pcConfig.aqtA4PCCompanionAppEnabled', false) &&
              Util.Util.renderLabelElement(AppConstants.EMPTY,
                get(this.props.params.customOptions, 'a4pcConfig.a4pcWakewordModel', AppConstants.EMPTY))
            }
            { Util.Util.renderLabelElement('Lab ', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
            { Util.Util.renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { Util.Util.renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === this.props.params.deviceName) }
            { Util.renderLabelElement('Email Notification ', AppConstants.EMPTY) }
            { this.props.params.sendCompletionEmail && Util.renderLabelElement(AppConstants.EMPTY, 'Enabled') }
            { !this.props.params.sendCompletionEmail && Util.renderLabelElement(AppConstants.EMPTY, 'Disabled') }
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              Util.renderLabelElement('Proxy Enabled', AppConstants.EMPTY)
            }
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              Util.renderLabelElement(AppConstants.EMPTY, `${customOptions.isProxyEnabled}`)
            }
            {
              /* Display "Trainers" list for scenarios applicable to training */
              this.props.params.testType === AppConstants.CUSTOMIZED &&
                AppConstants.SCENARIOS_WITH_TRAINERS.includes(this.props.params.scenarioType) &&
                (
                  <>
                    { Util.renderLabelElement('Trainers', AppConstants.EMPTY) }
                    { Util.renderLabelElement(AppConstants.EMPTY,
                      this.getTrainersPretty(this.props.params.customOptions)) }
                  </>
                )
            }
            { this.props.params.testType === AppConstants.CUSTOMIZED &&
              this.props.params.customizedTestType !== AppConstants.EMPTY &&
              Util.renderLabelElement('Custom Sub-Test Type', AppConstants.EMPTY) }
            { this.props.params.testType === AppConstants.CUSTOMIZED &&
              this.props.params.customizedTestType !== AppConstants.EMPTY &&

              Util.renderLabelElement(AppConstants.EMPTY,
                    Util.getDisplayNameForId(AppConstants.CUSTOMIZED_SCENARIOS, this.props.params.customizedTestType)) }
            { (this.props.params.scenarioType === FunctionalTestCases.FUNC_CUSTOM ||
              this.props.params.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM ||
              (this.props.params.testType === AppConstants.CUSTOMIZED ||
                this.props.params.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION)) && (
              <>
                { Util.renderLabelElement('Functional Test Cases', AppConstants.EMPTY) }
                { Util.renderLabelElement(AppConstants.EMPTY, this.getFunctionalTestCasesPretty(this.props.params))
                }
              </>
            )}
            {/*If TestCategory is DEVICE_LOCK_HANDS_FREE_VERIFICATION: */}
            {/*  - display "Lock Type"*/}
            { (this.props.params.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION) && (
              <>
                { Util.renderLabelElement('Lock Type', AppConstants.EMPTY) }
                { Util.renderLabelElement(AppConstants.EMPTY,
                  Util.getDisplayNameForId(AppConstants.LOCK_TYPES, customOptions.lockType)) }
              </>
            ) }
            { Util.renderLabelElement('Start Time (UTC)', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.startTime.replace('T', ' ')) }          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details feeds without TestType, which also includes StartTime as a field.
   * This is to be used for scenarios which do not have any test types, such as UPL, Stability.
   */
  renderTestDetailsFeedsWithoutTestType = () => {
    const { customOptions = {} } = this.props.params;
    return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { Util.renderLabelElement('Login ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { Util.renderLabelElement('Locale', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, Util.getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { Util.renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { Util.renderLabelElement(this.getTestTypeLabelToDisplay(this.props.params.testSuite), AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, Util.getScenarioTypeToDisplay(this.props.params.scenarioType,
                this.props.params.testSuite)) }
            { Util.renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === this.props.params.dsn) }
            { Util.renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                Util.getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            { Util.renderLabelElement('Lab ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
            { Util.renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === this.props.params.deviceName) }
            { Util.renderLabelElement('Email Notification ', AppConstants.EMPTY) }
            { this.props.params.sendCompletionEmail &&
                (
                  <div>
                    { Util.renderLabelElement(AppConstants.EMPTY, 'Enabled') }
                  </div>
                )}
            { !this.props.params.sendCompletionEmail &&
                (
                  <div>
                    { Util.renderLabelElement(AppConstants.EMPTY, 'Disabled') }
                  </div>
                )}
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              Util.renderLabelElement('Proxy Enabled', AppConstants.EMPTY)
            }
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              (
                <div>{Util.renderLabelElement(AppConstants.EMPTY, `${customOptions.isProxyEnabled}`)}</div>
              )
            }
            {
                <div>
                   { Util.renderLabelElement('Start Time (UTC)', AppConstants.EMPTY) }
                </div>
            }
            {
                <div>
                  { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.startTime.replace('T', ' ')) }
                </div>
            }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details feeds for Music
   */
  renderMusicTestDetailsFeeds = () => {
    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { Util.renderLabelElement('Login ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { Util.renderLabelElement('Locale', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, Util.getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { Util.renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { Util.renderLabelElement(MusicConstants.MUSIC_SCENARIO_LABEL, AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                Util.getDisplayNameForId(MusicConstants.MSP_TYPE, this.props.params.scenarioType)) }
            { Util.renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === this.props.params.dsn) }
            { Util.renderLabelElement('Test Type', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                Util.getTestTypeDisplay(this.props.params.testType, this.props.params.scenarioType, this.props.params.testSuite)) }
            { Util.renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY,
                Util.getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            { Util.renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.deviceName) }
            { Util.renderLabelElement('Lab ', AppConstants.EMPTY) }
            { Util.renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details
   */
  render() {
    if (!this.props.params.testSuite
          || this.props.params.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID
          || this.props.params.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      // Acoustic or Functional case
      return this.renderAcousticTestDetailsFeeds();
    } else if (this.props.params.testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
      // Music case
      return this.renderMusicTestDetailsFeeds();
    } else if (this.props.params.testSuite === AppConstants.SECURITY_SCENARIO_ID) {
      return (
        <SecurityTestDetails
          params={this.props.params}
        />
      );
    }
    // Default: Return Acoustic feeds when test suite is none of Acoustic, Functional or Music
    return this.renderAcousticTestDetailsFeeds();
  }
}

TestDetails.propTypes = {
  params: PropTypes.object.isRequired
};

export default TestDetails;

import constants from '../constants/auth';

/**
 * Sets app environment
 * @param {Object} environment
 */
export function setEnvironment(environment) {
  return (dispatch) => {
    return dispatch({
      type: constants.SET_ENVIRONMENT,
      payload: environment
    });
  }
}

/**
 * Set user session information
 * @param {Object} session
 */
export function setSession(session) {
  return (dispatch) => {
    return dispatch({
      type: constants.SET_SESSION,
      payload: session
    });
  }
}

export default {
  setEnvironment,
  setSession
}

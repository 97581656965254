(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@amzn/awsui-components-react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("AmazonDeviceQualificationUIResults", ["React", "@amzn/awsui-components-react", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["AmazonDeviceQualificationUIResults"] = factory(require("react"), require("@amzn/awsui-components-react"), require("react-dom"));
	else
		root["AmazonDeviceQualificationUIResults"] = factory(root["React"], root["@amzn/awsui-components-react"], root["ReactDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4639__, __WEBPACK_EXTERNAL_MODULE__6856__, __WEBPACK_EXTERNAL_MODULE__1346__) => {
return 
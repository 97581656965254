import axios from 'axios';
import openSocket from 'socket.io-client';
import {Actions, ApiConstants, AppConstants, Auth, FunctionalTestCases, Store, Util}
  from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { has } from 'lodash';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import get from "lodash/get"

export function fetchRunningJobs() {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  let query = {
      'state': {'notIn': ['SUCCEEDED', 'FAILED', 'CANCELED']},
      'createdAt': {'gte': moment().subtract(1, 'month').startOf('day'), "lte": moment().endOf('day')}
  };
  return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL +
      '?q=' +
      JSON.stringify(query) + '&limit=50',
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(labJobs => {
      if (labJobs.hasOwnProperty('data')) {
        Util.logToConsole('Lab Jobs : ' + JSON.stringify(labJobs.data));
        labJobs.data.forEach(labJob => {

          let liveFeed = {};
          const jobSocket = openSocket(controllerEndpoint, {
            query: {
              token: Store.aqtStore.getState().session.idToken.jwtToken,
              instanceID: labJob.id
            }
          });

          let testStats = {};

          liveFeed[labJob.id] = { labJob: labJob,
                                  socketClose: () => { jobSocket.close() },
                                  testStatus: AppConstants.RETRIEVING,
                                  testStats: testStats,
                                  visualStats: {},
                                  simulationState: {},
                                  isSocketConnected: false,
                                  error: { isError: false, message: '' } };

          liveFeed[labJob.id]['request'] = labJob
          Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeed));
          setSocketHandlers(labJob.id, jobSocket);
        });
        return labJobs.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, fetchRunningJobs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Gets ref mic status for Functional and Auto local search scenarios
 */
function getRefMicStatus(payload) {
  // Read refMicStatus from payload if available
  if (payload.refMicStatus) {
    return payload.refMicStatus;
  }
  // Read referenceMicConnectionStatus from payload if refMicStatus is not available.
  if (payload.referenceMicConnectionStatus) {
    return payload.referenceMicConnectionStatus;
  }
  // Return retrieving by default
  return FunctionalTestCases.REF_MIC_STATUS_UNAVAILABLE;
}

export function setLiveFeedData(labJobId, key, value) {
  let liveFeed = Store.liveFeedStore.getState().liveFeed;
  let overrideFlag = false;
  try {
    Util.logToConsole('Parsing key: ' + key + ' for labJobId: ' + labJobId + ' with value: ' + value);
    switch (key) {
      case 'commonFeeds':
        // currently utilized by morgan to publish utterance info
        let parsedCommonFeeds = Util.getParsedJSON(value);
        let commonFeeds = parsedCommonFeeds.hasOwnProperty('payload') ? Util.getParsedJSON(parsedCommonFeeds.payload) : {};
        liveFeed[labJobId].commonFeeds = {
          payload: commonFeeds
        }
        break;
      case 'utteranceFeeds':
        // currently utilized by morgan to publish utterance info
        let parsedUtteranceFeeds = Util.getParsedJSON(value);
        let utteranceFeeds = parsedUtteranceFeeds.hasOwnProperty('payload') ? Util.getParsedJSON(parsedUtteranceFeeds.payload) : {};
        liveFeed[labJobId].utteranceFeeds = {
          payload: utteranceFeeds
        }
        break;
      case 'noiseFeeds':
        // currently utilized by morgan to publish noise info
        let parsedNoiseFeeds = Util.getParsedJSON(value);
        let noiseFeeds = parsedNoiseFeeds.hasOwnProperty('payload') ? Util.getParsedJSON(parsedNoiseFeeds.payload) : {};
        liveFeed[labJobId].noiseFeeds = {
          payload: noiseFeeds
        }
        break;
      case 'payload':
        Util.logToConsole('Original value before stringify = ' + value);
        // Apply parsing on JSON only if it contains back slash
        let parsedPayload = Util.getParsedJSON(value);
        Util.logToConsole('Parsed payload = ' + JSON.stringify(parsedPayload));
        let payload = parsedPayload.hasOwnProperty('payload') ? Util.getParsedJSON(parsedPayload.payload) : {};
        // 'FunctionalTestCaseResults' in Mobile Functional payload (based on AQTEngine format) has the same structure as
        // 'customPayload' (based on MDXSimulator format)
        let customPayload = payload.hasOwnProperty('functionalTestCaseResults') ?
          Util.getParsedJSON(payload.functionalTestCaseResults) : parsedPayload.hasOwnProperty('customPayload') ?
            Util.getParsedJSON(parsedPayload.customPayload) : {};
        // 'FunctionalTestCase' in Mobile Functional payload (based on AQTEngine format) has the same structure as
        // 'payload' (based on MDXSimulator format)
        payload = payload.hasOwnProperty('functionalTestCase') ? Util.getParsedJSON(payload.functionalTestCase) : payload;
        let transcripts = customPayload.hasOwnProperty('transcripts') ? customPayload.transcripts : {};
        let simulationOptions = customPayload.hasOwnProperty('simulationOptions') ? customPayload.simulationOptions : {};

        // Only for functional test cases
        // Retrieve list of all functional tests + map which contains status at category level for each test category
        // Also retrieve 'Question Played' from payload in case of Functional scenario
        let functionalTestCases = customPayload.hasOwnProperty('functionalTestCases') ? customPayload.functionalTestCases : {};
        let testCaseCategoryStatusMap = customPayload.hasOwnProperty('testCaseCategoryStatusMap') ? customPayload.testCaseCategoryStatusMap : {};
        let questionPlayed = (customPayload.scenarioType === FunctionalTestCases.FUNC_ALL
          || customPayload.scenarioType === FunctionalTestCases.FUNC_CUSTOM
          || customPayload.scenarioType === FunctionalTestCases.AUTO_FUNC_ALL
          || customPayload.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM
          || customPayload.scenarioType === FunctionalTestCases.FUNC_NAV_ALL
          || customPayload.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM)
          ? payload.transcript : transcripts.asrText;
        let mobileFunctionalCondition = customPayload.scenarioType === AppConstants.MOBILE_FUNCTIONAL
          && payload.currentUtterance;
        questionPlayed = (mobileFunctionalCondition) ? payload.currentUtterance.questionPlayed : questionPlayed;

        let statusFromPayload = payload.status ? payload.status.toLowerCase() : 'error';
        let functionalUtteranceStatus = payload.status ? payload.status : FunctionalTestCases.FAILED;
        let intentTypeFromPayload = payload.intentType ? payload.intentType : AppConstants.EMPTY;
        let utteranceAttribute = payload.utteranceAttribute ? payload.utteranceAttribute : FunctionalTestCases.UTTERANCE_NORMAL;

        // Retrieve reference mic status from custom payload for Functional and Auto local search scenarios
        let refMicStatus = getRefMicStatus(payload);

        // Retrieve Utterance validation type for Functional scenario
        let utterValidationInfo = payload.utterValidationInfo ? payload.utterValidationInfo : {};
        let utteranceValidationType = AppConstants.EMPTY;
        if (utterValidationInfo
              && Object.keys(utterValidationInfo).length > 0
              && utterValidationInfo.hasOwnProperty('utterValidationType')) {
                utteranceValidationType = utterValidationInfo['utterValidationType'];
        }

        let frr;
        let iar;
        let curNumFrr = customPayload.curNum;
        try {
          if (customPayload.scenarioType === AppConstants.TRAINED_MOBILE) {
            curNumFrr = customPayload.curNumFrr;
          }
          if (!isUndefined(payload.frr) && curNumFrr) {
            frr = parseInt(curNumFrr, 10) - parseInt(payload.frr, 10)
          }
          if (payload.iar && customPayload.curNumIar) {
            iar = parseInt(customPayload.curNumIar, 10) - parseInt(payload.iar, 10)
          }
        } catch (error) {
          Util.logToConsole('Parse error : ' + JSON.stringify(error));
        }
        let measureType = customPayload.measureType;
        let locationNoise = AppConstants.NOT_APPLICABLE;
        if (measureType !== AppConstants.ADMIN_MEASURE) {
          locationNoise = customPayload.testActor + ', ' +customPayload.noiseType;
        }

        // Mobile param for active noise (stripped down version of locationNoise due to Mobile only having 1 location)
        let activeNoise = customPayload.hasOwnProperty('noiseType') ? customPayload.noiseType : AppConstants.EMPTY;

        // Check if test suite is AUTO_LOCAL_SEARCH
        if (simulationOptions
            && simulationOptions.hasOwnProperty('testSuite')
            && simulationOptions.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
              liveFeed[labJobId].testStats = {
                ...liveFeed[labJobId].testStats,
                utteranceId: payload.utteranceId,
                count: Util.countAndPercentage(payload.utteranceId, payload.totalTests),
                percentage: Util.getPercentage(payload.utteranceId, payload.totalTests),
                elapsedTime: Util.getTime(new Date().getTime() - customPayload.startTime),
                startTime: new Date(customPayload.startTime).toString(),
                eta: payload.eta,
                refMicStatus: refMicStatus,
                status: payload.status,
                transcribeStatus: payload.transcribeStatus,
                played: payload.utterancePlayedTranscript,
                heard: payload.dutHeardAsrText,
                responded: payload.dutResponse,
                locationNoise: AppConstants.LOCATION_THREE_NINETY,
                testCases: customPayload.testCases,
              };
        } else if (parsedPayload && simulationOptions.testSuite === AppConstants.SECURITY_SCENARIO_ID) {
          liveFeed[labJobId].testStats = {
            payload: parsedPayload
          }
        } else if (parsedPayload && simulationOptions.testSuite === AppConstants.CUSTOM_SCENARIO_ID) {
          liveFeed[labJobId].testStats = parsedPayload.payload;
        } else if (parsedPayload
            && parsedPayload.androidDeviceIsConnected !== undefined) {
          // For automated Mobile Voice Training scenario, every time training begins, UI should receive
          // following key in payload. Any other regular payloads should not contain this key
          // Key: androidDeviceIsConnected --> Whether android device is connected to Pi & online or not
          liveFeed[labJobId].testStats = {
              androidDeviceIsConnected: parsedPayload.androidDeviceIsConnected
          }
        } else {
        // Display test metrics only if measureType is NOT ADMIN_NO_RECORD
        // TODO: Display informational message on live run page when admin utterances are being played
        // SIM: https://sim.amazon.com/issues/P22697831
        if (measureType !== AppConstants.ADMIN_MEASURE) {
          liveFeed[labJobId].testStats = {
            ...liveFeed[labJobId].testStats,
            num: customPayload.curNum,
            played: questionPlayed,
            heard: (mobileFunctionalCondition) ? payload.currentUtterance.dutHeard : payload.asrText ,
            responded: (mobileFunctionalCondition) ? payload.currentUtterance.dutResponded :payload.ttsResponse,
            count: Util.countAndPercentage(customPayload.curNum, customPayload.totalNum),
            percentage: Util.getPercentage(customPayload.curNum, customPayload.totalNum),
            frr: Util.countAndPercentage(frr, curNumFrr),
            rar: Util.countAndPercentage(payload.rar, payload.frr),
            har: Util.countAndPercentage(payload.har, customPayload.curNum),
            iar: Util.countAndPercentage(iar, customPayload.curNumIar),
            scoreFrr: payload.scoreFalseRejectionRate,
            scoreRar: payload.scoreResponseAccuracyRate,
            scoreHar: payload.scoreHearingAccuracyRate,
            scoreIar: payload.scoreImposterAcceptanceRate,
            status: statusFromPayload === 'success' ? 'success' : 'error',
            timeRemaining: customPayload.etaString,
            elapsedTime: Util.getTime(new Date().getTime() - customPayload.startTime),
            startTime: new Date(customPayload.startTime).toString(),
            testType: customPayload.testType,
            avgTestTime: payload.avgTestTime,
            scenarioType: customPayload.scenarioType,
            labJobId: labJobId,
            locationNoise: locationNoise,
            activeNoise: activeNoise,
            measureType: transcripts.measureType,
            currentTrainedVoice: customPayload.currentTrainedVoice,
            measureTypeCustomPayload: customPayload.measureType,
            currentUtteranceCount: customPayload.curNum,
            latencyValue: customPayload.latencyValue,
            functionalTestCases: functionalTestCases,
            testCaseCategoryStatusMap: testCaseCategoryStatusMap,
            currentFunctionalTest: payload.testCase,
            utteranceValidationType: utteranceValidationType,
            functionalUtteranceStatus: functionalUtteranceStatus,
            utteranceAttribute: utteranceAttribute,
            refMicStatus: refMicStatus,
            isProxyEnabled: payload.hasOwnProperty('isProxyEnabled') ? payload.isProxyEnabled : AppConstants.UNKNOWN,
            is3PDAEnabled: payload.hasOwnProperty('is3PDAEnabled') ? payload.is3PDAEnabled : AppConstants.UNKNOWN
          };
        } else {
          liveFeed[labJobId].testStats = {
            measureTypeCustomPayload: measureType,
            intentType: intentTypeFromPayload
          };
        }
        }

        // Check for payload override cases - Keep this at the bottom in case other scenarios want to
        //    utilize some of the above info for overriding values
        if (AppConstants.OVERRIDE_LIVE_RUN_PAYLOAD_TEST_TYPES.includes(customPayload.scenarioType)) {
          // Handler for AC_VOICE_ENROLLMENT_ROBUSTNESS override
          if (customPayload.scenarioType == AppConstants.VOICE_ROBUSTNESS) {
            // VE Robustness relies exclusively on the enrollmentFeed - No need to adjust params (just override)
            overrideFlag = true;
          }
        }
        break;
      case 'testStatus':
        Util.logToConsole(value);
        let parsedTestStatus = JSON.parse(value);
        liveFeed[labJobId].testStatus = parsedTestStatus.status;
        if (parsedTestStatus.status.toLowerCase() === 'completed') {
          liveFeed[labJobId].testStats = {
            ...liveFeed[labJobId].testStats,
            timeRemaining: 'Finished'
          };
        }
        break;
      case 'fan':
      case '1.8 m 135 deg':
        let parsedNoiseStatus = JSON.parse(value);
        liveFeed[labJobId].visualStats = {
          ...liveFeed[labJobId].visualStats,
          noiseState: parsedNoiseStatus.state
        }
        liveFeed[labJobId].simulationState[key] = parsedNoiseStatus;
        break;
      case 'DUT':
        liveFeed[labJobId].simulationState[key] = JSON.parse(value);
        break;
      case 'speakNineNinety':
      case 'speakNineThirty':
      case 'speakThreeNinety':
      case 'speakThreeThirty':
      case '2.7 m 90 deg':
      case '2.7 m 30 deg':
      case '0.9 m 90 deg':
      case '0.9 m 30 deg':
      case 'Automotive Companion':
      case 'automotiveCompanion':
        let parsedPayloadValue = JSON.parse(value);
        liveFeed[labJobId].simulationState[key] = parsedPayloadValue;
        liveFeed[labJobId].visualStats = setSpeakerState(liveFeed[labJobId].visualStats, parsedPayloadValue);
        break;
      case 'enrollmentFeed':
          // Standardize input format
          let cleanedValue = JSON.stringify(value).replace(/\\*\"/g,'"').replace(/\"\{/g,'{').replace(/\}\"/g,'}');
          let enrollmentFeedPayload = has(Util.getParsedJSON(cleanedValue), 'payload') ? Util.getParsedJSON(cleanedValue).payload : AppConstants.EMPTY;

          // Populate enrollmentFeed values with voice enrollment metrics
          let enrollmentFeed = {};
          if (enrollmentFeedPayload) {
            if (has(enrollmentFeedPayload, 'veRobProgress')) {
              let veRobProgress = enrollmentFeedPayload.veRobProgress;
              enrollmentFeed.currentTrainedVoice = has(veRobProgress, 'currentTrainedVoice') ? veRobProgress.currentTrainedVoice : AppConstants.EMPTY;
              enrollmentFeed.activeNoise = has(veRobProgress, 'activeNoise') ? veRobProgress.activeNoise : AppConstants.EMPTY;
              enrollmentFeed.totalNum = has(veRobProgress, 'totalNum') ? veRobProgress.totalNum : [];
              enrollmentFeed.curNum = has(veRobProgress, 'curNum') ? veRobProgress.curNum : [];
              let testStartTime = has(veRobProgress, 'testStartTime') ? veRobProgress.testStartTime : [];
              if (testStartTime) {
                enrollmentFeed.startTime = new Date(testStartTime).toString();
                enrollmentFeed.elapsedTime = Util.getTime(new Date().getTime() - testStartTime);
              }
            }
            enrollmentFeed.uiAutomationStatus = has(enrollmentFeedPayload, 'uiAutomationStatus') ?
                                                      enrollmentFeedPayload.uiAutomationStatus : AppConstants.EMPTY;
            // Calculations for test progress
            enrollmentFeed.completedCount = enrollmentFeed.curNum;
            // If current training iteration isn't complete (doesn't have uiAutomationStatus), subtract 1 to reflect
            //    only the completed count
            if (enrollmentFeed.uiAutomationStatus === AppConstants.EMPTY) {
              enrollmentFeed.completedCount = enrollmentFeed.curNum > 0 ? enrollmentFeed.curNum - 1 : 0;
            }
            enrollmentFeed.completedPercentage = (enrollmentFeed.completedCount && enrollmentFeed.totalNum) ?
              Math.round((enrollmentFeed.completedCount / enrollmentFeed.totalNum) * 100) : [];
          }

        liveFeed[labJobId].testStats = {
          enrollmentFeed: enrollmentFeed
        };
        break;
      default:
        liveFeed[labJobId].simulationState[key] = JSON.parse(value);
        break;

    }
  } catch (ex) {
    Util.logToConsole('Error in parsing payload from server : ' + ex);
  }
  if (!overrideFlag) {
    Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeed));
  }
}

export function setSocketHandlers(labJobId, jobSocket) {
  // sample setup for mocking live run data
  //setLiveFeedData(labJobId, 'utteranceFeeds', TestData.AUTOMOTIVE_UTTERANCE_FEED);
  //setLiveFeedData(labJobId, 'noiseFeeds', TestData.AUTOMOTIVE_NOISE_FEED);
  //setLiveFeedData(labJobId, 'commonFeeds', TestData.AUTOMOTIVE_COMMON_FEED);
  //setLiveFeedData(labJobId, 'payload', MobileFunctionalTestData.TEST_DATA);
  //setLiveFeedData(labJobId, 'testStatus', "{ \"status\": \"RUN\" }");
  let keys = {}
  AppConstants.keys.forEach(key => {
    keys[key] = false;
  });
  jobSocket.on('connect', () => {
    Util.logToConsole('Socket opened for job : ' + labJobId);
    let liveFeeds = Store.liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = true;
    liveFeeds[labJobId].error = { isError: false, message: '' };
    Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('connect_failed', error => {
    Util.logToConsole('Socket connection failed for job : ' + labJobId + ' - ' + error);
    let liveFeeds = Store.liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = false;
    liveFeeds[labJobId].error = { isError: true, message: 'Connection to Job failed!' };
    Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('error', error => {
    Util.logToConsole('Socket error from server for job : ' + labJobId + ' - ' + error);
    let liveFeeds = Store.liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = false;
    liveFeeds[labJobId].error = { isError: true, message: 'Error in connection to Job!' };
    Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('stateChange', data => {
    try {
      let currentKey = AppConstants.keys[0];
      JSON.parse(JSON.stringify(data), (key, value) => {
        if (keys[currentKey]) {
          // comment out the below when u try to mock live run
          setLiveFeedData(labJobId, currentKey, value);
          keys[currentKey] = false;
        }
        else if (key === 'key' && typeof value === 'string') {
          currentKey = value.split(':').slice(-1)[0];
          keys[currentKey] = true;
        }
      });
    } catch (ex) {
      Util.logToConsole('Error in parsing keys from server : ' + JSON.stringify(ex));
    }
    // v2 logic to get entire redis data for the job
    let liveFeeds = Store.liveFeedStore.getState().liveFeed;
    const dataKey = data.key.split(":").slice(-1)[0];
    let dataValue = data.data;
    try {
      dataValue = JSON.parse(dataValue);
    } catch (error) {
      Util.logToConsole("error parsing redis key data", error);
    }
    const liveFeedsJobData = get(liveFeeds, `${labJobId}.v2data`, {});
    liveFeeds[labJobId]["v2data"] = {
      ...liveFeedsJobData,
      [dataKey]: dataValue,
    };
    Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('disconnect', () => {
    Util.logToConsole('Socket closed for job : ' + labJobId);
    let liveFeeds = Store.liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = false;
    liveFeeds[labJobId].error = { isError: false, message: '' };
    Store.liveFeedStore.dispatch(Actions.setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
}

export function terminateJob(labJobId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  // Closing the job socket
  if (Store.liveFeedStore.getState().liveFeed && Store.liveFeedStore.getState().liveFeed[labJobId]) {
    Store.liveFeedStore.getState().liveFeed[labJobId].socketClose();
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  let body = {
    state: 'CANCELED'
  };
  return axios.put(controllerEndpoint +
      ApiConstants.JOBS_URL +
      '/' + labJobId,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.status === 200) {
        return {
          success: response.statusText
        }
      } else {
        return {
          error: response.statusText
        };
      }
    })
    .catch(error => {
      Util.networkError(error, terminateJob.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Resumes the paused job for Trained mobile scenario
 * @param labJobId Job ID
 */
export function resumeJob(labJobId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  let body = {
    state: AppConstants.RESUME
  };
  return axios.put(controllerEndpoint +
      ApiConstants.JOBS_URL +
      '/' + labJobId,
      body,
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.status === 200) {
        return {
          success: response.statusText
        }
      } else {
        return {
          error: response.statusText
        };
      }
    })
    .catch(error => {
      Util.networkError(error, resumeJob.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Get Lab information from the job ID. This is needed to for raspi sync status in live run.
 * @param labJobId Job ID
 */
export function getLabInformation(labJobId) {
  if (Util.getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = Store.aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
      ApiConstants.JOBS_URL +
      '/' + labJobId + '/lab',
      Auth.withHeader(Store.aqtStore.getState().session.idToken.jwtToken))
    .then(labInfo => {
      Util.logToConsole('Lab Info : ' + JSON.stringify(labInfo));
      if (labInfo.data.hasOwnProperty('id')) {
        return labInfo.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      Util.networkError(error, resumeJob.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

//Returns a new payload if the playing state is not Idle
export function setSpeakerState(currPayload, parsedSpeakerPayload) {
  return parsedSpeakerPayload.state !== AppConstants.IDLE ?
    Object.assign({}, currPayload,
      { speakerState: {
          speaker: parsedSpeakerPayload.name,
          state: parsedSpeakerPayload.state
      }}
    ) : currPayload;
}

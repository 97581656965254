/**
 * Component to render Functional test cases
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FUNCTIONAL_TEST_METRICS_COLUMNS, FUNCTIONAL_EXPANDABLE_COLUMNS }
  from './FunctionalTestMetricsTableConfig';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, FunctionalTestCases, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import './FunctionalLiveFeedStyles.css';
import { getOverallTestStatusList, getCompletedTestsNum, getCompletedTestsStats,
  getOverallEta, getTestStatusForValidationType, getFailureLogsForValidationType,
  getOverallValidationStatus, getOverallFailureLogs, getInProgressTestNameDisplay,
  getInProgressStatus, getTestStatusValue,
  setValueForExpandedSection, getExpandableSectionContent, getFunctionalTestMetrics,
  getValueForUtteranceFeed }
  from './FunctionalLiveFeedUtil';

/**
 * Component to render functional test cases
 * Displays status for all functional tests in a tabular format
 */
class FunctionalTestMetrics extends React.Component {

  state = {
    expandedSections: []
  };

  componentWillMount() {
    this.loadFunctionalTestData();
  }

  /** Gets test status to display for individual test case
  * @param jobStatus Status for test case -> Queued, Running, Failed, Passed or Error
  * @param failureLogs Error logs to display in tooltip in case of test failure/ error
  * @return Component with test status + error tooltip if any
  */
  getTestStatus = (jobStatus, failureLogs) => {
    let testStatusValue = getTestStatusValue(jobStatus);
    let failureLogsString = this.getFailureLogsMessage(failureLogs);
    // Renders test status & if test status is FAILED or ERROR, renders additional tooltip
    // containing error message
    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div data-awsui-column-layout-root='true'>
          {
            (jobStatus !== FunctionalTestCases.IN_PROGRESS && jobStatus !== FunctionalTestCases.EVALUATING_RESULT) ? (
            <div className={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusClass }>
              <AWSUI.Icon name={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusIcon }
                  variant={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].variant }/>
                <span className = { FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusTextStyle }>
                  { testStatusValue }
                </span>
            </div>
            ) : (
              <div>
                { getInProgressStatus() }
              </div>
            )
            }
            <div></div>
            <div>
              {
                ((jobStatus === FunctionalTestCases.FAILED || jobStatus === FunctionalTestCases.ERROR)
                    && failureLogsString && failureLogsString.length > 0) &&
                (
                  <AWSUI.Tooltip text={ failureLogsString } size='small' position='top' className='awsui-util-ml-xxl tooltip-inner'>
                    <AWSUI.Icon name='status-info'></AWSUI.Icon>
                  </AWSUI.Tooltip>
                )
             }
            </div>
            <div></div>
          </div>
      </AWSUI.ColumnLayout>
    );
  }

  /**
   * Gets error message to display in tooltip for a particular test case
   * @param failureLogs List of failure logs
   * @returns String representing error message
   */
  getFailureLogsMessage = (failureLogs) => {
    let failureLogsString = AppConstants.EMPTY;
    if (failureLogs && failureLogs.length > 0) {
      // Iterate over list of failure logs
      for (let i = 0; i < failureLogs.length; i++) {
         failureLogsString += failureLogs[i];
         failureLogsString += '\n';
      }
    }
    return failureLogsString;
  }

  /**
   * Gets the expandable section for Functional test case category
   * @param functionalCategory Functional test case category e.g. Volume
   * @param functionalCategoryStatus Category level status e.g. IN_PROGRESS
   * @param functionalTestsData Data for list of tests under current category e.g. data for Volume Up,
   * Volume Down etc.
   * @returns Expandable section for category
   */
  getExpandableSectionForCategory = (functionalCategory, functionalCategoryStatus, functionalTestsData) => {
    let isCategoryExpanded = this.state.expandedSections.includes(functionalCategory);
    return (
      <AWSUI.ExpandableSection
        id = { functionalCategory }
        header={
          getExpandableSectionContent(functionalCategory, functionalCategoryStatus, isCategoryExpanded)
        }
        expanded={ isCategoryExpanded }
        onChange={ event => { this.onSectionExpanded(functionalCategory, event) } }
      >
      <AWSUI.Table
          columnDefinitions={ FUNCTIONAL_TEST_METRICS_COLUMNS }
          items={ functionalTestsData }
          variant='borderless'
          >
       </AWSUI.Table>
      </AWSUI.ExpandableSection>
    )
  }

  /**
   * Event handler for expanding/ collapsing Functional test case sections
   */
  onSectionExpanded = (id, event) => {
    let expandedSectionsIn = setValueForExpandedSection(id, event, this.state.expandedSections);
    this.setState({
      expandedSections: expandedSectionsIn
    });
    this.loadFunctionalTestData();
  }

  /**
   * Renders test metrics feeds for Functional
   */
  renderFunctionalTestMetricsFeeds = () => {
    const isDataAvailable = Object.keys(this.props.params).length > 0;
    let completedTestsStats, startTime, elapsedTime, eta;
    let currentFunctionalTest = AppConstants.UNAVAILABLE;
    let questionPlayed = AppConstants.RETRIEVING;
    let dutHeard = AppConstants.RETRIEVING;
    let dutResponded = AppConstants.RETRIEVING;
    let scoreFrr;
    let scoreRar;
    let labJobId = AppConstants.EMPTY;
    let utteranceValidationType = AppConstants.EMPTY;
    let utteranceStatus = FunctionalTestCases.FAILED;

    if (isDataAvailable) {
      startTime = this.props.params.startTime;
      elapsedTime = this.props.params.elapsedTime;
      labJobId = this.props.params.labJobId;

      let functionalStatusList = getOverallTestStatusList(this.props.params.functionalTestCases);
      let completedTestsNum = getCompletedTestsNum(functionalStatusList);
      completedTestsStats = getCompletedTestsStats(completedTestsNum, functionalStatusList);
      eta = getOverallEta(this.props.params.avgTestTime, completedTestsNum, this.props.params.functionalTestCases);
      currentFunctionalTest = getInProgressTestNameDisplay(this.props.params.currentFunctionalTest);
      utteranceValidationType = this.props.params.utteranceValidationType;
      utteranceStatus = this.props.params.utteranceStatus;

      if (this.props.params.testStats && Object.keys(this.props.params.testStats).length > 0) {
        let currentUtteranceData = getValueForUtteranceFeed(labJobId + FunctionalTestCases.UTTERANCE_DATA_KEY,
          this.props.params.questionPlayed,
          this.props.params.dutHeard,
          this.props.params.dutResponded,
          this.props.params.scoreFrr,
          this.props.params.scoreRar,
          this.props.params.utteranceStatus,
          this.props.params.utteranceAttribute
        );

        questionPlayed = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.QUESTION_PLAYED] :
          AppConstants.RETRIEVING;
        dutHeard = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.DUT_HEARD] :
          AppConstants.RETRIEVING;
        dutResponded = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.DUT_RESPONDED] :
          AppConstants.RETRIEVING;
        scoreFrr = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.FRR_KEY] :
          AppConstants.RETRIEVING;
        scoreRar = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.RAR_KEY] :
          AppConstants.RETRIEVING;
        utteranceStatus = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.STATUS_KEY] :
          AppConstants.RETRIEVING;
      }
    }

    return (
      getFunctionalTestMetrics(completedTestsStats, currentFunctionalTest, startTime, questionPlayed, elapsedTime,
        dutHeard, eta, dutResponded, scoreFrr, scoreRar, utteranceValidationType, utteranceStatus)
    );
  }

  /**
   * Gets reason to display if overall result is PASSED or FAILED when Cloud or Ref Mic validation results
   * conflict
   * @param overallValidationStatus Overall validation status
   * @param cloudValidationStatus Alexa Cloud validation status
   * @param refMicValidationStatus Ref Mic validation status
   * @returns Reason for overallValidationStatus being PASSED or FAILED
   */
  getOverallValidationReason = (overallValidationStatus, cloudValidationStatus, refMicValidationStatus) => {
    let overallValidationReason = AppConstants.EMPTY;
    if (overallValidationStatus && cloudValidationStatus && refMicValidationStatus) {
      let overallReasonKey = 'OVERALL_' + overallValidationStatus + '_CLOUD_' + cloudValidationStatus
        + '_REF_MIC_' + refMicValidationStatus;
      // Retrieve Overall validation reason from Map
      if (FunctionalTestCases.OVERALL_VALIDATION_REASON_MAP.hasOwnProperty(overallReasonKey)) {
        return FunctionalTestCases.OVERALL_VALIDATION_REASON_MAP[overallReasonKey];
      }
    }
    return overallValidationReason;
  }

  /**
   * Gets reason to display when results of Cloud Validation & Ref Mic validation are conflicting
   * @param overallValidationReason Reason to display
   * @returns Text in display friendly format
   */
  getOverallValidationReasonDisplay = (overallValidationReason) => {
    return (
        <span className='overall-validation-reason-style'>{ overallValidationReason }</span>
    )
  }

  /**
   * Gets Utterance details button which can be clicked to view all utterances for a test case
   * @param functionalTestCaseName Name of the functional test case
   * @returns Button for a test case
   */
  getUtteranceDetailsButton = (functionalTestCaseName) => {
    return (
      <div className='awsui-util-ml-l'>
        <AWSUI.Button
          variant='normal'
          icon='zoom-in'
          text='Utterances'
          onClick={ () => this.handleTabChange(functionalTestCaseName) }/>
      </div>
    )
  }

  /**
   * Handles onClick event when Utterance button is clcked which results in
   * navigation to Utterance details tab
   * @param functionalTestCaseName Name of the functional test case
   */
  handleTabChange = (functionalTestCaseName) => {
    this.props.onTabChange('utteranceDetailsTab', functionalTestCaseName);
  }

  /**
   * Loads the functional test cases table with data
   * @returns Data for functional scenario in tabular format
   */
  loadFunctionalTestData = () => {
    let functionalCategoryDataIn = [];
    // Read functional test cases JSON from params
    let functionalTestsFromJson = this.props.params.functionalTestCases;
    // Read map of category level status from JSON
    let testCaseCategoryStatusMap = this.props.params.testCaseCategoryStatusMap;

    let functionalTestsDataIn = [];
    if (functionalTestsFromJson && functionalTestsFromJson.length > 0 &&
          testCaseCategoryStatusMap && Object.keys(testCaseCategoryStatusMap).length > 0) {
      let previousTestCaseCategory = functionalTestsFromJson[0].testCaseCategory;
      let currentTestCaseCategory = functionalTestsFromJson[0].testCaseCategory;
      // Iterate over list of functional test cases
      for (let i = 0; i < functionalTestsFromJson.length; i++) {
        let currentTestCase = functionalTestsFromJson[i];
        if (currentTestCase) {
          // Retrieve status data for current test case
          currentTestCaseCategory = currentTestCase.testCaseCategory;
          let functionalTestCaseName = AppConstants.UNKNOWN;
          // Get a display friendly test case name
          if (FunctionalTestCases.FUNCTIONAL_TEST_CASES_DISPLAY.hasOwnProperty(currentTestCase.testCaseName)) {
            functionalTestCaseName = FunctionalTestCases.FUNCTIONAL_TEST_CASES_DISPLAY[currentTestCase.testCaseName];
          }
          if (FunctionalTestCases.FUNCTIONAL_NAV_TEST_CASES_DISPLAY.hasOwnProperty(currentTestCase.testCaseName)) {
            functionalTestCaseName = FunctionalTestCases.FUNCTIONAL_NAV_TEST_CASES_DISPLAY[currentTestCase.testCaseName];
          }
          // Retrieve following test statuses:
          // 1. Alexa Cloud Validation
          // 2. Ref Mic Validation
          // 3. Overall Status
          let testStatusData = currentTestCase.testCaseData;
          let cloudValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          let cloudValidationFailureLogs = getFailureLogsForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          // Retrieve REF_MIC_VALIDATION status first
          let refMicValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.REF_MIC_VALIDATION);
          let refMicValidationFailureLogs = [];
          // If REF_MIC_VALIDATION is not NOT_APPLICALBLE, retrieve failure logs for the same, otherwise check for
          // REF_MIC_SPEECH_AUDIO_VALIDATION status & failure logs
          if (refMicValidationStatus !== FunctionalTestCases.STATUS_NOT_APPLICABLE) {
            refMicValidationFailureLogs = getFailureLogsForValidationType(testStatusData, FunctionalTestCases.REF_MIC_VALIDATION);
          } else {
            refMicValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.REF_MIC_SPEECH_AUDIO_VALIDATION);
            refMicValidationFailureLogs = getFailureLogsForValidationType(testStatusData, FunctionalTestCases.REF_MIC_SPEECH_AUDIO_VALIDATION);
          }
          let overallValidationStatus = getOverallValidationStatus(currentTestCase);
          let overallFailureLogs = getOverallFailureLogs(currentTestCase);


          let overallValidationReason = this.getOverallValidationReason(overallValidationStatus, cloudValidationStatus,
              refMicValidationStatus);

          if (currentTestCaseCategory !== previousTestCaseCategory || i === functionalTestsFromJson.length - 1) {
            // If this is last record, push the data for category into category array
            if (i === functionalTestsFromJson.length - 1) {
              // Check if last record belongs to same test category as previous one & if true,
              // push it under similar test category as previous
              if (currentTestCaseCategory === previousTestCaseCategory) {
                functionalTestsDataIn.push({
                  functionalTestCaseName: functionalTestCaseName,
                  cloudValidationStatus: this.getTestStatus(cloudValidationStatus, cloudValidationFailureLogs),
                  refMicValidationStatus: this.getTestStatus(refMicValidationStatus, refMicValidationFailureLogs),
                  overallValidationStatus: this.getTestStatus(overallValidationStatus, overallFailureLogs),
                  comments: this.getOverallValidationReasonDisplay(overallValidationReason),
                  utteranceDetails: this.getUtteranceDetailsButton(functionalTestCaseName)
                });
              } else {
                // If last record belongs to different test category, first make sure to push previous test under
                // previous category
                if (previousTestCaseCategory && previousTestCaseCategory !== AppConstants.EMPTY) {
                  let functionalCategoryDisplay = AppConstants.UNKNOWN;
                  // Get a display friendly test category name
                  if (FunctionalTestCases.FUNCTIONAL_TEST_CATEGORY_DISPLAY.hasOwnProperty(previousTestCaseCategory)) {
                    functionalCategoryDisplay = FunctionalTestCases.FUNCTIONAL_TEST_CATEGORY_DISPLAY[previousTestCaseCategory];
                  }
                  if (FunctionalTestCases.FUNCTIONAL_NAV_TEST_CATEGORY_DISPLAY.hasOwnProperty(previousTestCaseCategory)) {
                    functionalCategoryDisplay = FunctionalTestCases.FUNCTIONAL_NAV_TEST_CATEGORY_DISPLAY[previousTestCaseCategory];
                  }
                  let functionalCategoryStatus = FunctionalTestCases.QUEUED;
                  // Retrieve status for current category from category level map
                  if (testCaseCategoryStatusMap.hasOwnProperty(previousTestCaseCategory)) {
                    functionalCategoryStatus = testCaseCategoryStatusMap[previousTestCaseCategory];
                  }
                  functionalCategoryDataIn.push({
                    functionalCategory: this.getExpandableSectionForCategory(functionalCategoryDisplay, functionalCategoryStatus, functionalTestsDataIn)
                  });
                }
                // If last record belongs to different test category, push it under new test category
                functionalTestsDataIn = [];
                functionalTestsDataIn.push({
                  functionalTestCaseName: functionalTestCaseName,
                  cloudValidationStatus: this.getTestStatus(cloudValidationStatus, cloudValidationFailureLogs),
                  refMicValidationStatus: this.getTestStatus(refMicValidationStatus, refMicValidationFailureLogs),
                  overallValidationStatus: this.getTestStatus(overallValidationStatus, overallFailureLogs),
                  comments: this.getOverallValidationReasonDisplay(overallValidationReason),
                  utteranceDetails: this.getUtteranceDetailsButton(functionalTestCaseName)
                });
                previousTestCaseCategory = currentTestCaseCategory;
              }
            }

            if (previousTestCaseCategory && previousTestCaseCategory !== AppConstants.EMPTY) {
              let functionalCategoryDisplay = AppConstants.UNKNOWN;
              // Get a display friendly test category name
              if (FunctionalTestCases.FUNCTIONAL_TEST_CATEGORY_DISPLAY.hasOwnProperty(previousTestCaseCategory)) {
                functionalCategoryDisplay = FunctionalTestCases.FUNCTIONAL_TEST_CATEGORY_DISPLAY[previousTestCaseCategory];
              }
              if (FunctionalTestCases.FUNCTIONAL_NAV_TEST_CATEGORY_DISPLAY.hasOwnProperty(previousTestCaseCategory)) {
                functionalCategoryDisplay = FunctionalTestCases.FUNCTIONAL_NAV_TEST_CATEGORY_DISPLAY[previousTestCaseCategory];
              }
              let functionalCategoryStatus = FunctionalTestCases.QUEUED;
              // Retrieve status for current category from category level map
              if (testCaseCategoryStatusMap.hasOwnProperty(previousTestCaseCategory)) {
                functionalCategoryStatus = testCaseCategoryStatusMap[previousTestCaseCategory];
              }
              functionalCategoryDataIn.push({
                functionalCategory: this.getExpandableSectionForCategory(functionalCategoryDisplay, functionalCategoryStatus, functionalTestsDataIn)
              });
            }
            functionalTestsDataIn = [];
          }
          // Push the data for current category into category array
          functionalTestsDataIn.push({
            functionalTestCaseName: functionalTestCaseName,
            cloudValidationStatus: this.getTestStatus(cloudValidationStatus, cloudValidationFailureLogs),
            refMicValidationStatus: this.getTestStatus(refMicValidationStatus, refMicValidationFailureLogs),
            overallValidationStatus: this.getTestStatus(overallValidationStatus, overallFailureLogs),
            comments: this.getOverallValidationReasonDisplay(overallValidationReason),
            utteranceDetails: this.getUtteranceDetailsButton(functionalTestCaseName)
          });
          previousTestCaseCategory = currentTestCaseCategory;
        }
      }
    }
    return functionalCategoryDataIn;
  }

  render() {
    // Refresh functional stats table upon every render
    let functionalCategoryData = this.loadFunctionalTestData();
    return (
        functionalCategoryData && functionalCategoryData.length > 0 ? (
          <div>
            {
              this.renderFunctionalTestMetricsFeeds()
            }
            <div>
              <AWSUI.Table
                columnDefinitions={ FUNCTIONAL_EXPANDABLE_COLUMNS }
                items={ functionalCategoryData }
              >
              </AWSUI.Table>
            </div>
          </div>
        ) : (
          this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
            ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
            Util.getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
        )
    );
  }
}

FunctionalTestMetrics.propTypes = {
  onTabChange: PropTypes.func.isRequired
};

export default FunctionalTestMetrics;

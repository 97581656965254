/**
 * Tab which displays sync progress for multiple locations as well as list of resources (manifest files) for multiple
 * locations. Also, gives an option to download resources per-location basis
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { Actions, AppConstants, AQTTestOptionsParser, Store, SyncResourceConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { countAndPercentage, getOverallPercentage, getOverallProgressText,
  getOverallProgressPercentage, getProgressBarResourceSizeText, getSyncCompletedDate,
  generateSyncResourceDownloadJsonFromFileNames, getManifestDisplayName } from './SyncResourceUtil.js';
import { SYNC_PROGRESS_COLUMNS, MANIFEST_STATUS_COLUMNS } from './SyncDetailTableConfig';
import './SyncResourceStyles.css';
import { fetchLabs, getManifestsForRasPi } from './controller';

class SyncResourceDetails extends React.Component {

  state = {
    activeTabIds: {},
    syncManifestData: {},
    manifestLoading: new Array(SyncResourceConstants.MAX_NUM_LOCATIONS).fill(true),
    manifestDataAll: new Array(SyncResourceConstants.MAX_NUM_LOCATIONS).fill([]),
    expandedLocation: AppConstants.EMPTY,
    isErrorSync: new Array(SyncResourceConstants.MAX_NUM_LOCATIONS).fill(false),
    isErrorLablocked: false,
    isSyncSubmitting: new Array(SyncResourceConstants.MAX_NUM_LOCATIONS).fill(false),
    downloadResourceJson: []
  };

  componentWillUnmount() {
    // If tab is navigated away, save the state of selections made on this tab
    let labId = this.props.params.labId;
    if(labId) {
       this.handleTabChangeSyncDetails(labId);
    } 
  }

  componentWillMount() {
    // Retrieve the already stored state from state store
    let labId = this.props.params.labId;
    let labData = this.props.params.labData;
    if(labId) {
      let key = labId + SyncResourceConstants.SYNC_DETAILS_TAB_ID;
      let syncSubmittedKey = labId + SyncResourceConstants.SYNC_DETAILS_TAB_ID + '_syncSubmitted';

      if (Store.stateStore.getState().hasOwnProperty(key)) {
        let savedState =  Store.stateStore.getState()[key];
        if(Store.stateStore.getState().hasOwnProperty(syncSubmittedKey)) {
          let syncSubmittedValue = Store.stateStore.getState()[syncSubmittedKey];
          // If resources has not been submitted for download yet, retrieve the saved state for this tab,
          // otherwise show fresh options
          if (!syncSubmittedValue) {
            // Set the state to the saved one
            this.state = savedState;
          } else {
            Store.stateStore.dispatch(Actions.saveState(syncSubmittedKey, false));
            this.loadManifestsForAllRasPis(labId, labData);
          }
        } else {
          this.state = savedState;
        }
      } else {
        Util.logToConsole('Loading manifests for all locations');
        this.loadManifestsForAllRasPis(labId, labData);
      }
    }
  }

  /**
   * Loads manifest files for single location
   */
  loadManifestsForRasPi = (labId, rasPiIndex, rasPi) => {
    if(labId && rasPi) {
      let manifestLoading = this.state.manifestLoading;
      let rasPiId = rasPi.id;
      let manifestDataForLocation = {};

      // Set loading manifest state for current location
      manifestLoading[rasPiIndex] = true;
      this.setState({
        ...this.state,
        manifestLoading: manifestLoading
      })

      let manifests = [];

      // Retrieve the state of manifests for this location from controller
      getManifestsForRasPi(labId, rasPiId, SyncResourceConstants.ACK_TIMEOUT, SyncResourceConstants.ACTION_TIMEOUT).then(response => {
        Util.logToConsole('Calling API for rasPi = ' + rasPiId);
        if (!response.hasOwnProperty('error')) {
          Util.logToConsole('Successfully retrieved manifests for RasPi = ' + rasPiId + ' = ' + JSON.stringify(response));
          manifestDataForLocation = response;
          if(manifestDataForLocation && Object.keys(manifestDataForLocation).length > 0) {
            Object.keys(manifestDataForLocation).map(manifestKey => {
              let manifestJson = manifestDataForLocation[manifestKey];
              if(manifestJson.hasOwnProperty('payload')) {
                let payload = manifestJson['payload'];
                if(payload.hasOwnProperty('manifests')) {
                  let currentManifestList = payload['manifests'];
                  currentManifestList.map(manifestObject => {
                    manifests.push(manifestObject);
                  })
                }
              }
            });
          }
        } else {
          Util.logToConsole('Error retrieving manifest data in getManifestStatus for RasPi = ' + rasPiId);
          Util.logToConsole('Error = ' + JSON.stringify(response));
        }        
        
        // After manifest is loaded, reset the loading state
        let manifestLoading = this.state.manifestLoading;
        let manifestDataAll = this.state.manifestDataAll;
        manifestLoading[rasPiIndex] = false;
        manifestDataAll[rasPiIndex] = manifests;
        this.setState({
          ...this.state,
          manifestLoading: manifestLoading,
          manifestDataAll: manifestDataAll
        });
      })
    }
  }

  /**
   * Loads manifests information for all locations
   */
  loadManifestsForAllRasPis = (labId, labData) => {
    if(labId && labData && Object.keys(labData).length > 0) {
      let rasPis = labData.rasPis;
      if(rasPis) {
        // Retrieve manifests data for each rasPi
        rasPis.map(rasPi => {
          let rasPiId = rasPi.id
          let rasPiIndex = rasPis.indexOf(rasPi);
          this.loadManifestsForRasPi(labId, rasPiIndex, rasPi);
        })
      }
    }
  }

  /**
   * Gets detailed data for the current location
   * Detailed data includes:
   * 1. In progress status --> Data for any sync which is in progress
   * 2. Resources status --> Data for any resources which are in or out of sync
   */
  getSyncDetailsData = (labId, labData) => {
    if(!labId || !labData) {
      return(
        <div align='center'>
          {
            Util.getLoadingMessage(SyncResourceConstants.ERROR_NO_DATA)
          }
        </div>
      )
    }
    let rasPis = labData.rasPis;

    return (
      <div>
        {
        rasPis.map(rasPi => {
          let locationName = Util.getRasPiName(rasPi, labData);
          let rasPiIndex = rasPis.indexOf(rasPi);
          let manifestData = this.state.manifestDataAll[rasPiIndex];

          // Retrieve data for sync progress
          let syncData = {};
          if(rasPi.hasOwnProperty('rasPiStatus')) {
            let rasPiStatus = rasPi['rasPiStatus'];
            if(rasPiStatus.hasOwnProperty('shadowState')) {
              syncData = rasPiStatus['shadowState'];
            }
          }

          let locationStatus = rasPi.rasPiStatus;
          let tabs = this.getTabsForSyncStatus(labId, labData, locationName, locationStatus, syncData, rasPiIndex, 
            manifestData, rasPi);
          let activeTabId = this.getActiveTabForSyncStatus(labId, locationName, syncData);

          return (
            <AWSUI.ExpandableSection
              header={ this.getLocationExpandableSectionContent(locationName, locationStatus, syncData, manifestData, rasPiIndex) }
              expanded={ this.isLocationExpanded(labId, locationName) }
              onChange={ event => { this.onLocationExpanded(labId, locationName, event) } }
              variant={ 'borderless' }
            >
              <AWSUI.Tabs 
                variant='container'
                tabs={ tabs } 
                activeTabId={ activeTabId }
                onChange={ event => {
                this.setState({
                  activeTabIds: {
                    ...this.state.activeTabIds,
                    [labId + '_' + locationName]: event.detail.activeTabId
                  }
                })
              }}/>
            </AWSUI.ExpandableSection>
          )
        })
      }
    </div>)
  }

  /**
   * Invoked when particular location within the lab is expanded. All other locatins are collapsed automatically
   * in such case
   */
  onLocationExpanded = (labId, locationName, event) => {
    if(labId && locationName) {
      let isExpanded = event.detail.expanded ? true : false;
      this.setState({
        ...this.state,
        expandedLocation : isExpanded ? locationName : AppConstants.EMPTY
      })
    }
  }

  /**
   * Returns whether location is expanded or not
   */
  isLocationExpanded = (labId, locationName) => {
    if(labId && locationName) {
      return this.state.expandedLocation === locationName;
    }
    return false;
  }

  /**
   * Gets the refresh button to reload list of manifests for given location
   */
  getRefreshButton = (labId, rasPiIndex, rasPi) => {
    return (
      <span>
        <AWSUI.Button className='awsui-util-mt-xl awsui-util-ml-l'
          variant='normal' 
          text= { 'Refresh' }
          onClick={ () => this.onRefreshClicked(labId, rasPiIndex, rasPi) }
        />
      </span>
    )
  }

  /**
   * Gets manifest status data for all manifests
   */
  getManifestStatusData = (labId, labData, locationName, locationStatus, rasPiIndex, manifestData, rasPi) => {
    let manifestStatusData = [];
    let isManifestLoading = this.state.manifestLoading[rasPiIndex];

    // Check whether Pi is online or offline
    let isLocationOnlineStatus = locationStatus && locationStatus.hasOwnProperty('status') ?
      locationStatus.status : AppConstants.OFFLINE;

    // While manifest data is loading, return retrieving message
    if(isManifestLoading) {
      return (
        <div align='center' className='awsui-util-mt-xl awsui-util-mb-xl'>
          <h3>
            <span className='grey-color-text'>
              <AWSUI.Icon variant="subtle" name="status-pending"></AWSUI.Icon>
              <span className='awsui-util-ml-xs'>
                { SyncResourceConstants.RETRIEVING_MANIFESTS }
              </span>
            </span>
          </h3>
          {
            this.getRefreshButton(labId, rasPiIndex, rasPi)
          }
        </div> 
      )
    }

    if(!manifestData || manifestData.length == 0) {
      return (
        <div align='center' className='awsui-util-mt-l awsui-util-mb-m blue-color-text'>
          <h3>
            <span>
              <AWSUI.Icon variant='link' name='status-warning'></AWSUI.Icon>
              <span className='awsui-util-ml-s'>{ SyncResourceConstants.GET_MANIFEST_ERROR }</span>
            </span>
          </h3>
          {
            this.getRefreshButton(labId, rasPiIndex, rasPi)
          }
        </div>
      )
    } 

    let manifests = manifestData;
    manifests.map(manifest => { 
      let lastSyncdSeconds = manifest && manifest.hasOwnProperty('lastUpdateTime') ? manifest['lastUpdateTime'] : 0;
      let lastSyncdMillis = lastSyncdSeconds * 1000;
      let lastSyncdDate = lastSyncdMillis > 0 ? new Date(lastSyncdMillis).toString() : AppConstants.EMPTY;
      manifestStatusData.push({
        resourceName : this.getResourceNameWithCheckbox(labId, locationName, rasPiIndex, manifest.name, manifest.needsUpdate),
        status: manifest.hasOwnProperty('needsUpdate') ? manifest['needsUpdate'] : false,
        lastSyncd: lastSyncdDate
      })
    });

    // Sort manifest files so that outdated files show on the top
    manifestStatusData.sort(function(x, y) {
      if (!x['status'] && y['status']) {
        return 1;
      }
      if (x['status'] && !y['status']) {
        return -1;
      }
      return 0;
    });

    return (
      <div>
        <AWSUI.FormField className='awsui-util-mb-xl'
          label={ AppConstants.EMPTY }
          errorText={ AppConstants.EMPTY }
        >
          <div className='scrollstylecontainerlabs'>
            <span>
              <AWSUI.Button className='awsui-util-mb-l'
                text={ 'Select All'}
                icon={ 'status-positive' }
                onClick={ () => this.onManifestAllClicked(labId, locationName, rasPiIndex, manifests, this.state.syncManifestData, true) }
              />
              <AWSUI.Button className='awsui-util-mb-l awsui-util-ml-xl'
                text={ 'Clear All'}
                icon={ 'status-negative' }
                onClick={ () => this.onManifestAllClicked(labId, locationName, rasPiIndex, manifests, this.state.syncManifestData, false) }
              />
              <AWSUI.Tooltip className='awsui-util-ml-xl awsui-util-no-margin tooltip-inner'
                text={ 'Select All/ Clear All is applicable only to out-of-sync resources' } 
                size='small' 
                position='right'>
                  <AWSUI.Icon name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </span>
            <AWSUI.Table
              columnDefinitions={ MANIFEST_STATUS_COLUMNS }
              items={ manifestStatusData }
            >
            </AWSUI.Table>
          </div>
        </AWSUI.FormField>
        <div align='center'>
          {
            this.state.isErrorSync[rasPiIndex] && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant='error' name='status-warning'></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.SYNC_MANIFEST_ERROR }</span>
                </span>
              </div>
            )
          }
          {
            this.state.isErrorLablocked && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant='error' name='status-warning'></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.LAB_LOCKED_ERROR }</span>
                </span>
              </div>
            )
          }
          {
            this.state.isSyncSubmitting[rasPiIndex] 
              && !this.state.isErrorLablocked 
              && !this.state.isErrorSync[rasPiIndex] && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m blue-color-text'>
                <span>
                  <AWSUI.Icon variant='link' name='status-info'></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.SUBMIT_SYNC_INFO }</span>
                </span>
              </div>
            )
          }
          {
            isLocationOnlineStatus !== AppConstants.ONLINE && (
              <div align='center' className='awsui-util-mt-l awsui-util-mb-m red-color-text'>
                <span>
                  <AWSUI.Icon variant='error' name='status-warning'></AWSUI.Icon>
                  <span className='awsui-util-ml-s'>{ SyncResourceConstants.LOCATION_OFFLINE_ERROR }</span>
                </span>
              </div>
            )
          }
          <AWSUI.Button className='awsui-util-mt-xl awsui-util-ml-l'
            variant='normal' 
            text= { 'Sync' }
            disabled={ this.state.isSyncSubmitting[rasPiIndex] || isLocationOnlineStatus !== AppConstants.ONLINE  }
            onClick={ () => this.onSyncClicked(labId, labData, locationName, rasPiIndex) }
          />
          {
            this.getRefreshButton(labId, rasPiIndex, rasPi)
          }
        </div>
      </div>
    )
  }

  /**
   * Adds all out of sync manifest files to the selection
   */
  setAllManifestsOutOfSync = (manifests, selectedManifests) => {
    if(manifests && selectedManifests) {
      manifests.map(manifest => {
        if(manifest.hasOwnProperty('name') && manifest.hasOwnProperty('needsUpdate')) {
          // Add only file for which needsUpdate = true
          if(manifest['needsUpdate']) {
            selectedManifests[manifest['name']] = manifest['needsUpdate'];
          }
        }
      })
    }
    return selectedManifests;
  }

  /**
   * Removes all out of sync manifest files from selection
   */
  removeAllManifestsOutOfSync = (selectedManifests) => {
    if(selectedManifests) {
      Object.keys(selectedManifests).map(manifest => {
        // Remove only file for which needsUpdate = true
        if(selectedManifests[manifest]) {
          delete selectedManifests[manifest];
        }
      })
    }
    return selectedManifests;
  }

  /**
   * Invoked when refresh button is clicked for a particular location
   * Retrieves the list of  manifests for particular location on click
   */
  onRefreshClicked = (labId, rasPiIndex, rasPi) => {
    this.loadManifestsForRasPi(labId, rasPiIndex, rasPi);
  }

  /**
   * Invoked when sync button is clicked
   */
  onSyncClicked = (labId, labData, locationName, rasPiIndex) => {
    // Check if lab is locked, if so, don't invoke resource download
    let isLabLocked = SyncResourceConstants.LAB_AVAILABLE;

    fetchLabs().then(labs => {
      if (!labs.hasOwnProperty('error')) {
        labs.forEach(lab => {
          if(lab.hasOwnProperty('id') && lab.id === labId) {
            isLabLocked = lab.hasOwnProperty('lock') ? lab['lock'] : SyncResourceConstants.LAB_AVAILABLE;
            Util.logToConsole('Lab locked status for ' + labId + ' from controller API = ' + isLabLocked);
          }
        });
      } else {
        Util.logToConsole('Error retrieving labs');
      }
    });

    let isErrorSync = false;
    let downloadResourceJson = [];

    let isSyncSubmittingState = this.state.isSyncSubmitting;
    isSyncSubmittingState[rasPiIndex] = true;

    this.setState({
      ...this.state,
      isSyncSubmitting: isSyncSubmittingState
    })

    setTimeout(() => {
      Util.logToConsole('Lab locked status before submitting the resource sync for ' + labId + ' = ' + isLabLocked);
      // Generate download manifest JSON & kick off resource sync only if lab is not locked
      if(isLabLocked === SyncResourceConstants.LAB_AVAILABLE) {
        isErrorSync = true;
        let manifestsForLocation = {};
        if(labId && locationName) {
          let syncManifestData = this.state.syncManifestData;
          if(syncManifestData && Object.keys(syncManifestData).length > 0) {
            if(syncManifestData.hasOwnProperty(labId)) {
              let locationsMap = syncManifestData[labId];
              if(locationsMap && locationsMap.hasOwnProperty(locationName)) {
                manifestsForLocation = locationsMap[locationName];
                isErrorSync = !manifestsForLocation || Object.keys(manifestsForLocation).length == 0;
              }
            }
          }
        }

        // Generate the manifest JSON in case at least 1 file is selected for download
        if(!isErrorSync && manifestsForLocation) {
          let downloadResourceJsonForController = generateSyncResourceDownloadJsonFromFileNames(manifestsForLocation);
          downloadResourceJson = AQTTestOptionsParser.get_manifests_for_resource_sync_options(downloadResourceJsonForController, [AppConstants.actorMappingKeys[rasPiIndex]]);
        }
      }
      
      let isErrorSyncState = this.state.isErrorSync;
      isErrorSyncState[rasPiIndex] = isErrorSync;
      isSyncSubmittingState[rasPiIndex] = false;

      this.setState({
        ...this.state,
        isErrorSync: isErrorSyncState,
        isErrorLablocked: isLabLocked === SyncResourceConstants.LAB_AVAILABLE ? false : true,
        isSyncSubmitting: isSyncSubmittingState,
        downloadResourceJson: downloadResourceJson
      })
      
      // Submit resource sync only in case there's not any error
      if(!this.state.isErrorSync[rasPiIndex] && 
         !this.state.isLabLocked &&
         this.state.downloadResourceJson.length > 0) {
        // Inovke the sync method defined in parent container
        let rasPiIndexes = [];
        rasPiIndexes.push(rasPiIndex);
        // Save the fact that we are submitting sync & navigating away from the tab automatically
        let syncSubmittedKey = labId + SyncResourceConstants.SYNC_DETAILS_TAB_ID + '_syncSubmitted';
        Store.stateStore.dispatch(Actions.saveState(syncSubmittedKey, true));
        this.handleSyncClicked(labId, rasPiIndexes, downloadResourceJson, AppConstants.rasPiAction.SYNC.id);
      }  
    }, SyncResourceConstants.GET_LAB_STATUS_TIMEOUT);
  }

   /**
   * Gets the name of manifest file to display along with checkbox so that it can be
   * selected as an option for resource sync if outdated
   */
  getResourceNameWithCheckbox = (labId, locationName, rasPiIndex, resourceName, status) => {
    if(labId && locationName && resourceName && status != undefined) {
      let resourceClassStyle = status ? 'awsui-util-status-negative' : 'awsui-util-status-positive';
      let resourecIconName = status ? 'status-negative' : 'status-positive';
      let resourceTextStyle = status ? 'sync-failed-test-status' : 'sync-passed-test-status';

      let resourceNameDisplay = getManifestDisplayName(resourceName);   
      if(resourceNameDisplay === AppConstants.EMPTY) {
        resourceNameDisplay = resourceName.replace('.json', AppConstants.EMPTY);
      }

      return (
        <div>
          <AWSUI.Checkbox
            label={ 
              <div className={ resourceClassStyle }>
                <AWSUI.Icon name={ resourecIconName }
                  variant={ 'normal' }/>
                <span className = { 'awsui-util-ml-xs ' +  resourceTextStyle }>
                  { resourceNameDisplay }
                </span>
              </div>
            }
            checked= { this.isResourceChecked(labId, locationName, resourceName) }
            onChange={ event => { this.onResourceChecked(labId, locationName, rasPiIndex, resourceName, status, event) } }
          />
        </div>
      )
    }
    return (
      <div>
      </div>
    )
  }

  /**
   * Event handler called when Select/Deselect All manifests button is clicked
   */
  onManifestAllClicked= (labId, locationName, rasPiIndex, manifests, syncManifestData, isSelectAll) => {
    if(labId && locationName && manifests) {
      let locationsMap = {};
      let selectedManifests = {};
      if(syncManifestData.hasOwnProperty(labId)) {
        locationsMap = syncManifestData[labId];
      }
      if(locationsMap && locationsMap.hasOwnProperty(locationName)) {
        selectedManifests = locationsMap[locationName];
      }
      // Depending on whether to select ALL unsync'd resources or clear all of them, invoke
      // the helper method
      if(isSelectAll) {
        selectedManifests = this.setAllManifestsOutOfSync(manifests, selectedManifests);
      } else {
        selectedManifests = this.removeAllManifestsOutOfSync(selectedManifests);
      }

      locationsMap[locationName] = selectedManifests;
      syncManifestData[labId] = locationsMap;
      let isErrorSyncState = this.state.isErrorSync;
      if(isSelectAll) {
        isErrorSyncState[rasPiIndex] = selectedManifests && Object.keys(selectedManifests).length > 0 ?
          false :
          true;
      }

      this.setState({
        ...this.state,
        syncManifestData: syncManifestData,
        isErrorSync: isErrorSyncState
      })
    }
  }

  /**
   * Event handler invoked when checkbox next to resource name is checked/ unchecked
   */
  onResourceChecked = (labId, locationName, rasPiIndex, resourceName, status, event) => {
    if(labId && locationName && resourceName && resourceName) {
      let syncManifestData = this.state.syncManifestData;
      let isChecked = event.detail.checked ? true : false;
      // Map of manifest files to be sync'd for this location
      let manifestsForLocation = {};
      let locationsMap = {};
      // Retrieve the existing list of manifest files for current location under current lab
      if(syncManifestData.hasOwnProperty(labId)) {
        locationsMap = syncManifestData[labId];
        if(locationsMap && locationsMap.hasOwnProperty(locationName)) {
          manifestsForLocation = locationsMap[locationName];
        }
      }
      // If checkbox is checked, add manifest file to the list
      if(isChecked && !manifestsForLocation.hasOwnProperty(resourceName)) {
        manifestsForLocation[resourceName] = status ? true : false;
      }
      // If checkbox is unchecked, remove manifest file from the list
      if(!isChecked && manifestsForLocation.hasOwnProperty(resourceName)) {
        delete manifestsForLocation[resourceName]
      }
      // Update the map
      locationsMap[locationName] = manifestsForLocation;
      syncManifestData[labId] = locationsMap;

      let isErrorSyncState = this.state.isErrorSync;
      isErrorSyncState[rasPiIndex] = false;

      this.setState({
        ...this.state,
        syncManifestData: syncManifestData,
        isErrorSync: isErrorSyncState
      })
    }
  }

  /**
   * Checks whether manifest file under particular location under particular lab is checked or not
   */
  isResourceChecked = (labId, locationName, resourceName) => {
    if(labId && locationName && resourceName) {
      let syncManifestData = this.state.syncManifestData;
      if(syncManifestData.hasOwnProperty(labId)) {
        let locationsMap = syncManifestData[labId];
        if(locationsMap && locationsMap.hasOwnProperty(locationName)) {
          let manifestsForLocation = locationsMap[locationName];
          return manifestsForLocation && manifestsForLocation.hasOwnProperty(resourceName);
        }  
      }
    }
    return false;
  }

  /**
   * Gets data for resource sync which is in progress
   */
  getSyncProgressData = (labId, locationName, syncData) => {
    if(!labId || !locationName || !syncData) {
      return (
        <div align='center'>
        {
          Util.getLoadingMessage(SyncResourceConstants.SYNC_NOT_IN_PROGRESS_MESSAGE)
        }
        </div>
      )
    }
    let syncProgressData = [];
    // There should be at least 2 keys (overall state plus queued, in progress, completed or failed )
    // to display sync progress data
    if(!syncData || Object.keys(syncData).length <= 1) {
      return (
        <div align='center'>
        {
          Util.getLoadingMessage(SyncResourceConstants.SYNC_NOT_IN_PROGRESS_MESSAGE)
        }
        </div>
      )
    } 

    // If overall state of sync resource is not "In progress", display generic message on this tab
    if(syncData.hasOwnProperty('state') && syncData['state'] !== 'inprogress') {
      let resourceSyncMessage = SyncResourceConstants.SYNC_NOT_IN_PROGRESS_MESSAGE;
      let lastCompletedTimestamp = getSyncCompletedDate(syncData);
      if(lastCompletedTimestamp && lastCompletedTimestamp != AppConstants.EMPTY) {
        resourceSyncMessage += ' Last download was completed on ' + lastCompletedTimestamp;
      }
      return (
        <div align='center'>
        {
          Util.getLoadingMessage(resourceSyncMessage)
        }
        </div>
      )
    }

    Object.keys(syncData).map(syncState => {
      if(SyncResourceConstants.SYNC_STATES.hasOwnProperty(syncState)) {
        // Retrieve status to display
        let status = SyncResourceConstants.SYNC_STATES[syncState]['label'];
        let resourceName = AppConstants.EMPTY;
        // Logic to retrieve data will differ for In progress state vs other states
        // since JSON format is different for both
        if(syncState === SyncResourceConstants.IN_PROGRESS) {
          // Retrieve the name of current file for which sync is in progress
          if(syncData[syncState].hasOwnProperty('name')) {
            resourceName = syncData[syncState]['name'];
          }
          // Push the record in table
          syncProgressData.push({
            resourceName : this.getInProgressFeeds(resourceName),
            status : this.getStatusForResource(status, syncData),
            rawStatus: status
          })
        } 
      }
    })

    // Sort data so that file in progress shows up at the top followed by completed
    // followed  by failed & queued files
    syncProgressData.sort(function(x, y) {
      let status_1 = x['rawStatus'];
      let status_2 = y['rawStatus'];
      let index_1 = SyncResourceConstants.SYNC_PROGRESS_SORT_ORDER.indexOf(status_1);
      let index_2 = SyncResourceConstants.SYNC_PROGRESS_SORT_ORDER.indexOf(status_2);
      if (index_1 < index_2) {
        return -1;
      }
      if (index_1 > index_2) {
        return 1;
      }
      return 0;
    });


    // Retrieve the resource count text to be displayed on progress bar
    return (
      <div>
        <AWSUI.FormField className='awsui-util-mb-xl'
          label={ 
            <span className='awsui-util-mb-m'>
              <h4>Overall Progress</h4>
            </span>
          }
          errorText={ AppConstants.EMPTY }
        >
          <div className='awsui-util-mb-xxl'>
            <AWSUI.ProgressBar
              description={ 
                <span>
                  <span>
                  {
                    getOverallProgressText(syncData) 
                  }
                  </span>
                  <span className='awsui-util-ml-s'>
                  {
                    getProgressBarResourceSizeText(syncData)
                  }
                  </span>
                </span>
              }
              value={ getOverallProgressPercentage(syncData) }
            ></AWSUI.ProgressBar>
          </div>
          <div className='awsui-util-mb-m awsui-util-mt-l'>
            <h4>Resource In Progress</h4>
          </div>
          <div className='scrollstylecontainerlabs'>
            <AWSUI.Table
              columnDefinitions={ SYNC_PROGRESS_COLUMNS }
              items={ syncProgressData }
            >
            </AWSUI.Table>
          </div>
        </AWSUI.FormField>
      </div>
    )
  }


  /**
   * Get percentage of failed, completed, pending files for current resource file which is in progress
   */
  getCurrentResourceProgress = (syncData) => {
    if(syncData.hasOwnProperty(SyncResourceConstants.IN_PROGRESS)) {
      let inProgressData = syncData[SyncResourceConstants.IN_PROGRESS];
      if(inProgressData && inProgressData.hasOwnProperty('status')) {
        let status = inProgressData['status'];
        let completed = countAndPercentage(status['completed'], status['total']);
        let failed = countAndPercentage(status['failed'], status['total']);
        let pending = countAndPercentage(status['pending'], status['total']);
        let totalFileSize = status['total_size'];
        let totalPending = status['size_remaining'];
        let sizeCompleted = countAndPercentage(totalFileSize - totalPending, totalFileSize, true);
        let sizeCompletedCount = '0';
        if(sizeCompleted) {
          let sizeCompletedSplit = sizeCompleted.split('(');
          if(sizeCompletedSplit && sizeCompletedSplit.length > 0) {
            sizeCompletedCount = sizeCompletedSplit[0];
          }
        }

        return (
          <div>
              <div>
                <div>
                  <div align='left' className='awsui-util-ml-s nowrap-style'>
                    <span className='grey-color-text'><b>Completed:</b>
                      <span className='awsui-util-ml-s green-color-text'>{ completed }</span>
                    </span>
                  </div>
                  <div align='left' className='awsui-util-ml-s nowrap-style'>
                    <span className='grey-color-text'><b>Downloaded:</b>
                      <span className='awsui-util-ml-s green-color-text'>{ sizeCompletedCount }</span>
                    </span>
                  </div>
                </div>
                {
                  failed && failed !== AppConstants.EMPTY && failed !== '0' && (
                    <div>
                      <div align='left' className='awsui-util-ml-s nowrap-style'>
                        <span className='grey-color-text'><b>Failed:</b>
                          <span className='awsui-util-ml-s red-color-text'>{ failed }</span>
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
          </div>
        )
      }
    }
    return(
      <div>
        <span>AppConstants.EMPTY</span>
      </div>
    )
  }

  /**
   * Gets text for "In progress" resource using different style
   */
  getInProgressFeeds = (text) => {
    return(
      <span className='in-progress-text-color'>
        { text.replace('.json','') }
      </span>
    )
  }

 /**
  * Gets sync status to display for particular resource 
  */
  getStatusForResource = (status, syncData, totalFiles=AppConstants.EMPTY, totalSize=AppConstants.EMPTY) => {
  let toolTipText = AppConstants.EMPTY;
  if(status !== 'In Progress') {
    toolTipText = 'Number of resources ' + status + ': ' + totalFiles;
    if(totalSize !== AppConstants.EMPTY) {
      toolTipText += ' (' + totalSize  + ')';
    }
  }
  return (
    <div>
      <AWSUI.ColumnLayout columns={ 2 } borders='none'>
          <div data-awsui-column-layout-root='true'>
            {
              status === 'In Progress' ? (
                <div>
                  { this.getCurrentResourceProgress(syncData) }
                </div>
              ) : (
                <div>
                  <AWSUI.Tooltip text={ toolTipText } 
                    size='small' position='right'
                    className='awsui-util-no-margin tooltip-inner'>
                    <AWSUI.Icon name='status-info'></AWSUI.Icon>
                  </AWSUI.Tooltip>
                </div>
              )
            }
            <div></div>
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Gets the content for location (top level) expandable section
   */
  getLocationExpandableSectionContent = (locationName, locationStatus, syncData, manifestData, rasPiIndex) => {
    // When single location is expanded, we want to show all other locations in "disabled"
    // color so that it's easier to view the data for customer
    let isLocationExpanded = this.state.expandedLocation === locationName 
      || this.state.expandedLocation === AppConstants.EMPTY;
    let disabledTextStyle = isLocationExpanded ? AppConstants.EMPTY : 'light-gray-color-text';
    let locationNameStyle = 'awsui-util-mb-xs ' + disabledTextStyle;

    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div className={ locationNameStyle } data-awsui-column-layout-root='true'>
          <div className='dark-gray-color-text'>
              <b> { locationName } </b>
          </div>
          <div>
          {
            this.getPingStatus(locationStatus, isLocationExpanded)
          }    
          </div>
          <div>
          {
            this.getSyncProgressOverallStatus(locationName, syncData, isLocationExpanded)
          }
          </div>
          <div>
          {
            this.getOverallResourceStatus(locationName, isLocationExpanded, manifestData, rasPiIndex)
          }
          </div>
        </div> 
      </AWSUI.ColumnLayout>
    );
  }

  /**
   * Gets overall sync status for current location
   */
  getSyncProgressOverallStatus = (locationName, syncData, isLocationExpanded) => {
    if(locationName && syncData) {
      if(syncData.hasOwnProperty('state')) {
        let state = syncData['state'];
        // If resource sync state is In progress, get the percentage progress made
        let percentage = AppConstants.EMPTY;
        if(state === 'inprogress') {
          if(syncData.hasOwnProperty('sync_inprogress')) {
            let syncInProgressData = syncData['sync_inprogress'];
            if(syncInProgressData && syncInProgressData.hasOwnProperty('status')) {
              let syncStatusData = syncInProgressData['status'];
              let overallTotal = syncStatusData.hasOwnProperty('overall_total') ? syncStatusData['overall_total'] : 0;
              let overallPending = syncStatusData.hasOwnProperty('overall_pending') ? syncStatusData['overall_pending'] : 0;
              if(overallTotal !== 0) {
                percentage = ' (' + getOverallPercentage(overallTotal - overallPending, overallTotal) + ')'
              }
            }
          }
        }

        let variant = isLocationExpanded ? SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[state].variant 
          : 'subtle';
        let textStyle = isLocationExpanded ? SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[state].textStyle 
          : 'awsui-util-ml-xs light-gray-color-text';

        return (
          <div className={ SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[state].class }>
            <AWSUI.Icon name={ SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[state].icon }
              variant={ variant }/>
            <span className = { textStyle }>
              { SyncResourceConstants.RESOURCE_SYNC_STATUS_MAP[state].text + percentage }
            </span>
         </div>
        )
      }
    }
    let message = this.props.params.elapsedSeconds < SyncResourceConstants.MAX_WAIT_TIME ? 
      SyncResourceConstants.MESSAGE_RETRIEVING : 
      SyncResourceConstants.MESSAGE_FAILED_RETRIEVING;
    let iconName = this.props.params.elapsedSeconds < SyncResourceConstants.MAX_WAIT_TIME ? 
      'status-pending' : 
      'status-negative';

    return (
      <div className={ 'awsui-util-status-pending nowrap-style' }>
        <AWSUI.Icon name={ iconName }
          variant={ 'subtle' }/>
        <span className='awsui-util-ml-xs sync-pending-test-status'>
          { message }
        </span>
        {
          message === SyncResourceConstants.MESSAGE_FAILED_RETRIEVING && (
            <AWSUI.Tooltip className='awsui-util-ml-m awsui-util-no-margin tooltip-inner'
              text={ SyncResourceConstants.SYNC_DATA_MISSING_MESSAGE } 
              size='small' 
              position='right'>
              <AWSUI.Icon name='status-info'></AWSUI.Icon>
            </AWSUI.Tooltip>
          )
        }
      </div>
    )
  }

  /**
   * Gets overall status for manifest files on particular location
   */
  getOverallResourceStatus = (locationName, isLocationExpanded, manifestData, rasPiIndex) => {
    let isManifestLoading = this.state.manifestLoading[rasPiIndex];
    let tooltipStyle = isLocationExpanded ? 
      'awsui-util-ml-m awsui-util-no-margin tooltip-inner black-color-style' :
      'awsui-util-ml-m awsui-util-no-margin tooltip-inner grey-color-text';
    
    if(!locationName || !manifestData || manifestData.length == 0) {
      let message = isManifestLoading ?
        SyncResourceConstants.MESSAGE_RETRIEVING :
        SyncResourceConstants.MESSAGE_MANIFEST_DATA_UNAVAILABLE;

      let iconName = 'status-pending';

      return (
        <div className={ 'awsui-util-status-pending nowrap-style' }>
          <AWSUI.Icon name={ iconName }
            variant={ 'subtle' }/>
          <span className='awsui-util-ml-xs sync-pending-test-status'>
            { message }
         </span>
         {
          message === SyncResourceConstants.MESSAGE_MANIFEST_DATA_UNAVAILABLE && (
            <span>
              <AWSUI.Tooltip className={ tooltipStyle }
                  text={ SyncResourceConstants.GET_MANIFEST_ERROR_OVERVIEW } 
                  size='small' 
                  position='right'>
                  <AWSUI.Icon className='black-color-text' name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </span>
          )
        }
        </div>
      )
    }

    let outOfSync = false;
    let manifests = manifestData;
    
    if(manifests) {
      manifests.map(manifest => {
        if(!outOfSync &&
          manifest.hasOwnProperty('needsUpdate') && manifest['needsUpdate']) {
            outOfSync = true;
        }
      })
    }

    let manifestStatus = outOfSync ? 'out_of_sync' : 'in_sync';
    let variant = isLocationExpanded ? SyncResourceConstants.MANIFEST_STATUS_MAP[manifestStatus].variant 
      : 'subtle';
    let textStyle = isLocationExpanded ? SyncResourceConstants.MANIFEST_STATUS_MAP[manifestStatus].textStyle 
      : 'awsui-util-ml-xs light-gray-color-text';

    return(
      <div className={ SyncResourceConstants.MANIFEST_STATUS_MAP[manifestStatus].class }>
        <AWSUI.Icon name={ SyncResourceConstants.MANIFEST_STATUS_MAP[manifestStatus].icon }
          variant={ variant }/>
        <span className = { textStyle }>
          { SyncResourceConstants.MANIFEST_STATUS_MAP[manifestStatus].text }
        </span>
      </div>
    )
  }

  /**
   * Gets the online/ offline/ Error status for RasPi
   */
  getPingStatus = (rasPiStatus, isLocationExpanded) => {
    let variant = isLocationExpanded ? 'normal' : 'subtle';
    let statusTextStyle = isLocationExpanded ? 'awsui-util-ml-xs' : 'light-gray-color-text awsui-util-ml-xs';
    if (rasPiStatus) {
      return (
        <div>
          { rasPiStatus.status ? (
            <div className={ rasPiStatus.status === AppConstants.ONLINE ? 'awsui-util-status-positive' : 'awsui-util-status-negative' }>
              <AWSUI.Icon name={ rasPiStatus.status === AppConstants.ONLINE ? 'status-positive' : 'status-negative' }
                variant={ variant } />
              <span className={ statusTextStyle }>
                { rasPiStatus.status }
              </span>
            </div>
          ) : (
            <span>
              <AWSUI.Spinner />
            </span>
          )}
        </div>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  /**
   * Gets active tab for sync status
   */
  getActiveTabForSyncStatus = (labId, locationName, syncData) => {
    let activeTabId = AppConstants.EMPTY;
    if(labId && locationName) {
      // Set Resources status tab as default
      activeTabId = labId + '_' + locationName + '_sync_status';
    }
    if (labId && locationName && syncData) {
      let overallState = syncData.hasOwnProperty('state') ? syncData['state'] : AppConstants.EMPTY;
      // Look up for current active tab for this lab in the map
      if (this.state.activeTabIds.hasOwnProperty(labId + '_' + locationName)) {
        activeTabId = this.state.activeTabIds[labId + '_' + locationName];
      } else {
        if(overallState === SyncResourceConstants.OVERALL_STATE_IN_PROGRESS) {
          // If sync is in progress for this location, set Sync Progress tab as default
          activeTabId = labId + '_' + locationName + '_sync_progress';
        } else {
          // Set Resources Status tab by default
          activeTabId = labId + '_' + locationName + '_sync_status';
        }
      }
    }
    return activeTabId;
  }

  /**
   * Gets tabs to display for Resoruce sync in progress and
   * Resources status
   */
  getTabsForSyncStatus = (labId, labData, locationName, locationStatus, syncData, rasPiIndex, manifestData, rasPi) => {
    let tabs = [];
    if (labId && locationName) {
      tabs = [{
        'label': 'Progress Details',
        'id': labId + '_' + locationName + '_sync_progress',
        'content' : this.getSyncProgressData(labId, locationName, syncData)
      }, {
        'label': 'Resources Status',
        'id': labId + '_' + locationName + '_sync_status',
        'content': this.getManifestStatusData(labId, labData, locationName, locationStatus, rasPiIndex, manifestData, rasPi)
      }];
    }
    return tabs;
  }

  /**
   * Inovked when tab is switched
   */
  handleTabChangeSyncDetails = (labId) => {
    // Save the state
    let key = labId + SyncResourceConstants.SYNC_DETAILS_TAB_ID;
    Store.stateStore.dispatch(Actions.saveState(key, this.state));
    this.props.onTabChange();            
  }

  /**
   * Invoked when Sync is clicked, this method transfers control back to parent container to
   * perform the actual sync
   */
  handleSyncClicked = (labId, rasPiIndex, downloadResourceJson, id) => {
    this.props.syncClicked(labId, rasPiIndex, downloadResourceJson, id);
  }

  render() {
    let labData = this.props.params.labData;
    let labId = this.props.params.labId;
    
    return (
      <div>
      {
        this.getSyncDetailsData(labId, labData)
      }
      </div>
    )
  }
}

SyncResourceDetails.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  syncClicked: PropTypes.func.isRequired
};

export default SyncResourceDetails;

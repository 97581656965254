import axios from 'axios';
// import store from '../stores/store';
// import { useSelector } from 'react-redux';
// import {jwtTokenSelector} from '../stores/newRun/userAccessSlice'

export const API = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete'
}

var currentAuthToken = null;

const client = () => {

  async function api(url, method, metaData) {

    const authHeader = {}
    let data;
    authHeader['Authorization'] = `Bearer ${currentAuthToken}`

    if(method === API.POST || method === API.PUT) {
      data = metaData;
    }

    try {
      const response = await axios({
        url,
        method,
        data,
        headers: {
          ...authHeader
        }
      });


      return response;
    } catch(err) {
      throw new Error(err);
    }
  }

  return {
    setToken: function (authToken) {
        currentAuthToken = authToken;
    },
    get: async function (url) {
      const resp = await api(url, API.GET);
      return resp;
    },
    post: async function (url, args) {
      const resp = await api(url, API.POST, args);
      return resp && resp.data;
    },
  }

}

export default client();
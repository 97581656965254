import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectedTests } from '../../../stores/newRun/testList/testListSlice';
import { deleteTestFromTestList } from '../../../stores/newRun/testList/testListMiddleware';
import { setInitiateDeleteTest, testListUISelector } from '../../../stores/newRun/testList/testListUiSlice';
import { Modal, Table, Button } from '@amzn/awsui-components-react';


export default () => {
  const allSelectedTests = useSelector(selectedTests);
  const { initiateDeleteTest } = useSelector(testListUISelector)
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(setInitiateDeleteTest(false))
  }

  const handleOkClick = () => {
    dispatch(deleteTestFromTestList());
  }

  const getColumnHeaders = () => {
    return [
      {
        id: "testGroupName",
        header: <FormattedMessage id="TEST_NAME" />,
        cell: e => e.name,
      },
      {
        id: "testSuite",
        header: <FormattedMessage id="TEST_SUITE" />,
        cell: e => e.testSuite.displayName
      },
      {
        id: "locale",
        header: <FormattedMessage id="LOCALE" />,
        cell: e => e.locale
      }
    ]
  }


  return (
    <div>
      <Modal
        header={<FormattedMessage id="FOLLOWING_TESTCASES_WILL_BE_DELETED" />}
        visible={initiateDeleteTest}
        onDismiss={handleCancel}
        size="large"
        footer={
          <span className="awsui-util-f-r">
            <span className="awsui-util-mr-s"><FormattedMessage id="ARE_YOU_SURE_YOU_WANT_TO_PROCEED" /></span>
            <Button variant="link" onClick={handleCancel}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button variant="primary" onClick={(handleOkClick)}>
              <FormattedMessage id="PROCEED" />
            </Button>
          </span>
        }
      >
        <div className="deleteTestPopup">

          <Table
            columnDefinitions={getColumnHeaders()}
            items={allSelectedTests}
            loading={false}
          >
         </Table>

        </div>
      </Modal>
    </div>
  );
}

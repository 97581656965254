import { createSlice, createSelector } from '@reduxjs/toolkit'
import { SCENARIO_TYPE } from './scenarioSelectionSlice';
import { ACC_DEV_MAPPING, TESTID_DEVICE_MAP } from '../../../constants';
import { isEmpty } from 'lodash';

export const deviceAccSelector = createSelector(
  (state) => state.acmScenarioSlection,
  (state) => getDeviceAccMapping(state)
)

export const getDeviceAccMapping = (state) => {
  if(!state) {
    return {}
  }
  const {scenarioType, customizedScenarios} = state;
  let allDevices = getAllDevices(scenarioType, customizedScenarios);

  const allAccounts = [...new Set(allDevices.map(deviceId=> getAccount(deviceId).label))];

  const devicAccObj = getAccountDeviceMap(allDevices);

  return {
    deviceAccMap: devicAccObj,
    devices: allDevices,
    accounts: allAccounts
  };
}


export const getAllDevices = (scenarioType, customizedScenarios) => {
  let allDevices = [];
  if(scenarioType === SCENARIO_TYPE.STANDARD) {
    const allDevicesConfig = Object.keys(TESTID_DEVICE_MAP).reduce(
      (iter, scenarioId) => {
        iter.push(...TESTID_DEVICE_MAP[scenarioId]["devices"]);
        return iter;
      }, []
     );
     allDevices = [...new Set(allDevicesConfig)];
  } else if(scenarioType === SCENARIO_TYPE.CUSTOM && Array.isArray(customizedScenarios)) {
    const allDevicesConfig = customizedScenarios.reduce(
      (iter, {scenarios}) => {
        if(Array.isArray(scenarios)) {
          scenarios.forEach(scenarioId => {
            if(scenarioId && TESTID_DEVICE_MAP[scenarioId] && Array.isArray(TESTID_DEVICE_MAP[scenarioId].devices))
            iter.push(...TESTID_DEVICE_MAP[scenarioId].devices);
          });
        }
        return iter;
      }, []
    );
    allDevices = [...new Set(allDevicesConfig)];
  }
  return allDevices;
}

export const getAccount = (devId) => {
  return Object.values(ACC_DEV_MAPPING)
  .find(({devices}) =>  devices[devId])
}

const getAccountDeviceMap = (allDevices) => {

  const deviceAccObj = allDevices.reduce((iter, devId)=>{
    const { label: accountLabel, devices  } = getAccount(devId);

    const { type, label: deviceLabel, id } = devices[devId]
    if(!iter[accountLabel]) {
      iter[accountLabel] = [];
    }
    iter[accountLabel].push({
      accountLabel,
      deviceLabel,
      deviceId: id,
      deviceType: type,
    });

    return iter;
  }, {});
  return deviceAccObj;
}



export const validate = (state) => {
  const error = {};
  if(!state) {
    throw new Error("Please pass valid state information")
  }
  const {acmScenarioSlection, acmLabConfig, testMapping, testInit} = state;

  const { labInfo, systemLabInfo, accountConfig } = acmLabConfig !== null && typeof(acmLabConfig) === "object" && acmLabConfig;
  if(!labInfo || !labInfo.id) {
    if (!systemLabInfo || !systemLabInfo.deviceModelName) {
      //Both lab info and system lab info are not set.
      error['labInfo'] = 'Please select lab configuration';
    }
  }

  if (!(testInit && testInit.testSuite) || (testInit.testSuite.displayName==='ACM')) {
    const {deviceAccMap, accounts} = getDeviceAccMapping(acmScenarioSlection);
    const configuredAccounts = accountConfig !== null && typeof(accountConfig) === "object" && Object.keys(accountConfig);

    if( !Array.isArray(configuredAccounts) || accounts.length !== configuredAccounts.length || !configuredAccounts.length) {
      error['account'] = 'Required accounts not configured to perfom test';
    } else {
      const allPis = [];
      const allCids = [];
      const allDevices = [];

      accounts.forEach(
        account => {
          const allDevicesForAccount = deviceAccMap[account];
          const errorList = {
            customerId: null,
            deviceConfig: null,
            deviceConfigList: {}

          }
          const {customerIdInfo, devicePiConfig} = accountConfig[account];

          if(!customerIdInfo || typeof(customerIdInfo) !== "object") {
            errorList.customerId = "Please select a customer id";
          } else {
            allCids.push(customerIdInfo.customerId);
          }

          if( !Array.isArray(devicePiConfig) || allDevicesForAccount.length !== devicePiConfig.length) {
            errorList.deviceConfig = "All devices are not configured for this account";
          }

          if(errorList.customerId || errorList.deviceConfig) {
            error[account] = errorList;
            return;
          }


          devicePiConfig.forEach((devicePiMap, index) => {
            const {dsnInfo, pi } = devicePiMap;
            if(!dsnInfo || !pi) {
              errorList.deviceConfigList[index] = "Please Select a valid Device and PI mapping";
            }
            if(pi) {
              allPis.push(pi.id);
            }
            if(dsnInfo) {
              allDevices.push(dsnInfo.dsn);
            }
          })

          if(errorList.customerId || errorList.deviceConfig || Object.keys(errorList.deviceConfigList).length > 0) {
            error[account] = errorList;
          }

        }
      );


      const uniquePis = Array.from(new Set(allPis));
      if(uniquePis.length !== allPis.length) {
        error['account'] = 'Same pi cannot be configured to multiple devices';
      }

      const uniqueCids = Array.from(new Set(allCids));
      if(uniqueCids.length !== allCids.length) {
        error['account'] = 'Same customer id cannot be used across multiple accounts';
      }

      const uniqDsn = Array.from(new Set(allDevices));
      if(uniqDsn.length !== allDevices.length) {
        error['account'] = 'Same device cannot be configured to multiple pi';
      }
    }
  } else {
    if (isEmpty(testMapping.mapping) || isEmpty(testMapping.actorMapping)) {
      if (!systemLabInfo || !systemLabInfo.deviceModelName) {
        //Both test mapping data and system lab info are not set
        error['account'] = 'Missing lab mapping';
      }
    }

  }

  return Object.keys(error).length ? error : null;
}


const getInitialState = () => ({
  labInfo: {},
  systemLabInfo: {},
  accountConfig: {},
  error: null
})


export const labConfigSlice = createSlice({
  name: 'labConfig',
  initialState: getInitialState(),
  reducers: {
    setLab: (state, action) => {
      const {
        address,
        companyId,
        createdAt,
        id,
        lock,
        name,
        preference,
        region,
        thingGroupArn,
        thingGroupId,
        thingGroupName,
        updatedAt
      } = action.payload;

      if(state.labInfo && state.labInfo.id !== id) {
        state.accountConfig = {};
        state.labInfo = {
          address,
          companyId,
          createdAt,
          id,
          lock,
          name,
          preference,
          region,
          thingGroupArn,
          thingGroupId,
          thingGroupName,
          updatedAt
        };

      }
    },
    setAccountConfig: (state, action) => {
      const { account, customerIdInfo, devicePiConfig } = action.payload;
      if(!state.accountConfig) {
        state.accountConfig = {}
      }
      state.accountConfig[account] = {customerIdInfo, devicePiConfig};
    },
    removeAccountConfig: (state, action) => {
      const account = action.payload;
      if(!state.accountConfig) {
        state.accountConfig = {}
      }
      delete state.accountConfig[account];
    },
    removeDeviceConfig: (state, action) => {
      const account = action.payload;
      if(!state.accountConfig) {
        state.accountConfig = {}
      }

      if(account && state.accountConfig[account]) {
        state.accountConfig[account] = {...state.accountConfig[account], devicePiConfig: []}
      } else {
        Object
          .entries(state.accountConfig)
          .forEach(
            ([account, { customerIdInfo }]) => {
              state.accountConfig[account] = {customerIdInfo, devicePiConfig: []}
            }
          )
      }
    },
    setSystemLabConfig: (state, action) => {
      const {
        deviceModelName
      } = action.payload;
      const nameSpace = 'FireTV';
      const dtid = 'AQRMMA6JEEZF';
      state.systemLabInfo = {
        deviceModelName,
        nameSpace,
        dtid
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    reset: () => getInitialState(),
  },
  })

  export const { setLab, setError, setAccountConfig, removeDeviceConfig, reset, removeAccountConfig, setSystemLabConfig } = labConfigSlice.actions

  export const labConfigSelector = (state) => state.acmLabConfig;

  export default labConfigSlice.reducer

import * as React from "react";
import TopNavigation from "@amzn/awsui-components-react-v3/polaris/top-navigation";
import { connect } from "react-redux";
import { isEmpty } from 'lodash';
import * as authActions from '../../redux/actions/auth';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
/**
 * Header component for rendering the App header. 
 * More utilities can be added as and when required.
 */
class Header extends React.Component {

  state = {
    session: {},
    signedIn: false,
    userName: '',
  }
  static getDerivedStateFromProps(props, state) {
    const userSessionObj = props.auth.session;
    var userEmail = '';
    var isSignedIn = false;
    if (!isEmpty(userSessionObj) && !isEmpty(userSessionObj.accessToken)) {
      if (!isEmpty(userSessionObj.accessToken.payload)) {
        userEmail = userSessionObj.accessToken.payload.username;
      } else {
        userEmail = userSessionObj.username;
      }
      isSignedIn = true;
    }
    if (state.signedIn !== isSignedIn ||
      state.userName !== userEmail) {
      return {
        signedIn: isSignedIn,
        userName: userEmail
      };
    }
    //No change in state in this case. 
    return null;
  }

  /**
   * Function to trigger sign out is moved here from LogoutBar
   */
  handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        this.setState({ signedIn: false, userName: undefined });
        //Since signout complete, we need to reset redux store as well. 
        //This inturns causes the AppLogin component to re-render and automatically
        //Renders login page since session will be empty.
        this.props.setSession({});
        // History will be available here in props
        // since it is linked with "withRouter" in the default statement below. 
        const { history } = this.props;
        history.push('/');
      })
      .catch(error => {
        Util.logToConsole('Error signing out : ' + JSON.stringify(error));
      });
  };


  render() {
    return (
      <TopNavigation identity={
        {
          href: "#",
          title: "FTV Qualification Portal",
          logo: {
            src: "Resources/images/Fire_TV_logo_Transparent.png",
            alt: "Fire TV"
          }
        }
      }
        utilities={
          this.state.signedIn ? [{
            type: "menu-dropdown",
            text: this.state.userName ? this.state.userName : undefined,
            iconName: "user-profile",
            onItemClick: () => this.handleSignOut(),
            items: [{
              id: "signout",
              text: "Sign out"
            }]
          }] : []
        }
        i18nStrings={
          {
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "All",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu"
          }
        }
      />);
  }
}

export default withRouter(connect(state => state, {
  setEnvironment: authActions.setEnvironment,
  setSession: authActions.setSession,
})(Header));

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { executeTest } from '../../../stores/newRun/testList/testListMiddleware';
import { setInitiateAddNewTest, testListUISelector } from '../../../stores/newRun/testList/testListUiSlice';
import { API_STATUS } from '../../../constants';
import { Alert, FormField, Modal, Select, Button } from '@amzn/awsui-components-react';
import { setTestSuite, testInitSelector } from '../../../stores/newRun/testInitSlice';
import { fetchScenarios, scenariosSelector } from '../../../stores/newRun/scenariosSlice';


export default () => {
  const { testSuite } = useSelector(testInitSelector);
  const { initiateAddNewTest } = useSelector(testListUISelector);
  const {status, scenarioList, error: scenarioFetchError} = useSelector(scenariosSelector);

  useEffect(
    ()=> {
      dispatch(fetchScenarios());
    }, []
  )

  const dispatch = useDispatch();

  const handleTestSuiteSelection = (e) => {
    const testInfo = e.detail.selectedOption.meta;
    dispatch(setTestSuite(testInfo));
  }

  const handleCancel = () => {
    dispatch(setInitiateAddNewTest(false))
  }

  const handleOkClick = () => {
    dispatch(executeTest());
  }

  const options = scenarioList.map((scenInfo) => ({ label: scenInfo.displayName, id: scenInfo.id, meta: scenInfo }));
  let selectedOption = {};
  if(testSuite) {
    selectedOption = { label: testSuite.displayName, id: testSuite.id };
  }

  let fetchErrView;
  if(status === API_STATUS.ERROR) {
    fetchErrView = <Alert type="error" className="awsui-util-mt-s awsui-util-mb-s">{scenarioFetchError}</Alert>
  }


  return (
    <div>
      <Modal
        header={<FormattedMessage id="SELECT_TEST_SUITE" />}
        visible={initiateAddNewTest}
        onDismiss={handleCancel}
        size="large"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={handleCancel}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button variant="primary" onClick={(handleOkClick)} disabled={!testSuite}>
              <FormattedMessage id="PROCEED" />
            </Button>
          </span>
        }
      >
        <div>
          {fetchErrView}
          <FormField label={<FormattedMessage id="TEST_SUITE" />} description={<FormattedMessage id="SELECT_TEST_SUITE" />} >
            <Select
              options={options}
              selectedOption={selectedOption}
              onChange={handleTestSuiteSelection}
              selectedLabel="Selected"
              loading={ status === API_STATUS.PENDING }
            ></Select>

          </FormField>

        </div>

      </Modal>
    </div>
  );
}

import { FormSection, ColumnLayout, Button } from '@amzn/awsui-components-react';
import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UNNAMED_DEVICE } from '../../../../constants';
import { labConfigSelector } from '../../../../stores/newRun/acm/labConfigSlice';
import { SCENARIO_TYPE, testSuiteConfigSelector } from '../../../../stores/newRun/acm/scenarioSelectionSlice';
import { testInitSelector } from '../../../../stores/newRun/testInitSlice';
import { updateStep } from '../../../../stores/newRun/acm/uiSlice';
import { FTV_GOTO_STEP } from '../../../../constants';
import { componentSelector, getModulesAsList } from '../../../../stores/newRun/componentSlice';

export default () => {
  return <>
   <LabConfig />
   <TestSuiteConfig />
  </>
}
 
const TestSuiteConfig = () => {
  const {scenarioType, locale, name, enableEmailNotification, customizedScenarios,
    isDryRun, dryRunBundleUrl, dryRunTestMetadata, testrailRunId, shouldCreateTestrailRun, 
    testrailSuite, deviceLocale, deviceTypeGroup, isRetryConfigured, retryTypes, retryTimes} = useSelector(testSuiteConfigSelector);
  const {status: componentsFetchStatus, error: compValidationError, fosTestSuiteModules, includeModules, excludeModules, ctsRunType, selectedComponents} = useSelector(componentSelector);
  const {testSuite} = useSelector(testInitSelector);
  const dispatch = useDispatch();
  const onEditClick = () => {
  dispatch(updateStep(FTV_GOTO_STEP.SCEN_SELECTION))
  }
  let customScenarioView = null;
  if(scenarioType === SCENARIO_TYPE.CUSTOM && testSuite.displayName == "FTV") {
    const view = selectedComponents.map((comp) => <div key={comp}>{comp}</div>)
    customScenarioView = <ColumnLayout columns={2} borders="all">
      <div data-awsui-column-layout-root="true">
        <div className='awsui-util-label'>
          <FormattedMessage id="CATEGORY" />
        </div>
        {view}
      </div>
    </ColumnLayout>
  }
  else if(scenarioType === SCENARIO_TYPE.CUSTOM && testSuite.displayName == "CTS") {
  
    var includeModulesList = getModulesAsList(includeModules);
    var excludeModulesList = getModulesAsList(excludeModules);
    
    customScenarioView = <ColumnLayout borders="all">
    <div data-awsui-column-layout-root="true">
        <div>
            <div className="awsui-util-label">
                <FormattedMessage id="INCLUDED_MODULES_COUNT" />
            </div>
            {includeModulesList.length}
        </div>
    </div>
    <div data-awsui-column-layout-root="true">
        <div>
            <div className="awsui-util-label">
                <FormattedMessage id="EXCLUDED_MODULES_COUNT" />
            </div>
            {excludeModulesList.length}
        </div>
    </div>
</ColumnLayout>
   
  }
  else if(scenarioType === SCENARIO_TYPE.CUSTOM && testSuite.displayName == "FOS") {

    customScenarioView = <ColumnLayout borders="all">
      <div data-awsui-column-layout-root="true">
        <div>
          <div className="awsui-util-label">
            <FormattedMessage id="FOS_TEST_SUITE" />
          </div>
          {fosTestSuiteModules}
        </div>
      </div>
    </ColumnLayout>

  }
  // Render retry configurations only if the test suite is CTS
  const renderRetryConfigurations = () => {
      return (
          <>
            <div>
              <div className="awsui-util-label">
                <FormattedMessage id="RETRY_CONFIGURATIONS" />
              </div>
              <div>
                {`${!!isRetryConfigured}`}
              </div>
            </div>
            <div>
              <div className="awsui-util-label">
                <FormattedMessage id="RETRY_TYPE_LABEL" />
              </div>
              <div>
                {retryTypes.join(', ')}
              </div>
            </div>
            <div>
              <div className="awsui-util-label">
                <FormattedMessage id="RETRY_TIMES" />
              </div>
              <div>
                {retryTimes}
              </div>
            </div>
          </>
      );
  };

  return (
    <>
      <div>
        <div className="awsui-util-action-stripe awsui-util-mb-n">
          <div className="awsui-util-action-stripe-title">
            <div className='awsui-util-font-size-3 awsui-util-pv-xs'>
              <FormattedMessage id="STEP_2" />{': '}
              <FormattedMessage id="TEST_SUITE" />
            </div>
          </div>
          <div className="awsui-util-action-stripe-group">
            <Button onClick={onEditClick}>
              <FormattedMessage id="EDIT" />
            </Button>
          </div>
        </div>
        <FormSection header={<FormattedMessage id="GENERAL_INFO" />}>
          <ColumnLayout columns={2} borders="vertical">
            <div data-awsui-column-layout-root="true">
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="TEST_NAME" />
                </div>
                <div>
                  {name}
                </div>
              </div>
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="TESTRAIL_RUN_ID" />
                </div>
                <div>
                  {testrailRunId}
                </div>
              </div>
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="LOCALE" />
                </div>
                <div>
                  {locale}
                </div>
              </div>
              {testSuite.displayName === "CTS" && isRetryConfigured && renderRetryConfigurations()}
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="ENABLE_EMAIL_NOTIFICATION" />
                </div>
                <div>
                  {`${!!enableEmailNotification}`}
                </div>
              </div>
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="UPLOAD_LOCAL_TEST_PACKAGE" />
                </div>
                <div>
                  {`${!!isDryRun}`}
                </div>
              </div>
            {
              isDryRun && (
                  <div>
                    <div className="awsui-util-label">
                      <FormattedMessage id="DRY_RUN_TEST_META_DATA" />
                    </div>
                    <div>
                      {JSON.stringify(dryRunTestMetadata, null, 2)}
                    </div>
                  </div>
              )
            }
            {
              isDryRun && (
                <div>
                  <div className="awsui-util-label">
                    <FormattedMessage id="DRY_RUN_BUNDLE_URL" />
                  </div>
                  <div>
                    {dryRunBundleUrl}
                  </div>
                </div>
              )
            }
            </div>
          </ColumnLayout>
        </FormSection>
        <FormSection header={<FormattedMessage id="SCENARIO_SELECTION" />}>
          <div className="awsui-util-spacing-v-s">
            <div>
              <div className="awsui-util-label">
                <FormattedMessage id="SCENARIO" />
              </div>
              <div>
                {scenarioType}
              </div>
            </div>
            {ctsRunType === 'rerun' ? (
                <div className='awsui-util-mt-s'>
                  <ColumnLayout borders="all">
                    <div data-awsui-column-layout-root="true">
                      <div>
                        <div className="awsui-util-label">
                          <FormattedMessage id="CTS_RUN_TYPE" />
                        </div>
                        {"CTS Last Session Rerun"}
                      </div>
                    </div>
                  </ColumnLayout>
                </div>
            ) : (
                <div className='awsui-util-mt-s'>
                  {customScenarioView}
                </div>
            ) }
          </div>
        </FormSection>
      </div>
    </>
  )
}
 
 
const LabConfig = () => {
  const {labInfo, systemLabInfo, accountConfig} = useSelector(labConfigSelector);
  const accounts = Object.keys(accountConfig);
  const dispatch = useDispatch();
  const onEditClick = () => {
  dispatch(updateStep(FTV_GOTO_STEP.LAB_CONFIG))
  }
 
  const accountMapView = accounts.map(
    account => {
    const { customerIdInfo, devicePiConfig } =  accountConfig[account];
    let devicePiMapView;
    if(Array.isArray(devicePiConfig)) {
 
      devicePiMapView = devicePiConfig.reduce(
        (view, {dsnInfo, pi, firmware}) => {
          view.push(<div key={nanoid()}>{dsnInfo.name || UNNAMED_DEVICE}</div>);
          view.push(<div key={nanoid()}>{pi.name}</div>);
          view.push(<div key={nanoid()}>{firmware}</div>);
          return view;
        }, []
      )
    }
 
    const devicePiTable = <ColumnLayout columns={3} borders="all">
      <div data-awsui-column-layout-root="true">
        <div className='awsui-util-label'>
          <FormattedMessage id="DEVICE" />
        </div>
        <div className='awsui-util-label'>
          <FormattedMessage id="PI" />
        </div>
        <div className='awsui-util-label'>
          <FormattedMessage id="FIRMWARE" />
        </div>
        {devicePiMapView}
      </div>
    </ColumnLayout>
 
    return (
      <FormSection header={<><FormattedMessage id="ACCOUNT" /> - {account}</>}>
        {/* <ColumnLayout> */}
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">
              <FormattedMessage id="CUSTOMER_ID" />
            </div>
            <div>
              {customerIdInfo.email}
            </div>
          </div>
        </div>
        <div className='awsui-util-mt-s'>
          {devicePiTable}
        </div>
        {/* </ColumnLayout> */}
      </FormSection>
    )
    }
  )

  const LabInfoPreview = ({labInfo}) => {
    return <>
      <div className="awsui-util-label">
        <FormattedMessage id="LAB" />
      </div>
      <div>
        {labInfo.name}
      </div>
    </>
  };

  const SystemLabPreview = ({systemLabInfo}) => {
    return <>
      <div className="awsui-util-label">
        <FormattedMessage id="DEVICE_MODEL" />
      </div>
      <div>
        {systemLabInfo.deviceModelName}
      </div>
    </>
  };

  return (
    <div className='awsui-util-pt-l'>
      <div className="awsui-util-action-stripe awsui-util-mb-n">
        <div className="awsui-util-action-stripe-title">
          <div className='awsui-util-font-size-3 awsui-util-pv-xs'>
 
            <FormattedMessage id="STEP_1" />{': '}
            <FormattedMessage id="LAB_CONFIGURATION" />
          </div>
 
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button onClick={onEditClick}>
            <FormattedMessage id="EDIT" />
          </Button>
        </div>
      </div>
      <FormSection header={<FormattedMessage id="LAB_INFO" />}>
        <div className="awsui-util-spacing-v-s">
          <div>
            { // if labInfo object is empty we can assume it is system lab use-case because that validation is already
              // happening as part of the labConfigSlice
              (Object.keys(labInfo).length === 0) ? (
                <SystemLabPreview systemLabInfo={systemLabInfo} />
              ) : (
                  <LabInfoPreview labInfo={labInfo} />
              )
            }
          </div>
        </div>
      </FormSection>
      {accountMapView}
    </div>
  )
};
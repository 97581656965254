
export const STOP_MORGAN_NOISE = {
  "action": "NOISE_PLAYBACK",
  "type": "mdx_plugin_aqt_automotive",
  "payload": {
    "operation": "NOISE_PLAYBACK",
    "action": "STOP"
  }
}

export const PFM = ["US", 
"GB", 
"UK",
"IE",
"CA",
"DE",
"AT",
"IN",
"ES",
"JP",
"FR",
"IT",
"AU",
"BR",
"MX",
"CO",
"NZ",
"NL",
"SE",
"FI",
"NO",
"DK",
"PL",
"PT"]

export const SAMPLE_LAB_CONFIG = {
  "CONNECT_TO_NETWORK" : "<specify your network>",
  "NETWORK_PASSWORD" : "<specify network password>",
  "BACK_UP_NETWORK" : "<additional network name>",
  "BACK_UP_NETWORK_PASSWORD" : "<specify network password>",
  "DESIRED_COUNTRY" : "< Germany/Deutschland>",
  "FROM_NETWORK_NAME" : "<CONNECT_TO_NETWORK at the top>",
  "FROM_NETWORK_PASSWORD" : "<NETWORK_PASSWORD at the top>",
  "TO_NETWORK_NAME" : "<alt network name>",
  "TO_NETWORK_PASSWORD" : "<alt network pwrd>",
  "TO_5GHZ_NETWORK_NAME" : "<alt network name>",
  "TO_5GHZ_NETWORK_PASSWORD" : "<alt network pwrd>",
  "Launcher 60FPS Dropoff": "<dropoff_kpi_value>",
  "Launcher cold launch from aiv playback to home fully drawn" : "<launcher_cold_launch_kpi_value_from_playback>",
  "Launcher warm launch from aiv playback to home fully drawn": "<launcher_warm_launch_kpi_value_from_playback>",
  "Prime Video cool app launch until First Frame" : "<prime_video_cool_app_launch_kpi>",
  "Prime Video warm app launch until First Frame" : "<prime_video_warm_app_launch_kpi>",
  "audio_performance" : "<audio_perf_kpi_value>",
  "video_performance" : "<video_perf_kpi_value></video_perf_kpi_value>"
}
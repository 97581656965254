import React, {useEffect} from 'react';
import {  BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uiStepSelector } from '../../../stores/newRun/acm/uiSlice';
import { updateUserAccessInfo } from '../../../stores/newRun/userAccessSlice';
import { cancelTestCreation } from '../../../stores/newRun/acm/testCreationMiddleware';
import Acm from './acm/index';
import CustomTest from './customTest/index';
import TestInit from './testSuiteSelection';
import { testInitSelector } from '../../../stores/newRun/testInitSlice';
import FTV from './FTV/index';
import { Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';


const CreateTest = (props) => {
  const { step } = useSelector(uiStepSelector);
  const { testSuite } = useSelector(testInitSelector);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateUserAccessInfo({session: props.session, environment: props.environment}));
    Util.setCommonStore(props.session, props.environment);
  }, []);
  const items = [
    {
      "text": intl.formatMessage({id: "NEW_RUN_V2"})
      //TODO : [PTI-527] Re-enable href for this item once reload bug is fixed
    },
    {
      "text": `${intl.formatMessage({id: "CREATE"})}${testSuite && testSuite.displayName ? ` ${testSuite.displayName} test`: ''}`,
      "href": "/v2/run/create"
    },
  ]

  const onCancel = () => {
    dispatch(cancelTestCreation());
    history.push("/v2/run");
  }

  const renderWizard = () => {
    if (testSuite && testSuite.displayName==='ACM') {
      console.log('Rendering ACM');
      return <Acm />
    } else if(testSuite && Util.isFireTVEnv()){
      console.log('Rendering FTV')
      return <FTV/>
    } else {
      console.log('Rendering CustomTest');
      return <CustomTest />
    }
  }

  return (
    <>
      <BreadcrumbGroup label="Breadcrumbs" items={items} />
      {
        step === -1 ? <TestInit onCancel={onCancel} /> : renderWizard()
      }
    </>
  )

}

export default CreateTest;
import { addTest, deleteTest } from "./testListSlice";
import { saveState } from "../../../services/localStorage";
import { API_STATUS, GOTO_STEP, TEST_LIST_STORAGE, URL_MAPS } from "../../../constants";
import { setTestSuite } from "../testInitSlice";
import { setId, setName, setCategoryAndScenario, setEnableEmailNotification, setLocale, setScenarioType } from "../acm/scenarioSelectionSlice";
import {
  updateFosTestSuiteModules,
  updateModuleInclusionType,
  updateIncludeModules,
  updateExcludeModules,
  getModulesAsList,
  updateCtsRunType,
} from "../componentSlice"
import { setAccountConfig, setLab, setSystemLabConfig } from "../acm/labConfigSlice";
import { updateStep } from "../acm/uiSlice";
import client from "../../../services/client";
import { getUrl } from "../../../services/utils";
import { setInitiateDeleteTest, setStatus as setExecuteStatus} from "./testListUiSlice";
import { get } from "lodash";
import { nanoid } from "@reduxjs/toolkit";
import { resetMapping, setMapping } from "../mappingSlice";
import { setTestOpt } from "../testOptsSlice";
import {Util} from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

export const addNewTestInTestList = (testInfo) => (dispatch, getState) => {
  dispatch(addTest(testInfo))
  dispatch(resetMapping())
  saveState(getState().testList.tests, TEST_LIST_STORAGE);
}

export const deleteTestFromTestList = () => (dispatch, getState) => {
  dispatch(deleteTest());
  saveState(getState().testList.tests, TEST_LIST_STORAGE);
  dispatch(setInitiateDeleteTest(false));
}

export const loadTest = (testId) => (dispatch, getState) => {
  const testList = getState().testList;
  const selectedTest = testList.tests.find(testObj => testObj.id === testId);
  if(selectedTest) {
    const {
      id,
      testSuite,
      name,
      testrailRunId,
      accountConfig,
      customizedScenarios,
      enableEmailNotification,
      labInfo,
      systemLabInfo,
      locale,
      scenarioType,
      testMapping,
      testOpts,
      fosTestSuiteModules,
      moduleInclusionType,
      includeModules,
      excludeModules,
      isDryRun,
      dryRunBundleUrl,
      dryRunTestMetadata,
      isRetryConfigured,
      retryTimes,
      retryTypes,
      shouldCreateTestrailRun,
      testrailSuite,
      deviceLocale,
      deviceTypeGroup,
      ctsRunType
    } = selectedTest;
    dispatch(setTestSuite(testSuite));
    dispatch(setName(name));
    dispatch(setId(id));
    dispatch(setLocale(locale));
    dispatch(setScenarioType(scenarioType));
    dispatch(setEnableEmailNotification(enableEmailNotification));
    dispatch(setMapping(testMapping));
    dispatch(setTestOpt(testOpts));
    dispatch(updateCtsRunType(ctsRunType));
    dispatch(updateFosTestSuiteModules(fosTestSuiteModules));
    dispatch(updateModuleInclusionType(moduleInclusionType));
    dispatch(updateIncludeModules(includeModules));
    dispatch(updateExcludeModules(excludeModules));
    if(Array.isArray(customizedScenarios)) {
      customizedScenarios.map(
        ({category, scenarios}, index) => {
          dispatch(setCategoryAndScenario({
            category,
            scenarios,
            index,
          }))
      }
      );
    }
    dispatch(setLab(labInfo));
    dispatch(setSystemLabConfig(systemLabInfo));
    Object.entries(accountConfig)
      .forEach(
        ([account, {customerIdInfo, devicePiConfig}]) => {
          dispatch(setAccountConfig({
            account,
            customerIdInfo,
            devicePiConfig,
          }))
        }
      )
    dispatch(updateStep(GOTO_STEP.PREVIEW));
  }

}


export const executeTest = () => async (dispatch, getState) => {
  const {testList} = getState();
  const selectedTest = testList.tests.filter(test => test.isSelected);
  if(selectedTest.length > 0) {

    const scenarioId = get(selectedTest, "[0].testSuite.scenarioId", null);
    dispatch(setExecuteStatus({
      status: API_STATUS.PENDING
    }));

    try {
      const experiementResponse = await client.post(getUrl(URL_MAPS.EXPERIMENT), {
        name: nanoid(),
        scenarioId: scenarioId
      });

      const promiseArr = selectedTest.map(
        (test, ind) => {
          const payload = transformPayload(test, experiementResponse.id, ind);
          return variantsApi(payload, experiementResponse.id);
        }
      )

      await Promise.all(promiseArr);
      const jobsUrl = `${getUrl(URL_MAPS.EXPERIMENT)}/${experiementResponse.id}/runs`;
      await client.post(jobsUrl, {
        launchSequentially: true,
        sendCompletionEmail: false
      });

      dispatch(setExecuteStatus({
        status: API_STATUS.SUCCESS
      }));
      dispatch(deleteTest());
      dispatch(resetMapping());
      saveState(getState().testList.tests, TEST_LIST_STORAGE);

    } catch(e) {
      dispatch(setExecuteStatus({
        status: API_STATUS.ERROR,
        error: "Failed to execute test. Retry again"
      }))
    }
  }
}

const variantsApi = async (payload, id) => {
  const vairantUrl = `${getUrl(URL_MAPS.EXPERIMENT)}/${id}/variants`;
  return await client.post(vairantUrl, payload);
}


const transformPayload = (test, expId, index) => {
  const {
      id,
      testSuite,
      name,
      testrailRunId,
      accountConfig,
      customizedScenarios,
      enableEmailNotification,
      labInfo,
      systemLabInfo,
      locale,
      scenarioType,
      testMapping,
      testOpts,
      moduleInclusionType,
      includeModules,
      excludeModules,
      isDryRun,
      dryRunBundleUrl,
      dryRunTestMetadata,
      shouldCreateTestrailRun,
      testrailSuite,
      deviceLocale,
      deviceTypeGroup,
      isRetryConfigured, 
      retryTimes,
      retryTypes,
      ctsRunType
    } = test;
  let features;
  let deviceMapping;
  let actorMapping;
  let selectedDevice;
  let opts = {
    testName: name,
    locale,
    testSuite: test.testSuite.displayName,
    executionOrder: index,
    nameSpace: 'FireTV'
  };
  
  if (get(test,'testSuite.category','').toUpperCase()!=='UDT') {
    if(Array.isArray(customizedScenarios) && customizedScenarios.length > 0) {
      features = customizedScenarios.reduce(
        (iter, {scenarios}) => {
          iter.push(...scenarios);
          return iter;
        }, []
      );
      if (Util.isFireTVEnv()) { 
        opts['testSuiteDisplayName'] = opts['testSuite']
        opts['testSuite'] = "FTV";
        var ftvPayload = JSON.parse(JSON.stringify(test.ftvPayload));
        if(Object.keys(labInfo).length === 0) {
            console.log('transformPayload...systemLab use-case');
            opts['systemLab'] = systemLabInfo;
        } else {
            console.log('transformPayload...userLab use-case');
            selectedDevice = test.testMapping.selectedDevice;
            // Updating the selected DSN in the device details.
            // Initially this device detail is autoselected with the connected host.
            // After adding parallelization changes, this will be as per user selection.
            if (!ftvPayload.hasOwnProperty('DeviceDetails')) {
                ftvPayload["DeviceDetails"] = {}
                ftvPayload["DeviceDetails"]["device_type"] = selectedDevice.dtid
            }
            ftvPayload["DeviceDetails"]["dsn"] = selectedDevice.dsn.split(',');
        }
        if (get(test,'testSuite.displayName','').toUpperCase() == 'CTS') {
            /* The below json generation is required for running CTS tests as we've
               remove TestCasesList field from device preferences payload
               Sample payload:
                "TestCasesList": {
                    "cts-tradefed": {
                        "Standard": {
                          "testName": "CTS",
                          "testCustomizations": {
                              "shards": 1
                          }
                        }
                      }
                    }
            */  
            ftvPayload["TestRunDetails"]["TestCasesList"] = {}
            ftvPayload["TestRunDetails"]["TestCasesList"] = {}
            ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"] = {}
            ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"] = {}
            ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testName"] = "CTS"
            ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"] = {}
            ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["shards"] = 1
            if (test.ctsRunType === "rerun") {
                ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["rerun"] = "last_session"
            }
            if(test.includeModules.length > 0) {
              ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["include-modules"] = getModulesAsList(test.includeModules)
            } else if(test.excludeModules.length > 0) {
              ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["exclude-modules"] = getModulesAsList(test.excludeModules)
            }
            if(test.isRetryConfigured && test.retryTypes.length>0) {
              ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["retries"]={}
              ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["retries"]["types"]=retryTypes
              ftvPayload["TestRunDetails"]["TestCasesList"]["cts-tradefed"]["Standard"]["testCustomizations"]["retries"]["times"]=parseInt(retryTimes)
            }
        }

        else if(get(test,'testSuite.displayName','').toUpperCase() == 'FOS') {
          /* The below json generation is required for running FOS tests as we've
             remove TestCasesList field from device preferences payload
             Sample payload:
              "TestCasesList": {
                  "fos_platform": {
                      "Standard": {
                        "testSuite": "fos_standard_suite",
                        "testCustomizations": {
                            "testSuite": "dev_smoke_suite"
                        }
                      }
                    }
                  }
          */

          ftvPayload["TestRunDetails"]["TestCasesList"] = {}
          ftvPayload["TestRunDetails"]["TestCasesList"]["fos_platform"] = {}
          ftvPayload["TestRunDetails"]["TestCasesList"]["fos_platform"]["Standard"] = {}
          ftvPayload["TestRunDetails"]["TestCasesList"]["fos_platform"]["Standard"]["testSuite"] = "fos_standard_suite"
          ftvPayload["TestRunDetails"]["TestCasesList"]["fos_platform"]["Standard"]["testCustomizations"] = {}
          ftvPayload["TestRunDetails"]["TestCasesList"]["fos_platform"]["Standard"]["testCustomizations"]["testSuite"] = test.fosTestSuiteModules
        }

        // Add testrail config here
        if(testrailRunId) {
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"] = {}
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"]["TestrailRunID"] = testrailRunId;
        }

        if(isDryRun){
          ftvPayload["isDryRun"]= true;
          ftvPayload["DryRunDetails"] = {};
          ftvPayload["DryRunDetails"]["bundleUrl"] = dryRunBundleUrl;
          ftvPayload["TestRunDetails"]["TestCasesList"] = dryRunTestMetadata;
        }
        else{
          ftvPayload["isDryRun"]= false
        }

        if(test.shouldCreateTestrailRun){
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"] = {}
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"]["shouldCreateTestrailRun"] = true;
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"]["filterParams"] = {};
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"]["filterParams"]["testrailSuite"] = test.testrailSuite.id;
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"]["filterParams"]["deviceLocale"] = test.deviceLocale.id;
          ftvPayload["TestRunDetails"]["TestrailUpdateInfo"]["filterParams"]["deviceTypeGroup"] = test.deviceTypeGroup.id;
        }
        else{
          ftvPayload["shouldCreateTestrailRun"] = false;
        }

        opts['testOptions'] = {features: test.ftvComponents, ftvPayload: ftvPayload}      
        actorMapping = test.testMapping.actorMapping;
        deviceMapping = test.testMapping.deviceMapping;
      } else{
        opts['testOptions'] = {features: [...new Set(features)]};
        const {deviceMap, actorMap} = getDeviceActorMapping(accountConfig);
        actorMapping = actorMap;
        deviceMapping = deviceMap;
      }
    }
  } else {
    actorMapping = test.testMapping.actorMapping;
    deviceMapping = test.testMapping.deviceMapping;
    opts['testOptions'] = test.testOptions  // TODO: OAK ENABLEMENT | clean up testOptions 
    opts['labDependenciesUri'] =  test.testOptions.labDependenciesUri;
  }

  return {
    name: nanoid(),
    order: index,
    labId: test.labInfo.id ? test.labInfo.id : null,
    experimentId: expId,
    stateMachineType: "PhysicalMDXSimulationStateMachineV2Arn",
    customMapping: {
      actorMapping,
      deviceMapping,
      noiseMapping: {}
    },
    options: opts,
  }
}

const getDeviceActorMapping = (accountConfig) => {
  const deviceMap = {};
  const actorMap = {};
  const deviceThingMap = {}
  Object.values(accountConfig)
  .forEach(
    (accountInfo) => {
      const { devicePiConfig } = accountInfo;
      devicePiConfig.forEach(
        ({ dsnInfo, pi }) => {
          const { deviceOrderId, dsn, namespace, name, customerId, buildInfo, typeId } = dsnInfo;
          const { thingName } = pi;
          deviceMap[deviceOrderId] = {
            dsn,
            namespace,
            deviceType: typeId,
            deviceName: name,
            customerId: customerId,
            buildInfo,
            amazonId: typeId,
          }
          actorMap[deviceOrderId] = thingName;
        }
      )
    }
  )
  return {deviceMap, actorMap, deviceThingMap};
}

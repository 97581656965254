/**
 * Component to render Utterance details
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, MusicConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { getInProgressTestNameDisplay } from './Functional/FunctionalLiveFeedUtil';
import './LoadingAnimation.css'


const METRICSSTATUS = { 0: 'negative', 1: 'positive' };
const IAR = 'IAR';

/**
 * Component to render Utterance details for measured uttrance such as follows:
 * 1. Question played
 * 2. DUT Heard
 * 3. DUT Response
 * Uttrance container will show RED or GREEN color based on success or failure for a particular utterance
 */
class UtteranceContainer extends React.Component {
  /**
   * Provides kay value component with colored status
   *
   * @param label Label for the key
   * @param value Message for the key
   * @param status Status of the component
   *
   * @return Returns kay value component with colored status
   */

  getKeyValueWithStatus = (label, value='Unavailable', status='info') => {
    const textStatus = 'status-' + status;
    const labelStyle = 'awsui-util-ml-l awsui-util-p-xs awsui-util-' + textStatus;
    const valueStyle = 'awsui-util-ml-l awsui-util-mb-m awsui-util-p-xs';
    return (
      <div className='awsui-util-mt-xxl'>
        <h5 className={ labelStyle }>
          <AWSUI.Icon className='awsui-util-mr-s' name={ textStatus } />
          { label }
        </h5>
        <div className={ valueStyle }>{ value }</div>
      </div>
    );
  }

  /**
   * Method to render RAR/ HAR field for Music
   * @param measureTypeCustomPayload Type of measure -> HAR or REGULAR
   * @param heard Question heard by DUT
   * @param responded Response from alexa
   * @param scoreHar HAR metric
   * @param scoreRar RAR metric
   * @return Renders HAR or RAR element based on measuretype & other input data
   */
  renderResponse = (measureTypeCustomPayload, heard, responded, scoreHar, scoreRar) => {
    if (measureTypeCustomPayload === AppConstants.MEASURE_TYPE_ASR) {
      return (
      <div>
        { this.getKeyValueWithStatus('DUT Heard - HAR', heard, scoreHar) }
        { responded && (this.getKeyValueWithStatus('DUT Responded - RAR', responded)) }
      </div>
     )
    } else {
      return (
        <div>
          { this.getKeyValueWithStatus('DUT Heard - HAR', heard) }
          { (this.getKeyValueWithStatus('DUT Responded - RAR', responded, scoreRar)) }
        </div>
       )
    }
  }

  /**
   * Gets FRR status text for Music scenario
   * @param scoreFrr FRR value from payload
   * @return Returns Success or Fail status based on FRR value
   */
  getFrrStatus = (scoreFrr) => {
    if (scoreFrr === 0) {
      return AppConstants.FAIL;
    } else if (scoreFrr === 1) {
      return AppConstants.SUCCESS;
    }
    return AppConstants.EMPTY;
  }

  /**
   * Returns if current  measureType is IAR or RSR
   * @param scenarioType Type of scenario
   * @param measureType Type of measure
   * @returns true if it's mobile scenario & measureType is IAR or RSR
   */
  isIarOrRsrMeasure = (scenarioType, measureType) => {
    if (scenarioType && measureType) {
      return scenarioType === AppConstants.TRAINED_MOBILE && measureType === IAR;
    }
    return false;
  }

  /**
   * Renders Utterance container for Acoustic
   */
  renderAcoustic = () => {
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let played = Util.getTextOrLoading(), heard = Util.getTextOrLoading(), responded = Util.getTextOrLoading();
    let scoreFrr, scoreRar, scoreIar,scoreHar, passScore, wakeUpScoreValue;
    let dutWakeupLabel = 'DUT Heard - FRR';
    let isIarOrRsrMeasure = false;
    let utteranceContainerHeader =  Util.getUtteranceContainerHeader(this.props.params.testSuite);
    let currentFunctionalTest = AppConstants.UNAVAILABLE;
    if (isDataAvailable) {
      played = this.props.params.testStats.played;
      heard = this.props.params.testStats.heard;
      responded = this.props.params.testStats.responded;
      scoreFrr = METRICSSTATUS[this.props.params.testStats.scoreFrr];
      scoreRar = METRICSSTATUS[this.props.params.testStats.scoreRar];
      scoreHar = METRICSSTATUS[this.props.params.testStats.scoreHar];
      passScore = scoreHar || scoreRar;
      wakeUpScoreValue = scoreFrr;
      currentFunctionalTest = getInProgressTestNameDisplay(this.props.params.testStats.currentFunctionalTest);
      // For Mobile Voice Training scenario, need to consider a case when utterance
      // is being played for IAR or RSR case
      if (this.props.params.testStats.scenarioType === AppConstants.TRAINED_MOBILE) {
        if (this.props.params.testStats.measureTypeCustomPayload === IAR) {
          scoreIar = METRICSSTATUS[this.props.params.testStats.scoreIar];
          dutWakeupLabel = 'DUT Wake up - IAR';
          wakeUpScoreValue = scoreIar;
        }
        isIarOrRsrMeasure =  this.isIarOrRsrMeasure(this.props.params.testStats.scenarioType,
          this.props.params.testStats.measureTypeCustomPayload);
      }
    }
    return (
      <div>
        <h4 align='center' className='awsui-util-m-l awsui-util-p-s'>{ utteranceContainerHeader }</h4>
        {
          this.props.params.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID && (
            this.getKeyValueWithStatus('Test Case In Progress', currentFunctionalTest)
          )
        }
        { this.getKeyValueWithStatus('Question Played', played) }
        { this.getKeyValueWithStatus(dutWakeupLabel, heard, wakeUpScoreValue) }
        {
          isIarOrRsrMeasure && (
            this.getKeyValueWithStatus('DUT Responded - RAR', responded)
          )
        }
        {
          !isIarOrRsrMeasure && (
            this.getKeyValueWithStatus('DUT Responded - RAR', responded, passScore)
          )
        }
      </div>
    );
  }

  /**
   * Renders Utterance container for Music
   */
  renderMusic = () => {
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let played = Util.getTextOrLoading(), heard = Util.getTextOrLoading(), responded = Util.getTextOrLoading(), uttsInjectionStatus = Util.getTextOrLoading();
    let scoreFrr, scoreRar, scoreHar;
    if (isDataAvailable) {
      let isProxyEnabled = this.props.params.testStats.isProxyEnabled;
      uttsInjectionStatus = isProxyEnabled ? "Enabled" : "Disabled";
      played = this.props.params.testStats.played;
      heard = this.props.params.testStats.heard;
      responded = this.props.params.testStats.responded;
      scoreFrr = METRICSSTATUS[this.props.params.testStats.scoreFrr];
      scoreRar = METRICSSTATUS[this.props.params.testStats.scoreRar];
      scoreHar = METRICSSTATUS[this.props.params.testStats.scoreHar];
    }
    return (
      <div>
        <h4 align='center' className='awsui-util-m-l awsui-util-p-s'>Measured Utterance</h4>
        { this.getKeyValueWithStatus('Utterance Injection Status: ', uttsInjectionStatus) }
        { this.getKeyValueWithStatus('Question Played', played) }
        { this.getKeyValueWithStatus('DUT Wake up - FRR', this.getFrrStatus(this.props.params.testStats.scoreFrr), scoreFrr) }
        { this.renderResponse(this.props.params.testStats.measureTypeCustomPayload, heard, responded, scoreHar, scoreRar) }
      </div>
    );
  }

  render() {
    if (!this.props.params.testSuite
          || this.props.params.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID
          || this.props.params.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      // Acoustic or Functional case
      return this.renderAcoustic();
    } else if (this.props.params.testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
      // Music case
      return this.renderMusic();
    }
    // Default: Return Acoustic utterance container when test suite is none of Acoustic, Functional or Music
    return this.renderAcoustic();
  }
}

UtteranceContainer.propTypes = {
  params: PropTypes.object.isRequired
};

export default UtteranceContainer;

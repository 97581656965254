/**
  * This file contains helper methods for Functional Live Run test metrics
  */
import { Actions, AppConstants, FunctionalTestCases, Store, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import React from 'react';
import AWSUI from '@amzn/awsui-components-react';

/**
  * Gets list of 'Overall Status' for all functional test cases
  * @param functionalTestCases List of functional test cases from JSON
  * @returns List of only overall status for all tests
  */
export function getOverallTestStatusList(functionalTestCases) {
  let overallStatusList = [];
  if (functionalTestCases) {
    for (let i = 0; i < functionalTestCases.length; i++) {
      if (functionalTestCases[i].hasOwnProperty('status')) {
        overallStatusList.push(functionalTestCases[i].status);
      } else {
        overallStatusList.push(AppConstants.NOT_APPLICABLE);
      }
    }
  }
  return overallStatusList;
}

/**
  * Gets the count of completed tests out of all tests running
  * @param overallStatusList List of Overall test status for all tests
  * @returns Count of tests which are completed --> Passed, Failed or Error
  */
export function getCompletedTestsNum(overallStatusList) {
  let completedTestsNum = 0;
  if (overallStatusList && overallStatusList.length > 0) {
    // Get number of test cases which are Completed --> Passed, Failed or Errored
    for (let i = 0; i < overallStatusList.length; i++) {
      if (overallStatusList[i] !== FunctionalTestCases.QUEUED
            && overallStatusList[i] !== FunctionalTestCases.IN_PROGRESS
            && overallStatusList[i] !== FunctionalTestCases.EVALUATING_RESULT) {
              completedTestsNum++;
      }
    }
  }
  return completedTestsNum;
}

/**
 * Gets list of Cloud validation statuses for all functional test cases
 * @param functionalTestCases List of functional test cases from JSON
 * @returns List of Cloud validation statuses for all tests
 */
export function getCloudValidationTestStatusList(functionalTestCases) {
  let cloudValidationStatusList = [];
  // Iterate over list of Functional test cases
  if (functionalTestCases) {
    for (let i = 0; i < functionalTestCases.length; i++) {
      // Retrieve 'testCaseData' property of current functional test case
      if (functionalTestCases[i].hasOwnProperty('testCaseData')) {
        let currentTestCaseData = functionalTestCases[i]['testCaseData'];
        // Check if current functional test case has ALEXA_CLOUD_VALIDATION type of record
        // and if it contains status information
        for (let j = 0; currentTestCaseData && j < currentTestCaseData.length; j++) {
          if (currentTestCaseData[j].hasOwnProperty('validationType')
            && currentTestCaseData[j]['validationType'] === FunctionalTestCases.ALEXA_CLOUD_VALIDATION
            && currentTestCaseData[j].hasOwnProperty('status')) {
              cloudValidationStatusList.push(currentTestCaseData[j]['status']);
          }
        }
      }
    }
  }
  return cloudValidationStatusList;
}

/**
 * Checks if Ref mic validation for any of the test is QUEUED, IN_PROGRESS or EVALUATING_RESULT
 * @param functionalTestCases List of functional test cases from JSON
 * @returns true if for any test, Ref Mic validation is pending
 */
export function isRefMicValidationPending(functionalTestCases) {
  if (functionalTestCases) {
    for (let i = 0; i < functionalTestCases.length; i++) {
      // Retrieve 'testCaseData' property of current functional test case
      if (functionalTestCases[i].hasOwnProperty('testCaseData')) {
        let currentTestCaseData = functionalTestCases[i]['testCaseData'];
        // Check if current functional test case has REF_MIC_VALIDATION or REF_MIC_SPEECH_AUDIO_VALIDATION
        // and if it contains status which is one of the PENDING categories
        for (let j = 0; currentTestCaseData && j < currentTestCaseData.length; j++) {
          if (currentTestCaseData[j].hasOwnProperty('validationType')
            && (currentTestCaseData[j]['validationType'] === FunctionalTestCases.REF_MIC_VALIDATION
              || currentTestCaseData[j]['validationType'] === FunctionalTestCases.REF_MIC_SPEECH_AUDIO_VALIDATION)
            && currentTestCaseData[j].hasOwnProperty('status')) {
              let currentRefMicStatus = currentTestCaseData[j]['status'];
              // Return true only if current Ref mic validation status is QUEUED, IN_PROGRESS or
              // EVALUATING_RESULT
              if (FunctionalTestCases.PENDING_VALIDATION_STATUS.includes(currentRefMicStatus)) {
                return true;
            }
          }
        }
      }
    }
  }
  return false;
}

/**
  * Gets completed tests string to display in test metrics tab
  * @param completedTestsNum Number of tests completed
  * @param functionalTestCases List of functional test case statuses
  * @returns Functional test stats string to display e.g (5/10)
  */
export function getCompletedTestsStats(completedTestsNum, functionalStatusList) {
  let completedTestsStats = '0';
  if (functionalStatusList && functionalStatusList.length > 0) {
    completedTestsStats = Util.countAndPercentage(completedTestsNum, functionalStatusList.length);
  }
  return completedTestsStats;
}

/**
 * Gets overall ETA for all pending & in progress functional tests
 * @param avgTestTime Average test time per test in functional run
 * @param completedTestsNum Number of tests completed -> Passed, Failed or Error
 * @param functionalTestCases List of all functional tests in a run
 * @returns Overall ETA in hh mm format for all pending & in progress tests
 */
export function getOverallEta(avgTestTime, completedTestsNum, functionalTestCases) {
  let overallEta = '0';
  if (functionalTestCases && functionalTestCases.length > 0) {
    let remainingTestsNum = functionalTestCases.length - completedTestsNum;
    let overallEtaNum = avgTestTime * remainingTestsNum * 1000;
    overallEta = Util.getTime(overallEtaNum);
  }
  return overallEta;
}

/**
  * Gets actual percentage value based on number of tests completed
  * @param overallStatusList List of Overall test status for all tests
  * @returns Percentage value based on test status list
  */
export function getActualPercentage(overallStatusList) {
  let percentage = 0;
  if (overallStatusList && overallStatusList.length > 0) {
    // Get total number of test cases
    let totalNum = overallStatusList.length;
    let completedNum = 0;
    // Get number of test cases which are Completed --> Passed, Failed or Errored
    for (let i = 0; i < overallStatusList.length; i++) {
      if (overallStatusList[i] !== FunctionalTestCases.QUEUED
            && overallStatusList[i] !== FunctionalTestCases.IN_PROGRESS
            && overallStatusList[i] !== FunctionalTestCases.EVALUATING_RESULT) {
        completedNum++;
      }
    }
    if (totalNum !== 0) {
      percentage = Util.getPercentage(completedNum, totalNum);
    }
  }
  return percentage;
}

/**
  * Gets overall test status based on status of all tests
  * @param overallStatusList List of test status for all tests
  * @returns Overall status for the entire test run --> QUEUED, IN_PROGRESS or COMPLETED
  */
export function  getOverallTestStatus(overallTestStatusList) {
  // By default, assing overall status to 'QUEUED' when run begins
  let overallStatus = FunctionalTestCases.QUEUED;
  if (overallTestStatusList && overallTestStatusList.length > 0) {
    let completedTestsNum = 0;
    let queuedTestsNum = 0;
    // Iterate over all tests to determine overall status for the run
    for (let i = 0; i < overallTestStatusList.length; i++) {
      if (FunctionalTestCases.COMPLETED_STATES.includes(overallTestStatusList[i])) {
        completedTestsNum++;
      } else if (overallTestStatusList[i] === FunctionalTestCases.QUEUED) {
        queuedTestsNum++;
      }
    }
    if (completedTestsNum === overallTestStatusList.length) {
      // If all tests are either SUCCEEDED, FAILED or ERROR, overall status is COMPLETED
      overallStatus = FunctionalTestCases.COMPLETED;
    } else if (queuedTestsNum !== overallTestStatusList.length) {
      // If not all tests are QUEUED, overall status is IN_PROGRESS
      overallStatus = FunctionalTestCases.IN_PROGRESS;
    }
  }
  return overallStatus;
}

/**
 * Gets status for specific validation type for a test case
 * @param testCaseData Test case data for a particular test case
 * @param validationType Validation type for which status needs to be retrieved
 * @returns status value for validation type passed as an input parameter
 */
export function getTestStatusForValidationType(testCaseData, validationType) {
  if (testCaseData && validationType) {
    for (let i = 0; i < testCaseData.length; i++) {
      // If validation type matches & status exists for current validation type, return it
      if (testCaseData[i].hasOwnProperty('validationType')
        && testCaseData[i].validationType === validationType
        && testCaseData[i].hasOwnProperty('status')) {
          return testCaseData[i].status;
      }
    }
  }
  // Return N/A by default if status is not available
  return AppConstants.NOT_APPLICABLE;
}

/**
 * Gets Overall validation status for a test case
 * @param testCase Test case object
 * @returns Overall validation status for test case
 */
export function getOverallValidationStatus(testCase) {
  if (testCase && testCase.hasOwnProperty('status')) {
    return testCase.status;
  }
  // Return N/A by default if status is not available
  return AppConstants.NOT_APPLICABLE;
}

/**
 * Gets failure logs for specific validation type for a test case
 * @param testCaseData Test case data for a particular test case
 * @param validationType Validation type for which failure logs needs to be retrieved
 * @returns Failure logs for validation type passed as an input parameter
 */
export function getFailureLogsForValidationType(testCaseData, validationType) {
  if (testCaseData && validationType) {
    for (let i = 0; i < testCaseData.length; i++) {
      // If validation type matches & failure logs exists for current validation type, return it
      if (testCaseData[i].hasOwnProperty('validationType')
        && testCaseData[i].validationType === validationType
        && testCaseData[i].hasOwnProperty('failureLogs')) {
          return testCaseData[i].failureLogs;
      }
    }
  }
  // Return Empty failure logs by default
  return [];
}

/**
 * Gets Overall failure logs for a test case
 * @param testCase Test case object
 * @returns Overall failure logs for test case
 */
export function getOverallFailureLogs(testCase) {
  if (testCase && testCase.hasOwnProperty('failureLogs')) {
    return testCase.failureLogs;
  }
  // Return empty array by default if failure logs are not available
  return [];
}

/**
 * Gets display friendly name for current test case in progress
 * @param testCase Test case name from payload
 * @returns Display friendly test case name
 */
export function getInProgressTestNameDisplay(testCase) {
  if (testCase) {
    if (FunctionalTestCases.FUNCTIONAL_TEST_CASES_DISPLAY.hasOwnProperty(testCase)) {
      return FunctionalTestCases.FUNCTIONAL_TEST_CASES_DISPLAY[testCase];
    }
    // If display friendly name not found, return value from payload as it is
    return testCase;
  }
  // Return Unavailable by default
  return AppConstants.UNAVAILABLE;
}

/**
 * Gets the list of utterances for specific validation type for a test case
 * @param testCaseData Test case data for a particular test case
 * @param validationType Validation type for which utterances needs to be retrieved
 * @returns List of utterances for a validation type for a test case
 */
export function getUtterancesForValidationType(testCaseData, validationType) {
  if (testCaseData && validationType) {
    for (let i = 0; i < testCaseData.length; i++) {
      // If validation type matches & list of utterances or commandsResults exists for current validation type, return it
      if (testCaseData[i].hasOwnProperty('validationType')
        && testCaseData[i].validationType === validationType) {
        if (testCaseData[i].hasOwnProperty('utterancesResult')) {
          return testCaseData[i].utterancesResult;
        } else if (testCaseData[i].hasOwnProperty('commandsResult')) {
          return testCaseData[i].commandsResult;
        }
        if (testCaseData[i].hasOwnProperty('utteranceValidationResults')) {
          return testCaseData[i].utteranceValidationResults;
        } else if (testCaseData[i].hasOwnProperty('deviceValidationResults')) {
          return testCaseData[i].deviceValidationResults;
        }
      }
    }
  }
  // Return empty list by default
  return [];
}

/** Gets Display value for test status based on status retrieved from JSON
 * @param jobStatus Job Status retrieved from JSON
 * @return Test status to display
 */
export function getTestStatusValue(jobStatus) {
  if (FunctionalTestCases.TEST_STATUS_VALUES.hasOwnProperty(jobStatus)) {
    return FunctionalTestCases.TEST_STATUS_VALUES[jobStatus];
  }
  // Return Queued as default status
  return AppConstants.NOT_APPLICABLE;
}

/**
 * Gets status value for particular utterance -> SUCCEEDED, FAILED, IN_PROGRESS, QUEUED or ERROR
 * @param text Text from utterance -> Question Played, DUT Heard or DUT Responded
 * @param status Utterance status
 * @param score FRR or RAR score, if any
 * @param type Type of Utterance
 * @param utteranceValidationType Type of validation used for utternce -> INTENT vs REGEX
 * @return Display friendly utterance based on it's status
*/
 export function getUtteranceStatusValue(text, status, score, type, utteranceValidationType) {
  if (type === FunctionalTestCases.QUESTION_PLAYED_OVERALL) {
     return FunctionalTestCases.INFO_OVERALL;
  }
  if (type === FunctionalTestCases.QUESTION_PLAYED) {
      return FunctionalTestCases.INFO;
  }
  if (status && (FunctionalTestCases.UTTERANCE_INCOMPLETE_STATUS.includes(status)
        || status === FunctionalTestCases.IN_PROGRESS)) {
      return status;
  }

  if (isTextEmpty(text)) {
    return FunctionalTestCases.FAILED;
  }

  // For utterances with REGEX type validation, if utterance status == SUCCEEDED, don't check for  FRR & RAR scores
  // For utterances with INTENT_TYPE_VALIDATION, if response contains "(Action Response was Accurate)", show as
  // Success otherwise Failure
  if ((status && (status === FunctionalTestCases.SUCCEEDED || status === FunctionalTestCases.COMPLETED || status === FunctionalTestCases.SUCCESS))
        || ( utteranceValidationType !== FunctionalTestCases.UTTERANCE_REGEX_TYPE_VALIDATION
              && type === FunctionalTestCases.DUT_RESPONDED
              && text === FunctionalTestCases.INTENT_TYPE_SUCCESS_RESPONSE)) {
    return FunctionalTestCases.SUCCEEDED;
  }

  // For utterances with INTENT_TYPE_VALIDATION, if dutResponded is NOT "(Action Response was Accurate)", display it
  // as Failure
  if (type === FunctionalTestCases.DUT_RESPONDED
        && utteranceValidationType !== FunctionalTestCases.UTTERANCE_REGEX_TYPE_VALIDATION
        && text !== FunctionalTestCases.INTENT_TYPE_SUCCESS_RESPONSE) {
    return FunctionalTestCases.FAILED;
  }

  if (FunctionalTestCases.UTTERANCE_METRIC_MAPPING.hasOwnProperty(score)) {
      return FunctionalTestCases.UTTERANCE_METRIC_MAPPING[score];
  }
  if (text === AppConstants.UNAVAILABLE) {
      return FunctionalTestCases.FAILED;
  }
  // Return Queued as default status
  return FunctionalTestCases.FAILED;
 }

/**
 * Renders animated component for "In Progress" utterance/ test case
 * @returns "Running" text which will flash every second
 */
export function getInProgressStatus() {
  return (
    <div className='awsui-util-label'>
      <span className='ellipsis-anim-with-color'>
        <span>&bull;</span><span>&bull;</span><span>&bull;</span><span>&nbsp;</span><span>R</span>
          <span>u</span><span>n</span><span>n</span>
          <span>i</span><span>n</span><span>g</span>
        </span>
    </div>
  );
}

/**
 * Checks whether text to render is empty or not
 * @param text Text to render
 * @return true if text to render is empty or unavailable or undefined
 */
function isTextEmpty(text) {
  return text === undefined || text === AppConstants.EMPTY || text === AppConstants.UNAVAILABLE;
}

/**
 * Gets text to render in a column in utterance details table
 * @param text Text from payload/ custom payload
 * @param utteranceStatusValue Status value for current utterance
 * @param type Type of utterance -> Question played, DUT heard or DUT responded
 * @param utteranceValidationType Validation type for utterance -> INTENT vs REGEX
 * @returns Text to render in a particular column in utternace details table
 */
function getTextToRenderInUtteranceFeed(text, utteranceStatusValue, type, utteranceValidationType) {
  let textToRender = text;
  // If utterance is Queued, return Queued as text to render
  if (FunctionalTestCases.UTTERANCE_INCOMPLETE_STATUS.includes(utteranceStatusValue)
        && FunctionalTestCases.UTTERANCE_DETAILS_DISPLAY_TEXT.hasOwnProperty(utteranceStatusValue)) {
    return FunctionalTestCases.UTTERANCE_DETAILS_DISPLAY_TEXT[utteranceStatusValue];
  }
  return textToRender;
}

/**
  * Gets display friendly utterance
  * @param text Utterance text to be displayed
  * @param status Utterance status
  * @param score FRR or RAR score, if any -> 0 or 1
  * @param type Type of Utterance -> Question played, DUT heard or DUT responded
  * @param utteranceValidationType Validation type used for utternace -> INTENT vs REGEX
  * @return Display friendly utterance based on it's status
*/
export function getUtteranceStatus(text, status, score, type, utteranceValidationType) {
 if (text === AppConstants.RETRIEVING) {
    return Util.getTextOrLoading();
  }
  if (text === AppConstants.NOT_APPLICABLE) {
    return text;
  }
  let utteranceStatusValue = getUtteranceStatusValue(text, status, score, type, utteranceValidationType);
  let textToRender = getTextToRenderInUtteranceFeed(text, utteranceStatusValue, type, utteranceValidationType);

  let shouldRenderStatusIcon = utteranceStatusValue !== FunctionalTestCases.INFO;
   return (
     <AWSUI.ColumnLayout columns={ 4 } borders='none'>
       <div data-awsui-column-layout-root='true'>
         {
           utteranceStatusValue !== FunctionalTestCases.IN_PROGRESS ? (
           <div className={ FunctionalTestCases.UTTERANCE_STATUS_MAP[utteranceStatusValue].testStatusClass }>
             {
               shouldRenderStatusIcon && (
               <AWSUI.Icon name={ FunctionalTestCases.UTTERANCE_STATUS_MAP[utteranceStatusValue].testStatusIcon }
                 variant={ FunctionalTestCases.UTTERANCE_STATUS_MAP[utteranceStatusValue].variant }/>
                )
              }
               <span className = { FunctionalTestCases.UTTERANCE_STATUS_MAP[utteranceStatusValue].testStatusTextStyle }>
                 { textToRender }
               </span>
           </div>
           ) : (
             <div>
               { getInProgressStatus() }
             </div>
           )
           }
           <div>
        </div>
      </div>
    </AWSUI.ColumnLayout>
  );
}

/**
 * Gets status at category level e.g. status for Volume, Alarm, Notifications categories
 * @param jobStatus Category level status from JSON
 * @returns Component displaying category level status
 */
export function getStatusForCategory(jobStatus) {
  let testStatusValue = getTestStatusValue(jobStatus);
  return (
    <div>
    {
      (jobStatus !== FunctionalTestCases.IN_PROGRESS && jobStatus !== FunctionalTestCases.EVALUATING_RESULT) ? (
        <div className={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusClass }>
          <AWSUI.Icon name={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusIcon }
            variant={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].variant }/>
          <span className = { FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusTextStyle }>
              { testStatusValue }
          </span>
         </div>
        ) :
        (
          <div>
          { getInProgressStatus() }
          </div>
        )
      }
    </div>
  );
}

/**
 * Sets value for expandable section
 * @param id ID of the expandable section
 * @param event Event generated when expandable section is expanded/ collapsed
 * @param expandedSections Current list of expandable sections
 * @returns Updated list of expandable sections
 */
export function setValueForExpandedSection(id, event, expandedSections) {
  if (event.detail.expanded && !expandedSections.includes(id)) {
    expandedSections.push(id);
  } else {
    if (expandedSections.includes(id)) {
      let elementIndex = expandedSections.indexOf(id);
      if (elementIndex > -1) {
        expandedSections.splice(elementIndex, 1);
      }
    }
  }
  return expandedSections;
}

/**
 * Gets the content for Expandable section
 * @param header Header of expandable section -> Can be Test case or Category name
 * @param status Status of test case or category
 * @param isSectionExpanded Whether section is expanded or not
 * @returns Content to render in expandable section in Columnlayout format
 */
export function getExpandableSectionContent(header, status, isSectionExpanded) {
  return (
    <AWSUI.ColumnLayout columns={ 4 } borders='none'>
    <div className='awsui-util-mb-xs' data-awsui-column-layout-root='true'>
      <h3 className='test-category-text-style'>
      {
        header
      }
      </h3>
      <div></div>
      <div></div>
      {
        !isSectionExpanded && getStatusForCategory(status)
      }
    </div>
  </AWSUI.ColumnLayout>
  );
}

/**
  * Get value for current utterance feed, if not available retrieve from state store
  * @param key Key for state store
  * @param questionPlayed Question played for current utterance from payload
  * @param dutHeard DUT heard for current utterance from payload
  * @param dutResponded Alexa cloud response for current utterance from payload
  * @param scoreFrr FRR score for current utterance from payload
  * @param scoreRar RAR score for current utterance from payload
  * @param status Status of the utterance from payload
  * @param utteranceAttribute Type of utterance -> NORMAL or MULTI TURN
  * @returns Map containing data for current utterance being played
  */
export function getValueForUtteranceFeed(key, questionPlayed, dutHeard, dutResponded,
    scoreFrr, scoreRar, status, utteranceAttribute) {
  // Display the utterance only if it's supposed to be displayed in UI
  if (questionPlayed && utteranceAttribute !== FunctionalTestCases.UTTERANCE_IGNORE) {
    dutHeard = dutHeard ? dutHeard : AppConstants.UNAVAILABLE;
    dutResponded = dutResponded ? dutResponded : AppConstants.UNAVAILABLE;
    scoreFrr = scoreFrr ? scoreFrr : 0;
    scoreRar = scoreRar ? scoreRar : 0;
    status = status ? status : FunctionalTestCases.FAILED;
    let currentUtteranceData = {
      'questionPlayed': questionPlayed,
      'dutHeard': dutHeard,
      'dutResponded': dutResponded,
      'scoreFrr': scoreFrr,
      'scoreRar': scoreRar,
      'status': status
    }
    Store.stateStore.dispatch(Actions.saveState(key, currentUtteranceData));
    return currentUtteranceData;
  }
  // If there's no data for current utterance, render previous utterance till we have data
  // available
  if (Store.stateStore.getState().hasOwnProperty(key)) {
    return Store.stateStore.getState()[key];
  }
  // Return loading animation (...) by default
  return {};
}

/**
 * Gets Test Metrics to render for Functional test case
 * @param completedTestsStats Statistics of Completed tests
 * @param currentFunctionalTest Name of Current Functional test case
 * @param startTime Start time of the test case
 * @param questionPlayed Question Played (Current Utterance)
 * @param elapsedTime Time elapsed since test case was kicked off
 * @param dutHeard Asr text heard by DUT
 * @param eta Estimated time remaining
 * @param dutResponded Response from Alexa cloud
 * @param scoreFrr FRR score -> 0 or 1
 * @param scoreRar RAR score -> 0 or 1
 * @param utteranceValidationType Type of validation used for utterance - INTENT vs REGEX
 * @param utteranceStatus Status of current utterance from payload
 * @returns Test metrics in tabular format containing all above data
 */
export function getFunctionalTestMetrics(completedTestsStats, currentFunctionalTest, startTime, questionPlayed,
  elapsedTime, dutHeard, eta, dutResponded, scoreFrr, scoreRar, utteranceValidationType, utteranceStatus) {
  return (
    <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
      <AWSUI.ColumnLayout columns={ 4 } borders='all'>
        <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
          { Util.renderLabelElement('Test Completion Count ','(Completed / Total number of Tests)') }
          { Util.getTextOrLoading(completedTestsStats) }
          { Util.renderLabelElement('Current Test Case ', AppConstants.EMPTY) }
          { Util.getTextOrLoading(currentFunctionalTest) }
          { Util.renderLabelElement('Start Time', AppConstants.EMPTY) }
          { Util.getTextOrLoading(startTime) }
          { Util.renderLabelElement('Question Played ', '(Current Utterance)') }
          { getUtteranceStatus(questionPlayed, utteranceStatus, 0, FunctionalTestCases.QUESTION_PLAYED_OVERALL,
              utteranceValidationType) }
          { Util.renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
          { Util.getTextOrLoading(elapsedTime) }
          { Util.renderLabelElement('DUT Heard ', '(Current Utterance)') }
          { getUtteranceStatus(dutHeard, utteranceStatus, scoreFrr, FunctionalTestCases.DUT_HEARD,
              utteranceValidationType) }
          { Util.renderLabelElement('Overall ETA ', '(Total Estimated Time Remaining)') }
          { Util.getTextOrLoading(eta) }
          { Util.renderLabelElement('Alexa Cloud Response ', '(Current Utterance)') }
          { getUtteranceStatus(dutResponded, utteranceStatus, scoreRar, FunctionalTestCases.DUT_RESPONDED,
              utteranceValidationType) }
        </div>
      </AWSUI.ColumnLayout>
    </div>
  );
}

const ApiConstants = {
  THINGS: 'things',
  LABS_URL: '/api/labs',
  JOBS_URL: '/api/jobs',
  JOBS_V2_URL: '/api/v2/jobs',
  TESTSUITES_URL: '/api/testsuites',
  JOB_DEFINITION_VERSION_SETS_URL: '/api/jobdefinitionversionsets',
  DUT_URL: '/api/deviceundertests',
  SIMPLIFIED_JOB_DEFINITIONS_URL: '/api/simplifiedjobdefinitions',
  THING_SETUP_URL: '/api/things/thingId/setup',
  CID_ALLOWLIST_URL: '/api/customeridwhitelist',
  ALLOWLISTED_SCENARIOS_URL: '/api/scenarios',
  RELEASE_NOTES_URL: '/api/releasenotes',
  EXPERIMENT_V1_URL: '/api/experiments',
  EXPERIMENT_V2_URL: '/api/v2/experiments',
  MQTT_MESSAGE_URL: '/api/mqtt/message',
  CUSTOM_SCENARIOS_URL: '/api/customscenarios',
  BANNER_MESSAGES_URL: '/api/bannerMessages',
  DEVICE_PREFERENCES_URL : '/api/preference/deviceType',
  DSN_DEVICE_DATA_URL : '/api/deviceFarm/FireTV/dut'
}

export default ApiConstants;

export const TEST_PROGRESS_COLUMNS = [{
 id: 'in_progress',
 header: () => 'In Progress',
 cell: item => <div>{item.in_progress ? item.in_progress[0] : ""}</div>,
 minWidth: '200px',
 allowLineWrap: true
}, {
 id: 'queued',
 header: () => 'Queued',
 cell: item => ( item.queued ),
 minWidth: '100px',
 allowLineWrap: false
}, {
 id: 'total',
 header: () => 'Total',
 cell: item => ( item.total ),
 minWidth: '200px',
 allowLineWrap: true
},
{
 id: 'passed',
 header: () => 'Passed',
 cell: item => ( item.passed ),
 minWidth: '200px',
 allowLineWrap: true
},
{
 id: 'failed',
 header: () => 'Failed',
 cell: item => ( item.failed ),
 minWidth: '200px',
 allowLineWrap: true
},
{
 id: 'skipped',
 header: () => 'Skipped',
 cell: item => ( item.skipped ),
 minWidth: '200px',
 allowLineWrap: true
},
{
 id: 'eta',
 header: () => 'ETA',
 cell: item => ( item.eta ),
 minWidth: '200px',
 allowLineWrap: true
}
];
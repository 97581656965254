import { FormSection, ColumnLayout, Button } from '@amzn/awsui-components-react';
import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TESTID_DEVICE_MAP, UNNAMED_DEVICE } from '../../../../constants';
import { labConfigSelector } from '../../../../stores/newRun/acm/labConfigSlice';
import { SCENARIO_TYPE, testSuiteConfigSelector } from '../../../../stores/newRun/acm/scenarioSelectionSlice';
import { updateStep } from '../../../../stores/newRun/acm/uiSlice';
import { testInitSelector } from '../../../../stores/newRun/testInitSlice';
import { testMappingSelector } from "../../../../stores/newRun/mappingSlice";

export default () => {

  return <>
   <TestSuiteConfig />
   <LabConfig />
  </>
}


const TestSuiteConfig = () => {
  const {scenarioType, locale, name, enableEmailNotification, customizedScenarios} = useSelector(testSuiteConfigSelector);
  const dispatch = useDispatch();
  const onEditClick = () => {
    dispatch(updateStep(0))
  }
  let customScenarioView = null;
  if(scenarioType === SCENARIO_TYPE.CUSTOM) {
    const deviceMapTable = customizedScenarios.reduce(
      (view, {category, scenarios}, ind) => {
        view.push(<div key={category}>{category}</div>);
        view.push(<div key={ind}>{scenarios.map((scenarioId)=> TESTID_DEVICE_MAP[scenarioId].scenario).join(', ')}</div>);
        return view;
      }, []
    )

    customScenarioView = <ColumnLayout columns={2} borders="all">
      <div data-awsui-column-layout-root="true">
        <div className='awsui-util-label'>
          <FormattedMessage id="CATEGORY" />
        </div>
        <div className='awsui-util-label'>
          <FormattedMessage id="SCENARIO" />
        </div>
        {deviceMapTable}
      </div>
    </ColumnLayout>
  }

  return (
    <>
      <div>
        <div className="awsui-util-action-stripe awsui-util-mb-n">
          <div className="awsui-util-action-stripe-title">
            <div className='awsui-util-font-size-3 awsui-util-pv-xs'>

              <FormattedMessage id="STEP_1" />{': '}
              <FormattedMessage id="TEST_SUITE" />
            </div>
          </div>
          <div className="awsui-util-action-stripe-group">
            <Button onClick={onEditClick}>
              <FormattedMessage id="EDIT" />
            </Button>
          </div>
        </div>
        <FormSection header={<FormattedMessage id="GENERAL_INFO" />}>
          <ColumnLayout columns={2} borders="vertical">
            <div data-awsui-column-layout-root="true">
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="TEST_NAME" />
                </div>
                <div>
                  {name}
                </div>
              </div>
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="LOCALE" />
                </div>
                <div>
                  {locale}
                </div>
              </div>
              <div>
                <div className="awsui-util-label">
                  <FormattedMessage id="ENABLE_EMAIL_NOTIFICATION" />
                </div>
                <div>
                  {`${!!enableEmailNotification}`}
                </div>
              </div>
            </div>
          </ColumnLayout>
        </FormSection>
        <FormSection header={<FormattedMessage id="SCENARIO_SELECTION" />}>
          <div className="awsui-util-spacing-v-s">
            <div>
              <div className="awsui-util-label">
                <FormattedMessage id="SCENARIO" />
              </div>
              <div>
                {scenarioType}
              </div>
            </div>
            <div className='awsui-util-mt-s'>
              {customScenarioView}
            </div>
          </div>
        </FormSection>
      </div>
    </>
  )
}


const LabConfig = () => {
  const {labInfo, accountConfig} = useSelector(labConfigSelector);
  const { testSuite } = useSelector(testInitSelector);
  const { mapping } = useSelector(testMappingSelector);

  const featureFileMapping = testSuite && testSuite.mapping;
  
  

  const featureFileDeviceMapping = mapping.filter(m => m.configType==='dut').map(
    _mapping => {
      return <div>
      <div className="awsui-util-spacing-v-s">
        <div>
          <div className="awsui-util-label">Name</div>
          <div>{_mapping.name.toUpperCase()}</div>
        </div>
        <div>
          <div className="awsui-util-label">Customer Id</div>
          <div>{_mapping.customerId}</div>
        </div>
        <div>
            <div className="awsui-util-label">Device Name</div>
            <div>{_mapping.dsnMeta.name}</div>
          </div>
          <div>
            <div className="awsui-util-label">Device Serial No.</div>
            <div>{_mapping.dsn}</div>
          </div>
      </div>
    </div>
    }
  );
  const featureFileThingMapping = mapping.filter(m => m.configType==='actor').map(
    _mapping => {
      return <div>
      <div className="awsui-util-spacing-v-s">
        <div>
          <div className="awsui-util-label">Name</div>
          <div>{_mapping.name.toUpperCase()}</div>
        </div>
        <div>
          <div className="awsui-util-label">Thing</div>
          <div>{_mapping.piName}</div>
        </div>
      </div>
    </div>
    }
  );


  const accounts = Object.keys(accountConfig);
  const dispatch = useDispatch();
  const onEditClick = () => {
    dispatch(updateStep(1))
  }

  const accountMapView = accounts.map(
    account => {
    const { customerIdInfo, devicePiConfig } =  accountConfig[account];
    let devicePiMapView;
    if(Array.isArray(devicePiConfig)) {

      devicePiMapView = devicePiConfig.reduce(
        (view, {dsnInfo, pi, firmware}) => {
          view.push(<div key={nanoid()}>{dsnInfo.name || UNNAMED_DEVICE}</div>);
          view.push(<div key={nanoid()}>{pi.name}</div>);
          view.push(<div key={nanoid()}>{firmware}</div>);
          return view;
        }, []
      )
    }

    const devicePiTable = <ColumnLayout columns={3} borders="all">
      <div data-awsui-column-layout-root="true">
        <div className='awsui-util-label'>
          <FormattedMessage id="DEVICE" />
        </div>
        <div className='awsui-util-label'>
          <FormattedMessage id="PI" />
        </div>
        <div className='awsui-util-label'>
          <FormattedMessage id="FIRMWARE" />
        </div>
        {devicePiMapView}
      </div>
    </ColumnLayout>

    return (
      <FormSection header={<><FormattedMessage id="ACCOUNT" /> - {account}</>}>
        {/* <ColumnLayout> */}
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">
              <FormattedMessage id="CUSTOMER_ID" />
            </div>
            <div>
              {customerIdInfo.email}
            </div>
          </div>
        </div>
        <div className='awsui-util-mt-s'>
          {devicePiTable}
        </div>
        {/* </ColumnLayout> */}
      </FormSection>
    )
    }
  )
  return (
    <div className="awsui-util-pt-l">
      <div className="awsui-util-action-stripe awsui-util-mb-n">
        <div className="awsui-util-action-stripe-title">
          <div className="awsui-util-font-size-3 awsui-util-pv-xs">
            <FormattedMessage id="STEP_2" />
            {": "}
            <FormattedMessage id="LAB_CONFIGURATION" />
          </div>
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button onClick={onEditClick}>
            <FormattedMessage id="EDIT" />
          </Button>
        </div>
      </div>
      <FormSection header={<FormattedMessage id="LAB_INFO" />}>
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">
              <FormattedMessage id="LAB" />
            </div>
            <div>{labInfo.name}</div>
          </div>
        </div>
      </FormSection>
      <FormSection header={<FormattedMessage id="FEATURE_FILE_MAPPING" />}>
        <ColumnLayout columns={3} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            {featureFileDeviceMapping}
            {featureFileThingMapping}
          </div>
        </ColumnLayout>
      </FormSection>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';

class CustomCalendarInput extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: 600 }}>
        <AWSUI.Button
          text={ this.props.value }
          onClick={ this.props.onClick }
          icon='calendar' >
        </AWSUI.Button>
      </div>
    );
  }
}

CustomCalendarInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default CustomCalendarInput;
/**
 * Root component which displays Live Run feed
 */
import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import FeedsContainer from './FeedsContainer';
import UtteranceContainer from './UtteranceContainer';
import PropTypes from 'prop-types';
import { AppConstants, Store } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import { isEqual } from 'lodash';
import Security from './Security';

const orientCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(242, 243, 243, 0.9)'
};

const JOB_TERMINATED_HEADER = 'Test has been Terminated';
const JOB_TERMINATED_MESSAGE = 'It seems Android device is either offline or not connected.'
  + ' Please reconnect the device and resubmit the test from the New Run page.';

/**
 * Root component which displays following feeds:
 * 1. Test Details
 * 2. Test Metrics
 * 3. Utterance details
 * 4. Orientation diagram
 */
class LiveFeed extends React.Component {
  state = {
    labCanvasWidth: 0,
    testStats: {},
    testStatus: AppConstants.RETRIEVING,
    utteranceFeeds: {},
    noiseFeeds: {},
    elapsedSeconds: 0
  };

  componentDidMount() {
    // Update dimensions and add a window resize listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.interval = setInterval(() => this.setState({ elapsedSeconds: this.state.elapsedSeconds + 1 }), 1000);
  }

  componentWillMount() {
    this.unsubscribeLive = Store.liveFeedStore.subscribe(this.updateHandler);
  }

  componentWillUnmount() {
    // Remove the window resize listener
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    this.unsubscribeLive();
    clearInterval(this.interval);
  }

  updateDimensions() {
    if (this.liveFeed) {
      let canvasWidth = Math.floor(this.liveFeed.clientWidth / 2.1);
      if (this.state.labCanvasWidth !== canvasWidth) {
        this.setState({ labCanvasWidth: canvasWidth });
      }
    }
  }

  // Subscribe for Store.liveFeedStore to listen to any updates whenever custom payload changes
  updateHandler = () => {
    const { liveFeed } = Store.liveFeedStore.getState();
    let testStatsFromStore = liveFeed[this.props.params.labJobId]['testStats'];
    let testStatusFromStore = liveFeed[this.props.params.labJobId]['testStatus'];
    let utteranceFeedsFromStore = liveFeed[this.props.params.labJobId]['utteranceFeeds'];
    let noiseFeedsFromStore = liveFeed[this.props.params.labJobId]['noiseFeeds'];
    let commonFeedsFromStore = liveFeed[this.props.params.labJobId]['commonFeeds'];
    if (!isEqual(this.props.params.testStats, testStatsFromStore)) {
      this.setState({
        testStats: testStatsFromStore
      });
    }
    if (!isEqual(this.props.params.testStatus, testStatusFromStore)) {
      this.setState({
        testStatus: testStatusFromStore
      });
    }
    if (!isEqual(this.props.params.utteranceFeeds, utteranceFeedsFromStore)) {
      this.setState({
        utteranceFeeds: utteranceFeedsFromStore
      });
    }
    if (!isEqual(this.props.params.noiseFeeds, noiseFeedsFromStore)) {
      this.setState({
        noiseFeeds: noiseFeedsFromStore
      });
    }
    if (!isEqual(this.props.params.commonFeeds, commonFeedsFromStore)) {
      this.setState({
        commonFeeds: commonFeedsFromStore
      });
    }
  }

  /**
   * Gets static Orientation diagram to display based on test suite or scenario type
   * @returns Path to orientation diagram image based on testSuite/ scenarioType
   */
  getOrientationImageToDisplay = () => {
    let testSuite = this.props.params.testDetails.testSuite;
    let scenarioType = this.props.params.testDetails.scenarioType;
    // First loop up based on test suite
    if (testSuite
          && AppConstants.ORIENTATION_RESOURCE_MAP.hasOwnProperty(testSuite)) {
      return AppConstants.ORIENTATION_RESOURCE_MAP[testSuite];
    }
    // If test suite is not found in lookup table, search based on scenario type
    if (scenarioType
          && AppConstants.ORIENTATION_RESOURCE_MAP.hasOwnProperty(scenarioType)) {
      return AppConstants.ORIENTATION_RESOURCE_MAP[scenarioType];
    }
    return AppConstants.EMPTY;
  }

  /**
   * Method to decide when to render orientation diagram & utterance details box
   * Rendered ONLY when:
   *    - Either data from back-end is available or it's one of the FAR test types
   *    - AND scenarioType is NOT voice enrollment robustness (AC_VOICE_ENROLLMENT_ROBUSTNESS)
   * @returns true or false to decide whether to render orientation diagram & utterance details
   */
  shouldRenderOrientationDiagramUtteranceDetails = () => {
    return (((Object.keys(this.state.testStats).length !== 0 && this.state.testStats.num))
      && this.props.params.testDetails.scenarioType !== AppConstants.VOICE_ROBUSTNESS);
  }

  /**
   * Method to determine whether Job should be terminated - currently used only by Mobile Voice Training scenario
   * @param androidDeviceIsConnected Status of Android device connected to the Pi
   * @param mobileVoiceTrainingIsManual Whether voice training is Manual or Automated
   * @returns True or false based on Job should be terminated or not
   */
  shouldTerminateLabJob = (androidDeviceIsConnected, mobileVoiceTrainingIsManual) => {
    // Both keys needs to have some value (true or false) to determine whether job needs to be terminated
    if (androidDeviceIsConnected === undefined || mobileVoiceTrainingIsManual === undefined) {
      return false;
    }
    // Only if training is automated & device is not connected/ offline, terminate the Job
    return !mobileVoiceTrainingIsManual && !androidDeviceIsConnected;
  }

  /**
   * Method to disable Terminate button
   * @param labJobId Job ID of the current running job
   */
  disableTerminateButton = (labJobId) => {
    // Disable the terminate button since job has already been terminated
    let terminateButton = document.getElementById(labJobId + '_terminateButton');
    if (terminateButton) {
      terminateButton.style.visibility = "hidden";
    }
  }

  /**
   * Method to display an error message when Job is terminated
   * @param labJobId Job ID of the current running job
   * @param header Header of the error message
   * @param message Content of the error message
   * @returns Error message
  */
  getTerminateMessage(labJobId, header, message) {
    if (message && message.length > 0) {
      return (
        <div>
          <div align='center' className='awsui-util-mt-xxl'>
            <div className='nowrap-style'>
                <h2 className='job-terminate-style'>
                <AWSUI.Icon name='status-negative' variant='error'/>
                  <span className='awsui-util-ml-xs'>
                  { header }
                  </span>
                </h2>
            </div>
            <div className='awsui-util-mt-s'>
              <span className='job-terminate-style'>
                { message }
              </span>
            </div>
          </div>
          <div className='loading-data-style'>
          </div>
        </div>
      )
    }
    return (
      <div></div>
    )
  }

  renderUtternaceDetails() {
    const { testDetails: { testSuite, scenarioType }, labJobId  } = this.props.params;

    /* Utterance details are not needed for below testSuites */
    const testSuitesWithNoUtteranceDetails = [
      AppConstants.FUNCTIONAL_SCENARIO_ID,
      AppConstants.SECURITY_SCENARIO_ID,
    ];

    // Don't show utterance details container
    if (testSuitesWithNoUtteranceDetails.includes(testSuite)) return;

    let orientationImageToDisplay = this.getOrientationImageToDisplay();
    let orientationImagePath = 'Resources/images/' + orientationImageToDisplay;

    return (
      <AWSUI.ColumnLayout className='awsui-util-no-gutters' columns={ 2 }>
        <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-m-n'>
        {
          this.shouldRenderOrientationDiagramUtteranceDetails() && (
          <UtteranceContainer
            params={{
              labJobId: labJobId,
              testSuite: testSuite,
              testStats: this.state.testStats
            }}
          />
          )
        }
        </div>
          <div className='awsui-util-container awsui-util-no-gutters awsui-util-m-n labcanvas-div-style'
            style={ orientCenter }
          >
            {
              this.shouldRenderOrientationDiagramUtteranceDetails() && (
                <div>
                  <img className='labcanvas-style' src={ orientationImagePath } alt='' />
                </div>
              )
            }
          </div>
        </div>
      </AWSUI.ColumnLayout>
    );
  }

  render() {
    const { testSuite, testType, scenarioType } = this.props.params.testDetails;
    let shouldTerminateLabJob = this.shouldTerminateLabJob(
      this.state.testStats.androidDeviceIsConnected,
      this.props.params.testDetails.customOptions.mobileVoiceTrainingIsManual
    );

    // For automated mobile voice training scenario, if android device is not connected/ online, job should be terminated
    if (scenarioType === AppConstants.TRAINED_MOBILE && shouldTerminateLabJob) {
      this.disableTerminateButton(this.props.params.labJobId);
      return (
        <div>
          {
            this.getTerminateMessage(
              this.props.params.labJobId,
              JOB_TERMINATED_HEADER,
              JOB_TERMINATED_MESSAGE
            )
          }
        </div>
      );
    }

    return (
      <div ref={ node => { this.liveFeed = node; } }>
        <FeedsContainer
          params={{
            labJobId: this.props.params.labJobId,
            testDetails: this.props.params.testDetails,
            testStats: this.state.testStats,
            testStatus: this.state.testStatus,
            elapsedSeconds: this.state.elapsedSeconds,
            noiseFeeds: this.state.noiseFeeds
          }}
        />
        {this.renderUtternaceDetails()}
        {
          testSuite === AppConstants.SECURITY_SCENARIO_ID && (
            <Security
              testStatus={this.state.testStatus}
              testStats={this.state.testStats}
              testDetails={this.props.params.testDetails}
            />
          )
        }
      </div>
    );
  }
}

LiveFeed.propTypes = {
  params: PropTypes.object.isRequired
};

export default LiveFeed;

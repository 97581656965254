import React from 'react';
import TestList from './pages/newRun/testList';
import CreateTest from './pages/newRun/createTest';
import localeStrings from './assets/translations/en.json';
import { IntlProvider } from "react-intl";
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import store from './stores/store';
import { Provider } from 'react-redux';
import { useHistory } from "react-router-dom";
const App = (props) => {
  const history = useHistory();
  return (
    <Router history={history}>
    <IntlProvider locale='en' messages={localeStrings} >
      <Provider store={store}>
          <Switch>
            <Route path='/v2/run/create' exact>
              <CreateTest session={props.session} environment={props.environment} />
            </Route>
            <Route path='/v2/run'>
              <TestList session={props.session} environment={props.environment} />
            </Route>
          </Switch>
      </Provider>
    </IntlProvider>
    </Router>
  )
}

export default App;

/**
 * Component to render progress bar
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import './LiveFeedProgressBar.css';
import ReactDOM from 'react-dom';
import { resumeJob } from '../../containers/LiveRun/controller';
import AutoSyncProgressBar from './AutoSync/AutoSyncProgressBar';

const PAUSED_FOR_TRAINING_MANUAL_MESSAGE = 'Test has been paused for the training, click on the Resume button '
    + ' to continue the test once training is completed';
const PAUSED_FOR_TRAINING_AUTOMATED_MESSAGE = 'Test is paused as Mobile Voice training is in progress ...'
    + ' It may take a few minutes to complete the training.';

// Progress bar filler component to display progress in green color
const Filler = (props) => {
  let fillerId = props.labJobId ? 'filler_' + props.labJobId : 'filler';
  return ( 
    <div className='filler' id={ fillerId } style={{ width: `${ props.percentage }%`}}/>
  )
}

// Progress bar component to display actual progress bar
const ProgressBar = (props) => {
  return (
    <div className='progress-bar'>
      <Filler percentage={ props.percentage } labJobId={ props.labJobId }/>
    </div>
  )
}

/**
 * Component to display progress bar consisting of following states:
 * 1. Submitted
 * 2. Loading
 * 3. In progress (with % of progress)
 * 4. Completed
 * Each state is displayed at run time as test makes a progress from one state to another
 */
class LiveFeedProgressBar extends React.Component {

  state = {
    confirm: {}
  };

  componentDidMount() {
    // For FAR test, need to calculate percentage periodically & re-render
    // NOTE: This is a temporary logic & ideally we should get all the FAR specific values from Scenario
    if (this.props.params.testSuite === AppConstants.SECURITY_SCENARIO_ID) {
      this.interval = setInterval(() => {
        let payload = this.props.params.payload;
        let testStatus = this.props.params.testStatus;

        // When user navigates to other page and comes back to liverun page,
        // Controller sends the status as 'STARTING' which is wrong, and this status
        // will be updated only after 10 minutes of audio chunk is played.
        // Hence, forcing the status to 'inprogress' so that percentage can be displayed.
        if (testStatus === 'STARTING' && payload) {
          testStatus = 'inprogress';
        }

        let percentage = payload.testProgressPercent;
        
        // Force replace percentate with 0.3 instead of 0 so that progres bar can at least show a dot.
        percentage = percentage === 0 ? 0.3 : percentage;

        let inProgressText = this.getInProgressTextFarStabilityUPL(percentage, testStatus.toLowerCase());

        // Set the progress bar value & percentage for FAR / Stability / UPL progress bar
        this.setProgressBarFarStabilityUPL(percentage);
        this.setInProgressTextFarStabilityUPL(inProgressText);
      }, AppConstants.REFRESH_DURATION_FAR_STABILITY);
    }
  }
  
  componentWillUnmount() {
  }

  /**
   * Decides whether to display 'Queued' state or not
   * @param testStatus Current status of test
   * @return Returns true only if test is in progress or is completed.
   */
  shouldDisplayQueuedState = (testStatus) => {
    return AppConstants.QUEUED_STATES.includes(testStatus);
  }

  /**
   * Decides whether to display 'In progress' state or not
   * @param testStatus Current status of test
   * @return Returns true only if test is in progress or is completed.
   */
  shouldDisplayInProgressState = (testStatus) => {
     return AppConstants.IN_PROGRESS_STATES.includes(testStatus) || 
        AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
   * Decides whether to display 'Completed' state or not
   * @param testStatus Current status of test
   * @return Returns true only if test is completed.
   */
  shouldDisplayCompletedState = (testStatus) => {
     return AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
   * Gets the percentage progress to display in progress bar
   * @param testStatus Current status of test
   * @param inProgressPercentage Percentage calculated base on current utterance/ total utterances (for In progress state)
   * @return Percentage value based on specific state & inProgressPercentage
   */
  getPercentage = (testStatus, inProgressPercentage) => {
    if (!AppConstants.IN_PROGRESS_STATES.includes(testStatus) && 
          !AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return 5;
    } else if (AppConstants.IN_PROGRESS_STATES.includes(testStatus)) {
      return 10 + inProgressPercentage * 0.8;
    } else if (AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return 100;
    }
  }

  /**
   * Gets text to display when test is in progress
   * @param testStatus Status of test
   * @param inProgressPercentage Percentage test case has completed
   * @return Text whether test is loading or In progress with percentage
   */
  getInProgressText = (testStatus, inProgressPercentage) => {
    // Check if test Status belongs to RasPi update or Resource download
    if (Util.isRasPiUpdateCompleted(testStatus) && AppConstants.RASPI_STATES_DISPLAY.hasOwnProperty(testStatus)) {
      return AppConstants.RASPI_STATES_DISPLAY[testStatus];
    }
    let isTestQueued = this.shouldDisplayQueuedState(testStatus);
    let isTestInProgress = this.shouldDisplayInProgressState(testStatus);
    let isTestCompleted = this.shouldDisplayCompletedState(testStatus);
    if (isTestCompleted) {
      return 'In Progress (100%)';
    }
    if(isTestQueued) {
      return 'Queued';
    }
    if (isTestInProgress) {
      if (!inProgressPercentage || inProgressPercentage === 0) {
        return 'In Progress';
      } else {
        return 'In Progress (' + inProgressPercentage.toFixed(1) + '%)';
      }
    }
    // Return In Progress by default when testStatus does not match anything from controller
    return 'In Progress';
  }

  /**
   * Gets text to display when FAR / Stability / UPL test is in progress
   * @param inProgressPercentage Percentage to display in text
   * @param testStatus Status of test
   * @return Text whether FAR / Stability / UPL test is loading or In progress with percentage
   */
  getInProgressTextFarStabilityUPL = (inProgressPercentage, testStatus) => {
    // Check if test Status belongs to RasPi update or Resource download
    if (Util.isRasPiUpdateCompleted(testStatus) && AppConstants.RASPI_STATES_DISPLAY.hasOwnProperty(testStatus)) {
      return AppConstants.RASPI_STATES_DISPLAY[testStatus];
    }
    let inProgressText = 'In Progress';
    if (Util.isTestCompleted(testStatus)) {
        inProgressText = 'In Progress (100%)';
        inProgressPercentage = 100;
    } else {
      inProgressText = this.getInProgressText(testStatus.toLowerCase(), inProgressPercentage);
    }
    return inProgressText;
  }

  /**
   * Calculates the percentage for FAR test
   * @param chunkId completed so far
   * @param totalChunks out of total chunks
   * @param testStatus test status
   * @return Returns percentage value
   */
  getFARPercentage = (chunkId, totalChunks, testStatus) => {
    if (Util.isTestCompleted(testStatus)) {
      return 100;
    }
    if (totalChunks && totalChunks > 0) {
       return chunkId / totalChunks * 100;
    }
    return 0;
  }

  /**
   * Sets the text for "In Progress" state for FAR / Stability / UPL test
   * @param inProgressText Text to set 
   */
  setInProgressTextFarStabilityUPL = (inProgressText) => {
     let inProgressStateElement = ReactDOM.findDOMNode(this.farPercentage);
     if (inProgressStateElement) {
        let inProgressStateElementChildNode = inProgressStateElement.childNodes[0];
        if (inProgressStateElementChildNode) {
          let inProgressTextElement = inProgressStateElementChildNode.childNodes[0];
          if (inProgressTextElement) {
            inProgressTextElement.innerHTML = inProgressText;
          }
        }
     }
  }

  /**
   * Sets the progress bar for FAR / Stability / UPL
   * @param percentage Percentage to fill the progress bar upto
   */
  setProgressBarFarStabilityUPL = (percentage) => {
    if (percentage !== undefined) {
      let widthStyle = 'width: ' + percentage.toString() + '%';
      let progressBarFillerId = 'filler_' + this.props.params.labJobId;
      let progressBarFillerElement =  document.getElementById(progressBarFillerId);
      if (progressBarFillerElement) {
        progressBarFillerElement.setAttribute('style', widthStyle);
      }
    }
  }

  /**
   * Gets the informational text to display in pretty format
   * @param label Text message to be displayed
   * @param status Type of message -> Info in this case
   * @returns UI element containing informational message
   */
 
  getMessagePretty = (label, status='info') => {
    const textStatus = 'status-' + status;
    const labelStyle = 'awsui-util-ml-l awsui-util-p-xs awsui-util-' + textStatus;
    return (
      <div className='awsui-util-mt-xxl'>
        <h4 className={ labelStyle }>
          <AWSUI.Icon className='awsui-util-mr-s' name={ textStatus } />
          { label }
        </h4>
      </div>
    );
  }

  /**
   * Gets confirmation pop up dialog
   */
  getConfirmation() {
    return (
      <AWSUI.Modal
        content={ this.state.confirm.message }
        visible={ this.state.confirm.pop }
        header={ this.state.confirm.title }
        expandToFit={ true }
        footer={ <span className='awsui-util-f-r'>
            <AWSUI.Button variant='link' text='Cancel'
              onClick={ () => { this.state.confirm.callback(false); this.setState({ confirm: {} }); } }>
            </AWSUI.Button>
            <AWSUI.Button variant='primary' text='Ok'
              onClick={ () => { this.state.confirm.callback(true); this.setState({ confirm: {} }); } }>
            </AWSUI.Button>
          </span> }
      ></AWSUI.Modal>
    )
  }

  /**
   * Decides whether to display 'Resume' button or not -> True only for manual Trained Mobile scenario in
   * 'PAUSED' state
   * @param jobStatus Status of job
   * @param scenarioType Scenario type
   * @param mobileVoiceTrainingIsManual Whether it's manual or automatic Voice training
   * @returns true or false to decide whether to display 'Resume' button
   */
  shouldDisplayResumeButton = (jobStatus, scenarioType, mobileVoiceTrainingIsManual) => {
    // Display Resume button only for Manual type of Voice training
    if (jobStatus && scenarioType 
          && (mobileVoiceTrainingIsManual || mobileVoiceTrainingIsManual === undefined)) {
      return jobStatus === AppConstants.PAUSED && scenarioType === AppConstants.TRAINED_MOBILE;
    }
    return false;
  }

  /**
   * Decides whether to display Voice training in progress message or not -> True only for automated 
   * Trained Mobile scenario in 'PAUSED' state
   * @param jobStatus Status of job
   * @param scenarioType Scenario type
   * @param mobileVoiceTrainingIsManual Whether it's manual or automatic Voice training
   * @returns true or false to decide whether to display 'Resume' button
   */
  shouldDisplayTrainingInProgressMessage = (jobStatus, scenarioType, mobileVoiceTrainingIsManual) => {
    if (jobStatus && scenarioType && mobileVoiceTrainingIsManual === false) {
      return jobStatus === AppConstants.PAUSED && scenarioType === AppConstants.TRAINED_MOBILE;
    }
    return false;
  }

  /**
   * Resumes the paused job for given jobID
   * @param labJobId ID of the job to be resumed
   */
  resumeLabJob = (labJobId) => {
    resumeJob(labJobId).then(response => {
      if (!response.hasOwnProperty('error')) {
        Util.logToConsole('Test resumed successfully');
      } else {
        this.setState({
          confirm: {
            pop: true,
            title: 'Resume Status',
            message: 'Resuming test case failed. ' + response.error,
            callback: () => {}
          }
        });
      }
    });
  }

  // Renders progress bar
  render() {
    let testStatus = this.props.params.testStatus ? this.props.params.testStatus.toLowerCase() : AppConstants.RETRIEVING;
    let inProgressPercentage = this.props.params.inProgressPercentage ? this.props.params.inProgressPercentage : 0;
    let shouldDisplayResumeButton = this.shouldDisplayResumeButton(testStatus, 
      this.props.params.scenarioType,
      this.props.params.mobileVoiceTrainingIsManual);
    let shouldDisplayTrainingInProgressMessage = this.shouldDisplayTrainingInProgressMessage(testStatus, 
      this.props.params.scenarioType,
      this.props.params.mobileVoiceTrainingIsManual);

    return (
      <div>
        <div className='breadcrumb-style'>
          <AWSUI.BreadcrumbGroup>
            <AWSUI.BreadcrumbItem text='Started'/>
            {
              !Util.isRasPiUpdateInProgress(testStatus) && (
               <AWSUI.BreadcrumbItem ref={ node => { this.farPercentage = node; } }
               text={ this.getInProgressText(testStatus, inProgressPercentage) }/>
              )
            }
            {
              Util.isRasPiUpdateInProgress(testStatus) && (
                Util.getAnimatedRasPiState(AppConstants.RASPI_STATES_DISPLAY[testStatus])
              )
            }
            { this.shouldDisplayCompletedState(testStatus) && (
              <AWSUI.BreadcrumbItem text='Completed'/>
              )}
          </AWSUI.BreadcrumbGroup>
        </div>
        {
          Util.isRasPiResourceSyncUpdateInProgress(testStatus) && (
            <div className='breadcrumb-style'>
                <AutoSyncProgressBar params={{ labJobId: this.props.params.labJobId,
                  testType: this.props.params.testType,
                  scenarioType: this.props.params.scenarioType,
                  testSuite: this.props.params.testSuite,
                  customOptions: this.props.params.customOptions,
                  marketPlace: this.props.params.marketPlace }} />
            </div>
          )
        }
        {
          !Util.isRasPiResourceSyncUpdateInProgress(testStatus) && (
            <ProgressBar percentage={ this.getPercentage(testStatus, inProgressPercentage) }
                      labJobId={ this.props.params.labJobId }/>
          )
        }
        <div align='center'>
          { shouldDisplayResumeButton &&
            this.getMessagePretty(PAUSED_FOR_TRAINING_MANUAL_MESSAGE) 
          }
          <div align='center'>
           { shouldDisplayResumeButton && (
            <AWSUI.Button text='Resume' variant='link' icon='caret-right-filled'
              onClick={ () => { 
               this.setState({
                 confirm: {
                  pop: true,
                  title: 'Confirm Resume',
                  message: 'Do you want to resume the test ?',
                  callback: (action) => {
                    if (action) {
                      this.resumeLabJob(this.props.params.labJobId);
                    }
                  }
                }
              });
            }}/>)
           }
          </div>  
        </div> 
        <div align='center'>
          {
            shouldDisplayTrainingInProgressMessage && (
              this.getMessagePretty(PAUSED_FOR_TRAINING_AUTOMATED_MESSAGE)
            )
          }
        </div>
        <div>
          { this.state.confirm.pop && (
            this.getConfirmation()
          )}
        </div>
      </div>
    )
  }
}

LiveFeedProgressBar.propTypes = {
  params: PropTypes.object.isRequired
};

export default LiveFeedProgressBar;
import * as React from 'react';
import Header from "./components/Header";
import Tabs from "./components/Tabs";

const App = () => {
  return (
    <div className="App">
      <Tabs />
    </div>
  );
};

export default App;

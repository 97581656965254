import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../services/client';
import { API_STATUS, URL_MAPS, FOS_7_MODULES, FOS_8_MODULES } from '../../constants';
import { getUrl } from '../../services/utils';

export const fetchComponents = createAsyncThunk('api/preferences/devicePreferences', async (dtid) => {
 const url =  getUrl(URL_MAPS.DEVICEPREFERENCES) + '/' + dtid;
 const response = await client.get(url);
 return response.data;
})

export const validate = (state, scenarioType, testSuite) => {
  if(state['error'] && state['error']['apiError']){
    return state['error']
  }
  if(!state['ftvPayload'] || !state['ftvPayload']['DeviceDetails'] || !state['ftvPayload']['TestRunDetails']){
    return {'MissingComponents':  "Couldn't fetch test components"};
  }
  const error = {};
  if(scenarioType == "custom" && testSuite == "CTS") {
   if (!state.ctsRunType) {
    error["CtsRunTypeNotSelected"] = "Please select the CTS run type";
    return Object.keys(error).length ? error : null;
   }
   if(state.ctsRunType==="modularized") {
       if (state.moduleInclusionType.length == 0) {
           error["ModuleInclusionTypeNotSelected"] = "Please select modules to be included or excluded"
       }
       if (state.moduleInclusionType.length != 0 && state.includeModules == 0 && state.excludeModules == 0) {
           error["ModuleError"] = "Please enter modules to be executed."
       }
       return Object.keys(error).length ? error : null;
   }
} else if(scenarioType == "custom" && testSuite == "FOS") {
      if(!state.fosTestSuiteModules) {
          error["FosTestSuiteModulesNotSelected"] = "Please select Fos test suite"
      }
      return Object.keys(error).length ? error : null;
  }
}

const getInitialState = () => ({
  status: API_STATUS.INIT,
  components: [],
  selectedComponents: [],
  ftvPayload: {},
  error: null,
  //The below are required for CTS
  ctsRunType: "",
  fosTestSuiteModules: "",
  moduleInclusionType : "",
  //To store the exact input entered by the user
  includeModules: "",
  excludeModules: ""
});

export const componentsSlice = createSlice({
 name: 'componentsList',
 initialState: getInitialState(),
 reducers : {
  updateSelectedComponents: (state, action) => {
    const comp = action.payload;
    state['selectedComponents'] = comp
  },
  updateComponents: (state, action) => {
    const comp = action.payload;
    state['components'] = comp;
  },
  updateCtsRunType: (state, action) => {
    const ctsRunType = action.payload;
    state['ctsRunType'] = ctsRunType;
  },
  updateFosTestSuiteModules: (state, action) => {
    const fosTestSuiteModules = action.payload;
    state['fosTestSuiteModules'] = fosTestSuiteModules;
  },
  updateModuleInclusionType: (state, action) => {
    const moduleInclusionType = action.payload
    state['moduleInclusionType'] = moduleInclusionType
  },
  updateIncludeModules:  (state, action) => {
    const modules = action.payload;
    state['includeModules'] = modules;
  },
  updateExcludeModules:  (state, action) => {
    const modules = action.payload;
    state['excludeModules'] = modules;
  },
  setError: (state, action) => {
    state['error'] = action.payload;
  },
  reset: () => getInitialState()
},
extraReducers:(builder) => {
 builder
   .addCase(fetchComponents.fulfilled, (state, action) => {
     state['status'] = API_STATUS.SUCCESS;
     const data = action.payload
     const customParams = data["TestRunDetails"]["CustomisationParams"]
     state['components'] = customParams;
     state['ftvPayload'] = action.payload
     state['error'] = null;
   })
   .addCase(fetchComponents.pending, (state) => {
     state['status'] = API_STATUS.PENDING;
     state['error'] = null;
   })
   .addCase(fetchComponents.rejected, (state) => {
     state['status'] = API_STATUS.ERROR;
     state['error'] = {};
     state['error']['apiError'] = "Failed to fetch test component list.";
   })
}
})

export const getModulesAsList = (modules) => {
    if(modules.length == 0){
      return []
    }
    modules = modules.replace(/["]/g, '')
    modules = modules.replace(/[']/g, '')
    modules = modules.replace(/\s/g,'')
    var modulesList = modules.split(",")
    return modulesList;
}
export const { updateSelectedComponents,
               updateComponents,
               updateCtsRunType,
               updateFosTestSuiteModules,
               updateModuleInclusionType,
               updateIncludeModules,
               updateExcludeModules,
               setError,
               reset } = componentsSlice.actions;
 
export const componentSelector = (state) => state.componentsList;
 
export default componentsSlice.reducer
import { FormSection, ColumnLayout, Tiles, Select, Multiselect, RadioButton, Input, FormField} from '@amzn/awsui-components-react';
import React, {useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { scenarioSliceSelector, SCENARIO_TYPE, scenarioCategoryErrorSelector } from '../../../../stores/newRun/acm/scenarioSelectionSlice';
import {
  testMappingSelector
} from "../../../../stores/newRun/mappingSlice";
import { fetchComponents, componentSelector, updateSelectedComponents, updateComponents, updateFosTestSuiteModules, updateModuleInclusionType, updateIncludeModules, updateExcludeModules, setError, updateCtsRunType } from '../../../../stores/newRun/componentSlice';
import { setScenarioType } from "../../../../stores/newRun/acm/scenarioSelectionSlice";
import { testInitSelector } from './../../../../stores/newRun/testInitSlice';
import { labConfigSelector } from "../../../../stores/newRun/acm/labConfigSlice";
import {FOS_TEST_SUITE_OPTIONS} from "../../../../constants";

export default () => {
  const {testSuite} = useSelector(testInitSelector);
  const scenarioType = useSelector(scenarioSliceSelector);
  const errors = useSelector(scenarioCategoryErrorSelector);
  const dispatch = useDispatch();
  const handleCustomization = (e) => {
    dispatch(setScenarioType(e.detail.value));
  }
  const [missingComponentsErr, setMissingComponentsErr] = React.useState("")
  const { mapping } = useSelector(testMappingSelector);
  let dtid;
  if (!mapping || mapping.length === 0) {
    const { systemLabInfo } = useSelector(labConfigSelector);
    dtid = systemLabInfo.dtid;
  } else {
    const device = mapping.filter((items)=>items["configType"] == "ftvdut");
    dtid = device[0]["dtid"]
  }

  useEffect(() => {
    dispatch(fetchComponents(dtid)).then((components) => {
      if(!components || !components.payload || !components.payload.TestRunDetails ||  !components.payload.DeviceDetails){
        setMissingComponentsErr("Could not fetch test components.")
        return
      }
      if(testSuite && testSuite.displayName == "CTS") {
        dispatch(updateComponents({"@CTS-Standard" : "value"}));
      }
      if(testSuite && testSuite.displayName == "FOS") {
        dispatch(updateComponents({"@FOS-Standard" : "value"}));
      }
    }
    ).catch((e) => {
      setMissingComponentsErr("Could not fetch test components.")
    });  
  }, []);
  
  const items = [
    {
      "label": <FormattedMessage id="CUSTOM" />,
      "description": <FormattedMessage id="RUN_CUSTOMIZED_TESTS" />,
      "value": SCENARIO_TYPE.CUSTOM,
      "disabled": (testSuite && (testSuite.displayName == "CTS" || testSuite.displayName == "FOS")) ? false : true
    },
    {
      "label": <FormattedMessage id="STANDARD" />,
      "description": <FormattedMessage id="RUN_STANDARD_TESTS" />,
      "value": SCENARIO_TYPE.STANDARD
    }
  ]

  return <FormSection header={<FormattedMessage id="SCENARIO_SELECTION" />}>
    <ColumnLayout>
      <Tiles value={scenarioType} onChange={(handleCustomization)} items={items}></Tiles>
    </ColumnLayout>
    {
      missingComponentsErr && <p className='red-color-text'>{missingComponentsErr}</p>
    }
    {
      scenarioType === SCENARIO_TYPE.CUSTOM ? <CategoryScenarioMap errors={errors} testSuite={testSuite} /> : null
    }
  </FormSection>
}

const CTSRunTypeFragment = ({ ctsRunType, handleCtsRunTypeChange, error, hasError }) => {
    const ctsRunTypeOptions = [
      { id: 'CTS Last Session Rerun', value: 'rerun', label: 'CTS Last Session Rerun' },
      { id: 'CTS Modularized Run', value: 'modularized', label: 'CTS Modularized Run' },
    ];

    return (
        <>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField label={<FormattedMessage id="CTS_RUN_TYPE" />}>
                        <Select
                            options={ctsRunTypeOptions}
                            onChange={handleCtsRunTypeChange}
                            selectedOption={{
                              id: ctsRunType,
                              label: ctsRunType,
                              value: ctsRunType,
                            }}
                        />
                    </FormField>
                </div>
            </ColumnLayout>

            {ctsRunType === 'rerun' &&
                hasError &&
                error["CtsRunTypeNotSelected"] && (
                    <div className="formErrMsg">{error["CtsRunTypeNotSelected"]}</div>
                )}
        </>
    );
};

const CTSModularizedRunFragment = ({
                                       moduleInclusionType,
                                       handleModuleInclusionTypeSelection,
                                       includeModules,
                                       updateIncludemodules,
                                       excludeModules,
                                       updateExcludemodules,
                                       error,
                                       hasError,
                                   }) => {

    return (
        <>
            <FormSection header={<FormattedMessage id="CTS Options" />}>

                <ColumnLayout>
                    <div data-awsui-column-layout-root="true">
                        {(
                            <>
                                {hasError && error["ModuleInclusionTypeNotSelected"] ? (
                                    <div className="formErrMsg">
                                        {error["ModuleInclusionTypeNotSelected"]}
                                    </div>
                                ) : null}
                                {hasError && error["ModuleError"] ? (
                                    <div className="formErrMsg">{error["ModuleError"]}</div>
                                ) : null}
                                <RadioButton
                                    value="includeModules"
                                    label="Include Modules"
                                    onChange={handleModuleInclusionTypeSelection}
                                ></RadioButton>
                                {moduleInclusionType === "includeModules" ||
                                (includeModules !== "" && moduleInclusionType !== "excludeModules") ? (
                                    <Input
                                        label="Include Modules"
                                        value={includeModules}
                                        onChange={updateIncludemodules}
                                    ></Input>
                                ) : null}
                                <RadioButton
                                    value="excludeModules"
                                    label="Exclude Modules"
                                    onChange={handleModuleInclusionTypeSelection}
                                ></RadioButton>
                                {moduleInclusionType === "excludeModules" ||
                                (excludeModules !== "" && moduleInclusionType !== "includeModules") ? (
                                    <Input
                                        label="Exclude Modules"
                                        value={excludeModules}
                                        onChange={updateExcludemodules}
                                    ></Input>
                                ) : null}
                            </>
                        )}
                    </div>
                </ColumnLayout>
            </FormSection>
        </>
    );
};

const FOSSuiteFragment = ({ fosTestSuiteModules, handleFosSuiteSelection, error, hasError }) => (
    <>
        <FormSection header={<FormattedMessage id="FOS Options" />}>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField label={<FormattedMessage id="FOS_TEST_SUITE" />}>
                        <Select
                            options={FOS_TEST_SUITE_OPTIONS}
                            onChange={handleFosSuiteSelection}
                            selectedOption={{
                                id: fosTestSuiteModules,
                                label: fosTestSuiteModules,
                                value: fosTestSuiteModules,
                            }}
                        />
                        {hasError && error["FosTestSuiteModulesNotSelected"] ? (
                            <div className="formErrMsg">{error["FosTestSuiteModulesNotSelected"]}</div>
                        ) : null}
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    </>
);

const CategoryFragment = ({ options, handleChange }) => (
    <>
        <FormSection header={<FormattedMessage id="CATEGORY" />}>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <Multiselect onChange={handleChange} options={options} />
                </div>
            </ColumnLayout>
        </FormSection>
    </>
);

//TODO : Change error message colour to red - https://issues.labcollab.net/browse/PTI-887
const CategoryScenarioMap = ({ errors, testSuite }) => {
    const dispatch = useDispatch();
    const {
        status: componentsFetchStatus,
        components,
        error,
        fosTestSuiteModules,
        moduleInclusionType,
        includeModules,
        excludeModules,
    } = useSelector(componentSelector);
    const componentsList = Object.keys(components);
    const options = componentsList.map((component) => {
        return { value: component, label: component, id: component };
    });
    const handleChange = (e) => {
        const componentsList = e.detail.selectedOptions.map(({ id }) => id);
        dispatch(updateSelectedComponents(componentsList));
    };

    if (testSuite && testSuite.displayName === "CTS") {
        const [ctsRunType, setCtsRunType] = useState('');
        const handleCtsRunTypeChange = (e) => {
            setCtsRunType(e.detail.selectedOption.value);
            dispatch(updateCtsRunType(e.detail.selectedOption.value));
            dispatch(setError(""));
        };

        const handleModuleInclusionTypeSelection = (e) => {
            dispatch(updateModuleInclusionType(e.detail.value));
            if (e.detail.value === "includeModules") {
                dispatch(updateExcludeModules(""));
            } else {
                dispatch(updateIncludeModules(""));
            }
            dispatch(setError(""));
        };

        const updateIncludemodules = (e) => {
            dispatch(updateIncludeModules(e.detail.value));
        };

        const updateExcludemodules = (e) => {
            dispatch(updateExcludeModules(e.detail.value));
        };

        const hasError = error && typeof error === "object";

        return (
            <>
                <FormSection header={<FormattedMessage id="CTS Custom Run Options" />}>
                    <CTSRunTypeFragment
                        ctsRunType={ctsRunType}
                        handleCtsRunTypeChange={handleCtsRunTypeChange}
                        error={error}
                        hasError={hasError}
                    />

                    {ctsRunType === 'modularized' && (
                        <CTSModularizedRunFragment
                            moduleInclusionType={moduleInclusionType}
                            handleModuleInclusionTypeSelection={handleModuleInclusionTypeSelection}
                            includeModules={includeModules}
                            updateIncludemodules={updateIncludemodules}
                            excludeModules={excludeModules}
                            updateExcludemodules={updateExcludemodules}
                            error={error}
                            hasError={hasError}
                        />
                    )}
                </FormSection>
            </>
        );
    } else if (testSuite && testSuite.displayName === "FOS") {
        const handleFosSuiteSelection = (e) => {
            dispatch(updateFosTestSuiteModules(e.detail.selectedOption.value));
            dispatch(setError(""));
        };

        const hasError = error && typeof error === "object";
        return (
            <FOSSuiteFragment
                fosTestSuiteModules={fosTestSuiteModules}
                handleFosSuiteSelection={handleFosSuiteSelection}
                error={error}
                hasError={hasError}
            />
        );
    } else {
        return <CategoryFragment options={options} handleChange={handleChange} />;
    }
};
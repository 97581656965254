import { Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

const logger = ({ getState }) => {
  return next => action => {
    const { type, payload } = action;
    const logState = false;

    if (logState) {
      Util.logToConsole('previous state', getState());
      Util.logToConsole('dispatching action', type, payload);
    }

    const result = next(action);

    if (logState) {
      Util.logToConsole('current state', getState());
    }
    return result;
  };
};

export default logger

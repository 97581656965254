export const API_STATUS = {
  INIT: 'init',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error'
}

export const STAGE_TYPES = {
  ALPHA: 'alpha',
  BETA: 'beta',
  PROD: 'prod',
}

export const BASE_URL = {
  [STAGE_TYPES.ALPHA]: 'https://api.us-west-2.alpha.amazon-aqt.com',
  [STAGE_TYPES.BETA]: 'https://api.us-west-2.beta.amazon-aqt.com',
  [STAGE_TYPES.PROD]: 'https://api.us-west-2.amazon-aqt.com'
};

export const URL_MAPS = {
  LAB: '/api/labs',
  CUSTOMER_ID: '/api/customeridwhitelist',
  DUT: '/api/deviceundertests',
  EXPERIMENT: '/api/experiments',
  SCENARIOS: '/api/scenarios',
  CUSTOMSCENARIOS: '/api/customscenarios?q={"state":"AVAILABLE"}',
  DEVICEPREFERENCES: '/api/preference/deviceType'
}
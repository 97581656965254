import React from 'react';
import { CreateTest } from '@amzn/amazon-devicequalification-ui-oakrun/dist/index.js';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

class OAKRunWrapper extends React.Component {


  render() {
    if (isEmpty(this.props.auth.session)) {
      //This logic gets invoked if the user is directly trying to access the 
      //New Run page through URL. If the session is not there, the page will be redirected. 
      return <Redirect to="/" />
    }
    return (<div className='container'><CreateTest session={this.props.auth.session}
      environment={this.props.auth.environment} /></div>);
  }
}

export default connect(state => state)(OAKRunWrapper);
import axios from 'axios';
import ApiConstants from './Constants/ApiConstants';
import AppConstants from './Constants/AppConstants';
import { withHeader } from './Auth';
import { networkError, getSession, logToConsole } from './Util';
import { aqtStore } from './components/State/Store';

export function fetchJobs(query) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let requestUrl = controllerEndpoint + ApiConstants.JOBS_URL + '?' + AppConstants.RECORDS_LIMIT_200;
  if (query !== AppConstants.EMPTY) {
    requestUrl += '&q=';
  }
  return axios.get(requestUrl + query, withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(jobs => {
      if (jobs.hasOwnProperty('data')) {
        logToConsole('Jobs: ', JSON.stringify(jobs.data));
        return jobs.data;
      } else {
        return {
          error: AppConstants.NOJOBS
        }
      }
    })
    .catch(error => {
      networkError(error, fetchJobs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function generateJobQuery(fromDate, toDate) {
  let query = {'createdAt' : {'gte' : fromDate, "lte" : toDate}};
  return JSON.stringify(query);
}

export function fetchDownloadUrl(jobId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let downloadUrl = ApiConstants.JOBS_URL + '/' + jobId + '/summaryReportDownloadURL'
  return axios.get(controllerEndpoint +
      downloadUrl,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(reportUrl => {
      if (reportUrl.hasOwnProperty('data')) {
        logToConsole('URL: ', JSON.stringify(reportUrl.data));
        return reportUrl.data;
      } else {
        return {
          error: AppConstants.NOURL
        }
      }
    })
    .catch(error => {
      networkError(error, fetchDownloadUrl.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function getAllowlistedScenarios() {
    if (getSession() === AppConstants.SESSIONERR) {
     return new Promise(resolve => {
       resolve({
         error: AppConstants.SESSIONERR
       })
      });
     }
     let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
     return axios.get(controllerEndpoint +
       ApiConstants.ALLOWLISTED_SCENARIOS_URL,
       withHeader(aqtStore.getState().session.idToken.jwtToken))
       .then(response => {
         if (response.hasOwnProperty('data')) {
         logToConsole('Get allowlisted scenarios: ' + JSON.stringify(response.data));
         return { scenarios: response.data ? response.data  : []};
        } else {
         return { error: AppConstants.SERVERERR }
       }
      })
      .catch(error => {
        networkError(error, getAllowlistedScenarios.name);
        return { error: AppConstants.NETWORKERR };
     }); 
   }


   export function fetchLabs() {
    if (getSession() === AppConstants.SESSIONERR) {
      return new Promise(resolve => {
        resolve({
          error: AppConstants.SESSIONERR
        })
      });
    }
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
        ApiConstants.LABS_URL,
        withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(labs => {
        if (labs.hasOwnProperty('data')) {
          logToConsole('Labs: ' + JSON.stringify(labs.data));
          return labs.data;
        } else {
          return {
            error: AppConstants.NOLABS
          }
        }
      })
      .catch(error => {
        networkError(error, fetchLabs.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }

  export function fetchDevicePreferences() {
    if (getSession() === AppConstants.SESSIONERR) {
      return new Promise(resolve => {
        resolve({
          error: AppConstants.SESSIONERR
        })
      });
    }
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    //FIXME : PTI-514 [Remove hard-coded DTID after device context implementation]
    let dtid = "AQRMMA6JEEZF";
    return axios.get(controllerEndpoint + ApiConstants.DEVICE_PREFERENCES_URL+ '/' + dtid, 
        withHeader(aqtStore.getState().session.idToken.jwtToken))
        .then( devicePreferences => {
          return devicePreferences
        }
        ).catch(error => {
          networkError(error, fetchDevicePreferences.name);
          return { error: AppConstants.NETWORKERR };
       })
  }

  export function runActionOnThing(labId, thingId, actionId) {
    if (getSession() === AppConstants.SESSIONERR) {
      return new Promise(resolve => {
        resolve({
          error: AppConstants.SESSIONERR
        })
      });
    }
    if (!thingId) {
      return new Promise(resolve => {
        resolve({
          error: AppConstants.NOTHINGID
        })
      });
    } else {
      let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
      return axios.get(controllerEndpoint +
        ApiConstants.LABS_URL + '/' +
        labId + '/' +
        ApiConstants.THINGS + '/' +
        thingId + '/' +
        AppConstants.rasPiAction[actionId].action,
        withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(response => {
        if (response.hasOwnProperty('data')) {
          logToConsole('Thing Action response: ' + JSON.stringify(response.data));
          return { success: response.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, runActionOnThing.name);
        return {
          error: error.message
        };
      });
    }
  }

  export function getDSNDeviceData(dsn, thingId) {
      if (getSession() === AppConstants.SESSIONERR) {
          return new Promise(resolve => {
              resolve({
                  error: AppConstants.SESSIONERR
              })
          });
      }
      if (!thingId || !dsn) {
          return new Promise(resolve => {
              resolve({
                  error: AppConstants.INVALID_INPUT_PARAMS
              })
          });
      } else {
          let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
          return axios.get(controllerEndpoint +
              ApiConstants.DSN_DEVICE_DATA_URL + '/' +
              dsn + '/' +
              thingId,
              withHeader(aqtStore.getState().session.idToken.jwtToken))
              .then(response => {
                  if (response.hasOwnProperty('data')) {
                      logToConsole('DSN device data: ' + JSON.stringify(response.data));
                      return { success: response.data };
                  } else {
                      return { error: AppConstants.SERVERERR }
                  }
              })
              .catch(error => {
                  networkError(error, getDSNDeviceData.name);
                  return {
                      error: error.message
                  };
              });
      }
  }

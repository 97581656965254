/**
 * Component to display test metrics
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { AppConstants, MusicConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';
import get from 'lodash/get'
/**
 * Component which displays following test metrics:
 * 1. Utterance Count (Played / Total in Test)
 * 2. FRR (Missed/ Spoken Wake Words)
 * 3. RAR (Correct Responses / Requests)
 * 4. Location & Noise Played
 * 5. Start Time
 * 6. Elapsed Time
 * 7. ETA (Estimated Time Remaining)
 */
class TestMetrics extends React.Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  /**
   * Gets current location, noise played for specific scenario type
   * @param scenarioType Type of scenario
   * @return Current location, noise played
   */
  getLocationNoiseForDisplay = (scenarioType) => {
    if (AppConstants.SCENARIO_WITH_TEST_TYPES.includes(scenarioType)
          || scenarioType === AppConstants.CUSTOM_STANDARD) {
      return this.props.params.testStats.locationNoise;
    }
    return AppConstants.NOT_APPLICABLE;
  }

  /**
   * Gets elapsed time element to display based on test case is FAR or non-FAR
   * @return Elapsed time element
   */
  getElapsedTimeDisplay = () => {
    return (
        <div className='awsui-util-label'>{ this.props.params.testStats.elapsedTime }</div>
    );
  }

  /**
   * Gets ETA element to display based on test case is FAR or non-FAR
   * @return ETA element
   */
  getEtaDisplay = () => {
    return (
        <div className='awsui-util-label'>{ this.props.params.testStats.timeRemaining }</div>
    );
  }

  /**
   * Renders test metrics feeds for Acoustic
   */
  renderAcousticTestMetricsFeeds = () => {
    Util.logToConsole('TestDebug - Test stats in TestMetrics = ' + JSON.stringify(this.props.params.testStats));
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let scenarioType = this.props.params.scenarioType;
    let startTime, frr, rar, count, elapsed, eta, location, iar, currentTrainedVoice, latencyValue,
      utteranceCountForIteration;
    let etaDescription = 'ETA ';
    let etaDescriptionAdditional = '(Estimated Time Remaining)';
    if (scenarioType === AppConstants.LATENCY) {
      etaDescription = 'Max. ETA ';
      etaDescriptionAdditional = '(Maximum Estimated Time Remaining)';
    }
    if (isDataAvailable) {
      startTime = Util.getTimeForDisplay(this.props.params.testStats.startTime);
      frr = this.props.params.testStats.frr;
      rar = this.props.params.testStats.rar;
      iar = this.props.params.testStats.iar;
      count = this.props.params.testStats.count;
      elapsed = this.getElapsedTimeDisplay();
      eta = this.getEtaDisplay();
      location = this.getLocationNoiseForDisplay(scenarioType);
      currentTrainedVoice = this.props.params.testStats.currentTrainedVoice;
      latencyValue = this.props.params.testStats.latencyValue;
      if (latencyValue) {
        latencyValue += ' secs';
      }
      let currentUtteranceCount = this.props.params.testStats.currentUtteranceCount;
      if (currentUtteranceCount) {
        utteranceCountForIteration = Util.getUtteranceCountPerIterationWWDD(currentUtteranceCount);
      }
    }

    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
           { Util.renderLabelElement('Utterance Count ','(Played / Total in Test)') }
           { Util.getTextOrLoading(count) }
           { Util.renderLabelElement('Start Time', AppConstants.EMPTY) }
           { Util.getTextOrLoading(startTime) }
           { Util.renderLabelElement('FRR ', '(Missed / Spoken Wake Words)') }
           { Util.getTextOrLoading(frr) }
           { Util.renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
           { Util.getTextOrLoading(elapsed) }
           { Util.renderLabelElement('RAR ', '(Correct Responses / Requests)') }
           { Util.getTextOrLoading(rar) }
           { Util.renderLabelElement(etaDescription, etaDescriptionAdditional) }
           { Util.getTextOrLoading(eta) }
           { scenarioType === AppConstants.TRAINED_MOBILE && (Util.renderLabelElement('IAR ', '(Heard / Spoken Wake Words for IAR)')) }
           { scenarioType === AppConstants.TRAINED_MOBILE && (Util.getTextOrLoading(iar)) }

           { Util.getTextOrLoading(location) }

           { scenarioType === AppConstants.TRAINED_MOBILE && (Util.renderLabelElement('Current Trainer ', AppConstants.EMPTY)) }
           { scenarioType === AppConstants.TRAINED_MOBILE && (Util.getTextOrLoading(currentTrainedVoice)) }
           { Util.isLatencyScenario(scenarioType) && (Util.renderLabelElement('Current Latency Value', AppConstants.EMPTY)) }
           { Util.isLatencyScenario(scenarioType) && (Util.getTextOrLoading(latencyValue)) }
           { scenarioType === AppConstants.LATENCY && Util.renderLabelElement('Utterance Count ', '(Current Round)') }
           { scenarioType === AppConstants.LATENCY && Util.getTextOrLoading(utteranceCountForIteration) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test metrics feeds for Music
   */
  renderMusicTestMetricsFeeds = () => {
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let startTime, count, elapsed, eta;
    if (isDataAvailable) {
      startTime = Util.getTimeForDisplay(this.props.params.testStats.startTime);
      count = this.props.params.testStats.count;
      elapsed = this.getElapsedTimeDisplay();
      eta = this.getEtaDisplay();
    }

    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
           { Util.renderLabelElement('Utterance Count ','(Played / Total in Test)') }
           { Util.getTextOrLoading(count) }
           { Util.renderLabelElement('Start Time', AppConstants.EMPTY) }
           { Util.getTextOrLoading(startTime) }
           { Util.renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
           { Util.getTextOrLoading(elapsed) }
           { Util.renderLabelElement('ETA ', '(Estimated Time Remaining)') }
           { Util.getTextOrLoading(eta) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Checks whether test status is in auto resource sync stage
   * @returns true if auto resource sync is in-progress
   */
  isAutoSyncInProgress = () =>  {
    return this.props.params.testStatus
        && AppConstants.RASPI_RESOURCES_SYNCING == this.props.params.testStatus.toLowerCase();
  }

  /**
   * Checks whether test is in the Queued state
   * @returns true if test is in the Queued state
   */
  isTestQueued = () => {
    return this.props.params.testStatus
        && AppConstants.QUEUED_STATES.includes(this.props.params.testStatus.toLowerCase());
  }

  /**
   * Checks whether test case has completed or canceled
   * @returns true if test case has completed or canceled
   */
  isTestCaseCompleted = () =>  {
    return this.props.params.testStatus
      && AppConstants.COMPLETED_STATES.includes(this.props.params.testStatus.toLowerCase());
  }

  /**
   * Returns message to be displayed when test has completed or canceled
   * @returns Message to display when test case has completed or canceled
   */
  getTestCaseCompletedMessage = () => {
    let testStatus = this.props.params.testStatus;
    let adminUtteranceMessage = AppConstants.ADMIN_UTTERANCE_MESSAGE;
    if (testStatus) {
      let testStatusLower = this.props.params.testStatus.toLowerCase();
      if (testStatusLower === AppConstants.CANCELED) {
        adminUtteranceMessage = AppConstants.TEST_CANCELED_MESSAGE;
      } else {
        adminUtteranceMessage = AppConstants.TEST_COMPLETED_MESSAGE;
      }
    }
    return adminUtteranceMessage;
  }

  // Renders test metrics
  render() {
    if(this.isTestQueued()) {
      return (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
            ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
            Util.getLoadingMessage(AppConstants.JOB_QUEUED_MESSAGE)
      )
    } else if (this.isAutoSyncInProgress()) {
      return (
          this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
              ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
              Util.getLoadingMessage(AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE)
      )
    } else if (Object.keys(this.props.params.testStats).length !== 0
          && this.props.params.testStats.num) {
      if (!this.props.params.testSuite
           || this.props.params.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID
           || this.props.params.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID) {
           // Acoustic case
           return this.renderAcousticTestMetricsFeeds();
        } else if (this.props.params.testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
           // Music case
           return this.renderMusicTestMetricsFeeds();
        }
        // Default: Return Acoustic Test Metrics by default
        return this.renderAcousticTestMetricsFeeds();
    } else if(this.props.params.testStats.measureTypeCustomPayload === AppConstants.ADMIN_MEASURE) {
      // TODO: Refactor the below code; below code renders notification while the admin utt is being executed
      let testStatsFromPayload = this.props.params.testStats;
      let adminUtteranceMessage = AppConstants.ADMIN_UTTERANCE_MESSAGE;
      // If test has completed, display a message & ask user to navigate to results page
      if (this.isTestCaseCompleted()) {
        adminUtteranceMessage = this.getTestCaseCompletedMessage();
      }
      else if (testStatsFromPayload
            && Object.keys(testStatsFromPayload).length > 0) {
        let intentTypeFromPayload = testStatsFromPayload.intentType;
        if (intentTypeFromPayload
              && AppConstants.ADMIN_UTTERANCE_MESSAGE_MAP.hasOwnProperty(intentTypeFromPayload)) {
          adminUtteranceMessage = AppConstants.ADMIN_UTTERANCE_MESSAGE_MAP[intentTypeFromPayload];
        }
      }
      return (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
         ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
         Util.getLoadingMessage(adminUtteranceMessage)
      )
    } else {
      return (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
         ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
          Util.getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
      )
    }
  }
}

TestMetrics.propTypes = {
  params: PropTypes.object.isRequired
};

export default TestMetrics;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react';
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import FormField from "@amzn/awsui-components-react-v3/polaris/form-field";
import { AppConstants, Util } from '@amzn/amazon-devicequalification-ui-components/dist/index.js';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

const styles = theme => ({
  paperControl: theme.mixins.gutters({
    width: 300,
    paddingTop: 30,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  }),
  container: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 10,
  },
  formControl: {
    margin: theme.spacing.unit
  },
  textField: {
    width: 300,
    marginBottom: theme.spacing.unit,
  },
  logo: {
    width: 190,
    height: 90,
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends AuthPiece {

  state = {
    username: AppConstants.EMPTY,
    password: AppConstants.EMPTY,
    error: window.location.href.includes(AppConstants.SESSION_EXPIRED_PARAM)
      ? AppConstants.SESSIONERR : AppConstants.EMPTY,
    signingIn: false,
    showPassword: false
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, error: AppConstants.EMPTY });
    this.handleInputChange(event);
  }

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleForgotPassword = () => {
    this.props.forgotpassword({});
  }

  handleSignIn = (event) => {
    // Authentication
    this.setState({ signingIn: true  });
    Auth.signIn(this.state.username, this.state.password)
        .then(user => {
          let userString = JSON.stringify(user);
          this.setState({ signingIn: false, error: AppConstants.EMPTY });
          this.changeState('signedIn');

          // remove sessionExpired from query params
          this.props.history.push({ search: '' });

          Auth.currentSession()
              .then( session => {
                this.props.authenticate(session);
              })
              .catch( err => {
                var message;
                if (typeof err === 'string') {
                  message = err;
                } else {
                  message = err.message;
                }
                Util.logToConsole('Error establishing session' + message);
                if (userString.includes(NEW_PASSWORD_REQUIRED)) {
                  this.props.authenticate(user);
                } else {
                  this.props.authenticate({});
                }
            });
        })
        .catch(err => {
          var message;
          if (typeof err === 'string') {
            message = err;
          } else {
            message = err.message;
          }
          this.setState({ signingIn: false, error: message });
          this.error(err);
          Util.logToConsole(err);
        });
  }

  render() {
    const { classes } = this.props;
    return(
      <div>
        { this.state.signingIn ? (
          <Grid className={classes.container} align="center">
            <CircularProgress size={50} />
          </Grid>
        ) : (
          <Grid className={classes.container} align="center">
            <Grid item xs={12}>
              <Paper className={classes.paperControl} elevation={4}>
                <Grid item xs={12}>
                  <img className={classes.logo}
                    src="Resources/images/Fire_TV_logo_Transparent.png"
                    alt="Fire TV"
                  />
                </Grid>
                <form>
                  <Grid item xs={12}>
                    <FormControl className={classes.textField}>
                      <InputLabel
                        className="login-input"
                        htmlFor="username"
                      >
                        Username
                      </InputLabel>
                      <Input
                        className="login-input"
                        id="username"
                        key="username"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange('username')}
                        endAdornment={
                          <InputAdornment>
                            <IconButton disabled={true}><AccountCircle color={"secondary"}/> </IconButton>
                          </InputAdornment>
                         }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.textField}>
                      <InputLabel
                        className="login-input"
                        htmlFor="password"
                      >
                        Password
                      </InputLabel>
                      <Input
                        className="login-input"
                        id="password"
                        key="password"
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Show Password"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                         }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                       <Button
                        variant='primary'
                        className='awsui-util-pt-s'
                        formAction="submit"
                        onClick={this.handleSignIn}>
                          Sign In
                        </Button>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <div>
                        <a onClick={this.handleForgotPassword}>
                          Forgot Password?
                        </a>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <FormField errorText = {this.state.error}/>
                    </FormControl>
                  </Grid>
                </form>
              </Paper>
            </Grid>
            <FormControl className={classes.formControl}>
              <div>
                By accessing the Fire TV Qualification Tool, you agree to <a target="_blank" rel="noopener noreferrer"
                  href="https://developer.amazon.com/support/legal/tou">Amazon Developer Services Portal Terms of Use</a>
              </div>
            </FormControl>
          </Grid>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  authenticate: PropTypes.func.isRequired,
  forgotpassword: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(Login));
